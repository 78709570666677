
import { EventBus } from "@/Events/event-bus.js";
export const toasterMixin = {
  data() {
    return {
      errors: {},
    }
  },
  methods: {
    popToast(data) {
      EventBus.$emit("toggle_toast", data);
    },
    pushToast() {
      EventBus.$emit("hide_toast");
    },
    handleArrayErrors(errorArr) {
      window.scrollTo(0, 0);

      this.popToast({
        state: "error",
        title: "Error",
        content: errorArr[0],
        duration: 10000
      })
    },
    handleArrayErrorsAndScrollToQuestion(errorArr) {
      window.scrollTo(0, errorArr[0].pos);

      this.popToast({
        state: "error",
        title: "Error",
        content: errorArr[0].text,
        duration: 10000
      })
    },
    informUser(data) {
      window.scrollTo(0, 0);

      this.popToast({
        state: data.state,
        title: data.title,
        content: data.content,
        duration: 10000,
      })
    },
    handleErrors(error) {
      let errorMessage, title;
      if (error.message && !error.response) {
        title = "Error "
        errorMessage = error.message.split('. ', 1)[0];
      } else {
        if (!!error.response.data.errorMessages) {
          errorMessage = error.response.data.errorMessages[0];
          title = 'Error : ' + error.response.data.details
        }
        if (!error.response.data.errorMessages) {
          errorMessage = error.response.data.details
          title = "Error "
        }
      }

      window.scrollTo(0, 0);

      this.popToast({
        state: "error",
        title: title,
        content: errorMessage,
        duration: 10000
      })

    },
    handleSuccess(successMessage = false) {
      let message;
      if (!!successMessage) {
        message = successMessage;
      } else {
        message = "Form submitted successfully";
      }
      window.scrollTo(0, 0);

      this.popToast({
        state: "success",
        title: 'Success',
        content: message,
        duration: 10000
      });


    }
  }
}
