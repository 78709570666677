import axios from "@/http/http-common";
import Vue from "vue";

class AddActivityBeneficiaryDataService {
  
  async getAllActivitiesBenis() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/activities/benificiaries/all", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/activities/benificiaries/activity", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  async addNewActivityBeneficiary(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/activities/benificiaries/create", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateNewActivityBeneficiarService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/activities/benificiaries/update", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async promoteNewActivityBeneficiary(data, id) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/activities/benificiaries/promote", {...data}, {
      headers: {  
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        id
      }
    });
  }
}
export default new AddActivityBeneficiaryDataService();
