<template>
  <ab-content-container>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#fff"
    ></pulse-loader>
    <div class="max-w-2xl mx-auto" v-else>
      <div>
        <div class="flex flex-col mt-3 title-wrapper">
          <p class="text-xl font-semibold">Current Activities</p>
          <p class="text-xs text-gray-500 italic">
            This is where we manage the current activities
          </p>
        </div>
        <div
          class="flex flex-col gap-3 mt-6"
          v-if="this.newActivities.length > 0"
        >
          <div v-for="(item, index) in newActivities" :key="item.id">
            <div class="single-activity" @click="getActivityRoute(item)">
              <div
                class="flex items-center bg-primary w-full p-3 rounded shadow-xl cursor-pointer hover:bg-secondary"
              >
                <i class="uil uil-file-landscape-alt text-white text-3xl"></i>
                <p class="text-md ml-2 text-white">
                  {{
                    'Activitiy: ' + item.name
                  }}
                  -
                  {{
                    'Status: ' + item.status
                  }}
                  - {{ "Sessions: " + item.sessionsNum }} -
                  {{
                     'Date: '+moment(item.submittedDate).format("YYYY-MM-DD")
                  }}
                </p>
              </div>
              <button
                type="button"
                @click="deleteActivity(item.id, $event, index)"
                class="btn remove"
              >
                <i class="uil uil-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-4">
          <ab-button
            :cancel="true"
            class="mt-1"
            label="Back"
            @click="routeToNavigation()"
          ></ab-button>
        </div>
      </div>
    </div>
  </ab-content-container>
</template>

<script>
import ActivityService from "@/features/Activities/ActivityService";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import AbButton from "@/components/abaad/AbButton/AbButton";
import PulseLoader from "vue-spinner/src/PulseLoader";

import moment from "moment";
export default {
  components: {
    AbContentContainer,
    AbButton,
    PulseLoader,
  },
  data() {
    return {
      moment: moment,
      newActivities: [],
      isLoading: false,
    };
  },
  name: "CurrentActivities",
  created() {
    this.fetchNewActivities();
  },
  methods: {
    routeToNavigation() {
      this.$router.push("/activities");
    },
    fetchNewActivities() {
      this.isLoading = true;
      ActivityService.getActivities()
        .then((response) => {
          
          console.log("🚀 ~ response.data:", response.data);
          response.data.forEach((activity) => {
              if(activity.status.includes('OPEN') || activity.status.includes('NEW'))
              this.newActivities.push(JSON.parse(JSON.stringify(activity)));
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    getActivityRoute(activity) {
      return this.$router.push({
        name: "singleActivity",
        params: { id: activity.id },
      });
    },
    routeToActivityNavigation() {
      // TODO: needs refactor
      return this.$router.push({ path: `/activities` });
    },
    deleteActivity(id, e, index) {
      console.log("🚀 ~ index:", index);
      e.stopPropagation();
      if (window.confirm("are you sure you want to delete this activity ?")) {
        ActivityService.deleteActivity(id).then(() => {
          this.newActivities.splice(index, 1)
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.single-activity {
  display: flex;
  .remove {
    background-color: #dc3545;
    margin-left: 5px;

    i {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.title-wrapper {
    margin-left: -2.5rem;
}
</style>
