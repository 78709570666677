<template>
  <pulse-loader
    v-if="isLoading"
    :loading="isLoading"
    class="loader"
    color="#2399A4"
  ></pulse-loader>
  <div v-else>
    <ab-content-container class="mt-10" ontainer :with-footer="false">
      <div class="max-w-2xl m-auto">
        <div class="title-wrapper">
          <p class=" pull-left font-semibold text-xl">
            {{ title }} Training Form
          </p>
          <p class="pull-left text-xs text-gray-500 italic">
            This is where we add training information
          </p>
        </div>
        <div class="form">
          <div class="mt-6" ref="submittedDate">
            <span class="font-semibold text-sm mb-1 flex">
              {{ submittedDateQuestion }}
            </span>
            <ab-input
              :full="true"
              v-model="training.submittedDate"
              type="date"
            ></ab-input>
          </div>
          <div class="mt-6" ref="trainerName">
            <span class="font-semibold text-sm mb-1 flex">
              {{ trainerNameQuestion }}
            </span>
            <ab-input
              :full="true"
              v-model="training.trainerName"
              type="text"
            ></ab-input>
          </div>
          <div class="mt-6" ref="title">
            <span class="font-semibold text-sm mb-1 flex">
              {{ titleQuestion }}
            </span>
            <ab-input
              :full="true"
              v-model="training.title"
              type="text"
            ></ab-input>
          </div>
          <div class="mt-6" ref="venue">
            <span class="font-semibold text-sm mb-1 flex">
              {{ venueQuestion }}
            </span>
            <ab-input
              :full="true"
              v-model="training.venue"
              type="text"
            ></ab-input>
          </div>
          <div class="mt-6" ref="nbOfParticipants">
            <span class="font-semibold text-sm mb-1 flex">
              {{ participantsQuestion }}
              <i
                class="tooltip-title uil uil-question-circle"
                v-b-tooltip.hover
                title="If the number of participants decreases, the last records will emptied."
              ></i>
            </span>

            <ab-input
              :id="'nbOfParticipants'"
              class="mb-2"
              :full="true"
              :value="5"
              v-model="training.nbOfParticipants"
              type="number"
            >
            </ab-input>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <Participants
          @participantsDataUpdated="updateParticipants"
          v-if="!!training.nbOfParticipants"
          :nbOfParticipants="parseInt(training.nbOfParticipants)"
          :editService="training.participants"
          :questions="participantsQuestions"
        />
      </div>
      <div
        v-if="!isLoading"
        class="flex flex-col w-full justify-center items-center"
      >
        <ab-button
          :tooltip="
            'Submit button has been disabled due to missing participant(s) name and/or phone number.'
          "
          :disabled="isSubmitDisabled"
          class="mt-2"
          :label="!editService ? 'Submit Training' : 'Edit Training'"
          @click="!editService ? addTrainingService() : updateTrainingService()"
        ></ab-button>
        <ab-button
          :cancel="true"
          class="mt-2"
          label="cancel"
          @click="!editService ? routeToTraining() :  routeToSingleTraining()"
        ></ab-button>
      </div>
    </ab-content-container>
  </div>
</template>
<script>
import vue from "vue";
import { EventBus } from "@/Events/event-bus.js";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import AbInput from "../../components/abaad/AbInput/AbInput.vue";
import AbButton from "../../components/abaad/AbButton/AbButton.vue";
import Participants from "./Participants.vue";
import TrainingService from "./TrainingService.js";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { required, requiredIf } from "vuelidate/lib/validators";
import moment from 'moment'
vue.use("required", required);
vue.use("requiredIf", requiredIf);

export default {
  name: "NewTraining",
  mixins: [toasterMixin],
  components: {
    PulseLoader,
    AbContentContainer,
    AbInput,
    Participants,
    AbButton,
  },
  props: {
    editService: {
      type: Object,
    },
  },
  data() {
    return {
      title: "New",
      isSubmitDisabled: false,
      qaData: {},
      trainingId: "",
      errors: [],
      isLoading: false,
      participantsErrors: [],
      participantsBackup: [],
      training: {
        id: "",
        feedbacks: [],
        submittedDate: "",
        trainerName: "",
        title: "",
        venue: "",
        nbOfParticipants: null,
        participants: [],
      },
    };
  },
  validations() {
    return {
      training: {
        submittedDate: {
          required,
        },
        trainerName: {
          required,
        },
        title: {
          required,
        },
        venue: {
          required,
        },
        nbOfParticipants: {
          required,
        },
      },
    };
  },
  created() {
    this.getTrainingFormQA();
    if (!!this.editService) {
      this.populateEditService();
      this.title = "Edit";
    }
  },
  mounted() {
    EventBus.$on("participant_name_missing", (data) => {
      this.participantsErrors.push(data.participantField);
    });
    EventBus.$on("participant_phone_missing", (data) => {
      this.participantsErrors.push(data.participantField);
    });
    EventBus.$on("participant_name_added", (data) => {
      this.participantsErrors.splice(
        this.participantsErrors.indexOf(data.participantField),
        1
      );
    });
    EventBus.$on("participant_phone_added", (data) => {
      this.participantsErrors.splice(
        this.participantsErrors.indexOf(data.participantField),
        1
      );
    });
  },
  updated() {
    if (
      this.participantsErrors.length ||
      this.participantsErrors.length & !!this.editService
    ) {
      this.isSubmitDisabled = true;
    } else {
      this.isSubmitDisabled = false;
    }
  },
  methods: {
    getTrainingFormQA() {
        this.isLoading = true;
      TrainingService.getTrainingQA().then((response) => {
        this.qaData = response.data;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    updateParticipants(data) {
      this.training.participants = data;
      this.participantsBackup = data;
    },
    populateEditService() {
      console.log("🚀 ~ this.editService:", this.editService.feedbacks);
      this.training.submittedDate =  moment(this.editService.submittedDate).format('YYYY-MM-DD');
      this.training.feedbacks = this.editService.feedbacks;
      this.training.title = this.editService.title;
      this.training.venue = this.editService.venue;
      this.training.nbOfParticipants = this.editService.participants.length;
      this.training.participants = this.editService.participants;
      this.training.trainerName = this.editService.trainerName;
      this.$emit(
        "populate_participants_data",
        (this.training.participants, this.trainingId)
      );
      this.training.id = this.editService.id;
      this.applyValidationRulesOnParticipantField()
    },
    applyValidationRulesOnParticipantField() {
      this.training.participants.forEach((tp, index) => {
        if(!tp?.fullName?.length) {
          this.participantsErrors.push(`participant_${index+1}_fullName`)
        }
        if(!tp.phoneNumber?.length || tp.phoneNumber == undefined) {
          this.participantsErrors.push(`participant_${index+1}_phoneNumber`)
        }
      })
    },
    getTrainingData() {
      let trainingData = {};
      trainingData.participants = this.training.participants;
      trainingData.submittedDate = this.training.submittedDate;
      trainingData.title = this.training.title;
      trainingData.venue = this.training.venue;
      trainingData.nbOfParticipants = this.training.nbOfParticipants;
      trainingData.trainerName = this.training.trainerName;
      trainingData.feedbacks = this.training.feedbacks;
      if(!!this.editService) {
      trainingData.id = this.training.id;
        trainingData.startingDate = this.training.submittedDate;
        trainingData.createdBy = this.editService.createdBy
        trainingData.createdDate = this.editService.createdDate
        trainingData.createdByCenter = this.editService.createdByCenter
      }
      return trainingData;
    },
    addTrainingService() {
      if (!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          const trainingData = this.getTrainingData();
          TrainingService.createTraining(trainingData)
            .then((response) => {
              console.log("🚀 ~ response:", response);
              this.$router.push({
                name: "CurrentTraining",
              });
              console.log("🚀 ~ response:", response);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
    updateTrainingService() {
        if (!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          const trainingData = this.getTrainingData();
          TrainingService.updateTraining(trainingData)
            .then((response) => {
              console.log("🚀 ~ response:", response);
              this.$router.push({
                name: "CurrentTraining",
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
    catchErrors() {
      this.errors = [];
      if (!this.training.submittedDate) {
        let elPosition =
          this.$refs?.submittedDate?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Training Date is Required.",
          pos: elPosition,
        });
      }
      if (!this.training.trainerName) {
        let elPosition =
          this.$refs?.trainerName?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Facilitator/Trainer Name is Required.",
          pos: elPosition,
        });
      }
      if (!this.training.title) {
        let elPosition =
          this.$refs?.title?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Training Title is Required.",
          pos: elPosition,
        });
      }
      if (!this.training.venue) {
        let elPosition =
          this.$refs?.venue?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Training Venue is Required.",
          pos: elPosition,
        });
      }
      if (!this.training.nbOfParticipants) {
        let elPosition =
          this.$refs?.nbOfParticipants?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Participants Number is Required.",
          pos: elPosition,
        });
      }
      this.handleArrayErrorsAndScrollToQuestion(this.errors);
    },
    routeToTraining() {
      return this.$router.push({
        path: "/training",
      });
    },
    routeToSingleTraining() {
      return this.$router.push({
       name: 'SingleTraining'
      });
    },        

  },
  computed: {
    submittedDateQuestion() {
      return this.qaData?.startingDate?.question[0];
    },
    trainerNameQuestion() {
      return this.qaData?.trainerName?.question[0];
    },
    titleQuestion() {
      return this.qaData?.title?.question[0];
    },
    venueQuestion() {
      return this.qaData?.venue?.question[0];
    },
    participantsQuestion() {
      return this.qaData?.participants?.question[0];
    },
    participants_fullnameQuestion() {
      return this.qaData?.participants_fullname?.question[0];
    },
    participants_phoneQuestion() {
      return this.qaData?.participants_phone?.question[0];
    },
    participants_emailQuestion() {
      return this.qaData?.participants_email?.question[0];
    },
    participants_titleQuestion() {
      return this.qaData?.participants_title?.question[0];
    },
    participants_orgQuestion() {
      return this.qaData?.participants_org?.question[0];
    },
    participants_genderQuestion() {
      return this.qaData?.participants_gender?.question[0];
    },
    participants_nationalityQuestion() {
      return this.qaData?.participants_nationality?.question[0];
    },
    participantsQuestions() {
      return {
        participants_fullnameQuestion: this.participants_fullnameQuestion,
        participants_phoneQuestion: this.participants_phoneQuestion,
        participants_emailQuestion: this.participants_emailQuestion,
        participants_titleQuestion: this.participants_titleQuestion,
        participants_orgQuestion: this.participants_orgQuestion,
        participants_genderQuestion: this.participants_genderQuestion,
        participants_nationalityQuestion: this.participants_nationalityQuestion,
      };
    },
  },
  watch: {
    participantsErrors() {
      if (
        this.participantsErrors.length ||
        this.participantsErrors.length & !!this.editService
      ) {
        this.isSubmitDisabled = true;
      } else {
        this.isSubmitDisabled = false;
      }
    },
    "training.participants"() {
      console.log(
        "🚀 ~ this.training.participants:",
        this.training.participants
      );
      this.training.participants;
    },
  },
};
</script>
<style lang="scss" scoped>
.tooltip-title {
  padding: 0;
  margin-left: 0.2rem;
}
.tooltip-title:hover {
  cursor: pointer;
}
</style>
