import axios from "@/http/http-common";
import Vue from "vue";

class SafetyServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/safetyservice", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewSafetyService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/create/safety", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateSafetyService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/update/safety", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getLatestSafetyService(params) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/services/read/latest", {
      params: {
        gbvmis: params.gbvmis,
        number: params.number,
        serviceType: params.serviceType
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new SafetyServiceService();
