<template>
  <!-- <ab-content-container> -->
  <div class="mt-12">
    <div class="title-wrapper">
      <p class="font-semibold text-xl">Manage Evaluation Forms</p>

      <p class="pull-left text-xs text-gray-500 italic">
        This is where we manage the training evaluation forms
      </p>
    </div>

    <feedback-evaluation-table
      :columns="feedbackColumns"
      :fields="feedbacksList"
      :training="training"
    ></feedback-evaluation-table>
  </div>
  <!-- </ab-content-container> -->
</template>

<script>
// import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import FeedbackEvaluationTable from "@/features/Training/FeedbackEvaluationTable.vue";
import moment from "moment";
// import TrainingService from "@/features/Training/TrainingService";
import { EventBus } from "@/Events/event-bus.js";
export default {
  name: "FeedbackEvaluationForms",
  components: {
    // AbContentContainer,
    FeedbackEvaluationTable,
  },
  data() {
    return {
      trainingCopy: {},
    };
  },
  props: {
    training: {
      required: false,
      type: Object,
    },
  },
  created() {
   this.trainingCopy = this.training;
    if(!this.training) {
      this.trainingCopy = JSON.parse(localStorage.getItem("CurrentTraining"));
    }
    
    if (this.training && !this.training.feedbacks) {
      this.training.feedbacks = [];
    }
  },
  methods: {
    toggleTableView() {
      EventBus.$emit("table_view_change");
    },
  },
  computed: {
    feedbacksList() {
      return this.trainingCopy.feedbacks;
    },
    feedbackColumns() {
      return [
         {
          key: "delete",
          class: ["justify-center text-center font-medium"],
          isRowHeader: true,
          stickyColumn: true,
          label: "Delete",
        },
         {
          key: "submittedDate",
          class: ["justify-center text-center font-medium"],
          isRowHeader: true,
          stickyColumn: true,
          label: "Date",
          sortable: true,
           formatter: (value) => {
            return moment(value).format("DD MMM YYYY");
          },
        },
        {
          key: "title",
          class: ["justify-center text-center font-medium"],
          isRowHeader: true,
          stickyColumn: true,
          label: "Title",
          sortable: true,
         
        },
        {
          key: "orgName",
          class: ["justify-center text-center font-normal"],
          label: "Participant's Organization",
          sortable: true,
        },
        {
          key: "location",
          class: ["justify-center text-center font-normal"],
          label: "Location",
          sortable: true,
        },
        {
          key: "contentKnowledgeExpand",
          class: ["justify-center text-center font-normal"],
          label: "Content Knowledge Addition",
          sortable: true,
        },
        {
          key: "contentInformationQuality",
          class: ["justify-center text-center font-normal"],
          label: "Gained information expectations",
          sortable: true,
        },
        {
          key: "contentPracticalQuality",
          class: ["justify-center text-center font-normal"],
          label: "Parctical part integration",
          sortable: true,
        },

        {
          key: "interactionQAQuality",
          class: ["justify-center text-center font-normal"],
          label: "Answers satisfaction",
          sorable: true,
        },
        {
          key: "interactionComfortQuality",
          class: ["justify-center text-center font-normal"],
          label: "Comfort opinion sharing",
          sorable: true,
        },
        {
          key: "interactionInformationClear",
          class: ["justify-center text-center font-normal"],
          label: "Shared information clarity",
          sorable: true,
        },
        {

          key: "logisticsTimingQuality",
          class: ["justify-center text-center font-normal"],
          label: "Time dedication",
          sorable: true,
        },
        {
          key: "logisticsPlaceQuality",
          class: ["justify-center text-center font-normal"],
          label: "Place Comfort",
          sorable: true,
        },
        {
          key: "logisticsRefreshmentsQuality",
          class: ["justify-center text-center font-normal"],
          label: "Refreshments Quality",
          sortable: true,
        },
        {
          key: "overallQuality",
          class: ["justify-center text-center font-normal"],
          label: "General satisfaction",
          sortable: true,
        },
        {
          key: "overallRecommendation",
          class: ["justify-center text-center font-normal"],
          label: "Training recomendation",
          sortable: true,
        },

        {
          key: "overallExpectations",
          class: ["justify-center text-center font-normal"],
          label: "Training expectation",
          sortable: true,
        },
        {
          key: "areasImprovement",
          class: ["justify-center text-center font-normal"],
          label: "Knowledge Improvement",
          sortable: true,
        },
        {
          key: "areasInteresting",
          class: ["justify-center text-center font-normal"],
          label: "Interesting areas",
          sortable: true,
        },
        {
          key: "otherTraining",
          class: ["justify-center text-center font-normal"],
          label: "Training topics",
          sortable: true,
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped></style>
