<template>
  <div>
    <pulse-loader
      :loading="isLoading"
      class="loader"
      color="#2399A4"
      v-if="isLoading"
    ></pulse-loader>
    <div v-else>
      <ab-content-container>
        <p class="font-semibold text-xl">Closure Service</p>
        <form @submit.prevent="!editService ? addClosureService() : updateShelterService()"
          class="flex flex-col mx-auto max-w-2xl">
          <div class="mt-6 flex items-center justify-between">
            <div>
              <p class="text-sm font-semibold mb-1">Submit Date</p>
              <ab-input
                type="date"
                v-model="submittedDate"
              ></ab-input>
            </div>
          </div>
          <div class="mt-8">
              <p class="text-sm font-semibold mb-1">
                Please Choose Closure type
              </p>
              <ab-select
                :options="options" v-model="closureType" :selected-option="closureType">
              </ab-select>
          </div>
          <div class="mt-8">
            <div v-if="caseTransferedWithinAbaad">
              <p class="text-sm font-semibold mb-1">Please Choose a Center</p>
              <ab-select :options="centerOptions" v-model="center"></ab-select>
            </div>
          </div>
          <div class="mt-8" v-if="caseTransferedWithinAbaad && !!center">
              <p class="text-sm font-semibold mb-1">
                Please Choose Case Worker
              </p>
              <ab-select
                v-if="caseTransferedWithinAbaad && center"
                :options="caseWorkerOptions"
                v-model="caseWorker"
              ></ab-select>
            </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ summReasonsQuestion }}</p>
            <textarea
              v-model="summReasons"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ clientSafetyQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in clientSafetyAnswers"
              v-model="clientSafety"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ clientSafetyWhyQuestion }}
            </p>
            <textarea
              v-model="clientSafetyWhy"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ clientInformedQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in clientInformedAnswers"
              v-model="clientInformed"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ clientInformedWhyQuestion }}
            </p>
            <textarea
              v-model="clientInformedWhy"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ caseSupReviewedQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in caseSupReviewedAnswers"
              v-model="caseSupReviewed"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ caseSupReviewedWhyQuestion }}
            </p>
            <textarea
              v-model="caseSupReviewedWhy"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>

          <div class="mt-12">
            <div
              class="flex flex-col justify-between items-center"
              v-if="!isLoading"
            >
              <ab-button
                @click="!editService ? addClosureService() : updateShelterService()"
                class="mt-2 submit-btn"
                label="submit"
              ></ab-button>
              <ab-button
                @click="routeToNavigation()"
                class="mt-2"
                :cancel="true"
                label="cancel"
              ></ab-button>
            </div>
          </div>
        </form>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import ClosureServiceService from "./ClosureServiceService";
// import CounselingServiceService from "../Counseling/CounselingServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import AbSelect from "@/components/abaad/AbSelect/AbSelect";
import vue from "vue";
import moment from "moment";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { mapActions, mapGetters } from "vuex";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-closure-service",
  mixins: [utilMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbSelect,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      options: [
        "Closed reached objectives",
        "Closed/Declined CM services",
        "Closed/Lost contact",
        "Closed/Transferred case OUTSIDE ABAAD",
        "Closed/Transferred case WITHIN ABAAD",
      ],
      gbvmis: "",
      summReasons: "",
      clientSafety: "",
      clientSafetyWhy: "",
      closureType: "",
      clientInformed: "",
      clientInformedWhy: "",
      caseSupReviewed: "",
      caseSupReviewedWhy: "",
      submittedDate: "",
      closureServiceQA: {},
      errors: [],
      centerEmployees: [],
      error: "",
      isLoading: false,
      caseTransferedWithinAbaad: false,
      center: null,
      centersData: {},
      centerOptions: [],
      caseWorkerOptions: [],
      centerWorkerId: "",
      caseWorker: "",
      latestCounselingServiceDate: "",
      tomorrowsDate: ""
    };
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: (value) => value < this.tomorrowsDate,
      },
      closureType: {
        required
      },
      center: {
        required: requiredIf(() => this.caseTransferedWithinAbaad && !this.center)
      },
      caseWorker: {
        required: requiredIf(() => this.caseTransferedWithinAbaad && !!this.center && !this.caseWorker)
      },
    }
  },
  created() {
    window.scrollTo(0,0);
    this.getClosureServiceQA();
    this.gbvmis = this.$route.params.id;
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 
  },
  methods: {
    ...mapActions(['fetchAllServices']),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getClosureServiceQA() {
      this.isLoading = true;
      ClosureServiceService.getQaData()
        .then((response) => {
          this.closureServiceQA = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = moment(new Date(this.editService.submittedDate) ).format("YYYY-MM-DD");
      this.summReasons = this.editService.summReasons;
      this.clientSafety = this.editService.clientSafety;
      this.clientSafetyWhy = this.editService.clientSafetyWhy;
      this.closureType = this.editService.closureType;
      this.clientInformed = this.editService.clientInformed;
      this.clientInformedWhy = this.editService.clientInformedWhy;
      this.caseSupReviewed = this.editService.caseSupReviewed;
      this.caseSupReviewedWhy = this.editService.caseSupReviewedWhy;
    },
    addClosureService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
        this.isLoading = true;
        const newClosureService = this.getNewClosureService();
        ClosureServiceService.addNewClosureService(newClosureService)
          .then(() => {
            if(this.isBeneWasTransferedWithinAbaad()) {
              ClosureServiceService.getSubmittedClosure(this.gbvmis).then(() => {
                ClosureServiceService.transferBene({
                  assignedCenter: this.center,
                  assignedUserId: this.getSelectedCenterWorker(),
                  gbvmis: this.gbvmis 
                });
              })
            }
            this.$router.push({ path: `/bene/${this.gbvmis}` });
          })
          .catch((error) => console.log(error))
          .finally(() => (this.isLoading = false));
      } 
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    catchErrors() {
      this.errors = [];
      
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
      if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }
      if(!this.closureType) {
        this.errors.push("Closure Type Field is Required");
      }
      if(this.caseTransferedWithinAbaad && !this.center) {
        this.errors.push("Center Field is Required");
      }
      if(this.caseTransferedWithinAbaad && !!this.center && !this.caseWorker) {
        this.errors.push("Case Worker Field is Required");
      }
      this.handleArrayErrors(this.errors);
    },
    updateShelterService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
      this.isLoading = true;
      ClosureServiceService.updateClosureService(this.getNewClosureService())
        .then(() => {
          this.$router.push({
            path: `/bene/${this.$route.params.id}/management`,
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
      }
    },
    getNewClosureService() {
      let newClosureService = {};
      if (!!this.editService) newClosureService.id = this.editService.id;
      newClosureService.gbvmis = this.gbvmis;
      newClosureService.summReasons = this.summReasons;
      newClosureService.clientSafety = this.clientSafety;
      newClosureService.clientSafetyWhy = this.clientSafetyWhy;
      newClosureService.closureType = this.closureType;
      newClosureService.clientInformed = this.clientInformed;
      newClosureService.clientInformedWhy = this.clientInformedWhy;
      newClosureService.caseSupReviewed = this.caseSupReviewed;
      newClosureService.caseSupReviewedWhy = this.caseSupReviewedWhy;
      newClosureService.submittedDate = new Date(this.submittedDate).toJSON();

      newClosureService.serviceType = "closure";

      return newClosureService;
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    getAbaadCenters() {
      ClosureServiceService.getAbaadCenters().then((result) => {
        this.centersData = result.data;
        for (let center in result.data) {
          this.centerOptions.push(center);
        }
      });
    },
    setCenterWorkers(center) {
      this.centersData[center].forEach((centerWorker) => {
        this.caseWorkerOptions.push(centerWorker.name);
        this.centerWorkerId = centerWorker.userId;
      });
    },
    getSelectedCenterWorker() {
      let center = this.centersData[this.center];
      for(let centerWorker of center) {
        if(centerWorker.name == this.caseWorker) {
          return centerWorker.userId;
        }
      }      
    },
    isBeneWasTransferedWithinAbaad() {
      return this.isTransferInternal && !!this.center && !!this.caseWorker;
    },
  },
  watch: {
    closureType(val) {
      if (val.includes("Closed/Transferred case WITHIN ABAAD")) {
        this.caseTransferedWithinAbaad = true;
        this.getAbaadCenters();
      } else {
        this.caseTransferedWithinAbaad = false;
      }
    },
    center(centerValue) {
      this.caseWorkerOptions = [];
      this.center = centerValue;
      this.setCenterWorkers(centerValue);
    },
  },
  computed: {
    ...mapGetters(['allServices']),
    isTransferInternal() {
      return this.closureType.includes("Closed/Transferred case WITHIN ABAAD");
    },
    summReasonsQuestion() {
      return this.closureServiceQA.summReasons.question[0];
    },
    clientSafetyQuestion() {
      return this.closureServiceQA.clientSafety.question[0];
    },
    clientSafetyAnswers() {
      return this.closureServiceQA.clientSafety.answers;
    },
    clientSafetyWhyQuestion() {
      return this.closureServiceQA.clientSafetyWhy.question[0];
    },
    clientInformedQuestion() {
      return this.closureServiceQA.clientInformed.question[0];
    },
    clientInformedAnswers() {
      return this.closureServiceQA.clientInformed.answers;
    },
    clientInformedWhyQuestion() {
      return this.closureServiceQA.clientInformedWhy.question[0];
    },
    shouldShowClientSafetyWhy() {
      return this.clientSafety.toLowerCase().includes("yes");
    },
    shouldShowClientInformedWhy() {
      return this.clientInformed.toLowerCase().includes("yes");
    },
    shouldShowCaseSupReviewedWhy() {
      return this.caseSupReviewed.toLowerCase().includes("yes");
    },
    caseSupReviewedQuestion() {
      return this.closureServiceQA.caseSupReviewed.question[0];
    },
    caseSupReviewedAnswers() {
      return this.closureServiceQA.caseSupReviewed.answers;
    },
    caseSupReviewedWhyQuestion() {
      return this.closureServiceQA.caseSupReviewedWhy.question[0];
    },
  },
};
</script>
<style lang="scss" scoped>
.date-error {
  input {
    border-color: red !important;
    color: red !important;
  }
  .p {
    color: red;
  }
}

.date-has-error-wrapper {
  border: solid 1px red !important;
  color: red !important;
  background-color: #fbe4e4;
  border-radius: 5px;
  padding: 0 10px;
  margin-top: 12px;
  font-size: 14px;
}
</style>
