<template>
  <create-new-session v-bind:current-activity="currentAct"></create-new-session>
</template>

<script>
import CreateNewSession from './NewSession';

export default {
  components: {
    CreateNewSession,
  },
  data() {
    return {
      currentAct: {},
    }
  },
  name: "SingleSession",
  created() {
    this.getCurrentActIfExists();
  },
  methods: {
    getCurrentActIfExists() {
      const currAct = localStorage.getItem('currentActivity');
      this.currentAct = currAct !== null && currAct !== undefined ? JSON.parse(currAct) : {};
    }
  }
}
</script>

<style scoped>

</style>
