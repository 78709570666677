<template>
 <pulse-loader v-if="isLoading" :loading="isLoading" color="#2399A4" class="loader"></pulse-loader>
	<div v-else>
    <ab-content-container>
			<p class="font-semibold text-xl">External Referal Service</p>
			<form @submit.prevent="!editService ? addExternalReferralService() : updateExternalReferralService()" class="flex flex-col mx-auto max-w-2xl">  		
				<div class="mt-12">
					<p class="text-sm font-semibold">Date of Service Referral</p>
					<ab-input :full="true" type="date" v-model="submittedDate"></ab-input>
				</div>

				<div class="mt-12">
					<p class="font-semibold text-sm mb-1">{{referralServiceTypeQuestion}}</p>
					<ab-radio class="mb-2" :key="index" :label="answer" :value="answer" 
						v-for="(answer, index) in referralServiceTypeAnswers" 
						v-model="referralServiceType">
					</ab-radio>
				</div>
				
				<div class="mt-12" v-if="referralServiceCashFromAbaad">
					<p class="font-semibold text-sm mb-1">{{cashTypeQuestion}}</p>
					<ab-input :full="true" type="text" v-model="cashType"></ab-input>
				</div>

				<div class="mt-12" v-if="referralServiceExternal">
					<p class="font-semibold text-sm mb-1">{{referralExternalTypeQuestion}}</p>
					<ab-input :full="true" type="text" v-model="referralExternalType"></ab-input>
				</div>
			
				<div class="mt-12" v-if="referralServiceOther">
					<p class="font-semibold text-sm mb-1">{{otherServiceQuestion}}</p>
					<ab-input :full="true" type="text" v-model="otherService"></ab-input>
				</div>

				<div class="mt-12" v-if="referralServiceExternal">
					<p class="font-semibold text-sm mb-1">{{referralResponseQuestion}}</p>
					<ab-radio class="mb-2" :key="index" :label="answer" :value="answer" 
						v-for="(answer, index) in referralResponseAnswers" 
						v-model="referralResponse">
					</ab-radio>
				</div>

				<div class="mt-12" v-if="referralResponseDeclined">
					<p class="font-semibold text-sm mb-1">{{referralResponseWhyQuestion}}</p>
					<ab-input :full="true" type="text" v-model="referralResponseWhy"></ab-input>
				</div>
				
				<div class="mt-12" v-if="referralResponseDeclined || referralResponseApproved">
					<p class="font-semibold text-sm mb-1">{{agencyQuestion}}</p>
					<ab-input :full="true" type="text" v-model="agency"></ab-input>
				</div>

				<div class="mt-12" v-if="referralServiceExternal">
					<p class="font-semibold text-sm mb-1">{{referralDetailsQuestion}}</p>
					<ab-input :full="true" type="text" v-model="referralDetails"></ab-input>
				</div>

				<div class="mt-12">
					<div class="flex flex-col justify-center items-center" v-if="!isLoading">
						<ab-button @click="!editService ? addExternalReferralService() : updateExternalReferralService()" class="mt-2 submit-btn" label="submit"></ab-button>
						<ab-button @click="routeToNavigation()" class="mt-2" :cancel="true" label="cancel"></ab-button>
					</div>
				</div>
			</form>
    </ab-content-container>
		</div>
</template>
<script>
import vue from 'vue';
import ExternalReferralServiceService from "./ExternalReferralServiceService";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer.vue";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import moment from "moment";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex'

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);


export default {
	name: "new-external-referral",
	mixins: [utilMixin, toasterMixin],
	components: {
		PulseLoader,
		AbContentContainer,
		AbButton,
		AbInput,
		AbRadio,
	},
	created() {
		window.scrollTo(0,0);
		this.getExternalReferralServiceQA();
		this.gbvmis = this.$route.params.id;
		if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
		this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD");
	},
	props: {
		editService: {
			type: Object,
			required: false,
		}
	},
	data() {
		return {
			submittedDate: "",
			agency: "",
			cashType: "",
			otherService: "",
			referralDate: "",
			referralDetails: "",
			referralExternalType: "",
			referralResponse: "",
			referralResponseWhy: "",
			referralServiceType: "",
			isLoading: false,
			tomorrowsDate: "",
      errors: [],
		}
	},
	validations() {
		return {
			submittedDate: {
				required,
				minValue: value => value >= this.latestCounselingServiceDate,
				maxValue: value => value < this.tomorrowsDate,
			},
			referralServiceType: {
				required
			},
			cashType: {
				required: requiredIf(() => this.referralServiceCashFromAbaad)
			},
			referralExternalType: {
				required: requiredIf(() => this.referralServiceExternal)
			},
			otherService: {
				required: requiredIf(() => this.referralServiceOther)
			},
			referralResponse: {
				required: requiredIf(() => this.referralServiceExternal)
			},
			referralResponseWhy: {
				required: requiredIf(() => this.referralResponseDeclined)
			},
			agency: {
				required: requiredIf(() => this.referralResponseDeclined || this.referralResponseApproved)
			}
		}
	},
	methods: {
    ...mapActions(['fetchAllServices']),
    async getAllServices() {
      this.fetchAllServices(this.gbvmis);
    },
		routeToNavigation() {
			if (this.editService) {
				return this.$router.push({
					path: `/bene/${this.$route.params.id}/management`,
				});
			}	

			return this.$router.push({ path: `/bene/${this.$route.params.id}` });
		},
		getExternalReferralServiceQA() {
			this.isLoading = true;
			ExternalReferralServiceService.getQaData()
			.then((response) => {
				this.externalReferralServiceQA = JSON.parse(JSON.stringify(response.data));
				if (!!this.editService) {
					this.populateEditService();
				}
			})
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
		async populateEditService() {
			this.submittedDate = moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD");
			this.agency = this.editService.agency;
			this.cashType = this.editService.cashType;
			this.otherService = this.editService.otherService;
			this.referralDetails = this.editService.referralDetails;
			this.referralExternalType = this.editService.referralExternalType;
			this.referralResponse = this.editService.referralResponse;
			this.referralResponseWhy = this.editService.referralResponseWhy;
			this.referralServiceType = this.editService.referralServiceType;
		},
		getNewExternalReferralService() {
      let newExternalReferralService = {};
      if (!!this.editService) newExternalReferralService.id = this.editService.id;
			newExternalReferralService.submittedDate = new Date(this.submittedDate).toJSON();
			newExternalReferralService.gbvmis = this.gbvmis;
			newExternalReferralService.agency = this.agency;
			newExternalReferralService.cashType = this.cashType;
			newExternalReferralService.otherService = this.otherService;
			newExternalReferralService.referralDetails = this.referralDetails;
			newExternalReferralService.referralExternalType = this.referralExternalType;
			newExternalReferralService.referralResponse = this.referralResponse;
			newExternalReferralService.referralResponseWhy = this.referralResponseWhy;
			newExternalReferralService.referralServiceType = this.referralServiceType;
			newExternalReferralService.type = 'External Referral'
      return newExternalReferralService;
    },
		addExternalReferralService() {
       this.$v.$touch();
        if (this.$v.$invalid) {
         this.catchErrors();
        } else {
          this.isLoading = true;
          const newExternalReferralService = this.getNewExternalReferralService();
          ExternalReferralServiceService.addNewExternalReferralService(newExternalReferralService)
            .then(() => this.$router.push({ path: `/bene/${this.gbvmis}` }))
            .catch((error) => {
              console.log(error)
            })
            .finally(() => (this.isLoading = false));
        }
    },
		updateExternalReferralService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
        this.isLoading = true;
        ExternalReferralServiceService.updateExternalReferralService(this.getNewExternalReferralService())
          .then((response) => {
            this.$router.push({
              path: `/bene/${this.$route.params.id}/management`,
            });
            this.handleSuccessResponse(response);
          })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
      }
    },
		dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);
      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
		submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    catchErrors() {
		this.errors = [];
		// date
		if(!this.submitDateExists()) {
			this.errors.push("Submitted Date is Required.");
		}
		if(this.submitDateHasError()) {
			this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
		}
		// referral type 
		if(!this.referralServiceType){
			this.errors.push("Type of Referral Service is Required.");
		}
		//cashType
		if(this.referralServiceCashFromAbaad && !this.cashType) {
			this.errors.push("Type of Cash Assistance is Required.");
		}
		if(this.referralServiceOther && !this.referralExternalType) {
			this.errors.push("Referral Type Other is Required.");
		}
		if(this.referralServiceExternal && !this.referralExternalType) {
			this.errors.push("External Referral Type is Rquired.");
		}
		if(this.referralServiceExternal && !this.referralResponse) {
			this.errors.push("Referral Response is Required.");
		}
		if(this.referralResponseDeclined && !this.referralResponseWhy) {
			this.errors.push("Decline Reason is Required.")
		}
		if((this.referralResponseDeclined || this.referralResponseApproved) && !this.agency) {
			this.errors.push("Agency is Required.")
		}
		this.handleArrayErrors(this.errors)
		},
	},
	computed: {
    ...mapGetters(['allServices']),
		referralServiceCashFromAbaad() {
			return !!this.referralServiceType && this.referralServiceType.includes('Cash Assistance By ABAAD');
		},
		referralServiceExternal() {
			return !!this.referralServiceType && this.referralServiceType.includes('External Referral');
		},
		referralResponseDeclined() {
			return !!this.referralResponse && this.referralResponse.includes('Declined/Not Accepted');
		},
		referralResponseApproved() {
			return !!this.referralResponse &&	this.referralResponse.includes('Approved');
		},
		referralServiceOther() {
			return !!this.referralServiceType && this.referralServiceType.includes('Other Service');
		},
		agencyQuestion() {
			return this.externalReferralServiceQA.agency.question[0];
		},
		cashTypeQuestion() {
			return this.externalReferralServiceQA.cashType.question[0];
		},
		otherServiceQuestion() {
			return this.externalReferralServiceQA.otherService.question[0];
		},
		referralDateQuestion() {
			return this.externalReferralServiceQA.referralDate.question[0];
		},
		referralDetailsQuestion() {
			return this.externalReferralServiceQA.referralDetails.question[0];
		},
		referralExternalTypeQuestion() {
			return this.externalReferralServiceQA.referralExternalType.question[0];
		},
		referralResponseQuestion() {
			return this.externalReferralServiceQA.referralResponse.question[0];
		},
		referralResponseAnswers() {
			return this.externalReferralServiceQA.referralResponse.answers;
		},
		referralResponseWhyQuestion() {
			return this.externalReferralServiceQA.referralResponseWhy.question[0];
		},
		referralServiceTypeQuestion() {
			return this.externalReferralServiceQA.referralServiceType.question[0];
		},
		referralServiceTypeAnswers() {
			return this.externalReferralServiceQA.referralServiceType.answers;
		}
	}
}
</script>