<template>
  <pulse-loader
    v-if="isLoading"
    :loading="isLoading"
    class="loader"
    color="#2399A4"
  ></pulse-loader>
  <div class="add-beneficiary-activity" v-else>
    <ab-content-container>
      <div>
        <div v-if="isNewBene()" class="title-wrapper">
          <p class="font-semibold text-xl">
            Add New Beneficiary To Activity
          </p>
          <p class=" text-xs text-gray-500 italic">
            This is where we add a new beneficiary to the activity
          </p>
        </div>
        <div v-if="isEditBene()" class="title-wrapper-edit">
          <div v-if="!isPromoteBene()">
            <p class="font-semibold text-xl">
              Edit Beneficiary Information 
            </p>
            <p class=" text-xs text-gray-500 italic">
              This is where we edit an activity beneficiary information
            </p>
          </div>
        </div>
        <form
          class="flex flex-col mx-auto max-w-2xl"
          @submit.prevent="
            !editService
              ? addNewActivityBeneficiary()
              : updateNewActivityBeneficiary()
          "
        >
          <div class="mt-6" ref="fullName">
            <span class="font-semibold text-sm mb-1 flex">
              {{ fullNameQuestion }}
            </span>
            <ab-input
              :full="true"
              type="text"
              placeholder="e.g: John Sam Doe"
              v-model="beneficiary.fullName"
              :required="false"
            ></ab-input>
          </div>
          <div class="mt-6" ref="phoneNumber">
            <span class="font-semibold text-sm mb-1 flex">
              {{ phoneNumberQuestion }}
            </span>
            <ab-input
              :full="true"
              type="number"
              placeholder="e.g: 71555555"
              v-model="beneficiary.phoneNumber"
              :required="false"
            ></ab-input>
          </div>
          <div class="mt-6" ref="dateBirth">
            <span class="font-semibold text-sm mb-1 flex">
              Date of Birth
            </span>
            <ab-input
              :full="true"
              type="date"
              v-model="beneficiary.dateBirth"
              :required="false"
            ></ab-input>
          </div>
          <div class="mt-12" ref="gender">
            <span class="font-semibold text-sm mb-1 flex">
              {{ genderQuestion }}
            </span>
            <ab-radio
              v-for="(genderAnswer, key) in genderAnswers"
              :key="key"
              :label="genderAnswer"
              :value="genderAnswer"
              class="mb-2"
              :required="false"
              v-model="beneficiary.gender"
            ></ab-radio>
          </div>
          <!-- end gender -->
          <div class="other" v-if="shouldShowGenderOther()">
            <p class="font-semibold text-sm mb-1">Specify</p>
            <ab-input :full="true" v-model="genderOther"></ab-input>
          </div>

          <div class="mt-6 maritalStatus" ref="maritalStatus">
            <span class="font-semibold text-sm mb-1 flex">
              {{ maritalStatusQuestion }}
            </span>
            <ab-select
              :full="true"
              :options="maritalStatusAnswers"
              :required="false"
              v-model="beneficiary.maritalStatus"
              :selected-option="beneficiary.maritalStatus"
            ></ab-select>
          </div>

          <div v-if="!editService" class="mt-6" ref="countryOrigin">
            <span class="font-semibold text-sm mb-1 flex">
              {{ countryOriginQuestion }}
              <div class="flags-wrapper">
                <div class="flags">
                  <div class="flag-wrapper">
                    <img
                      @click="setCommonNationality('Lebanon')"
                      src="../../assets/images/lb.png"
                      alt=""
                    />
                  </div>
                  <div class="flag-wrapper">
                    <img
                      @click="setCommonNationality('Palestine')"
                      src="../../assets/images/ps.png"
                      alt=""
                    />
                  </div>
                  <div class="flag-wrapper">
                    <img
                      @click="setCommonNationality('Syria')"
                      src="../../assets/images/sy.png"
                      alt=""
                    />
                  </div>
                  <div class="flag-wrapper">
                    <img
                      @click="setCommonNationality('Iraq')"
                      src="../../assets/images/ir.png"
                      alt=""
                    />
                  </div>
                  <div class="flag-wrapper">
                    <div  @click="setCommonNationality('PRL')" class="pr-nationality">
                      <span>PRL</span>
                    </div>
                  </div>
                  <div class="flag-wrapper">
                    <div  @click="setCommonNationality('PRS')" class="pr-nationality">
                      <span>PRS</span>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <ab-select
              ref="nationality-select"
              :required="false"
              :full="true"
              :placeHolder="'Select Country'"
              :options="countries"
              v-model="beneficiary.countryOrigin"
            ></ab-select>
          </div>
          <div v-if="!!editService" class="mt-6" ref="countryOrigin">
            <span class="font-semibold text-sm mb-1 flex">
              {{ countryOriginQuestion }}
              <div class="flags-wrapper">
                <div class="flags">
                  <div class="flag-wrapper">
                    <img
                      @click="setCommonNationality('Lebanon')"
                      src="../../assets/images/lb.png"
                      alt=""
                    />
                  </div>
                  <div class="flag-wrapper">
                    <img
                      @click="setCommonNationality('Palestine')"
                      src="../../assets/images/ps.png"
                      alt=""
                    />
                  </div>
                  <div class="flag-wrapper">
                    <img
                      @click="setCommonNationality('Syria')"
                      src="../../assets/images/sy.png"
                      alt=""
                    />
                  </div>
                  <div class="flag-wrapper">
                    <img
                      @click="setCommonNationality('Iraq')"
                      src="../../assets/images/ir.png"
                      alt=""
                    />
                  </div>
                  <div class="flag-wrapper">
                    <div class="pr-nationality">
                      <span @click="setCommonNationality('PRL')">PRL</span>
                    </div>
                    
                  </div>
                  
                  <div class="flag-wrapper">
                    <div class="pr-nationality">
                      <span @click="setCommonNationality('PRS')">PRS</span>
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <ab-select
              :required="false"
              :full="true"
              :options="countries"
              :selectedOption="beneficiary.countryOrigin"
              v-model="beneficiary.countryOrigin"
            ></ab-select>
          </div>
          <div class="mt-6 fill-content w-full" ref="DisabilityValue">
            <span class="font-semibold text-sm mb-1 flex">
              {{ disabilityQuestion }}
            </span>
            <multiselect
              v-model="disability"
              label="name"
              track-by="name"
              placeholder="Add Disability"
              :searchable="false"
              :options="disabilityOptions"
              :multiple="true"
              :taggable="true"
              @select="addDisability"
              @remove="removeDisability"
            >
            </multiselect>
          </div>
          <div class="mt-6 flex items-center w-full" ref="isUNHCRRegistered">
            <label class="switch">
              <span class="disability text-xs text-gray-800">{{
                isUNHCRRegisteredQuestion
              }}</span>
              <input
                type="checkbox"
                class="slide-toggle-checkbox"
                v-model="beneficiary.isUNHCRRegistered"
              />
              <div class="slider round"></div>
            </label>
          </div>
          <div
            class="mt-6 flex items-center w-full"
            v-if="beneficiary.isUNHCRRegistered"
            ref="unhcrNumber"
          >
            <span class="font-semibold text-sm mb-1 flex">
              UNHCR/ID Number
            </span>
            <ab-input
              :full="true"
              label=""
              :disabled="shouldDisableUNHCRNumber"
              v-model="beneficiary.unhcrNumber"
              placeholder="e.g: 99102000345"
            ></ab-input>
          </div>
          <div class="mt-6">
            <span class="font-semibold text-sm mb-1 flex">
              {{ occupancyQuestion }}
            </span>
            <ab-input
              :full="true"
              type="text"
              v-model="beneficiary.occupancy"
              :required="false"
            ></ab-input>
          </div>
          <div class="mt-6" ref="referredFrom">
            <p class="font-semibold text-sm mb-1">{{ referredFromQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in referredFromAnswers"
              :key="index"
              v-model="beneficiary.referredFrom"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
            <!-- end referThirdParty -->
            <div
              class="other"
              v-if="shouldShowreferredFrom()"
              ref="referredFromOther"
            >
              <p class="font-semibold text-sm mb-1">Specify</p>
              <ab-input
                :full="true"
                v-model="beneficiary.referredFromOther"
              ></ab-input>
            </div>
          </div>
          <div class="mt-6" ref="geographicCharacteristics">
            <p class="font-semibold text-sm mb-1">
              {{ geographicCharacteristicsQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in geographicCharacteristicsAnswers"
              :key="index"
              v-model="beneficiary.geographicCharacteristics"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <!-- Submit -->
          <div class="mt-12">
            <div
              class="flex flex-col justify-center items-center"
              v-if="!isLoading"
            ></div>
          </div>
          <!-- end Submit -->
        </form>
        <div
          class="flex flex-col items-center justify-center mx-auto mt-4"
          ref="reportDate"
        >
          <ab-button
            v-if="!noFooterButtons"
            @click="
              !editService
                ? addNewActivityBeneficiary()
                : updateNewActivityBeneficiary()
            "
            class="mt-2 submit-btn"
            label="submit"
          ></ab-button>
          <ab-button
            v-if="!noFooterButtons"
            @click="routeToNavigation()"
            class="mt-2"
            :cancel="true"
            label="cancel"
          ></ab-button>
        </div>
      </div>
    </ab-content-container>
  </div>
</template>

<script>
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer.vue";
import AbInput from "@/components/abaad/AbInput/AbInput.vue";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbSelect from "@/components/abaad/AbSelect/AbSelect";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import Multiselect from "vue-multiselect";
import AbButton from "@/components/abaad/AbButton/AbButton";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import AddBeneficiaryDataService from "./AddActivityBeneficiaryDataService";
import UserService from "@/shared/UserService";
import vue from "vue";

import {
  required,
  minValue,
  maxValue,
  requiredIf,
  minLength,
} from "vuelidate/lib/validators";
import moment from "moment";
import AddActivityBeneficiaryDataService from "./AddActivityBeneficiaryDataService";

vue.use("required", required);
vue.use("minLength", minLength);
vue.use("minValue", minValue);
vue.use("maxValue", maxValue);
vue.use("requiredIf", requiredIf);
import { EventBus } from "@/Events/event-bus.js";
export default {
  name: "AddBeneficiaryComponent",
  components: {
    AbContentContainer,
    PulseLoader,
    AbInput,
    AbSelect,
    AbRadio,
    AbButton,
    Multiselect,
  },
  mixins: [utilMixin, toasterMixin],
  data() {
    return {
      qaData: {},
      errors: [],
      isLoading: false,
      beneficiary: {
        fullName: "",
        phoneNumber: null,
        dateBirth: "",
        gender: "",
        maritalStatus: "",
        countryOrigin: "",
        isUNHCRRegistered: false,
        unhcrNumber: null,
        referredFrom: "",
        referredFromOther: "",
        geographicCharacteristics: "",
        occupancy: "",
        disability: [],
      },
      genderOther: "",
      disability: [],
      countries: [],
      disabilityOptions: [],
    };
  },
  props: {
    noFooterButtons: {
      type: Boolean,
      required: false,
    },
    editService: {
      type: Object,
      required: false,
    },
    isPromote: {
      type: Boolean,
      required: false,
    },
  },
  validations() {
    return {
      beneficiary: {
        fullName: {
          required,
          minLength: minLength(3),
        },
        // ! ask joesph about date range
        dateBirth: {
          required,
        },
        phoneNumber: {
          required,
        },
        gender: {
          required,
        },
        maritalStatus: {
          required,
        },
        countryOrigin: {
          required,
        },

        unhcrNumber: {
          required: requiredIf(
            () =>
              this.beneficiary.isUNHCRRegistered &&
              !this.beneficiary.unhcrNumber
          ),
        },
        referredFrom: {
          required,
        },
        referredFromOther: {
          required: requiredIf(
            () =>
              !this.beneficiary.referredFromOther &&
              this.beneficiary.referredFrom.includes("Other")
          ),
        },
        // !Check with joseph if required
        geographicCharacteristics: {
          required,
        },
        occupancy: {
          required: false,
        },
      },
    };
  },
  created() {
    this.getCountries();
    this.getQaData();
  },
  mounted() {
    window.scrollTo(0, 0);
    EventBus.$on("request_updated_data", () => {
      this.$emit("dataUpdated", this.beneficiary);
      this.$emit("additionDataAdded", this.beneficiary);
      if (!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        }
      }
    });
  },
  methods: {
    isNewBene() {
      return !this.editService && !this.isPromote;
    },
    isEditBene() {
      return !!this.editService;
    },
    isPromoteBene() {
      return !!this.isPromote && !!this.editService
    },
    getQaData() {
      this.isLoading = true;
      AddBeneficiaryDataService.getQaData()
        .then((response) => {
          this.qaData = response.data;
          this.prepareDisabilityArray();

          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async getCountries() {
      this.countries = await UserService.getCountries();
    },
    prepareDisabilityArray() {
      this.disabilityAnswers.forEach((disabilityItem) => {
        this.disabilityOptions.push({ name: disabilityItem });
      });
    },
    addDisability(data) {
      if (!this.beneficiary.disability.includes(data.name))
        this.beneficiary.disability.push(data.name);
      else this.removeDisability(data);
    },
    removeDisability(data) {
      this.beneficiary.disability = this.beneficiary.disability.filter(
        (e) => e !== data.name
      );
    },
    addNewActivityBeneficiary() {
      let newActivityBeneficiary = this.getnewActivityBeneficiary();
      if (!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          AddBeneficiaryDataService.addNewActivityBeneficiary(
            newActivityBeneficiary
          ).then(() => {
            this.$router.push({ path: `/activities` });
          });
        }
      }
    },
    getnewActivityBeneficiary() {
      let newActivityBeneficiary = {};
      if (!!this.editService) newActivityBeneficiary.id = this.editService.id;
      if (this.shouldShowGenderOther()) {
        newActivityBeneficiary.gender = this.genderOther;
      } else {
        newActivityBeneficiary.gender = this.beneficiary.gender;
      }
      if (this.shouldShowreferredFrom()) {
        newActivityBeneficiary.referredFrom = this.beneficiary.referredFromOther;
      } else {
        newActivityBeneficiary.referredFrom = this.beneficiary.referredFrom;
      }
      if (this.beneficiary.isUNHCRRegistered)
        newActivityBeneficiary.unhcrNumber = this.beneficiary.unhcrNumber;

      newActivityBeneficiary.fullName = this.beneficiary.fullName;
      newActivityBeneficiary.phoneNumber = this.beneficiary.phoneNumber;
      newActivityBeneficiary.dateBirth = this.beneficiary.dateBirth;
      newActivityBeneficiary.maritalStatus = this.beneficiary.maritalStatus;
      newActivityBeneficiary.disability = this.beneficiary.disability;
      newActivityBeneficiary.isUNHCRRegistered = this.beneficiary.isUNHCRRegistered;
      newActivityBeneficiary.occupancy = this.beneficiary.occupancy;
      newActivityBeneficiary.geographicCharacteristics = this.beneficiary.geographicCharacteristics;
      newActivityBeneficiary.countryOrigin = this.beneficiary.countryOrigin;
      newActivityBeneficiary.abCode = this.beneficiary.abCode;
      newActivityBeneficiary.createdBy = this.beneficiary.createdBy;
      newActivityBeneficiary.createdByCenter = this.beneficiary.createdByCenter;
      newActivityBeneficiary.createdDate = this.beneficiary.createdDate;

      return newActivityBeneficiary;
    },
    setCommonNationality(value) {
      if (!!this.$refs["nationality-select"])
        this.$refs["nationality-select"]._data.selected = value;
      this.beneficiary.countryOrigin = value;
    },
    populateEditService() {
      this.beneficiary.gender = this.ammendEditCaseOtherInput(
        this.genderAnswers,
        this.editService.gender,
        "Other"
      ).then((result) => {
        this.genderOther = result[1];
        this.beneficiary.gender = result[0];
      });
      this.beneficiary.referredFrom = this.ammendEditCaseOtherInput(
        this.referredFromAnswers,
        this.editService.referredFrom,
        "Other"
      ).then((result) => {
        this.beneficiary.referredFromOther = result[1];
        this.beneficiary.referredFrom = result[0];
      });
      this.beneficiary.isUNHCRRegistered = this.editService.isUNHCRRegistered;
      this.beneficiary.unhcrNumber = this.editService.unhcrNumber;
      this.beneficiary.fullName = this.editService.fullName;
      this.beneficiary.phoneNumber = this.editService.phoneNumber;
      this.beneficiary.dateBirth = moment(
        new Date(this.editService.dateBirth)
      ).format("YYYY-MM-DD");
      this.beneficiary.maritalStatus = this.editService.maritalStatus;
      this.editService.disability.forEach((disability) => {
        this.disability.push({ name: disability });
        this.beneficiary.disability.push(disability);
      });
      this.beneficiary.occupancy = this.editService.occupancy;
      this.beneficiary.geographicCharacteristics = this.editService.geographicCharacteristics;
      this.setCommonNationality(this.editService.countryOrigin);

      // this.beneficiary.countryOrigin = this.editService.countryOrigin;
      this.beneficiary.abCode = this.editService.abCode;
      this.beneficiary.createdBy = this.editService.createdBy;
      this.beneficiary.createdByCenter = this.editService.createdByCenter;
      this.beneficiary.createdDate = this.editService.createdDate;
      console.log("🚀 ~ this.beneficiary:", this.beneficiary);
    },
    updateNewActivityBeneficiary() {
      if (!!this.$v) {
        this.$v.$touch();
        console.log("🚀 ~ this.$v:", this.$v);
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          AddActivityBeneficiaryDataService.updateNewActivityBeneficiarService(
            this.getnewActivityBeneficiary()).then((response) => {
            this.$router.push({path: `${this.beneficiary.abCode}/user-management`})
            console.log("response: ", response);
          });
        }
      }
    },
    shouldShowreferredFrom() {
      if (this.beneficiary.referredFrom.includes("Other")) {
        return true;
      } else {
        return false;
      }
    },
    routeToNavigation() {
      history.go(-1);
    },
    shouldShowGenderOther() {
      return this.beneficiary.gender.includes("Other");
    },
    catchErrors() {
      this.errors = [];

      if (!this.beneficiary.fullName) {
        let elPosition =
          this.$refs?.fullName?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Full Name is Required.",
          pos: elPosition,
        });
      }

      if (this.beneficiary.fullName && this.beneficiary.fullName.length < 3) {
        let elPosition =
          this.$refs?.fullName?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Full Name Must be atleast 3 characters.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.phoneNumber) {
        let elPosition =
          this.$refs?.phoneNumber?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Phone Number is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.dateBirth) {
        let elPosition =
          this.$refs?.dateBirth?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Date Of Birth is Required.",
          pos: elPosition,
        });
      }

      if (!this.beneficiary.gender) {
        let elPosition =
          this.$refs?.gender?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Gender is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.maritalStatus) {
        let elPosition =
          this.$refs?.maritalStatus?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Marital Status is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.countryOrigin) {
        let elPosition =
          this.$refs?.countryOrigin?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "countryOrigin is Required.",
          pos: elPosition,
        });
      }
      if (this.beneficiary.isUNHCRRegistered && !this.beneficiary.unhcrNumber) {
        let elPosition =
          this.$refs?.unhcrNumber?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "UNHCR is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.referredFrom) {
        let elPosition =
          this.$refs?.referredFrom?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "referredFrom is Required.",
          pos: elPosition,
        });
      }
      if (
        !this.beneficiary.referredFromOther &&
        this.beneficiary.referredFrom.includes("Other")
      ) {
        let elPosition =
          this.$refs?.referredFromOther?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "RefferalOther is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.geographicCharacteristics) {
        let elPosition =
          this.$refs?.geographicCharacteristics?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Geographical Characteristics is Required.",
          pos: elPosition,
        });
      }
      console.log("🚀 ~ this.errors:", this.errors);

      this.handleArrayErrorsAndScrollToQuestion(this.errors);
    },
  },
  watch: {
    "beneficiary.countryOrigin"(newVal) {
      console.log("🚀 ~ newVal", newVal);
    },
  },
  computed: {
    maritalStatusQuestion() {
      return this.qaData.maritalStatus.question[0];
    },
    maritalStatusAnswers() {
      return this.qaData.maritalStatus.answers;
    },
    countryOriginQuestion() {
      return this.qaData.countryOrigin.question[0];
    },
    geographicCharacteristicsQuestion() {
      return this.qaData.geographicCharacteristics.question[0];
    },
    geographicCharacteristicsAnswers() {
      return this.qaData.geographicCharacteristics.answers;
    },
    shouldDisableUNHCRNumber() {
      if (!this.beneficiary.isUNHCRRegistered) {
        return true;
      }
      return false;
    },
    genderQuestion() {
      return this.qaData.gender.question[0];
    },
    genderAnswers() {
      return this.qaData.gender.answers;
    },
    fullNameQuestion() {
      return this.qaData.fullName.question[0];
    },
    phoneNumberQuestion() {
      return this.qaData.phoneNumber.question[0];
    },
    disabilityQuestion() {
      return this.qaData.disability.question[0];
    },
    isUNHCRRegisteredQuestion() {
      return this.qaData.unhcrRegistered.question[0];
    },
    isUNHCRRegisteredAnswers() {
      return this.qaData.unhcrRegistered.answers;
    },
    occupancyQuestion() {
      return this.qaData.occupancy.question[0];
    },
    referredFromQuestion() {
      return this.qaData.referredFrom.question[0];
    },
    referredFromAnswers() {
      return this.qaData.referredFrom.answers;
    },
    disabilityAnswers() {
      console.log("🚀 ~ this.qaData.disability", this.qaData.disability);
      return this.qaData.disability.answers;
    },
  },
};
</script>
<style lang="scss" scoped>
.add-beneficiary-activity {
  margin-top: 1rem;
}
.maritalStatus {
  width: 100%;
}
.title-wrapper-edit {
margin-left: -3rem;
    margin-top: -3rem;
}
.title-wrapper {
  margin-left: 14.5rem;
}

.flags-wrapper {
  display: flex;
  margin-left: auto;
  .flags {
    display: flex;
    .flag-wrapper {
      width: 25px;
      margin: 0 5px 0 5px;
      img {
        border-radius: 50%;
        cursor: pointer;
      }
      .pr-nationality {
        border: solid 1px black;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
</style>
