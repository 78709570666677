import axios from "../../http/http-common";
import Vue from "vue";

class TrainingService {
  async getTrainingQA() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/trainings/training", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  async getAllTrainings() {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.get("trainings/all", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  async createTraining(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.post("trainings/create", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  async updateTraining(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.post("/trainings/update", data , {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  async getTrainingFeedbackQA() {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.get("/forms/formoptions/trainings/feedback", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  async getTrainingById(id) {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.post("trainings/id", id ,{
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  async deleteTraining(id) {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.post("trainings/delete", null, {
      params: {
        id: id,
      },
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

}

export default new TrainingService();