<template>
  <ab-content-container>
    <div class="flex flex-col offset-lg-2 mt-10">
      <p class="text-xl font-semibold">Record log</p>
      <p class="text-xs text-gray-500 italic">Logs of activity creation and update</p>
    </div>
    <div class="max-w-2xl mx-auto">
      <div>
        <div class="flex flex-col gap-3 mt-6"
             v-if="this.recordLogs.length > 0">
             <b-table 
              hover
              stacked="sm" 
              responsive  
              :bordered="true" 
              :items="recordLogs" 
              :fields="recordLogsColumns"></b-table>
              <!-- <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Action</td>
                    <td>Subject</td>
                    <td>Information</td>
                    <td>Date & Time</td>
                    <td>GBVMIS</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in recordLogs" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.action }}</td>
                    <td>{{ item.subject }}</td>
                    <td>{{ item.info != null ? `${item.info}` : `` }}</td>
                    <td>{{ getDateAndTime(item.createdDate) }}</td>
                    <td>{{ item.gbvmis != null ? ` ${item.gbvmis}` : `` }}</td>
                  </tr>
                </tbody>
              </table> -->
        </div>
      </div>
    </div>
  </ab-content-container>
</template>

<script>
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import RecordlogService from "./RecordlogService";
// import bootstrap from 'bootstrap'

export default {
  components: {
    AbContentContainer
  },
  data() {
    return {
      recordLogs: [],
      recordLogsColumns: [
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "action",
          sortable: true
        },
        {
          key: "subject",
          label: "Subject",
          sortable: true
        },
        {
          key: "info",
          label: "Information",
          sortable: true
        },
        {
          key: "createdDate",
          label: "Date & Time",
          sortable: true,
          formatter: (value) => {
            return this.getDateAndTime(value);
          }
        },
        {
          key: "gbvmis",
          label: "GBVMIS",
          sortable: true
        } 
      ]
    }
  },
  name: "RecordLog",
  created() {
    this.fetchRecordLogDays(5);
  },
  methods: {
    fetchRecordLogDays(days) {
      RecordlogService.getRecordlogsForLastXDays(days).then(response => {
        this.recordLogs = JSON.parse(JSON.stringify(response.data));
      })
        .catch(error => console.log(error))
        .finally(() => this.isLoading = false);
    },
    getDateAndTime(timestamp) {
      var date = new Date(timestamp);

      return date.getDate()+
          "/" + (date.getMonth() + 1) +
          "/" + date.getFullYear() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds();
    }
  },
}
</script>

<style scoped>
.table > :not(:first-child) {
    border-top: 0 solid currentColor;
}

tr:hover {
  cursor: pointer;
}
</style>
