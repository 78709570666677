<template>
  <div class="main-div">
    <ab-toaster></ab-toaster>
    <aiomis-loader v-if="isLoading" :isLoading="isLoading" class="full-screen login-loader"></aiomis-loader>
    <ab-header v-if="!isLoading"></ab-header>
      <router-view v-if="!isLoading">
      </router-view>
  </div>
</template>

<script>
import AbHeader from "@/components/Header/Header";
import aiomisLoader from '@/components/aiomisLoader/aiomisLoader';
import AbToaster from "@/components/abaad/AbToaster/AbToaster"
import "@/shared/styles/custom.scss"

export default {
  data() {
    return {
      isLoading: true
    }
  },
  name: 'App',
  components: {
    AbHeader,
    aiomisLoader,
    AbToaster
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3500)
  }
}
</script>

<style lang="scss">
.v-spinner.loader {
  display: block;
  margin: 50% auto;
  width: 100px;
}
input[type='date'] {
  background-color: #fff;
}
.login-loader {
  background-color: #8dd4de
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.submit-btn {
  background-color: #8dd4de;
  &:hover {
    background-color: #2399A4 !important;
  }
  &:disabled {
    background-color: #7bb4ba !important;
  }
 
}

</style>
