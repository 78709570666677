import axios from "../../../http/http-common";
import Vue from "vue";

class AttachmentService {
    async getUploadedFiles(gbvmis) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.get('minio/attachments/' + gbvmis, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    async uploadFile(file, gbvmis) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('gbvmis', gbvmis)

        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.post('https://api.abaadmena.online/v1/minio/attachments/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }
}

export default new AttachmentService();
