<template>
  <ab-content-container
    :isTableMode="true"
    :no-padding="true"
    class="table-wrapper shadow-lg"
  >
    <b-table-simple
      id="participants-table"
      class="bg-white table b-table-stacked-xs b-table-stacked-sm"
      hover
      :responsive="true"
      sort-icon-right
      :bordered="true"
      :fields="columns"
    >
      <b-thead>
        <template>
          <tr class="b-header">
            <th
              v-for="column in columns"
              :key="column.key"
              class="border thead text-left font-medium "
            >
              {{ column.label }}
            </th>
          </tr>
        </template>
      </b-thead>
      <b-tbody>
        <training-participant
          :editService="editService[index]"
          :countries="countries"
          v-for="(participant, index) in participants"
          :key="index"
          :participant="{ ...participant, participantIndex: index }"
          @participantdata="updateParticipantsData"
        >
        </training-participant>
      </b-tbody>
    </b-table-simple>
  </ab-content-container>
</template>
<script>
import TrainingParticipant from "./TrainingParticipant.vue";
import AbContentContainer from "../../components/abaad/AbContentContainer/AbContentContainer.vue";
import UserService from "@/shared/UserService";

export default {
  components: { TrainingParticipant, AbContentContainer },
  name: "ParticipantsComponent",
  props: {
    nbOfParticipants: {
      type: Number,
      required: true,
    },
    editService: {
      type: Array,
      required: false,
    },
    questions: {
      type: Object
    }
  },
  data() {
    return {
      isStacked: "lg",
      countries: [],
      nbOfParticipantsModel: null,
      participants: [],
      columns: [
        {
          key: "fullName",
          type: "text",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          stickyColumn: true,
          label: this.questions.participants_fullnameQuestion,
        },
        {
          key: "phoneNumber",
          type: "number",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          stickyColumn: true,
          label: this.questions.participants_phoneQuestion,
        },
        {
          key: "email",
          type: "text",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          stickyColumn: true,
          label:  this.questions.participants_emailQuestion,
        },
        {
          key: "title",
          type: "text",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          stickyColumn: true,
          label: this.questions.participants_titleQuestion,
        },
        {
          key: "organization",
          type: "text",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          stickyColumn: true,
          label:  this.questions.participants_orgQuestion
        },
        {
          key: "gender",
          type: "radio",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          stickyColumn: true,
          label: this.questions.participants_genderQuestion
        },
        {
          key: "nationality",
          type: "select",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          stickyColumn: true,
          label: this.questions.participants_nationalityQuestion
        },
      ],
    };
  },
  created() {
    this.nbOfParticipantsModel = this.nbOfParticipants;
    if (!!this.editService) {
        this.populateParticipants();
    }
     this.participants = Array.apply(
        null,
        Array(this.nbOfParticipantsModel)
      ).map(function() {
        return {};
      });
    this.getCountries();
  },
  mounted() {
    if (this.checkIfMobile()) {
      document.getElementById("participants-table").classList.add("mobile");
    }
    this.$on('populate_participants_data', (participantsData) => {
      this.participants = participantsData;
    })
  },
  updated() {
   
  },

  methods: {
    populateParticipants() {
      this.participants = this.editService;
    },
    async getCountries() {
      this.countries = await UserService.getCountries();
    },
    updateParticipantsData(data) {
      this.participants[data.participantIndex] = data.changedData;
      this.$emit("participantsDataUpdated", this.participants);
    },
    checkIfMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    nbOfParticipants(newVal) {
      this.nbOfParticipantsModel = newVal;
      this.participants = Array.apply(null, Array(newVal)).map(function() {
        return {};
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  overflow: auto;
  width: 80vw;
  margin: auto;
  height: 45vh;
}
.b-header {
  .thead {
    font-size: 16px !important;
  }
}
</style>
