<template>
  <div>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#2399A4"
    ></pulse-loader>
    <div class="pt-2" v-else>
      <div>
        <div class="flex justify-between max-w-2xl mx-auto">
          <p class="font-semibold text-xl">Beneficiary Management</p>
        </div>
      </div>
      <div class="flex justify-center mt-8">
        <table
          class="shadow-lg table table-hover"
          title="Click on the item you wish to see/edit"
        >
          <caption>
            Click on the item you wish to see/edit<br /><br />
          </caption>
          <thead>
            <th
              class="border px-2 py-2 font-medium-bold"
              v-for="column in columns"
              :key="column.id"
            >
              {{ column.name }}
            </th>
          </thead>

          <tbody>
            <tr @click="routeToItem($event, beniEditiableData)">
              <td
                v-for="mem in managementEditableItems"
                :key="mem.id"
                class="table-cell border border-aqua px-2 py-2"
              >
                <span v-if="mem.id != 'dateBirth' && mem.id != 'disability'">{{
                  mem.value
                }}</span>
                <span v-if="mem.id == 'dateBirth'">
                  {{ formatDOB(mem.value) }}
                </span>
                <span v-if="mem.id == 'disability'">
                  {{ destructDisability(mem.value) }}
                </span>
              </td>
              <td
                class="table-cell border px-4 py-2 table-button delete"
                @click="triggerDelete(beniEditiableData.id)"
              >
                <span class="uil-user-minus"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ab-not-found
        v-if="!managementEditableItems.length && !isLoading"
      ></ab-not-found>
    </div>
    <div class="mt-8">
      <div class="flex justify-center items-center" v-if="!isLoading">
        <ab-button
          @click="routeToNavigation()"
          :cancel="true"
          label="Back"
        ></ab-button>
      </div>
    </div>
    <footer class="footer">
      <img
        class="footer-img"
        src="@/assets/images/form-footer.jpeg"
        alt="form-footer"
      />
    </footer>
  </div>
</template>

<script>
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbNotFound from "@/components/abaad/AbNotFound/AbNotFound";
import PulseLoader from "vue-spinner/src/PulseLoader";
import Vue from "vue";
import moment from "moment";
// import axios from "axios";
export default {
  name: "ManageActivityBeneficiary",
  data() {
    return {
      id: "",
      gbvmis: "",
      managementEditableItems: [],
      newMember: {},
      errors: {},
      error: "",
      isLoading: false,
      columns: [
        {
          id: "abCode",
          name: "AbCode",
        },
        {
          id: "countryOrigin",
          name: "Nationality ",
        },
        {
          id: "dateBirth",
          name: "Date Of Birth",
        },
        {
          id: "disability",
          name: "Disability",
        },
        {
          id: "fullName",
          name: "Full Name",
        },
        {
          id: "gender",
          name: "Gender",
        },
        {
          id: "geographicCharacteristics",
          name: "Geographical Characteristics",
        },
        {
          id: "maritalStatus",
          name: "Marital Status",
        },
        {
          id: "phoneNumber",
          name: "Phone Number",
        },
        {
          id: "referredFrom",
          name: "Referred From",
        },
        {
          id: "unhcrNumber",
          name: "UNHCR",
        },
      ],
      beniEditiableData: {},
    };
  },
  components: {
    AbNotFound,
    AbButton,
    PulseLoader,
  },
  created() {
    this.gbvmis = this.$route.params.id;
    this.getManagementEditableItems();
  },
  updated() {
    let scrollPos = parseInt(localStorage.getItem("scroll_position"));
    if (!!scrollPos) {
      window.scrollTo(0, parseInt(scrollPos));
    }

    setTimeout(() => {
      localStorage.removeItem("scroll_position");
    }, 1000);
  },
  methods: {
    routeToNavigation() {
      this.$router.go(-1);
    },
    routeToItem(event, data) {
      console.log("🚀 ~ data", data);
      let firstElm = event.target.firstChild;
      if (!event.target.outerHTML.startsWith("<td")) {
        firstElm = event.target.parentNode.firstChild;
      }
      const firstElmClasses =
        firstElm && firstElm.classList
          ? firstElm.classList.contains("uil-user-minus")
          : false;
      if (firstElmClasses) return;
      this.$router.push({
        name: "EditBeneficiaryComponent",
        params: { editService: data },
      });
    },
    async triggerDelete(id) {
      console.log("🚀 ~ id", id);
      if (confirm("Are you sure you want to delete this entry?")) {
        localStorage.setItem("scroll_position", window.pageYOffset);
        const accessToken = await new Vue().$auth.getTokenSilently();
        let response = await fetch(
          `https://api.abaadmena.online/v1/activities/benificiaries/delete?id=${id}`,
          {
            method: "post",
            headers: new Headers({
              Authorization: `Bearer ${accessToken}`,
            }),
          }
        );
        console.log("🚀 ~ response", response);
      }
    },
    formatDOB(date) {
      return moment(date).format("DD MMM YYYY");
    },
    destructDisability(disabilities) {
      let disabilityText = "";
      disabilities.forEach((disability) => {
        console.log("🚀 ~ disability", disability);
        disabilityText = disabilityText + " " + disability;
      });
      return disabilityText;
    },
    async getManagementEditableItems() {
      let beneId = JSON.parse(localStorage.getItem("ActivityBeneficiary")).id;
      const accessToken = await new Vue().$auth.getTokenSilently();

      let response = await fetch(
        `https://api.abaadmena.online/v1/activities/benificiaries/id/${beneId}`,
        {
          method: "post",
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
          }),
        }
      );
      let resultArray = await response.json();
      this.beniEditiableData = resultArray;
      console.log("🚀 ~ this.beniEditiableData", this.beniEditiableData);
      this.columns.forEach((column) => {
        this.managementEditableItems.push({
          id: column.id,
          value: resultArray[column.id],
        });
      });
    },

    checkIfNotEmpty(obj) {
      return obj !== null && obj !== undefined && !!obj;
    },
    confrimDelete(string) {
      window.confirm(string);
    },
    getTime(timestamp) {
      var date = new Date(timestamp);

      return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.table > :not(:first-child) {
  border-top: 0 solid currentColor;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 180px;
}

.table-cell {
  cursor: pointer;
}

tr:hover {
  .delete {
    color: #fff;
  }
}

.table-button {
  font-size: 24px;
  color: #fff;
  cursor: pointer;

  &.delete {
    background-color: #dc3545;
  }
}
</style>
