<template>
    <div v-if="isLoading" id="aiomis-loader">
      <!-- :class="{ loader: true, fadeout: !isLoading }" -->
      <div class="wrapper">
        <p class="aiomis">AIOMIS</p>
        <pulse-loader :loading="isLoading" class="loader" color="#fff"></pulse-loader>
      </div>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader";

export default {
  name: "aiomisLoader",
  props: ["isLoading"],
  components: {
      PulseLoader
  }
};
</script>

<style lang="scss" scoped>
#aiomis-loader {
  color: white;
  height: 100vh;
  width: 100vw;
  padding: 0 40% 0 40%;

  .wrapper {
    display: block;
    margin:0 auto;
    width: 120px;
    position: relative;
    top: 60%;
  
    .loader, .aiomis {
      font-size: 32px;
      margin: 0 auto;
    }
    
    .loader {
      position: relative;
      left: 15px;
    }
  }
}
@media (max-width: 600px) {
  #aiomis-loader {
    .wrapper {
      margin-left: -25px;
    }
  }
}
.fadeout {
  /* animation-duration: 2s forwards; */
  /* animation-name: fadeout; */
}

@keyframes fadeout {
  from {
    opacity: 1;
    visibility: visible;  
  }
  
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
    visibility: hidden;
  }
  75% {
    opacity: 0.75;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>