<template>
    <div v-if="shouldShow" class="card border border-solid border-gray-400 shadow-sm mt-2 rounded p-5">
        <p class="text-sm font-medium">{{ question }}</p>
        <p class="text-sm font-light mt-2">{{ answer }}</p>
    </div>    
</template>

<script>
export default {
    name: "ab-card",
    props: {
        question: {
            type: String,
            required: false
        },
        answer: {
            required: false
        },
        shouldShow: {
            type: Boolean,
            default: true
        }
    }
}
</script>
<style lang="scss">
    .card.border.border-solid.border-gray-400.shadow-sm.mt-2.rounded.p-5 {
        padding: 1.25rem !important;
    }
</style>