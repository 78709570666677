import axios from "@/http/http-common";
import Vue from "vue";

class MedicalServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/medicalservice", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewMedicalService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/create/medical", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateMedicalService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/update/medical", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new MedicalServiceService();
