export const computedMixin = {
    computed: {
        shouldShowIncidentArea() {
            return this.caseAnswers?.incident?.incidentArea;
        },
        shouldShowIncidentVillage() {
            return this.caseAnswers?.incident?.incidentVillage;
        },
        shouldShowIncidentGovernate() {
            return this.caseAnswers?.incident?.incidentGovernate;
        },
        shouldShowIncidentFrequency() {
            return this.caseAnswers?.incident?.incidentFrequency;
        },
        shouldShowIncidentDetails() {
            return this.caseAnswers?.incident?.incidentDetails;
        },
        shouldShowIncidentAbductionType() {
            return this.caseAnswers?.incident?.incidentAbductionType;
        },
        shouldShowIncidentReportedElsewhere() {
            return this.caseAnswers?.incident?.incidentReportedElsewhere;
        },
        shouldShowSurvivorPreviousGBV() {
            return this.caseAnswers?.survivor?.survivorPreviousGBV;
        },
        shouldShowSurvivorSupportNetwork() {
            return this.caseAnswers?.survivor?.survivorSupportNetwork;
        },
        shouldShowSurvivorRiskAssesmentLevel() {
            return this.caseAnswers?.survivor?.survivorRiskAssesmentLevel;
        },
        shouldShowPerpetratorNumber() {
            return this.caseAnswers?.perpetrator?.number;
        },
        shouldShowPerpetratorRelationship() {
            return this.caseAnswers?.perpetrator?.relationship;
        },
        shouldShowPerpetratorSex() {
            return this.caseAnswers?.perpetrator?.sex;
        },
        shouldShowPerpetratorAge() {
            return this.caseAnswers?.perpetrator?.age;
        },
        shouldShowPerpetratorMainOccupation() {
            return this.caseAnswers?.perpetrator?.mainOccupation;
        },
        shouldShowEmoStateBeginning() {
            return this.caseAnswers?.assesmentPoint?.emotionalStateBeginning;
        },
        shouldShowEmoStateEnd() {
            return this.caseAnswers?.assesmentPoint?.emotionalStateEnding;
        },
        shouldShowRapeHealthInform() {
            return this.caseAnswers?.assesmentPoint?.rapeHealthInform;
        },
        shouldShowConsent() {
            return this.caseAnswers?.assesmentPoint?.consent;
        },
        shouldShowSurvivorMinorInfo() {
            return this.caseAnswers?.survivor?.survivorMinorInfo;
        },
        shouldShowIncidentFirstDate() {
            return this.caseAnswers?.incident?.incidentFirstDate;
        },
        shouldShowIncidentType() {
            return this.caseAnswers?.incident?.incidentType;
        },
        shouldShowIncidentTraditionalPractice() {
            return this.caseAnswers?.incident?.incidentTraditionalPractice;
        },
        shouldShowIncidentTrade() {
            return this.caseAnswers?.incident?.incidentTrade;
        },
        shouldShowIncidentLastDate() {
            return this.caseAnswers?.incident?.incidentLastDate;
        },
        shouldShowIncidentReported() {
            return this.caseAnswers?.incident?.incidentReported;
        }
    }
}
