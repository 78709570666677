<template>
  <div class="mx-auto">
    <label class="flex flex-col" :for="label">
      <div>
        <span class="text-xs text-gray-800">{{label}}</span>
        <span v-if="required" class="text-red-500"> *</span>
      </div>
      <select :id="label" :class="`${half ? 'half-width' : '', oneThird ? 'width-one-third': '', full ? 'width-full': 'w-64 '} border border-solid border-gray-300 bg-white rounded text-gray-700 focus:outline-none text-sm font-light p-2`"
              v-model="selected"
              @change="select()">
        <option v-if="!!placeHolder" value="" selected disabled hidden>{{placeHolder}}</option>
        <option v-for="(option, index) in options"
                :value="option"
                class="option"
                :key="index">{{option.length > 20 && trim ? `${option.substring(0, 20)}...` : option}}</option>
      </select>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: ""
    }
  },
  beforeMount() {
    if(this.selectedOption) {
      this.selected = this.selectedOption;
    }
  },
  mounted() {    
    this.options.forEach((option) => {
      if (this.selectedOption !== null && this.selectedOption !== undefined && this.selectedOption !== '') {
        if (option === this.selectedOption) {
          this.selected = option;
        }
      }
    });
  },
  watch: {
    selectedOption() {
      this.options.forEach((option) => {
        if (this.selectedOption !== null && this.selectedOption !== undefined && this.selectedOption !== '') {
          if (option === this.selectedOption) {
            this.selected = option;
          }
        }
      });
    }
  },
  props: {
     isTableMode: {
      type: Boolean,
      required: false
    },
    options: {
      type: Array,
      default: () => []
    },
    trim: {
      type: Boolean,
      default: false
    },
    half: {
      type: Boolean,
      default: false
    },
    oneThird: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false
    },
    selectedOption: {
      type: String,
      required: false
    },
    placeHolder: {
      type: String,
      required: false
    }
  },
  methods: {
    select() {
      console.log("🚀 ~ this.selected:", this.selected);      
      this.$emit("input", this.selected)
    },
  }
}
</script>

<style lang="scss" scoped>
.half-width {
  width: 200px !important;
}
.width-one-third {
  width: 150px !important;
}
.width-full {
  width: 100%;
}
@media (max-width: 1150px) {
  .width-one-third,input[type='date'].width-one-third.w-64 {
    width: 100% !important;
    margin-left: auto;
   }
}
</style>
