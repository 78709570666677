<template>
  <ab-content-container>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#2399A4"
    ></pulse-loader>
    <div v-else>
      <div>
        <p class="font-semibold text-xl">Financial Service - ECA</p>
        <form class="flex flex-col mx-auto max-w-2xl"
          @submit.prevent="!editService ? addEcaService() : updateEcaService()">
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">Submit Date</p>
            <ab-input
              v-model="submittedDate"
              :full="true"
              type="date"
            ></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ isSupervisorApprovedQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in isSupervisorApprovedAnswers"
              :key="index"
              v-model="isSupervisorApproved"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ supervisorNameQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in supervisorNameAnswers"
              :key="index"
              v-model="supervisorName"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ donorQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in donorAnswers"
              :key="index"
              v-model="donor"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ isAssessmentUploadedQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in isAssessmentUploadedAnswers"
              :key="index"
              v-model="isAssessmentUploaded"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">
              {{ assessmentDateQuestion }}
            </p>
            <ab-input
              v-model="assessmentDate"
              :full="true"
              type="date"
            ></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ monthQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in monthAnswers"
              :key="index"
              v-model="month"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ unhcrNumberQuestion }}</p>
            <textarea
              v-model="unhcrNumber"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ nationalityQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in nationalityAnswers"
              :key="index"
              v-model="nationality"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ ecaRecipientArabicNameQuestion }}
            </p>
            <textarea
              v-model="ecaRecipientArabicName"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ ecaRecipientGenderQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in ecaRecipientGenderAnswers"
              :key="index"
              v-model="ecaRecipientGender"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ ecaRecipientPhoneQuestion }}
            </p>
            <textarea
              v-model="ecaRecipientPhone"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ ecaReceivedTwelveMonthsQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in ecaReceivedTwelveMonthsAnswers"
              :key="index"
              v-model="ecaReceivedTwelveMonths"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div v-if="shouldShowEcaReceivedTwelveMonthsYesInput" class="mt-6">
            <p class="text-sm font-semibold mb-1">
              {{ ecaReceivedDateQuestion }}
            </p>
            <ab-input
              v-model="ecaReceivedDate"
              :full="true"
              type="date"
            ></ab-input>
          </div>
          <div v-if="shouldShowEcaReceivedTwelveMonthsYesInput" class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ ecaReceivedNumberQuestion }}
            </p>
            <ab-input
              v-model="ecaReceivedNumber"
              :full="true"
              type="number"
            ></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ ecaRecipientCashReceivedQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in ecaRecipientCashReceivedAnswers"
              :key="index"
              v-model="ecaRecipientCashReceived"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ protectionIncidentQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in protectionIncidentAnswers"
              :key="index"
              v-model="protectionIncident"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div v-if="shouldShowProtectionOtherInput" class="mt-12">
            <p class="font-semibold text-sm mb-1">Other/Please Specify</p>
            <textarea
              v-model="protectionIncidentOther"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ medicalECAQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in medicalECAAnswers"
              :key="index"
              v-model="medicalECA"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div v-if="shouldShowMedicalECAOtherInput" class="mt-12">
            <p class="font-semibold text-sm mb-1">Other/Please Specify</p>
            <textarea
              v-model="medicalECAOther"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ caseDescriptionQuestion }}
            </p>
            <textarea
              v-model="caseDescription"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ modalityQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in modalityAnswers"
              :key="index"
              v-model="modality"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div v-if="shouldShowModalityOtherInput" class="mt-12">
            <p class="font-semibold text-sm mb-1">Other/Please Specify</p>
            <textarea
              v-model="modalityOther"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div v-if="shouldShowModalityCashInHandInput" class="mt-6">
            <p class="text-sm font-semibold mb-1">
              {{ cashDeliveryDateQuestion }}
            </p>
            <ab-input
              v-model="cashDeliveryDate"
              :full="true"
              type="date"
            ></ab-input>
          </div>
          <div v-if="shouldShowModalityCashFSPInput" class="mt-6">
            <p class="text-sm font-semibold mb-1">
              {{ fspTransferDateQuestion }}
            </p>
            <ab-input
              v-model="fspTransferDate"
              :full="true"
              type="date"
            ></ab-input>
          </div>
          <div v-if="shouldShowModalityCashFSPInput" class="mt-6">
            <p class="text-sm font-semibold mb-1">
              {{ fspWithdrawalDateQuestion }}
            </p>
            <ab-input
              v-model="fspWithdrawalDate"
              :full="true"
              type="date"
            ></ab-input>
          </div>
          <div v-if="shouldShowModalityCashInHandInput" class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ cashUsageQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in cashUsageAnswers"
              :key="index"
              v-model="cashUsage"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div
            v-if="
              shouldShowModalityCashInHandInput ||
              shouldShowModalityCashFSPInput
            "
            class="mt-12"
          >
            <p class="font-semibold text-sm mb-1">{{ ecaValueQuestion }}</p>
            <ab-input v-model="ecaValue" :full="true" type="number"></ab-input>
          </div>
          <div v-if="shouldShowModalityInKindInput" class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ inKindQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in inKindAnswers"
              :key="index"
              v-model="inKind"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div v-if="shouldShowModalityInKindInput" class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ inKindAmountQuestion }}</p>
            <ab-input
              v-model="inKindAmount"
              :full="true"
              type="number"
            ></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ ecaRAISUploadedQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in ecaRAISUploadedAnswers"
              :key="index"
              v-model="ecaRAISUploaded"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ assessmentMethodQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in assessmentMethodAnswers"
              :key="index"
              v-model="assessmentMethod"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>

          <div class="mt-12">
            <div v-if="!isLoading" class="flex flex-col justify-center items-center">
              <ab-button class="mt-2" label="submit"
                         @click="!editService ? addEcaService() : updateEcaService()"></ab-button>
              <ab-button :cancel="true" class="mt-2" label="cancel" @click="routeToNavigation()"></ab-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ab-content-container>
</template>
<script>
import EcaServiceService from "./EcaServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import moment from "moment";
import vue from "vue";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { methodsMixin } from "@/shared/financial/mixins/methodsMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from "vuex";

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-financial-eca-service",
  mixins: [utilMixin, methodsMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
    created() {
    this.getEcaServiceQA();
    this.gbvmis = this.$route.params.id;
    let latestCounselingSession = this.getLatestCounselingSession();
    this.latestCounselingServiceDate = moment(latestCounselingSession.submittedDate).format("YYYY-MM-DD");
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD")
  },
  mounted() {
    window.scrollTo(0,0);
  },
  data() {
    return {
      gbvmis: "",
      isSupervisorApproved: "",
      isSupervisorApprovedResult: false,
      supervisorName: "",
      donor: "",
      isAssessmentUploaded: "",
      isAssessmentUploadedResult: false,
      assessmentDate: null,
      month: "",
      unhcrNumber: "",
      nationality: "",
      ecaRecipientArabicName: "",
      ecaRecipientGender: "",
      ecaRecipientPhone: "",
      ecaReceivedTwelveMonths: "",
      ecaReceivedTwelveMonthsResult: false,
      ecaReceivedDate: null,
      ecaReceivedNumber: "",
      ecaRecipientCashReceived: "",
      protectionIncident: "",
      protectionIncidentOther: "",
      medicalECA: "",
      medicalECAOther: "",
      caseDescription: "",
      modality: "",
      modalityOther: "",
      cashDeliveryDate: null,
      fspTransferDate: null,
      fspWithdrawalDate: null,
      cashUsage: "",
      ecaValue: "",
      inKind: "",
      inKindAmount: "",
      ecaRAISUploaded: "",
      ecaRAISUploadedResult: false,
      assessmentMethod: "",
      submittedDate: "",
      ecaServiceQA: {},
      errors: {},
      error: "",
      isLoading: false,
      latestCounselingSession: {},
      latestCounselingServiceDate: ""
    }
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: (value) => value < this.tomorrowsDate,
      }
    }
  },
  watch: {
    isSupervisorApproved(val) {
      if (val.toLowerCase().includes("yes")) {
        this.isSupervisorApprovedResult = true;
        return;
      }
      this.isSupervisorApprovedResult = false;
    },
    isAssessmentUploaded(val) {
      if (val.toLowerCase().includes("yes")) {
        this.isAssessmentUploadedResult = true;
        return;
      }
      this.isAssessmentUploadedResult = false;
    },
    ecaReceivedTwelveMonths(val) {
      if (val.toLowerCase().includes("yes")) {
        this.ecaReceivedTwelveMonthsResult = true;
        return;
      }
      this.ecaReceivedTwelveMonthsResult = false;
    },
    ecaRAISUploaded(val) {
      if (val.toLowerCase().includes("yes")) {
        this.ecaRAISUploadedResult = true;
        return;
      }
      this.ecaRAISUploadedResult = false;
    },
    protectionIncident(val) {
      this.resultProtectionIncident = val;
    },
    protectionIncidentOther(val) {
      this.resultProtectionIncident = val;
    },
    medicalECA(val) {
      this.resultMedicalECA = val;
    },
    medicalECAOther(val) {
      this.resultMedicalECA = val;
    },
    modality(val) {
      //There's a change in the modality option, thus we need to reset other fields to avoid data issues in the future
      this.resultModality = val;
      this.resetModalityRelatedFields(val);
    },
    modalityOther(val) {
      this.resultModality = val;
    },
  },
  methods: {
    ...mapActions(["fetchAllServices"]),
    async getLatestCounselingSession() {
      this.fetchAllServices(this.gbvmis);
      
      if(!!this.allServices.counseling && this.allServices.counseling.length) {
        return await this.allServices.counseling[this.allServices.counseling.length - 1];
      }
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getEcaServiceQA() {
      this.isLoading = true;
      EcaServiceService.getQaData()
        .then((response) => {
          this.ecaServiceQA = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = !!this.editService.submittedDate
        ? moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD")
        : null;

      this.isSupervisorApproved = this.editService.isSupervisorApproved
        ? "Yes"
        : "No";
      // this.isSupervisorApproved = this.editService.isSupervisorApproved;
      this.supervisorName = this.editService.supervisorName;
      this.donor = this.editService.donor;
      this.isAssessmentUploaded = this.editService.isAssessmentUploaded
        ? "Yes"
        : "No";
      // this.isAssessmentUploaded = this.editService.isAssessmentUploaded;
      this.assessmentDate = !!this.editService.assessmentDate
        ? moment(new Date(this.editService.assessmentDate)).format("YYYY-MM-DD")
        : null;
      // this.assessmentDate = this.editService.assessmentDate;
      this.month = this.editService.month;
      this.unhcrNumber = this.editService.unhcrNumber;
      this.nationality = this.editService.nationality;
      this.ecaRecipientArabicName = this.editService.ecaRecipientArabicName;
      this.ecaRecipientGender = this.editService.ecaRecipientGender;
      this.ecaRecipientPhone = this.editService.ecaRecipientPhone;
      this.ecaReceivedTwelveMonths = this.editService.ecaReceivedTwelveMonths
        ? "Yes"
        : "No";
      // this.ecaReceivedTwelveMonths = this.editService.ecaReceivedTwelveMonths;
      this.ecaReceivedDate = !!this.editService.ecaReceivedDate
        ? moment(new Date(this.editService.ecaReceivedDate)).format(
            "YYYY-MM-DD"
          )
        : null;
      // this.ecaReceivedDate = this.editService.ecaReceivedDate;
      this.ecaReceivedNumber = this.editService.ecaReceivedNumber;
      this.ecaRecipientCashReceived = this.editService.ecaRecipientCashReceived;
      this.protectionIncident = this.editService.protectionIncident;
      this.medicalECA = this.editService.medicalECA;
      this.caseDescription = this.editService.caseDescription;
      this.modality = this.editService.modality;
      this.cashDeliveryDate = !!this.editService.cashDeliveryDate
        ? moment(new Date(this.editService.cashDeliveryDate)).format(
            "YYYY-MM-DD"
          )
        : null;
      // this.cashDeliveryDate = this.editService.cashDeliveryDate;
      this.fspTransferDate = !!this.editService.fspTransferDate
        ? moment(new Date(this.editService.fspTransferDate)).format(
            "YYYY-MM-DD"
          )
        : null;
      // this.fspTransferDate = this.editService.fspTransferDate;
      this.fspWithdrawalDate = !!this.editService.fspWithdrawalDate
        ? moment(new Date(this.editService.fspWithdrawalDate)).format(
            "YYYY-MM-DD"
          )
        : null;
      // this.fspWithdrawalDate = this.editService.fspWithdrawalDate;
      this.cashUsage = this.editService.cashUsage;
      this.ecaValue = this.editService.ecaValue;
      this.inKind = this.editService.inKind;
      this.inKindAmount = this.editService.inKindAmount;
      this.ecaRAISUploaded = this.editService.ecaRAISUploaded ? "Yes" : "No";
      // this.ecaRAISUploaded = this.editService.ecaRAISUploaded;
      this.assessmentMethod = this.editService.assessmentMethod;

      this.ammendEditCaseOtherInput(this.protectionIncidentAnswers, this.editService.protectionIncident, 'Other/Please Specify')
          .then((result) => {
            this.protectionIncidentOther = result[1];
            this.protectionIncident = result[0];
          });
      this.ammendEditCaseOtherInput(this.medicalECAAnswers, this.editService.medicalECA, 'Other/Please Specify')
          .then((result) => {
            this.medicalECAOther = result[1];
            this.medicalECA = result[0];
          });
      this.ammendEditCaseOtherInput(this.modalityAnswers, this.editService.modality, 'Other/Please Specify')
          .then((result) => {
            this.modalityOther = result[1];
            this.modality = result[0];
          });
    },
    addEcaService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {          
          this.isLoading = true;
          const newEcaService = this.getNewEcaService();
          EcaServiceService.addNewEcaService(newEcaService)
            .then(() => this.$router.push({path: `/bene/${this.gbvmis}`}))
            .catch(error => console.log(error))
            .finally(() => this.isLoading = false);
      }
    },
    updateEcaService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
        this.isLoading = true;
        EcaServiceService.updateEcaService(this.getNewEcaService())
          .then(() => {
            this.$router.push({path: `/bene/${this.$route.params.id}/management`});
          })
          .catch(error => console.log(error))
          .finally(() => this.isLoading = false);
      } 
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    catchErrors() {
      this.errors = [];
      
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
       if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }

      this.handleArrayErrors(this.errors);

    },
    getNewEcaService() {
      let newEcaService = {};
      if (!!this.editService) newEcaService.id = this.editService.id;
      newEcaService.gbvmis = this.gbvmis;

      newEcaService.isSupervisorApproved = this.isSupervisorApprovedResult;
      newEcaService.supervisorName = this.supervisorName;
      newEcaService.donor = this.donor;
      newEcaService.isAssessmentUploaded = this.isAssessmentUploadedResult;
      newEcaService.assessmentDate = this.assessmentDate;
      newEcaService.month = this.month;
      newEcaService.unhcrNumber = this.unhcrNumber;
      newEcaService.nationality = this.nationality;
      newEcaService.ecaRecipientArabicName = this.ecaRecipientArabicName;
      newEcaService.ecaRecipientGender = this.ecaRecipientGender;
      newEcaService.ecaRecipientPhone = this.ecaRecipientPhone;
      newEcaService.ecaReceivedTwelveMonths =
        this.ecaReceivedTwelveMonthsResult;
      newEcaService.ecaReceivedDate = this.ecaReceivedDate;
      newEcaService.ecaReceivedNumber = this.ecaReceivedNumber;
      newEcaService.ecaRecipientCashReceived = this.ecaRecipientCashReceived;
      newEcaService.protectionIncident = this.resultProtectionIncident;
      newEcaService.medicalECA = this.resultMedicalECA;
      newEcaService.caseDescription = this.caseDescription;
      newEcaService.modality = this.resultModality;
      newEcaService.cashDeliveryDate = this.cashDeliveryDate;
      newEcaService.fspTransferDate = this.fspTransferDate;
      newEcaService.fspWithdrawalDate = this.fspWithdrawalDate;
      newEcaService.cashUsage = this.cashUsage;
      newEcaService.ecaValue = this.ecaValue;
      newEcaService.inKind = this.inKind;
      newEcaService.inKindAmount = this.inKindAmount;
      newEcaService.ecaRAISUploaded = this.ecaRAISUploadedResult;
      newEcaService.assessmentMethod = this.assessmentMethod;
      newEcaService.submittedDate = new Date(this.submittedDate).toJSON();
      newEcaService.serviceType = "eca";

      return newEcaService;
    },
    resetModalityRelatedFields(val) {
      // This is required and should be set again after the switch finishes
      // nullifying modalityOther is setting modality itself to null, need to retain modality value
      switch (val) {
        case "In-kind":
          this.cashDeliveryDate = null;
          this.fspTransferDate = null;
          this.fspWithdrawalDate = null;
          this.cashUsage = null;
          this.ecaValue = null;
          // Due to the slight delay in the watcher updating the value, modalityOther nullified is causing modality
          // itself to nullify, even after setting it back after the switch exits
          // weird and needs some investigation, putting a timeout will solve it but it's a tmp solution
          // this.modalityOther = null;
          break;
        case "Cash-in-hand":
          this.fspTransferDate = null;
          this.fspWithdrawalDate = null;
          this.inKind = null;
          this.inKindAmount = null;
          // this.modalityOther = null;
          break;
        case "Cash through FSP":
          this.cashDeliveryDate = null;
          this.cashUsage = null;
          this.inKind = null;
          this.inKindAmount = null;
          // this.modalityOther = null;
          break;
        case "Other/Please Specify":
          this.cashDeliveryDate = null;
          this.fspTransferDate = null;
          this.fspWithdrawalDate = null;
          this.cashUsage = null;
          this.ecaValue = null;
          this.inKind = null;
          this.inKindAmount = null;
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allServices"]),
    getLatestCounselingSessionDate() {
      return moment(this.latestCounselingSession.submittedDate).format("MM/DD/YYYY");
    },
    getTomorrowsDate() {
      return moment().add(1, "days").format("MM/DD/YYYY");
    },
    // QA
    isSupervisorApprovedQuestion() {
      return this.ecaServiceQA.isSupervisorApproved.question[0];
    },
    isSupervisorApprovedAnswers() {
      return this.ecaServiceQA.isSupervisorApproved.answers;
    },
    supervisorNameQuestion() {
      return this.ecaServiceQA.supervisorName.question[0];
    },
    supervisorNameAnswers() {
      return this.ecaServiceQA.supervisorName.answers;
    },
    donorQuestion() {
      return this.ecaServiceQA.donor.question[0];
    },
    donorAnswers() {
      return this.ecaServiceQA.donor.answers;
    },
    isAssessmentUploadedQuestion() {
      return this.ecaServiceQA.isAssessmentUploaded.question[0];
    },
    isAssessmentUploadedAnswers() {
      return this.ecaServiceQA.isAssessmentUploaded.answers;
    },
    assessmentDateQuestion() {
      return this.ecaServiceQA.assessmentDate.question[0];
    },
    assessmentDateAnswers() {
      return this.ecaServiceQA.assessmentDate.answers;
    },
    monthQuestion() {
      return this.ecaServiceQA.month.question[0];
    },
    monthAnswers() {
      return this.ecaServiceQA.month.answers;
    },
    unhcrNumberQuestion() {
      return this.ecaServiceQA.unhcrNumber.question[0];
    },
    unhcrNumberAnswers() {
      return this.ecaServiceQA.unhcrNumber.answers;
    },
    nationalityQuestion() {
      return this.ecaServiceQA.nationality.question[0];
    },
    nationalityAnswers() {
      return this.ecaServiceQA.nationality.answers;
    },
    ecaRecipientArabicNameQuestion() {
      return this.ecaServiceQA.ecaRecipientArabicName.question[0];
    },
    ecaRecipientArabicNameAnswers() {
      return this.ecaServiceQA.ecaRecipientArabicName.answers;
    },
    ecaRecipientGenderQuestion() {
      return this.ecaServiceQA.ecaRecipientGender.question[0];
    },
    ecaRecipientGenderAnswers() {
      return this.ecaServiceQA.ecaRecipientGender.answers;
    },
    ecaRecipientPhoneQuestion() {
      return this.ecaServiceQA.ecaRecipientPhone.question[0];
    },
    ecaRecipientPhoneAnswers() {
      return this.ecaServiceQA.ecaRecipientPhone.answers;
    },
    ecaReceivedTwelveMonthsQuestion() {
      return this.ecaServiceQA.ecaReceivedTwelveMonths.question[0];
    },
    ecaReceivedTwelveMonthsAnswers() {
      return this.ecaServiceQA.ecaReceivedTwelveMonths.answers;
    },
    ecaReceivedDateQuestion() {
      return this.ecaServiceQA.ecaReceivedDate.question[0];
    },
    ecaReceivedDateAnswers() {
      return this.ecaServiceQA.ecaReceivedDate.answers;
    },
    ecaReceivedNumberQuestion() {
      return this.ecaServiceQA.ecaReceivedNumber.question[0];
    },
    ecaReceivedNumberAnswers() {
      return this.ecaServiceQA.ecaReceivedNumber.answers;
    },
    ecaRecipientCashReceivedQuestion() {
      return this.ecaServiceQA.ecaRecipientCashReceived.question[0];
    },
    ecaRecipientCashReceivedAnswers() {
      return this.ecaServiceQA.ecaRecipientCashReceived.answers;
    },
    protectionIncidentQuestion() {
      return this.ecaServiceQA.protectionIncident.question[0];
    },
    protectionIncidentAnswers() {
      return this.ecaServiceQA.protectionIncident.answers;
    },
    medicalECAQuestion() {
      return this.ecaServiceQA.medicalECA.question[0];
    },
    medicalECAAnswers() {
      return this.ecaServiceQA.medicalECA.answers;
    },
    caseDescriptionQuestion() {
      return this.ecaServiceQA.caseDescription.question[0];
    },
    caseDescriptionAnswers() {
      return this.ecaServiceQA.caseDescription.answers;
    },
    modalityQuestion() {
      return this.ecaServiceQA.modality.question[0];
    },
    modalityAnswers() {
      return this.ecaServiceQA.modality.answers;
    },
    cashDeliveryDateQuestion() {
      return this.ecaServiceQA.cashDeliveryDate.question[0];
    },
    cashDeliveryDateAnswers() {
      return this.ecaServiceQA.cashDeliveryDate.answers;
    },
    fspTransferDateQuestion() {
      return this.ecaServiceQA.fspTransferDate.question[0];
    },
    fspTransferDateAnswers() {
      return this.ecaServiceQA.fspTransferDate.answers;
    },
    fspWithdrawalDateQuestion() {
      return this.ecaServiceQA.fspWithdrawalDate.question[0];
    },
    fspWithdrawalDateAnswers() {
      return this.ecaServiceQA.fspWithdrawalDate.answers;
    },
    cashUsageQuestion() {
      return this.ecaServiceQA.cashUsage.question[0];
    },
    cashUsageAnswers() {
      return this.ecaServiceQA.cashUsage.answers;
    },
    ecaValueQuestion() {
      return this.ecaServiceQA.ecaValue.question[0];
    },
    ecaValueAnswers() {
      return this.ecaServiceQA.ecaValue.answers;
    },
    inKindQuestion() {
      return this.ecaServiceQA.inKind.question[0];
    },
    inKindAnswers() {
      return this.ecaServiceQA.inKind.answers;
    },
    inKindAmountQuestion() {
      return this.ecaServiceQA.inKindAmount.question[0];
    },
    inKindAmountAnswers() {
      return this.ecaServiceQA.inKindAmount.answers;
    },
    ecaRAISUploadedQuestion() {
      return this.ecaServiceQA.ecaRAISUploaded.question[0];
    },
    ecaRAISUploadedAnswers() {
      return this.ecaServiceQA.ecaRAISUploaded.answers;
    },
    assessmentMethodQuestion() {
      return this.ecaServiceQA.assessmentMethod.question[0];
    },
    assessmentMethodAnswers() {
      return this.ecaServiceQA.assessmentMethod.answers;
    },
    // Active data checks
    shouldShowEcaReceivedTwelveMonthsYesInput() {
      return this.ecaReceivedTwelveMonths?.toLowerCase().includes("yes");
    },
    shouldShowProtectionOtherInput() {
      return this.protectionIncident?.toLowerCase().includes("please specify");
    },
    shouldShowMedicalECAOtherInput() {
      return this.medicalECA?.toLowerCase().includes("please specify");
    },
    shouldShowModalityCashInHandInput() {
      return this.modality?.toLowerCase().includes("cash-in-hand");
    },
    shouldShowModalityCashFSPInput() {
      return this.modality?.toLowerCase().includes("cash through fsp");
    },
    shouldShowModalityInKindInput() {
      return this.modality?.toLowerCase().includes("in-kind");
    },
    shouldShowModalityOtherInput() {
      return this.modality?.toLowerCase().includes("please specify");
    },
  },
};
</script>
