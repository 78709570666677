<template>
  <div class="grid-cols-12">
    <div class="grid-cols-6 border-2">
      {{ selectedItem.customMapId }}
    </div>
    <div v-if="isDisabled">
       <div class="grid-cols-3 border-2">
        <ab-checkbox :disabled="isDisabled" v-model="isInfluencer" label="Influencer"></ab-checkbox>
      </div>
      <div class="grid-cols-3 border-2">
        <ab-checkbox :disabled="isDisabled" v-model="isPresent" label="Present"></ab-checkbox>
      </div>
    </div>
    <div v-else>
      <div class="grid-cols-3 border-2">
        <ab-checkbox v-model="isInfluencer" label="Influencer"></ab-checkbox>
      </div>
      <div class="grid-cols-3 border-2">
        <ab-checkbox v-model="isPresent" label="Present"></ab-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import AbCheckbox from "@/components/abaad/AbCheckbox/AbCheckbox";
export default {
  components: {
    AbCheckbox,
  },
  name: "SingleAttendee",
  data() {
    return {
      isInfluencer: false,
      isPresent: true,
    };
  },
  props: 
    {
      isDisabled: {
        required: false,
        type: Boolean,
      },
      selectedItem: {
        type: Object
      },
    },
  created() {
    this.isInfluencer = this.selectedItem.isInfluencer;
    this.isPresent = this.selectedItem.isPresent;
  },
  watch: {
    isInfluencer() {
      if (this.isInfluencer !== this.selectedItem.isInfluencer) {
        this.selectedItem.isInfluencer = this.isInfluencer;
        this.$emit("updateSelectedItem", this.selectedItem);
      }

    },
    isPresent() {
      if (this.isPresent !== this.selectedItem.isPresent) {
        this.selectedItem.isPresent = this.isPresent;
        this.$emit("updateSelectedItem", this.selectedItem);
      }
    },
  },
};
</script>

<style scoped></style>
