<template>
  <ab-content-container>
    <div class="col-span-1 md:col-span-1">
      <p class="text-xl font-semibold">
        Counseling Session - {{ submittedDate }}
      </p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
      <ab-card :answer="submittedDate" question="Date of Counseling"></ab-card>
      <ab-card
        :answer="typeInterventionAnswers"
        :question="typeInterventionQuestion"
      ></ab-card>
      <ab-card
        :answer="counselingSession.overview"
        :question="overviewQuestion"
      ></ab-card>
      <ab-card
        :answer="counselingSession.actionTaken"
        :question="actionTakenQuestion"
      ></ab-card>
    </div>

    <div class="add-counseling-session">
      <router-link :to="routeToNewCounselingSession()">
        <div
          class="
            flex
            items-center
            bg-primary
            w-full
            p-3
            rounded
            shadow-xl
            cursor-pointer
            hover:bg-secondary
          "
        >
          <i class="uil uil-headphones-alt text-white text-3xl"></i>
          <p class="text-md ml-2 text-white">Add New Counseling</p>
        </div>
      </router-link>
    </div>
    <ab-button
      @click="routeToNavigation()"
      class="
        back-button
        block
        mx-auto
        mt-10
        py-2
        px-20
        bg-red-700
        text-white
        rounded
        mt-2
        active:transform active:translate-y-1 active:outline-none
        hover:bg-red-800
      "
      :cancel="true"
      label="Back"
    ></ab-button>
  </ab-content-container>
</template>
<script>
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import AbCard from "@/components/abaad/AbCard/AbCard";
import AbButton from "@/components/abaad/AbButton/AbButton";
import moment from "moment";
import _ from "lodash";

export default {
  name: "readonly-counseling",
  components: {
    AbContentContainer,
    AbCard,
    AbButton,
  },
  data() {
    return {
      gbvmis: "",
      isLoading: false,
    };
  },
  beforeCreate() {
    this.isLoading = true;
  },
  created() {
    window.scrollTo(0,0);
    this.gbvmis = this.$route.params.id;
  },
  mounted() {
    this.isLoading = false;
    window.scrollTo(0, 0);
  },
  computed: {
    submittedDate() {
      return moment(this.counselingSession.submittedDate).format("YYYY/MM/DD");
    },
    typeInterventionQuestion() {
      return this.counselingServiceQA.typeIntervention.question[0];
    },
    typeInterventionAnswers() {
      return (!!this.counselingSession?.typeIntervention &&
        _.isEmpty(this.counselingSession.typeIntervention)) ||
        _.isNull(this.counselingSession.typeIntervention)
        ? "Question Not Answered"
        : this.counselingSession.typeIntervention;
    },
    overviewQuestion() {
      return this.counselingServiceQA.overview.question[0];
    },
    actionTakenQuestion() {
      return this.counselingServiceQA.actionTaken.question[0];
    },
  },
  methods: {
    routeToNewCounselingSession() {
      return `/bene/${this.$route.params.id}/counseling`;
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({
        path: `/bene/${this.$route.params.id}/counseling`,
      });
    },
  },
  props: {
    counselingSessions: {
      required: false,
      type: Array,
    },
    counselingServiceQA: {
      required: false,
      type: Object,
    },
    counselingSession: {
      required: false,
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.add-counseling-session {
  margin: 15px 0;
}
.pt-2 {
  .col-span-1 {
    margin-bottom: 10px;
  }
}
.back-button {
  background-color: #ECEDF0;
  color: #7D8C9E !important;
  font-weight: 500;
  &:hover {
    background-color: #7D8C9E;
    color: #ECEDF0 !important;
  }
}
</style>