<template>
  <ab-content-container class="pt-1">
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      color="#2399A4"
      class="loader"
    ></pulse-loader>
    <div v-else>
      <span class="font-semibold text-lg">Case Details:  </span>
        <span class="text-lg font-semibold">{{ gbvmis }}</span>
        <div class="max-w-2xl mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
          <ab-card
            :answer="reportDate"
            question="Report Date* - تاريخ المقابلة (يوم/شهر/سنة) "
          ></ab-card>
            <ab-card
              :answer="socioDemo.displacementStatus"
              question="Displacement Status - حالة النزوح"
            ></ab-card>
            <ab-card
              :answer="socioDemo.education"
              question="Educational Level - المستوى العلمي"
            ></ab-card>
            <ab-card
              :answer="socioDemo.workStatus"
              question="Work Status - الوضع المهني"
            ></ab-card>
            <ab-card
              :answer="socioDemo.socEcoStatus"
              question="How would you evaluate your socioeconomic status - كيف تقيم/ين وضك الإقتصادي"
            ></ab-card>
            <ab-card
              :answer="socioDemo.healthStatus"
              question="How would you evaluate your Health Status -  كيف تقيم/ين وضك الصحي"
            ></ab-card>
            <!--  -->
             <ab-card
              :answer="socioDemo.accomodationType"
              question="Type of accommodation نوع الإقامة"
            ></ab-card>
             <ab-card
              :answer="socioDemo.livingArrangements"
              question="Living arrangements - ترتيبات المعيشة"
            ></ab-card>
             <ab-card
              :answer="socioDemo.housingCondition"
              question="Housing condition: ظروف المنزل"
            ></ab-card>
            <!--  -->
            <ab-card :answer="caseAnswers.caseType" question="Case Type"></ab-card>
            <ab-card
              :answer="caseAnswers.interviewType"
              question="Interview Type"
            ></ab-card>

              <ab-card
              :answer="caseAnswers.outsideReferral"
              question="Who referred this survivor to you? * - من أحال هذا العميل إليك؟"
            ></ab-card>

            <div v-if="shouldShowSurvivorMinorInfo">
              <ab-card
                :answer="
                  caseAnswers.survivor.survivorMinorInfo == true
                    ? 'Yes - نعم'
                    : 'No -  لا'
                "
                :question="caseQuestions.survivorUnaccimpanied"
                :should-show="!!shouldShowSurvivorMinorInfo"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentType">
              <ab-card
                :answer="caseAnswers.incident.incidentType"
                :question="caseQuestions.incidentType"
                :should-show="!!caseAnswers.incident.incidentType"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentTraditionalPractice">
              <ab-card
                :answer="caseAnswers.incident.incidentTraditionalPractice"
                :question="caseQuestions.incidentTradition"
                :should-show="!!caseAnswers.incident.incidentTraditionalPractice"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentTrade">
              <ab-card
                :answer="
                  caseAnswers.incident.incidentTrade == true
                    ? 'Yes - نعم'
                    : 'No -  لا'
                "
                :question="caseQuestions.incidentTrade"
                :should-show="!!caseAnswers.incident.incidentTrade"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentFirstDate">
              <ab-card
                :answer="incidentFirstDate"
                :question="caseQuestions.incidentDate"
                :should-show="!!caseAnswers.incident.incidentFirstDate"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentLastDate">
              <ab-card
                :answer="incidentLastDate"
                :question="caseQuestions.actualIncident"
                :should-show="!!caseAnswers.incident.incidentLastDate"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentReported">
              <ab-card
                :answer="
                  caseAnswers.incident.incidentReported == true
                    ? 'Yes - نعم'
                    : 'No -  لا'
                "
                :question="caseQuestions.reportBySurvivor"
                :should-show="!!caseAnswers.incident.incidentReported"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentDisplacement">
              <ab-card
                :answer="caseAnswers.incident.incidentDisplacement"
                :question="caseQuestions.incidentDisplacement"
                :should-show="!!caseAnswers.incident.incidentDisplacement"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentDayPeriod">
              <ab-card
                :answer="caseAnswers.incident.incidentDayPeriod"
                :question="caseQuestions.incidentTime"
                :should-show="!!caseAnswers.incident.incidentDayPeriod"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentLocation">
              <ab-card
                :answer="caseAnswers.incident.incidentLocation"
                :question="caseQuestions.incidentLocation"
                :should-show="!!caseAnswers.incident.incidentLocation"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentArea">
              <ab-card
                :answer="caseAnswers.incident.incidentArea"
                :question="caseQuestions.incidentArea"
                :should-show="!!caseAnswers.incident.incidentArea"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentGovernate">
              <ab-card
                :answer="caseAnswers.incident.incidentGovernate"
                :question="caseQuestions.incidentGovernate"
                :should-show="!!caseAnswers.incident.incidentGovernate"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentVillage">
              <ab-card
                :answer="caseAnswers.incident.incidentVillage"
                :question="caseQuestions.incidentVillage"
                :should-show="!!caseAnswers.incident.incidentVillage"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentFrequency">
              <ab-card
                :answer="caseAnswers.incident.incidentFrequency"
                :question="caseQuestions.incidentFrequency"
                :should-show="!!caseAnswers.incident.incidentFrequency"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentDetails">
              <ab-card
                :answer="caseAnswers.incident.incidentDetails"
                :question="caseQuestions.incidentDescription"
                :should-show="!!caseAnswers.incident.incidentDetails"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentAbductionType">
              <ab-card
                :answer="caseAnswers.incident.incidentAbductionType"
                :question="caseQuestions.incidentAbduction"
                :should-show="!!caseAnswers.incident.incidentAbductionType"
              ></ab-card>
            </div>
            <div v-if="shouldShowIncidentReportedElsewhere">
              <ab-card
                :answer="caseAnswers.incident.incidentReportedElsewhere"
                :question="caseQuestions.incidentReported"
                :should-show="!!caseAnswers.incident.incidentReportedElsewhere"
              ></ab-card>
            </div>
            <div v-if="shouldShowSurvivorPreviousGBV">
              <ab-card
                :answer="caseAnswers.survivor.survivorPreviousGBV"
                :question="caseQuestions.incidentPrevious"
                :should-show="!!caseAnswers.survivor.survivorPreviousGBV"
              ></ab-card>
            </div>
            <div v-if="shouldShowSurvivorSupportNetwork">
              <ab-card
                :answer="caseAnswers.survivor.survivorSupportNetwork"
                :question="caseQuestions.incidentSupportNetwork"
                :should-show="!!caseAnswers.survivor.survivorSupportNetwork"
              ></ab-card>
            </div>
            <div v-if="shouldShowSurvivorRiskAssesmentLevel">
              <ab-card
                :answer="caseAnswers.survivor.survivorRiskAssesmentLevel"
                :question="caseQuestions.incidentRiskAssessment"
                :should-show="!!caseAnswers.survivor.survivorRiskAssesmentLevel"
              ></ab-card>
            </div>
            <div v-if="shouldShowPerpetratorNumber">
              <ab-card
                :answer="caseAnswers.perpetrator.number"
                :question="caseQuestions.allegPerpNumber"
                :should-show="!!caseAnswers.perpetrator.number"
              ></ab-card>
            </div>
            <div v-if="shouldShowPerpetratorRelationship">
              <ab-card
                :answer="caseAnswers.perpetrator.relationship"
                :question="caseQuestions.allegPerpRelationshup"
                :should-show="!!caseAnswers.perpetrator.relationship"
              ></ab-card>
            </div>
            <div v-if="shouldShowPerpetratorSex">
              <ab-card
                :answer="caseAnswers.perpetrator.sex"
                :question="caseQuestions.allegPerpSex"
                :should-show="!!caseAnswers.perpetrator.sex"
              ></ab-card>
            </div>
            <div v-if="shouldShowPerpetratorAge">
              <ab-card
                :answer="caseAnswers.perpetrator.age"
                :question="caseQuestions.allegPerpAge"
                :should-show="!!caseAnswers.perpetrator.age"
              ></ab-card>
            </div>
            <div v-if="shouldShowPerpetratorMainOccupation">
              <ab-card
                :answer="caseAnswers.perpetrator.mainOccupation"
                :question="caseQuestions.allegPerpMainOccup"
                :should-show="!!caseAnswers.perpetrator.mainOccupation"
              ></ab-card>
            </div>
            <div v-if="shouldShowEmoStateBeginning">
              <ab-card
                :answer="caseAnswers.assesmentPoint.emotionalStateBeginning"
                :question="caseQuestions.assessmentPointEmoStateStart"
                :should-show="!!caseAnswers.assesmentPoint.emotionalStateBeginning"
              ></ab-card>
            </div>
            <div v-if="shouldShowEmoStateEnd">
              <ab-card
                :answer="caseAnswers.assesmentPoint.emotionalStateEnding"
                :question="caseQuestions.assessmentPointEmoStateEnd"
                :should-show="!!caseAnswers.assesmentPoint.emotionalStateEnding"
              ></ab-card>
            </div>
            <div v-if="shouldShowRapeHealthInform">
              <ab-card
                :answer="caseAnswers.assesmentPoint.rapeHealthInform"
                :question="caseQuestions.assessmentPointExplained"
                :should-show="!!caseAnswers.assesmentPoint.rapeHealthInform"
              ></ab-card>
            </div>
            <div v-if="shouldShowConsent">
              <ab-card
                :answer="
                  caseAnswers.assesmentPoint.consent == true
                    ? 'Yes - نعم'
                    : 'No -  لا'
                "
                :question="caseQuestions.assessmentPointShareData"
                :should-show="!!caseAnswers.assesmentPoint.consent"
              ></ab-card>
            </div>
          </div>
        </div>
      <ab-button
        v-show="!isLoading"
        :cancel="true"
        class="block mx-auto mt-10"
        label="Cancel"
        @click="routeToNavigation()"
      ></ab-button>
    </div>
  </ab-content-container>
</template>

<script>
import moment from "moment";
import AbCard from "@/components/abaad/AbCard/AbCard";
import SocioDemoService from "@/shared/SocioDemoService";
import PulseLoader from "vue-spinner/src/PulseLoader";
import { computedMixin } from "@/mixins/forms/ViewCaseManagement/computedMixin";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer.vue";
import AbButton from "@/components/abaad/AbButton/AbButton";
import Vue from "vue";

export default {
  mixins: [computedMixin],
  name: "view-case-management",
  components: {
    AbCard,
    PulseLoader,
    AbButton,
    AbContentContainer,
  },
  data() {
    return {
      caseAnswers: {},
      socioDemo: {},
      caseQuestions: {},
      isLoading: false,
    };
  },
  created() {
    this.gbvmis = this.$route.params.id;

    this.getCase().then(() => {
      this.caseAnswers.assesmentPoint.emotionalStateBeginning =
        this.caseAnswers.assesmentPoint.emotionalStateBeginning
          .toString()
          .trim();
      this.caseAnswers.assesmentPoint.emotionalStateEnding =
        this.caseAnswers.assesmentPoint.emotionalStateEnding.toString().trim();
      this.caseAnswers.perpetrator.relationship =
        this.caseAnswers.perpetrator.relationship.toString().trim();
    });
    this.getSocioDemo();
  },
  computed: {
    reportDate() {
      return moment(this.socioDemo.reportDate).format("YYYY/MM/DD");
    },
    shouldShowSurvivorMinorInfo() {
      return this.caseAnswers?.survivor?.survivorMinorInfo;
    },
    shouldShowIncidentDisplacement() {
      return this.caseAnswers?.incident?.incidentDisplacement;
    },
    shouldShowIncidentDayPeriod() {
      return this.caseAnswers?.incident?.incidentDayPeriod;
    },
    shouldShowIncidentLocation() {
      return this.caseAnswers?.incident?.incidentLocation;
    },
    createdAt() {
      return moment(this.caseAnswers.createdDate).format("DD/MM/YYYY hh:mm");
    },
    incidentFirstDate() {
      return moment(this.caseAnswers.incident.incidentFirstDate).format(
        "DD/MM/YYYY hh:mm"
      );
    },
    incidentLastDate() {
      return moment(this.caseAnswers.incident.incidentLastDate).format(
        "DD/MM/YYYY hh:mm"
      );
    },
  },
  methods: {
    routeToNavigation() {
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    async getCase() {
      try {
        this.isLoading = true;

        const accessToken = await new Vue().$auth.getTokenSilently();
        let response = await fetch(
          `https://api.abaadmena.online/v1/survivorinfo/get/${this.$route.params.id}`,
          {
            method: "get",
            headers: new Headers({
              Authorization: `Bearer ${accessToken}`,
            }),
          }
        );
        let result = await response.json();
        this.caseAnswers = result[result.length - 1];
        this.getCaseManagement();
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async getSocioDemo() {
      try {
        this.isLoading = true;

        const accessToken = await new Vue().$auth.getTokenSilently();
        let response = await fetch(
          `https://api.abaadmena.online/v1/socdemo/get/${this.$route.params.id}`,
          {
            method: "get",
            headers: new Headers({
              Authorization: `Bearer ${accessToken}`,
            }),
          }
        );
        if (response.status === 500) {
          throw new Error("Something went wrong");
        }
        let result = await response.json();
        this.socioDemo = result;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    destructureSocioDemo(obj) {
      let res = {};
      for (let [key, val] of Object.entries(obj)) {
        res[this.addSpaceToWord(key)] = val;
      }
      res["created date"] = moment(res["created date"]).format("YYYY/MM/DD");
      res["report date"] = moment(res["report date"]).format("YYYY/MM/DD");
      return res;
    },
    addSpaceToWord(str) {
      let res = "";
      for (let letter of str) {
        if (letter.toUpperCase() === letter) {
          let index = str.indexOf(letter);
          res = `${str.substr(0, index)} ${str.substr(index)}`;
        }
      }
      return res.toLowerCase() || str.toLowerCase();
    },
    async chooseCase() {
      let caseType = this.caseAnswers.caseType.toLowerCase();
      switch (caseType) {
        case "gbv":
          return SocioDemoService.getGbvData();
        case "cpgbv":
          return SocioDemoService.getCpGbvData();
        case "nongbv":
          return SocioDemoService.getNonGbvData();
        case "cpnongbv":
          return SocioDemoService.getNonCpGbvData();
      }
    },
    getCaseManagement() {
      this.chooseCase()
        .then((response) => this.destructureObj(response.data, "caseQuestions"))
        .catch((error) => console.log(error));
    },
    destructureObj(obj, prop) {
      let questions = {};
      for (const key in obj) {
        questions[key] = obj[key].question[0];
      }

      this[prop] = questions;
    },
  },
};
</script>
