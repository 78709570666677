import axios from "@/http/http-common";
import Vue from "vue";

class FinancialBaseServiceService {
    async getFinancialServiceById(id) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.post("/services/financial/read/id/" + id, null, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

}

export default new FinancialBaseServiceService();
