import axios from "@/http/http-common";
import Vue from "vue";

class FamilyCompService {
    async getFamilyComp(gcode) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.get(`/familycomp/`, {
            params: {
                gbvmis: gcode,
            },
            headers: {
                "accept": "*/*",
                Authorization: `Bearer ${accessToken}`,
            }
        });
    }

    async deleteFamilyMember(id) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.post('/familycomp/delete', null, {
            params: {
                id: id,
            },
            headers: {
                "accept": "*/*",
                Authorization: `Bearer ${accessToken}`,
            }
        });
    }

    async createFamilyMember(newMember) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.post('/familycomp/create', newMember, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        });
    }
}

export default new FamilyCompService();
