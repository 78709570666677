import axios from "@/http/http-common";
import Vue from 'vue';

class ActionServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.get("/forms/formoptions/actionservice", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewActionService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.post("/services/create/action", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateActionService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.post("/services/update/action", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new ActionServiceService();
