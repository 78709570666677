import axios from "../http/http-common";
import Vue from "vue";

class UserService {

    getUserInfo() {
        return this.getMeData()
            .then((response) => {
                localStorage.setItem('userInfo', JSON.stringify(response.data));
                return JSON.parse(JSON.stringify(response.data));
        });
    }

    async getCountries() {
      return ["Lebanon", "Palestine", "Syria", "Iraq", "PRL", "PRS"];
    }

    async getMeData() {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.get("/me/", {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }
}

export default new UserService();
