<template>
  <div>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#2399a4"
    ></pulse-loader>
    <div v-else>
      <ab-content-container :with-footer="true" class="pt-2">
        <form class="flex flex-col mx-auto max-w-2xl">
          <div class="flex justify-between">
            <p class="font-semibold text-xl">Attachments</p>
          </div>
        </form>
        <div class="mt-12">
          <div
            v-if="!isLoading"
            class="flex justify-around flex-col items-center"
          >
            <div class="file-drop-area">
              <span class="fake-btn">Choose file</span>
              <span class="file-msg">{{
                fileName || "or drag and drop file here"
              }}</span>
              <input
                class="file-input"
                type="file"
                ref="file"
                @change="selectFile"
              />
            </div>
          </div>
        </div>

        <div class="mt-12 flex flex-col gap-2">
          <div v-for="file in this.uploadedFiles" :key="file">
            <a
              v-auth-href
              :href="`https://api.abaadmena.online/v1/minio/attachments//${file}`"
              class="
                flex
                items-center
                bg-primary
                py-2
                px-4
                rounded
                shadow-xl
                cursor-pointer
                hover:bg-secondary
              "
            >
              <i :class="`uil uil-file text-white text-3xl`"></i>
              <p class="text-sm ml-4 font-semibold text-white">
                {{ getFileName(file) }}
              </p>
            </a>
          </div>
        </div>
        <div class="flex flex-col justify-center items-center w-full mt-4">
          <ab-button class="mt-0" label="Upload" @click="upload()"></ab-button>
          <ab-button
            :cancel="true"
            class="mt-0"
            label="Cancel"
            @click="routeToNavigation()"
          ></ab-button>
        </div>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AttachmentService from "@/features/Profile/Attachment/AttachmentService";
import Vue from "vue";
import VueAuthHref from "vue-auth-href";

export default {
  name: "attachments",
  components: {
    AbContentContainer,
    PulseLoader,
    AbButton,
  },
  created() {
    this.gbvmis = this.$route.params.id;
  },
  mounted() {
    this.getUploadedFiles(this.gbvmis);
    let token = "";
    new Vue().$auth.getTokenSilently().then((res) => {
      token = res;
    });
    // setTimeout 0 needed here, weird error pops(strict mode caller/argument/caller) with Auth0 library that requires it
    setTimeout(() => {
      const options = {
        token: () => token, // Note that this MUST BE a function that returns the token.
        // other options here (full list of options described below)
      };
      Vue.use(VueAuthHref, options);
    }, 0);
  },
  data() {
    return {
      gbvmis: "",
      fileName: "",
      uploadedFiles: [],
      isLoading: false,
      currentFile: null,
      selectedFiles: null,
    };
  },
  methods: {
    getUserToken: async () => {
      return await new Vue().$auth.getTokenSilently();
    },
    vueAuthHrefOptions() {
      let token = "";
      this.getUserToken().then((res) => {
        token = res;
      });
      const options = {
        token: () => token, // Note that this MUST BE a function that returns the token.
        // other options here (full list of options described below)
      };
      return options;
    },
    routeToNavigation() {
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getFileName(fullPath) {
      return fullPath.split("/")[1];
    },
    getUploadedFiles() {
      AttachmentService.getUploadedFiles(this.gbvmis)
        .then((response) => {
          this.uploadedFiles = JSON.parse(JSON.stringify(response.data));
        })
        .catch((error) => console.log(error));
    },
    upload() {
      this.currentFile = this.selectedFiles.item(0);
      AttachmentService.uploadFile(this.currentFile, this.gbvmis)
        .then(() => {
          // display success
          this.getUploadedFiles(this.gbvmis);
        })
        .catch((error) => {
          console.error("Error while uploading", error);
        });
    },
    selectFile() {
      this.selectedFiles = this.$refs.file.files;
      this.fileName = this.$refs.file.files[0].name;
    },
  },
};
</script>
<style lang="scss">
.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 50px;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  transition: 0.2s;
  &.is-active {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.fake-btn {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  &:focus {
    outline: none;
  }
}
</style>
