<template>
  <tr class="text-xs capitalize text-gray-700 cursor-pointer hover:bg-primary">
    <td class="border">
      <ab-input
        label=""
        v-model="familyMember.name"
        :one-third="true"
        :placeholder="'Full Name'"
        class="h-14 border-secondary rounded-sm"
      ></ab-input>
      <!--     <textarea v-model="familyMember.name"-->
      <!--               class="w-full h-14 border-secondary rounded-sm">-->
      <!--     </textarea>-->
    </td>
    <td class="border">
      <ab-select
        label=""
        class="w-full h-14 border-secondary rounded-sm"
        :options="relations"
        :one-third="true"
        :placeHolder="'Relation To Benificiary'"
        :selected-option="familyMember.relation"
        v-model="familyMember.relation"
      ></ab-select>
      <!--     <textarea v-model="familyMember.relation"-->
      <!--               class="w-full h-14 border-secondary rounded-sm">-->
      <!--     </textarea>-->
    </td>
    <td class="border">
      <ab-input
        class="h-14 border-secondary rounded-sm"
        type="date"
        :one-third="true"
        v-model="familyMember.dateBirth"
        :value="familyMember.dateBirth"
      ></ab-input>
    </td>
    <td class="border">
      <ab-select
        label=""
        class="h-14 border-secondary rounded-sm"
        :one-third="true"
        :options="educations"
        :placeHolder="'Education Status'"
        :selected-option="familyMember.education"
        v-model="familyMember.education"
      ></ab-select>
      <!--     <textarea v-model="familyMember.education"-->
      <!--               class="w-full h-14 border-secondary rounded-sm">-->
      <!--     </textarea>-->
    </td>
    <td class="border">
      <ab-select
        label=""
        :one-third="true"
        class="h-14 border-secondary rounded-sm"
        :options="healths"
        :placeHolder="'Health Status'"
        :selected-option="familyMember.health"
        v-model="familyMember.health"
      ></ab-select>
      <!--     <textarea v-model="familyMember.health"-->
      <!--               class="w-full h-14 border-secondary rounded-sm">-->
      <!--     </textarea>-->
    </td>
    <td class="border">
      <ab-input
        label=""
        :one-third="true"
        v-model="familyMember.mentalHealth"
        placeholder="Mental Status"
        class="w-full h-14 border-secondary rounded-sm"
      ></ab-input>
    </td>
    <td class="border">
      <label class="switch">
        <input
          type="checkbox"
          class="slide-toggle-checkbox"
          placeholder="Dependant child to survivor"
          v-model="familyMember.dependantChild"
        />
        <div class="slider round"></div>
      </label>
      <span class="dependant-text">
        {{ formatBoolean }}
      </span>
    </td>
    <td
      v-if="!isNew"
      class="border px-4 py-2 table-button delete"
      @click="triggerDelete()"
    >
      <span :class="getButtonIcon"></span>
    </td>
    <td
      v-if="isNew"
      class="border px-4 py-2 table-button add"
      @click="triggerAdd()"
    >
      <span :class="getButtonIcon"></span>
    </td>
  </tr>
</template>

<script>
import AbInput from "@/components/abaad/AbInput/AbInput";
import FamilyCompService from "./FamilyCompService";
import moment from "moment";
import AbSelect from "@/components/abaad/AbSelect/AbSelect";
export default {
  components: {
    AbInput,
    AbSelect,
  },
  data() {
    return {
      relations: [
        "Husband الزوج",
        "Wife زوجة",
        "Father/Mother الأم/الأب",
        "Daughter/Son بنت/ابن",
        "Other Relative قريب اخر",
        "Other Non-Relative غير قريب آخر ",
      ],
      educations: [
        "Illiterate أمّي/ة ",
        "Primary إبتدائي ",
        "Intermediate متوسط",
        "Secondary ثانوي",
        "Vocational مهني",
        "University جامعي",
      ],
      healths: ["Chronic disease أمراض مزمنة", "Good Health Status صحة جيدة"],
    };
  },
  props: {
    familyMember: {
      type: Object,
      required: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    gbvmis: {
      type: String,
      required: false,
    },
  },
  methods: {
    triggerDelete() {
      if (confirm("Are you sure you want to delete this entry?")) {
        FamilyCompService.deleteFamilyMember(this.familyMember.id)
          .then((response) => {
            let familyComp = JSON.parse(JSON.stringify(response.data));
            this.$emit("updateFamilyComp", familyComp);
          })
          .catch((error) => console.log(error))
          .finally(() => (this.isLoading = false));
      }
    },
    triggerAdd() {
      this.familyMember.gbvmis = this.gbvmis;
      FamilyCompService.createFamilyMember(this.familyMember)
        .then((response) => {
          let familyComp = JSON.parse(JSON.stringify(response.data));
          this.$emit("updateFamilyComp", familyComp);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.isLoading = false;
          this.triggerFieldsReset();
        });
    },
    triggerFieldsReset() {
      this.familyMember.name = "";
      this.familyMember.relation = "";
      this.familyMember.dateBirth = "";
      this.familyMember.education = "";
      this.familyMember.health = "";
      this.familyMember.mentalHealth = "";
      this.familyMember.dependantChild = false;
    },
    confirmDelete(string) {
      window.confirm(string);
    },
  },
  computed: {
    formattedDate() {
      return moment(this.familyMember.dateBirth).format("YYYY-MM-dd");
    },
    formatBoolean() {
      return this.familyMember.dependantChild === true ? "Yes" : "No";
    },
    getButtonIcon() {
      return this.isNew ? "uil-user-plus" : "uil-user-minus";
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 991px) {
  td.border.table-button.delete {
    span {
      display: table;
      margin: auto;
      position: relative;
      &:after {
        content: "Delete Family Member";
        font-size: 18px;
        margin-left: 5px;
      }
    }
  }
  td.border.table-button.add {
    span {
      display: table;
      margin: auto;
      position: relative;
      &:after {
        content: "Add Family Member";
        font-size: 18px;
        margin-left: 5px;
      }
    }
  }
}

.table-button {
  font-size: 24px;
  color: #fff;

  &.add {
    background-color: #90ee90;
    span:hover {
      color: #fff;
    }
  }

  &.delete {
    background-color: #dc3545;
    span:hover {
      color: #fff;
    }
  }
}
.dependant-text {
  position: relative;
  top: -3px;
}
.switch {
  display: inline-block;
  height: 17px;
  position: relative;
  width: 30px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 13px;
  left: 1px;
  position: absolute;
  transition: 0.4s;
  width: 15px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(13px);
}

.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 50%;
}</style
>>
