import Vue from "vue";
import axios from "@/http/http-common";

const state = {
    services: [],
    actionServices: [],
    service: {},
    counselingService: {},
    allServices:{}
};
const getters = {
    allServices: (state) => state.services,
    service: (state) => state.service,
    actionServices: (state) => state.actionServices,
    counselingService: (state) => state.counselingService
};
const actions = {
    async fetchAllServices({ commit }, gbvmis) {
        if(!!this.getters.allServices.length) {
            commit("setServices", this.getters.allServices)           
        } else {
            const accessToken = await new Vue().$auth.getTokenSilently();
            const response = await axios.post("/services/read/user?gbvmis=" + gbvmis, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            })
            commit("setServices", response.data);
        }
    },
    async fetchLatestServiceByType({commit}, data) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        const response = await axios.post("/services/read/user?gbvmis=" + data.gbvmis, null,  {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        })
        commit("setActionServices", response.data['action']);
    }
};
const mutations = {
    setServices: (state, services) => state.services = services,
    setService: (state, service) => state.service = service,
    setActionServices: (state, actionServices) => state.actionServices = actionServices,
    setCounselingService: (state, counselingService) => state.counselingService = counselingService
};

export default {
    state,
    getters,
    actions,
    mutations
}
