<template>
  <pulse-loader
    v-if="isLoading"
    :loading="isLoading"
    class="loader"
    color="#2399A4"
  ></pulse-loader>
  <ab-content-container
    v-else
    :noPadding="true"
    class="mt-12 rounded max-w-2xl mx-auto"
  >
    <div class="title-wrapper">
      <p class="text-xl font-semibold">
        Trainging Feedback Survey
      </p>
      <p class="pull-left text-xs text-gray-500 italic">
        This is where we fill a feedback servey
      </p>
      <button
        id="toggleAllSections"
        class="btn btn-primary btn-sm"
        @click="toggleAllSections()"
      >
        Toggle All Sections
      </button>
    </div>
    <div class="max-w-2xl max-auto">
      <ab-collapse
        :expand="true"
        ref="collapseFeedbackInformation"
        label="Basic Information"
      >
        <div class="mt-6" ref="title">
          <p class="font-semibold text-sm mb-1">
            {{ titleQuestion }}
          </p>
          <ab-input
            type="text"
            :full="true"
            v-model="feedback.title"
            :required="false"
          ></ab-input>
        </div>
        <div class="mt-6" ref="submittedDate">
          <p class="font-semibold text-sm mb-1">
            {{ submittedDateQuestion }}
          </p>
          <ab-input
            type="date"
            :full="true"
            v-model="feedback.submittedDate"
            :required="false"
          ></ab-input>
        </div>
        <div class="mt-6" ref="orgName">
          <p class="font-semibold text-sm mb-1">
            {{ orgNameQuestion }}
          </p>
          <ab-input
            type="text"
            :full="true"
            v-model="feedback.orgName"
            :required="false"
          ></ab-input>
        </div>
        <div class="mt-6" ref="location">
          <p class="font-semibold text-sm mb-1">
            {{ locationQuestion }}
          </p>
          <ab-input
            type="text"
            :full="true"
            v-model="feedback.location"
            :required="false"
          ></ab-input>
        </div>
      </ab-collapse>
      <ab-collapse
        :expand="true"
        ref="collapseContentTraining"
        label="Session/ Training Content - مضمون الجلسة والتدريب"
      >
        <div ref="contentKnowledgeExpand">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.contentKnowledgeExpand"
            @abEvaluateValue="applyModelValue"
            :question="contentKnowledgeExpandQuestion"
            :refernce="'contentKnowledgeExpand'"
            v-model="feedback.contentKnowledgeExpand"
          >
          </ab-evaluate>
        </div>
        <div ref="contentInformationQuality">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.contentInformationQuality"
            @abEvaluateValue="applyModelValue"
            :question="contentInformationQualityQuestion"
            :refernce="'contentInformationQuality'"
            v-model="feedback.contentInformationQuality"
          >
          </ab-evaluate>
        </div>
        <div ref="contentPracticalQuality">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.contentPracticalQuality"
            @abEvaluateValue="applyModelValue"
            :question="contentPracticalQualityQuestion"
            :refernce="'contentPracticalQuality'"
            v-model="feedback.contentPracticalQuality"
          >
          </ab-evaluate>
        </div>
      </ab-collapse>
      <ab-collapse
        :expand="true"
        ref="collapseInteraction"
        label="Interaction - النفاعل"
      >
        <div ref="interactionQAQuality">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.interactionQAQuality"
            @abEvaluateValue="applyModelValue"
            :question="interactionQAQualityQuestion"
            :refernce="'interactionQAQuality'"
            v-model="feedback.interactionQAQuality"
          >
          </ab-evaluate>
        </div>
        <div ref="interactionComfortQuality">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.interactionComfortQuality"
            @abEvaluateValue="applyModelValue"
            :question="interactionComfortQualityQuestion"
            :refernce="'interactionComfortQuality'"
            v-model="feedback.interactionComfortQuality"
          >
          </ab-evaluate>
        </div>
        <div ref="interactionInformationClear">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.interactionInformationClear"
            @abEvaluateValue="applyModelValue"
            :question="interactionInformationClearQuestion"
            :refernce="'interactionInformationClear'"
            v-model="feedback.interactionInformationClear"
          >
          </ab-evaluate>
        </div>
      </ab-collapse>
      <ab-collapse
        :expand="true"
        ref="collapseLogistics"
        label="The Logistics - اللوجيستيات"
      >
        <div ref="logisticsTimingQuality">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.logisticsTimingQuality"
            @abEvaluateValue="applyModelValue"
            :question="logisticsTimingQualityQuestion"
            :refernce="'logisticsTimingQuality'"
            v-model="feedback.logisticsTimingQuality"
          >
          </ab-evaluate>
        </div>
        <div ref="logisticsPlaceQuality">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.logisticsPlaceQuality"
            @abEvaluateValue="applyModelValue"
            :question="logisticsPlaceQualityQuestion"
            :refernce="'logisticsPlaceQuality'"
            v-model="feedback.logisticsPlaceQuality"
          >
          </ab-evaluate>
        </div>
        <div ref="logisticsRefreshmentsQuality">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.logisticsRefreshmentsQuality"
            @abEvaluateValue="applyModelValue"
            :question="logisticsRefreshmentsQualityQuestion"
            :refernce="'logisticsRefreshmentsQuality'"
            v-model="feedback.logisticsRefreshmentsQuality"
          >
          </ab-evaluate>
        </div>
      </ab-collapse>
      <ab-collapse
        :expand="true"
        ref="collapseOverallFeedback"
        label="Overall Feedback - تقييم عام"
      >
        <div ref="overallQuality">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.overallQuality"
            @abEvaluateValue="applyModelValue"
            :question="overallQualityQuestion"
            :refernce="'overallQuality'"
            v-model="feedback.overallQuality"
          >
          </ab-evaluate>
        </div>
        <div ref="overallRecommendation">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.overallRecommendation"
            @abEvaluateValue="applyModelValue"
            :question="overallRecommendationQuestion"
            :refernce="'overallRecommendation'"
            v-model="feedback.overallRecommendation"
          >
          </ab-evaluate>
        </div>
        <div ref="overallExpectations">
          <ab-evaluate
            :isAgreementEvalution="true"
            :model="feedback.overallExpectations"
            @abEvaluateValue="applyModelValue"
            :question="overallExpectationsQuestion"
            :refernce="'overallExpectations'"
            v-model="feedback.overallExpectations"
          >
          </ab-evaluate>
        </div>
      </ab-collapse>
      <div class="mt-6" ref="areasInteresting">
        <p class="font-semibold text-sm mb-1">
          {{ areasInterestingQuestion }}
        </p>
        <ab-input
          type="text"
          :full="true"
          v-model="feedback.areasInteresting"
          :required="false"
        ></ab-input>
      </div>
      <div class="mt-6" ref="areasImprovement">
        <p class="font-semibold text-sm mb-1">
          {{ areasImprovementQuestion }}
        </p>
        <ab-input
          type="text"
          :full="true"
          v-model="feedback.areasImprovement"
          :required="false"
        ></ab-input>
      </div>
      <div class="mt-6" ref="otherTraining">
        <p class="font-semibold text-sm mb-1">
          {{ otherTrainingQuestion }}
        </p>
        <ab-input
          type="text"
          :full="true"
          v-model="feedback.otherTraining"
          :required="false"
        ></ab-input>
      </div>
    </div>
    <div
      v-if="!isLoading"
      class="flex flex-col w-full justify-center items-center"
    >
      <ab-button
        class="mt-2"
        :label="!editService ? 'Submit Feedback' : 'Edit Feedback'"
        @click="
          !editService
            ? addTrainingFeedbackService()
            : updateTrainingFeedbackService()
        "
      ></ab-button>
      <ab-button
        :cancel="true"
        class="mt-2"
        :label="!editService ? 'Cancel' : 'Back'"
        @click="!editService ? routeToTraining() : routeToFeedback()"
      ></ab-button>
    </div>
  </ab-content-container>
</template>
<script>
import vue from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader";

import AbContentContainer from "../../components/abaad/AbContentContainer/AbContentContainer.vue";
import AbCollapse from "../../components/abaad/AbCollapse/AbCollapse.vue";
import AbInput from "../../components/abaad/AbInput/AbInput.vue";
import AbEvaluate from "../../components/abaad/AbEvaluate/AbEvaluate.vue";
import AbButton from "../../components/abaad/AbButton/AbButton.vue";
import TrainingService from "./TrainingService";
import { required } from "vuelidate/lib/validators";
vue.use("required", required);
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import moment from "moment";
export default {
  name: "TrainingFeedback",
  mixins: [toasterMixin],
  props: {
    editService: {
      type: Object,
      required: false,
    },
    training: {
      type: Object,
      required: false,
    },
  },
  components: {
    PulseLoader,
    AbContentContainer,
    AbCollapse,
    AbInput,
    AbEvaluate,
    AbButton,
  },
  data() {
    return {
      isLoading: "",
      qaData: {},
      feedback: {
        areasImprovement: "",
        areasInteresting: "",
        contentInformationQuality: "",
        contentKnowledgeExpand: "",
        contentPracticalQuality: "",
        interactionComfortQuality: "",
        interactionInformationClear: "",
        interactionQAQuality: "",
        location: "",
        logisticsPlaceQuality: "",
        logisticsRefreshmentsQuality: "",
        logisticsTimingQuality: "",
        orgName: "",
        otherTraining: "",
        overallExpectations: "",
        overallQuality: "",
        overallRecommendation: "",
        submittedDate: "",
        title: "",
      },
    };
  },
  validations() {
    return {
      feedback: {
        title: { required },
        submittedDate: { required },
        orgName: { required },
        location: { required },
        contentKnowledgeExpand: { required },
        contentInformationQuality: { required },
        contentPracticalQuality: { required },
        interactionComfortQuality: { required },
        interactionInformationClear: { required },
        interactionQAQuality: { required },
        logisticsPlaceQuality: { required },
        logisticsRefreshmentsQuality: { required },
        logisticsTimingQuality: { required },
        overallExpectations: { required },
        overallQuality: { required },
        overallRecommendation: { required },
        id: "",
      },
    };
  },
  created() {
    console.log("🚀 ~ this.training:", this.training.id);
    if (!!this.editService) {
      this.populateEditService();
    }
  
  },
  mounted() {
    this.getTrainingFeedbackQA();
    window.scrollTo(0, 0);
  },
  methods: {
    getTrainingFeedbackQA() {
      TrainingService.getTrainingFeedbackQA().then(
        (response) => (this.qaData = response.data)
      );
    },
    populateEditService() {
      this.feedback = this.editService;
      this.feedback.submittedDate = moment(
        this.editService.submittedDate
      ).format("YYYY-MM-DD");
    },
    toggleAllSections() {
      for (let ref in this.$refs) {
        if (ref.includes("collapse")) {
          this.$refs[ref].toggle();
        }
      }
    },
    addTrainingFeedbackService() {
      if (!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          const trainingData = this.getFeedbackData();
          console.log("🚀 ~ trainingData:", trainingData);
          TrainingService.updateTraining(trainingData)
            .then((response) => {
              this.$router.push({
                name: "CurrentTraining",
              });
              console.log("🚀 ~ response:", response);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
    updateTrainingFeedbackService() {
      if (!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          const trainingData = this.getFeedbackData();
          console.log("🚀 ~ trainingData:", trainingData);
          TrainingService.updateTraining(trainingData)
            .then((response) => {
              console.log("🚀 ~ response:", response);
              this.$router.push({
                name: "CurrentTraining",
              });
              console.log("🚀 ~ response:", response);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
    applyModelValue(data) {
      this.feedback[data.model] = data.value;
    },
    catchErrors() {
      this.errors = [];
      if (!this.feedback.title) {
        let elPosition =
          this.$refs?.title?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Title is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.submittedDate) {
        let elPosition =
          this.$refs?.submittedDate?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Date is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.orgName) {
        let elPosition =
          this.$refs?.orgName?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Participant's organization name is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.location) {
        let elPosition =
          this.$refs?.location?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Location evaluation is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.contentKnowledgeExpand) {
        let elPosition =
          this.$refs?.contentKnowledgeExpand?.getBoundingClientRect().top +
          window.scrollY -
          60;
        console.log("🚀 ~ elPosition:", elPosition);
        this.errors.push({
          text: "Content knowledge addition evaluation is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.contentInformationQuality) {
        let elPosition =
          this.$refs?.contentInformationQuality?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Gained information expectations evaluation is Required.",
          pos: elPosition,
        });
      }

      if (!this.feedback.contentPracticalQuality) {
        let elPosition =
          this.$refs?.contentPracticalQuality?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "parctical part integration evaluation is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.interactionQAQuality) {
        let elPosition =
          this.$refs?.interactionQAQuality?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Answers satisfaction evaluation is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.interactionComfortQuality) {
        let elPosition =
          this.$refs?.interactionComfortQuality?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Comfort opinion sharing evaluation is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.interactionInformationClear) {
        let elPosition =
          this.$refs?.interactionInformationClear?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Shared information clarity evaluation is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.logisticsTimingQuality) {
        let elPosition =
          this.$refs?.logisticsTimingQuality?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Time dedication evaluation is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.logisticsPlaceQuality) {
        let elPosition =
          this.$refs?.logisticsPlaceQuality?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Place Comfort evaluation is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.logisticsRefreshmentsQuality) {
        let elPosition =
          this.$refs?.logisticsRefreshmentsQuality?.getBoundingClientRect()
            .top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Refreshment Quality evaluation is Required.",
          pos: elPosition,
        });
      }

      if (!this.feedback.overallQuality) {
        let elPosition =
          this.$refs?.overallQuality?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "General satisfaction evaluation is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.overallRecommendation) {
        let elPosition =
          this.$refs?.overallRecommendation?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Training recomendation is Required.",
          pos: elPosition,
        });
      }
      if (!this.feedback.overallExpectations) {
        let elPosition =
          this.$refs?.overallExpectations?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Training expectation is Required.",
          pos: elPosition,
        });
      }
      this.handleArrayErrorsAndScrollToQuestion(this.errors);
    },
    getFeedbackData() {
      let training = this.training;
      let feedbackData = {
        contentInformationQuality: this.feedback.contentInformationQuality,
        contentKnowledgeExpand: this.feedback.contentKnowledgeExpand,
        contentPracticalQuality: this.feedback.contentPracticalQuality,
        interactionComfortQuality: this.feedback.interactionComfortQuality,
        interactionInformationClear: this.feedback.interactionInformationClear,
        interactionQAQuality: this.feedback.interactionQAQuality,
        location: this.feedback.location,
        logisticsPlaceQuality: this.feedback.logisticsPlaceQuality,
        logisticsRefreshmentsQuality: this.feedback
          .logisticsRefreshmentsQuality,
        logisticsTimingQuality: this.feedback.logisticsTimingQuality,
        orgName: this.feedback.orgName,
        overallExpectations: this.feedback.overallExpectations,
        overallQuality: this.feedback.overallQuality,
        overallRecommendation: this.feedback.overallRecommendation,
        submittedDate: this.feedback.submittedDate,
        title: this.feedback.title,
        areasImprovement: this.feedback.areasImprovement,
        areasInteresting: this.feedback.areasInteresting,
        otherTraining: this.feedback.otherTraining,
      };
      if (!this.editService) {
        if(!this.training.feedbacks) {
          this.training.feedbacks = [];
        }
        training.feedbacks.push(feedbackData);
      }

      if (!!this.editService) {
        feedbackData.id = this.editService.id;
      }

      console.log("🚀 ~ feedbackData:", feedbackData);
      console.log("🚀 ~ training:", training);

      return training;
    },
    routeToTraining() {
      this.$router.push({name: 'SingleTraining'})
    },
    routeToFeedback() {
      this.$router.push({name: 'FeedbackEvaluationForms', params: { training: this.training }})
    },
  },
  computed: {
    areasImprovementQuestion() {
      return this.qaData?.areasImprovement?.question[0];
    },
    areasInterestingQuestion() {
      return this.qaData?.areasInteresting?.question[0];
    },
    contentInformationQualityQuestion() {
      return this.qaData?.contentInformationQuality?.question[0];
    },
    contentKnowledgeExpandQuestion() {
      return this.qaData?.contentKnowledgeExpand?.question[0];
    },
    contentPracticalQualityQuestion() {
      return this.qaData?.contentPracticalQuality?.question[0];
    },
    interactionComfortQualityQuestion() {
      return this.qaData?.interactionComfortQuality?.question[0];
    },
    interactionInformationClearQuestion() {
      return this.qaData?.interactionInformationClear?.question[0];
    },
    interactionQAQualityQuestion() {
      return this.qaData?.interactionQAQuality?.question[0];
    },
    locationQuestion() {
      return this.qaData?.location?.question[0];
    },
    logisticsPlaceQualityQuestion() {
      return this.qaData?.logisticsPlaceQuality?.question[0];
    },
    logisticsRefreshmentsQualityQuestion() {
      return this.qaData?.logisticsRefreshmentsQuality?.question[0];
    },
    logisticsTimingQualityQuestion() {
      return this.qaData?.logisticsTimingQuality?.question[0];
    },
    orgNameQuestion() {
      return this.qaData?.orgName?.question[0];
    },
    otherTrainingQuestion() {
      return this.qaData?.otherTraining?.question[0];
    },
    overallExpectationsQuestion() {
      return this.qaData?.overallExpectations?.question[0];
    },
    overallQualityQuestion() {
      return this.qaData?.overallQuality?.question[0];
    },
    overallRecommendationQuestion() {
      return this.qaData?.overallRecommendation?.question[0];
    },
    submittedDateQuestion() {
      return this.qaData?.submittedDate?.question[0];
    },
    titleQuestion() {
      return this.qaData?.title?.question[0];
    },
  },
};
</script>
<style scoped lang="scss">
.title-wrapper {
  margin-left: -2.5rem;
  margin-top: 0rem;
}
#toggleAllSections {
  display: flex;
  color: white;
  margin-left: auto;
  border-radius: 0;
}
</style>
