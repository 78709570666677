import { render, staticRenderFns } from "./aiomisLoader.vue?vue&type=template&id=6dcfe9d2&scoped=true"
import script from "./aiomisLoader.vue?vue&type=script&lang=js"
export * from "./aiomisLoader.vue?vue&type=script&lang=js"
import style0 from "./aiomisLoader.vue?vue&type=style&index=0&id=6dcfe9d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dcfe9d2",
  null
  
)

export default component.exports