import axios from "@/http/http-common";
import Vue from "vue";

class CounselingServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/counselingservice", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewCounselingService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/create/counseling", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateCounselingService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/update/counseling", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getServiceSessions(params) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/services/read/latest", {
      params: {
        gbvmis: params.gbvmis,
        number: params.number,
        serviceType: params.serviceType
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
  }
}

export default new CounselingServiceService();
