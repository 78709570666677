import axios from "@/http/http-common";
import Vue from "vue";

class ShelterServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/shelterservice", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewShelterService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/create/shelter", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateShelterService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/update/shelter", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new ShelterServiceService();
