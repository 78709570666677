<template>
    <div v-if="visible" class="ab-toaster-wrapper animate__animated animate__slideInRight">
        <div class="toaster-wrapper" :class="state">
            <div class="toaster">
                <div class="heading">
                    <span>{{title}}</span>
                </div>
                <span class="action" @click="hide()">x</span>
                <div class="body">
                <span>{{content}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from "@/Events/event-bus.js";

export default {
    name: 'ab-toaster',

    data() {
        return {
            visible: false,
            content: "",
            title: "",
            state: "",
            duration: "",
        }
    },
    mounted() {
        EventBus.$on("toggle_toast", (data) => {      
            this.setToastData(data);
            if(!!data && data.duration) {
                setTimeout(() => {
                   this.hide();
                }, data.duration)
            }
        });
        EventBus.$on("hide_toast", () => {
            this.hide()
        });         
    },
    beforeDestroy() {
        console.log("before")
    },
    destroyed() {
        console.log("onDestroyed")
    },
    methods: {
        setToastData(data) {
            this.content = data.content;
            this.title = data.title;
            this.state = data.state;
            this.duration = data.duration;
            this.visible = true;
        },
        animateHide() {
            let toasterWrapper = document.querySelector(".ab-toaster-wrapper");
            if(!!toasterWrapper) {
                toasterWrapper.classList.remove("animate__slideInRight");
                toasterWrapper.classList.add("animate__slideOutRight");
            }
        },
        hide() {
            this.animateHide();
            setTimeout(() => {
                this.visible = false;
            },600);
        }
    },
  watch:{
    $route (){
       this.hide();
    }
} 
}
</script>
<style lang="scss" scoped>
.ab-toaster-wrapper {
  position: fixed;
  top: 60px;
  right: 0%;
  width: 350px;
  max-height: 2000px;
  z-index: 101;
}
.toaster-wrapper {
    right: 10px;
    width: 350px;
    height: inherit;
    position: fixed;
    .body {
        max-height: 300px;
    }
    
    &.success,
    &.error {
        .toaster {
            width: 100%;
            height: inherit;
            display: inline-block;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .heading {
                float: left;
                align-items: center;
                padding: 0.25rem 0.75rem;
                background-clip: padding-box;
                border-top-left-radius: 5px;
                height: 30px;
                width: 95%;
            }
            .body {                
                margin-top: 30px;
                max-height: 300px;
                display: block;
                padding: 0.75rem;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
            .action {
                border-top-right-radius: 5px;
                height: 30px;
                width: 5%;
                float: right;
                cursor: pointer;
            }
        }
    }
    &.success {
        border-color: rgba(184,218,255,.85);
        .toaster {
            border-color: rgba(184,218,255,.85);
            background-color: rgba(230,242,255,.85);
            color: #004085;
            .heading {
                background-color: rgba(204,229,255,.85);
                border-bottom-color: rgba(184,218,255,.85);
            }
            .body {
                color: #004085;
            }
            .action {  
                color: #004085;
                background-color: rgba(204,229,255,.85);
                border-bottom-color: rgba(184,218,255,.85);
            }      
        }            
    }
    &.error {
        border-color: rgba(245,198,203,.85);
        .toaster {
            background-color: rgba(252,237,238,.85);
            border-color: rgba(245,198,203,.85);
            .heading {
                color: #721c24;
                background-color: rgba(248,215,218,.85);
                border-bottom-color: rgba(245,198,203,.85);
            }
            .body {
                color: #721c24;
            }
            .action {  
                color: #721c24;
                background-color: rgba(248,215,218,.85);
                border-bottom-color: rgba(245,198,203,.85);
            }     
        }
    }
}
</style>
