<template>
  <ab-content-container>
    <main class="flex justify-center mx-auto">
      <div class="w-11/12 grid grid-cols-3 items-center gap-2">
        <ab-search type="text"
            class="mx-auto w-full md:w-1/2 col-span-3 md:col-span-3"
            v-model="search"
            placeholder="Search for Beneficiary..."></ab-search>
        <ab-benis :search="search" class="col-span-3"></ab-benis>
      </div>
    </main>
  </ab-content-container>
</template>

<script>
import AbBenis from "./Benis.vue";
import AbSearch from "@/components/abaad/AbSearch/AbSearch";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
export default {
  data() {
    return {
      search: "",
    }
  },
  name: 'App',
  components: {
    AbBenis,
    AbSearch,
    AbContentContainer
  }
}
</script>
