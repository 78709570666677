<template>
  <div>
    <!-- <aiomis-loader v-if="isLoading" :isLoading="isLoading" class="full-screen"></aiomis-loader> -->
    <div v-if="!isLoading">
      <ab-content-container>
        <div class="md:max-w-4xl lg:max-w-3xl xl:max-w-4xl mx-auto">
          <div v-if="!search.length">
            <div
              v-if="isMain"
              class="main">
              <router-link
                to="/bene/create"
                tag="div"
                class="page-button group flex flex-col justify-center 
                  items-center p-3 py-6 bg-primary rounded cursor-pointer 
                  hover:shadow-2xl hover:bg-secondary">
                <i class="uil uil-plus transform translate-y-3 text-white 
                  main-icon transition-all duration-300 group-hover:transform rotate-180"></i>
                <p class="
                  mb-3
                  button-text
                  transition-all
                  duration-300
                  font-semibold
                  text-white
                  group-hover:translate-x-8">
                  New User
                </p>
              </router-link>
              
              <router-link
                to="#"
                tag="div"
                class="page-button group flex flex-col justify-center items-center p-3 bg-primary rounded cursor-pointer hover:shadow-2xl hover:bg-secondary"
              >
                <i class="transition-all duration-300 uil uil-bell text-white main-icon group-hover:animate-wiggle"
                ></i>
                <p
                  class="
                    mb-3
                    button-text
                    transition-all
                    duration-300
                    font-semibold
                    text-white
                    group-hover:translate-x-4">
                  Notifications
                </p>
              </router-link>
              <router-link
                to="#" tag="div" @click.native="routeToExport()"
                class="page-button group flex flex-col justify-center items-center p-3 bg-primary 
                  rounded cursor-pointer hover:shadow-2xl hover:bg-secondary">
                <i class="uil uil-th text-white main-icon transform translate-y-3 
                  transition-all duration-300 group-hover:transform rotate-180"></i>
                <p class="
                    mb-3
                    button-text
                    transition-all
                    duration-300
                    font-semibold
                    text-white
                    group-hover:translate-x-10
                  "
                >
                  Insights
                </p>
              </router-link>
              <router-link
                :class="{ disabled: false }"
                to="/activities"
                tag="div"
                class="
                  page-button
                  group
                  flex flex-col
                  justify-center
                  items-center
                  p-3
                  bg-primary
                  rounded
                  cursor-pointer
                  hover:shadow-2xl hover:bg-secondary">
                <i class="
                    uil uil-layer-group
                    text-white
                    main-icon
                    transform
                    translate-y-3
                    transition-all
                    duration-300
                    group-hover:transform
                    rotate-180"></i>
                <p class="mb-3
                    button-text
                    transition-all
                    duration-300
                    font-semibold
                    text-white
                    group-hover:translate-x-10">
                  Activities
                </p>
              </router-link>
               <router-link
                to="/training"
                tag="div"
                class="page-button new-row group flex flex-col justify-center 
                  items-center p-3 py-6 bg-primary rounded cursor-pointer 
                  hover:shadow-2xl hover:bg-secondary">
                <i class="uil uil-comment-lines transform translate-y-3 text-white 
                  main-icon transition-all duration-300 group-hover:transform rotate-180"></i>
                <p class="
                  mb-3
                  button-text
                  transition-all
                  duration-300
                  font-semibold
                  text-white
                  group-hover:translate-x-8">
                  Training
                </p>
              </router-link>
            </div>
            <div v-else>
               <div class="w-full px-4 xl:w-5/6 xl:p-0 justify-center items-center mx-auto flex flex-col md:flex-row gap-2">
                <a v-auth-href href="https://api.abaadmena.online/v1/export/all/dump"
                class="export-dump page-button group flex flex-col justify-center 
                    items-center p-3 py-6 bg-primary rounded cursor-pointer 
                    hover:shadow-2xl hover:bg-secondary">
                    <i class="uil uil-export text-white main-icon transform translate-y-3 transition-all duration-300 group-hover:transform rotate-180"></i>
                    <p class="  mb-3
                    button-text
                    transition-all
                    duration-300
                    font-semibold
                    text-white
                    group-hover:translate-x-10">
                        Export
                    </p>
                </a>
            <router-link
                to="/recordlog"
                tag="div"
                class="page-button group flex flex-col justify-center 
                items-center p-3 py-6 bg-primary rounded cursor-pointer 
                hover:shadow-2xl hover:bg-secondary">
                <i class="
                    uil uil-align-letter-right
                    transform
                    translate-y-3
                    text-white
                    main-icon
                    transition-all
                    duration-300
                    group-hover:transform
                    rotate-180">
                </i>
                <p class="
                mb-3
                button-text
                transition-all
                duration-300
                font-semibold
                text-white
                group-hover:translate-x-10">
                    Record Logs
                </p>
            </router-link>
            </div>
            </div>
          </div>
        </div>
        <ab-benis :search="search" v-if="search.length"></ab-benis>
      </ab-content-container>
    </div>
  </div>
</template>

<script>
import AbContentContainer from "../components/abaad/AbContentContainer/AbContentContainer";
import AbBenis from "@/features/beneficiaries/Benis.vue";
import { EventBus } from "@/Events/event-bus.js";
import Vue from "vue";
import UserService from "@/shared/UserService";
import VueAuthHref from "vue-auth-href";
// import aiomisLoader from '@/components/aiomisLoader/aiomisLoader';
export default {
  components: {
    AbContentContainer,
    AbBenis,
    // aiomisLoader
  },
  data() {
    return {
      search: "",
      isMain: true,
      userInfo: {},
      isLoading: true,
      isLanding: true,
    };
  },
  created() {
    setTimeout(() => {
      EventBus.$on("onSearch", (term) => {
        this.search = term;
      });
      EventBus.$on("toMain", () => (this.isMain = true));
      let token = "";
      new Vue().$auth.getTokenSilently().then((res) => {
        token = res;
      });
      UserService.getUserInfo();
      let userInfo = localStorage.getItem('userInfo')
      this.userInfo = JSON.parse(userInfo);
      // setTimeout 0 needed here, weird error pops(strict mode caller/argument/caller) with Auth0 library that requires it
      setTimeout(() => {
        const options = {
          token: () => token, // Note that this MUST BE a function that returns the token.
          // other options here (full list of options described below)
        };
        Vue.use(VueAuthHref, options);
      }, 0);
    }, 1000);
    this.isLoading = false;
    // sessionStorage.setItem("isLanding", false);
  },
  methods: {
    searchBenis(term) {
      this.search = term;
    },
    routeToExport() {
      this.isMain = false;
    },
  },
};
</script>

<style lang="scss">
.w50 {
  width: 50%;
}
i.uil-export,
i.uil-align-letter-right {
  font-size: 3.5rem;
}
.main {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .main {
    flex-direction: column;
  }
  .page-button.group.flex.flex-col.justify-center.items-center{
    width: 100% !important;
    height: 75px !important;
    margin: 5px 0 !important;
    flex-direction: row;
    i {
      order: 0;
      margin-top: -10px;
    }
    p.button-text {
      font-size: 18px;
      margin-top: 20px;
      margin-left: 15px;
      margin-right: auto;
    }
  }
}
.group.page-button:hover {
  background-color: #2399a4 !important;
}
.button-text {
  font-size: 14px;
  white-space: nowrap;
}

.page-button {
  height: 130px !important;
  width: 112px !important;
}

.search {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}
@media (max-width: 850px) {
  .main-icon {
    font-size: 3rem !important;
  }
}

.main-icon {
  font-size: 4rem;
}

.p-rl {
  padding-right: 1.1rem;
  padding-left: 1.1rem;
}

.full-screen {
  width: 100%;
  height: 100%;
}
.disabled {
  cursor: not-allowed;
    pointer-events: none;  

}
.new-row {
  margin-top: auto;
}
</style>
