import axios from "../../http/http-common";
import Vue from "vue";

class ActivityService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.get("/forms/formoptions/activities/activity", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getSessionQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.get("/forms/formoptions/activities/session", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getAllBeneficiariesLimited() {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.get("beni/search/limited", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewActivityService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.post("/activities/create", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewSession(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.post("/activities/sessions/create", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getActivitiesByStatus(status) {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.get("/activities/bystatus", {
      params: {
        status: status,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getActivities() {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.get("/activities/all", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getSingleActivity(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();

    return axios.post("activities/id", data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async deleteActivity(id) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("activities/delete", null, {
      params: {
        id: id,
      },
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getActivityAwarenessEvaluationFormQA(model) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    let url;
    if (model.includes("PSS")) {
      url = "forms/formoptions/activities/satisfactionforms/pss";
    } else {
      url = "forms/formoptions/activities/satisfactionforms/awarness";
    }
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addAwarenessForm(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("activities/update", data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateActivity(data) {
    return this.addAwarenessForm(data);
  }

  async updateSession(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios({
      method: "POST",
      url: "activities/session/update",
      data,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async deleteSession(id) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios({
      method: "POST",
      url: "activities/session/delete",
      params: {
        id: id,
      },
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new ActivityService();
