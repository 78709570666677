<template>
  <div class="h-64 w-full flex flex-col justify-center items-center">
    <i class="uil uil-desert text-6xl text-gray-400"></i>
    <p class="text-md font-light text-gray-600">Nothing to show</p>
  </div>
</template>

<script>
export default {
  
}
</script>