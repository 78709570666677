<template>
  <ab-content-container
    :noPadding="true"
    class="mt-12 rounded max-w-2xl mx-auto"
  >
    <div class="title-wrapper">
      <p class="text-xl font-semibold">
        {{ title }} - {{ formModel }} Satisfaction Survey
      </p>
      <p class="pull-left text-xs text-gray-500 italic">
        This is where we add a new activity session
      </p>
      <button
        id="toggleAllSections"
        class="btn btn-primary btn-sm"
        @click="toggleAllSections()"
      >
        Toggle All Sections
      </button>
    </div>
    <div class="max-w-2xl max-auto">
      <ab-collapse
        :expand="true"
        ref="collapseAttendeeInfo"
        label="Introduction - مقدمة"
      >

        <div class="mt-6" ref="submittedDate">
          <p class="font-semibold text-sm mb-1">
            {{ submittedDateQuestion }}
          </p>
          <ab-input
            type="date"
            :full="true"
            v-model="form.submittedDate"
            :required="false"
          ></ab-input>
        </div>
        <div class="mt-6" ref="center">
          <p class="font-semibold text-sm mb-1">
            {{ centerQuestion }}
          </p>

          <ab-select
            ref="center_select"
            :full="true"
            :options="centerAnswers"
            :placeHolder="'Select Center'"
            v-model="form.center"
          ></ab-select>
        </div>

        <div class="mt-6" ref="age">
          <p class="font-semibold text-sm mb-1">
            {{ ageQuestion }}
          </p>
          <ab-input
            :full="true"
            type="number"
            label=""
            placeholder="e.g: 20"
            v-model="form.age"
          ></ab-input>
        </div>
        <div class="mt-6" ref="nationality">
          <span class="font-semibold text-sm mb-1 flex">
            {{ nationalityQuestion }}
            <div class="flags-wrapper">
              <div class="flags">
                <div class="flag-wrapper">
                  <img
                    @click="setCommonNationality('Lebanon')"
                    src="../../../assets/images/lb.png"
                    alt=""
                  />
                </div>
                <div class="flag-wrapper">
                  <img
                    @click="setCommonNationality('Palestine')"
                    src="../../../assets/images/ps.png"
                    alt=""
                  />
                </div>
                <div class="flag-wrapper">
                  <img
                    @click="setCommonNationality('Syria')"
                    src="../../../assets/images/sy.png"
                    alt=""
                  />
                </div>
                <div class="flag-wrapper">
                    <div  @click="setCommonNationality('PRL')" class="pr-nationality">
                      <span>PRL</span>
                    </div>
                  </div>
                  <div class="flag-wrapper">
                    <div  @click="setCommonNationality('PRS')" class="pr-nationality">
                      <span>PRS</span>
                    </div>
                    </div>
              </div>
            </div>
          </span>
          <ab-select
            ref="nationality-select"
            :required="false"
            :full="true"
            :placeHolder="'Select Country'"
            :options="countries"
            v-model="form.nationality"
            :selected-option="form.nationality"
          ></ab-select>
        </div>
      </ab-collapse>
      <ab-collapse
        :expand="true"
        ref="collapseOverallStatement"
        label="Overall Statement - نظرة شاملة"
      >
        <div ref="overallStatement">
          <ab-evaluate
            :model="form.overallStatement"
            @abEvaluateValue="applyModelValue"
            :question="'Overall, the sessions provided were good - بشكل عام, الجلسات كانت جيدة ومفيدة'"
            :refernce="'overallStatement'"
            v-model="form.overallStatement"
          ></ab-evaluate>
        </div>

        <div class="mt-6" ref="comments">
          <p class="font-semibold text-sm mb-1">{{ commentsQuestion }}</p>
          <textarea
            v-model="form.comments"
            placeholder="Enter comments here..."
            class="
                w-full
                h-24
                border border-solid
                p-2
                text-sm
                font-light
                border-gray-300
                rounded-sm
              "
          ></textarea>
        </div>
      </ab-collapse>
      <ab-collapse
        v-if="formModel.includes('PSS')"
        :expand="true"
        ref="collapseWellbeing"
        label="Wellbeing - الرفاه النفسي"
      >
        <div>
          <ab-evaluate
            :model="form.postSessionEager"
            @abEvaluateValue="applyModelValue"
            :question="postSessionEagerQuestion"
            :refernce="'postSessionEager'"
            v-model="form.postSessionEager"
          >
          </ab-evaluate>
        </div>
        <div>
          <ab-evaluate
            :model="form.postSessionTeach"
            @abEvaluateValue="applyModelValue"
            :question="postSessionTeachQuestion"
            :refernce="'postSessionTeach'"
            v-model="form.postSessionTeach"
          >
          </ab-evaluate>
        </div>
        <div>
          <ab-evaluate
            :model="form.postSessionHelp"
            @abEvaluateValue="applyModelValue"
            :question="postSessionHelpQuestion"
            :refernce="'postSessionHelp'"
            v-model="form.postSessionHelp"
          >
          </ab-evaluate>
        </div>
        <div>
          <ab-evaluate
            :model="form.postSessionFriend"
            @abEvaluateValue="applyModelValue"
            :question="postSessionFriendQuestion"
            :refernce="'postSessionFriend'"
            v-model="form.postSessionFriend"
          >
          </ab-evaluate>
        </div>
        <div>
          <ab-evaluate
            :model="form.postSessionRelation"
            @abEvaluateValue="applyModelValue"
            :question="postSessionRelationQuestion"
            :refernce="'postSessionRelation'"
            v-model="form.postSessionRelation"
          >
          </ab-evaluate>
        </div>
      </ab-collapse>
      <ab-collapse
        :expand="true"
        ref="collapseContentAndService"
        label="Session Content and service provided محتوى الجلسات والخدمة المقدمة"
      >
        <div ref="contentInteresting">
          <ab-evaluate
            :model="form.contentInteresting"
            @abEvaluateValue="applyModelValue"
            :question="contentInterestingQuestion"
            :refernce="'contentInteresting'"
            v-model="form.contentInteresting"
          ></ab-evaluate>
        </div>

        <div ref="contentUseful">
          <ab-evaluate
            :model="form.contentUseful"
            @abEvaluateValue="applyModelValue"
            :question="contentUsefulQuestion"
            :refernce="'contentUseful'"
            v-model="form.contentUseful"
          ></ab-evaluate>
        </div>

        <div ref="sessionsUseful">
          <ab-evaluate
            :model="form.sessionsUseful"
            @abEvaluateValue="applyModelValue"
            :question="sessionsUsefulQuestion"
            :refernce="'sessionsUseful'"
            v-model="form.sessionsUseful"
          ></ab-evaluate>
        </div>
      </ab-collapse>
      <ab-collapse
        :expand="true"
        ref="collapseFacilitator"
        label="The facilitator - ألمسهل"
      >
        <div ref="facilitatorKnowledge">
          <ab-evaluate
            :model="form.facilitatorKnowledge"
            @abEvaluateValue="applyModelValue"
            :question="facilitatorKnowledgeQuestion"
            :refernce="'facilitatorKnowledge'"
            v-model="form.facilitatorKnowledge"
          ></ab-evaluate>
        </div>

        <div ref="facilitatorPresentation">
          <ab-evaluate
            :model="form.facilitatorPresentation"
            @abEvaluateValue="applyModelValue"
            :question="facilitatorPresentationQuestion"
            :refernce="'facilitatorPresentation'"
            v-model="form.facilitatorPresentation"
          ></ab-evaluate>
        </div>

        <div ref="facilitatorRespect">
          <ab-evaluate
            :model="form.facilitatorRespect"
            @abEvaluateValue="applyModelValue"
            :question="facilitatorRespectQuestion"
            :refernce="'facilitatorRespect'"
            v-model="form.facilitatorRespect"
          ></ab-evaluate>
        </div>

        <div ref="facilitatorAttitude">
          <ab-evaluate
            :model="form.facilitatorAttitude"
            @abEvaluateValue="applyModelValue"
            :question="facilitatorAttitudeQuestion"
            :refernce="'facilitatorAttitude'"
            v-model="form.facilitatorAttitude"
          ></ab-evaluate>
        </div>
      </ab-collapse>
      <ab-collapse
        :expand="true"
        ref="collapseLogistics"
        label="The Logistics - اللوجيستيات"
      >
        <div ref="environmentSafe">
          <ab-evaluate
            :model="form.environmentSafe"
            @abEvaluateValue="applyModelValue"
            :question="environmentSafeQuestion"
            :refernce="'environmentSafe'"
            v-model="form.environmentSafe"
          ></ab-evaluate>
        </div>

        <div ref="powerPointActivities">
          <ab-evaluate
            :model="form.powerPointActivities"
            @abEvaluateValue="applyModelValue"
            :question="powerPointActivitiesQuestion"
            :refernce="'powerPointActivities'"
            v-model="form.powerPointActivities"
          ></ab-evaluate>
        </div>

        <div ref="timing">
          <ab-evaluate
            :model="form.timing"
            @abEvaluateValue="applyModelValue"
            :question="timingQuestion"
            :refernce="'timing'"
            v-model="form.timing"
          ></ab-evaluate>
        </div>

        <div ref="refreshment">
          <ab-evaluate
            :model="form.refreshment"
            @abEvaluateValue="applyModelValue"
            :question="refreshmentQuestion"
            :refernce="'refreshment'"
            v-model="form.refreshment"
          ></ab-evaluate>
        </div>
      </ab-collapse>
      <ab-collapse
        :expand="true"
        ref="collapseComplaintsfeedback"
        label=" ABAAD's Complaints/Feedback & Response complaintsMechanism آلية الشكاوى/الملاحظات
        والاستجابة الرسمية لأبعاد"
      >
        <div ref="complaintsMechanism">
          <ab-evaluate
            :model="form.complaintsMechanism"
            @abEvaluateValue="applyModelValue"
            :question="complaintsMechanismQuestion"
            :refernce="'complaintsMechanism'"
            v-model="form.complaintsMechanism"
          ></ab-evaluate>
        </div>

        <div ref="communicationChannels">
          <ab-evaluate
            :model="form.communicationChannels"
            @abEvaluateValue="applyModelValue"
            :question="communicationChannelsQuestion"
            :refernce="'communicationChannels'"
            v-model="form.communicationChannels"
          ></ab-evaluate>
        </div>
      </ab-collapse>
      <ab-collapse
        :expand="true"
        ref="collapseSuggestions"
        label="suggestions مقترحات"
      >
        <div ref="suggestions">
          <p class="font-semibold text-sm mb-1">
            {{ suggestionsQuestion }}
          </p>
          <!-- change to textArea component -->
          <textarea
            class="
                w-full
                h-24
                border border-solid
                p-2
                text-sm
                font-light
                border-gray-300
                rounded-sm
              "
            v-model="form.suggestions"
            :refernce="'suggestions'"
          >
          </textarea>
          <!-- <ab-evaluate
            @abEvaluateValue="applyModelValue"
            v-model="form.suggestions"
          ></ab-evaluate> -->
        </div>
      </ab-collapse>
    </div>
    <div class="mt-12 max-w-2xl m-auto">
      <div
        v-if="!isLoading"
        class="flex flex-col w-full justify-center items-center"
      >
        <ab-button
          class="mt-2 submit-btn"
          label="submit"
          @click="submitAwarenessForm()"
        ></ab-button>
        <ab-button
          :cancel="true"
          class="mt-2"
          label="cancel"
          @click="goBack()"
        ></ab-button>
      </div>
    </div>
  </ab-content-container>
</template>
<script>
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbSelect from "@/components/abaad/AbSelect/AbSelect";
import AbCollapse from "@/components/abaad/AbCollapse/AbCollapse";
import ActivityService from "@/features/Activities/ActivityService";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbEvaluate from "@/components/abaad/AbEvaluate/AbEvaluate";
import UserService from "@/shared/UserService";
import vue from "vue";

import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";

import { required, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";
vue.use("required", required);
vue.use("required", requiredIf);

export default {
  name: "SatisfactionForm",
  mixins: [toasterMixin],
  components: {
    AbContentContainer,
    AbInput,
    AbSelect,
    AbCollapse,
    AbButton,
    AbEvaluate,
  },
  props: {
    activity: {
      type: Object,
      required: false,
    },
    formModel: {
      type: String,
      required: true,
    },
    editService: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      qaData: {},
      isLoading: false,
      title: "New",
      form: {
        submittedDate: "",
        center: "",
        age: "",
        type: "",
        nationality: "",
        overallStatement: "",
        comments: "",
        contentInteresting: "",
        contentUseful: "",
        sessionsUseful: "",
        facilitatorKnowledge: "",
        facilitatorPresentation: "",
        facilitatorRespect: "",
        facilitatorAttitude: "",
        environmentSafe: "",
        powerPointActivities: "",
        timing: "",
        refreshment: "",
        complaintsMechanism: "",
        communicationChannels: "",
        suggestions: "",
        postSessionEager: "",
        postSessionTeach: "",
        postSessionHelp: "",
        postSessionFriend: "",
        postSessionRelation: "",
      },
      countries: [],
    };
  },
  validations() {
    return {
      form: {
        submittedDate: {
          required,
        },
        center: {
          required,
        },
        age: {
          required,
        },
        nationality: {
          required,
        },
        overallStatement: {
          required,
        },
        contentInteresting: {
          required,
        },
        contentUseful: {
          required,
        },
        sessionsUseful: {
          required,
        },
        facilitatorKnowledge: {
          required,
        },
        facilitatorPresentation: {
          required,
        },
        facilitatorRespect: {
          required,
        },
        facilitatorAttitude: {
          required,
        },
        environmentSafe: {
          required,
        },
        powerPointActivities: {
          required,
        },
        timing: {
          required,
        },
        refreshment: {
          required,
        },
        complaintsMechanism: {
          required,
        },
        communicationChannels: {
          required,
        },
        postSessionEager: {
          required: requiredIf(() => {
            this.formModel.includes("PSS");
          }),
        },
        postSessionTeach: {
          required: requiredIf(() => {
            this.formModel.includes("PSS");
          }),
        },
        postSessionHelp: {
          required: requiredIf(() => {
            this.formModel.includes("PSS");
          }),
        },
        postSessionFriend: {
          required: requiredIf(() => {
            this.formModel.includes("PSS");
          }),
        },
        postSessionRelation: {
          required: requiredIf(() => {
            this.formModel.includes("PSS");
          }),
        },
      },
    };
  },
  created() {
    this.getCountries();
    this.getQaData(this.formModel).then((data) => {
      this.qaData = data;
    });

    if (!this.activity.awarnessSatisfactionList) {
      this.activity.awarnessSatisfactionList = [];
    }

    this.form.type = this.formModel.toUpperCase();

    if (!!this.editService) {
      this.title = "Edit ";
      this.populateForm();
    }
  },
  mounted() {
    if (!this.title.includes("Edit")) {
      this.toggleAllSections();
    }
    window.scroll(0, 0);
  },
  methods: {
    populateForm() {
      if (!!this.editService) {
        this.form = this.editService;
        this.setCommonNationality(this.editService.nationality);
        this.setCenter(this.editService.center);
        this.form.submittedDate = moment(this.editService.submittedDate).format(
          "YYYY-MM-DD"
        );
      }
    },
    setCenter(value) {
      setTimeout(() => {
        if (!!this.$refs["center_select"]) {
          this.$refs["center_select"]._data.selected = value;
          this.form.center = value;
        }
      }, 500);
    },
    setCommonNationality(value) {
      if (!!this.$refs["nationality-select"])
        this.$refs["nationality-select"]._data.selected = value;
      this.form.nationality = value;
    },
    goBack() {
      if (!!this.editService) {
        this.$router.push({
          path: "view",
          name: "EvaluationForms",
          params: { activity: this.getCleanActivity(this.activity) },
        });
      } else {
        this.$router.go(-1);
      }
    },
    getCleanActivity(data) {
      console.log("🚀 ~ data:", data.awarnessSatisfactionList);
      data.awarnessSatisfactionList = [this.editService];
      return data;
    },
    toggleAllSections() {
      for (let ref in this.$refs) {
        if (ref.includes("collapse")) {
          this.$refs[ref].toggle();
        }
      }
    },
    applyModelValue(data) {
      this.form[data.model] = data.value;
    },
    getQaData(model) {
      return ActivityService.getActivityAwarenessEvaluationFormQA(model)
        .then((result) => {
          return result.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async getCountries() {
      this.countries = await UserService.getCountries();
    },
    submitAwarenessForm() {
      if (!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          if (!this.formModel.includes("PSS")) {
            delete this.form.postSessionEager;
            delete this.form.postSessionTeach;
            delete this.form.postSessionHelp;
            delete this.form.postSessionFriend;
            delete this.form.postSessionRelation;
          }

          if (!this.editService) {
            this.activity.awarnessSatisfactionList.push(this.form);
          }

          ActivityService.addAwarenessForm(this.activity)
            .then(() => {
              localStorage.setItem(
                "currentActivity",
                JSON.stringify(this.activity)
              );
              if (!!this.editService) {
                this.$router.push({ path: `view` });
              } else {
                this.$router.push({ path: `/activities/current` });
              }
            })
            .catch((error) => console.log(error))
            .finally(() => (this.isLoading = false));
        }
      }
    },
    catchErrors() {
      this.errors = [];

      if (!this.form.submittedDate) {
        let elPosition =
          this.$refs?.submittedDate?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Submitted Date is Required.",
          pos: elPosition,
        });
      }
      if (!this.form.center) {
        let elPosition =
          this.$refs?.center?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Center Field is Required",
          pos: elPosition,
        });
      }
      if (!this.form.age) {
        let elPosition =
          this.$refs?.age?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Age Question is Required",
          pos: elPosition,
        });
      }
      if (!this.form.nationality) {
        let elPosition =
          this.$refs?.nationality?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Nationality Question is Required",
          pos: elPosition,
        });
      }
      if (!this.form.overallStatement) {
        let elPosition =
          this.$refs?.overallStatement?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Overall Statement is required",
          pos: elPosition,
        });
      }
      if (!this.form.contentInteresting) {
        let elPosition =
          this.$refs?.contentInteresting?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Content Interest Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.contentUseful) {
        let elPosition =
          this.$refs?.contentUseful?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Content usefullness Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.sessionsUseful) {
        let elPosition =
          this.$refs?.sessionsUseful?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "session usefullness Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.facilitatorKnowledge) {
        let elPosition =
          this.$refs?.facilitatorKnowledge?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Facilitator Knowledge Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.facilitatorPresentation) {
        let elPosition =
          this.$refs?.facilitatorPresentation?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Facilitator Presentation Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.facilitatorRespect) {
        let elPosition =
          this.$refs?.facilitatorRespect?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Facilitator Respect Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.facilitatorAttitude) {
        let elPosition =
          this.$refs?.facilitatorAttitude?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Facilitator Attitude Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.environmentSafe) {
        let elPosition =
          this.$refs?.environmentSafe?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Environment Safety Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.powerPointActivities) {
        let elPosition =
          this.$refs?.powerPointActivities?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Power Points/Activity Excercises Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.timing) {
        let elPosition =
          this.$refs?.timing?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Timing and Place Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.refreshment) {
        let elPosition =
          this.$refs?.refreshment?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Refreshments Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.complaintsMechanism) {
        let elPosition =
          this.$refs?.complaintsMechanism?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text:
            "Complaints/Feedback and Reponse Mechanism Evaluation is Required",
          pos: elPosition,
        });
      }
      if (!this.form.communicationChannels) {
        let elPosition =
          this.$refs?.communicationChannels?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Communication Channels Evaluation is Required",
          pos: elPosition,
        });
      }
     
      if (this.formModel.includes("PSS") && !this.form.postSessionEager) {
        let elPosition =
          this.$refs?.postSessionEager?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Looking Forward Session Field is Required",
          pos: elPosition,
        });
      }
      if (this.formModel.includes("PSS") && !this.form.postSessionTeach) {
        let elPosition =
          this.$refs?.postSessionTeach?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Session Learning Coping Field is Required",
          pos: elPosition,
        });
      }
      if (this.formModel.includes("PSS") && !this.form.postSessionHelp) {
        let elPosition =
          this.$refs?.postSessionHelp?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Services Access Field is Required",
          pos: elPosition,
        });
      }
      if (this.formModel.includes("PSS") && !this.form.postSessionFriend) {
        let elPosition =
          this.$refs?.postSessionFriend?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Network Support Response Field is Required",
          pos: elPosition,
        });
      }
      if (this.formModel.includes("PSS") && !this.form.postSessionRelation) {
        let elPosition =
          this.$refs?.postSessionRelation?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Relationship Strength Response Field is Required",
          pos: elPosition,
        });
      }
      if (!!this.errors) {
        this.handleArrayErrorsAndScrollToQuestion(this.errors);
      }
    },
  },
  computed: {
    // introductionQuestion() {
    //   return this.qaData.introduction?.question[0];
    // },
    submittedDateQuestion() {
      return "Submitted Date - التاريخ";
    },
    centerQuestion() {
      return this.qaData.center?.question[0];
    },
    centerAnswers() {
      return this.qaData.center?.answers;
    },
    ageQuestion() {
      return this.qaData.age?.question[0];
    },
    nationalityQuestion() {
      return this.qaData.nationality?.question[0];
    },
    overallStatementQuestion() {
      return this.qaData.overallStatement?.question[0];
    },
    commentsQuestion() {
      return this.qaData.comments?.question[0];
    },
    contentInterestingQuestion() {
      return this.qaData.contentInteresting?.question[0];
    },
    contentUsefulQuestion() {
      return this.qaData.contentUseful?.question[0];
    },
    sessionsUsefulQuestion() {
      return this.qaData.sessionsUseful?.question[0];
    },
    facilitatorKnowledgeQuestion() {
      return this.qaData.facilitatorKnowledge?.question[0];
    },
    facilitatorPresentationQuestion() {
      return this.qaData.facilitatorPresentation?.question[0];
    },
    facilitatorRespectQuestion() {
      return this.qaData.facilitatorRespect?.question[0];
    },
    facilitatorAttitudeQuestion() {
      return this.qaData.facilitatorAttitude?.question[0];
    },
    environmentSafeQuestion() {
      return this.qaData.environmentSafe?.question[0];
    },
    powerPointActivitiesQuestion() {
      return this.qaData.powerPointActivities?.question[0];
    },
    timingQuestion() {
      return this.qaData.timing?.question[0];
    },
    refreshmentQuestion() {
      return this.qaData.refreshment?.question[0];
    },
    complaintsMechanismQuestion() {
      return this.qaData.complaintsMechanism?.question[0];
    },
    communicationChannelsQuestion() {
      return this.qaData.communicationChannels?.question[0];
    },
    suggestionsQuestion() {
      return this.qaData.suggestions?.question[0];
    },
    postSessionEagerQuestion() {
      return this.qaData.postSessionEager?.question[0];
    },
    postSessionTeachQuestion() {
      return this.qaData.postSessionTeach?.question[0];
    },
    postSessionHelpQuestion() {
      return this.qaData.postSessionHelp?.question[0];
    },
    postSessionFriendQuestion() {
      return this.qaData.postSessionFriend?.question[0];
    },
    postSessionRelationQuestion() {
      return this.qaData.postSessionRelation?.question[0];
    },
  },
};
</script>
<style lang="scss" scoped>
p.text-xl.font-semibold {
  display: flex;
}
#toggleAllSections {
  display: flex;
  color: white;
  margin-left: auto;
  border-radius: 0;
}

.title-wrapper {
  margin-left: -2.5rem;
  margin-top: -2rem;
}
.flags-wrapper {
  display: flex;
  margin-left: auto;
  .flags {
    display: flex;
    .flag-wrapper {
      width: 25px;
      margin: 0 5px 0 5px;
      img {
        border-radius: 50%;
        cursor: pointer;
      }
      .pr-nationality {
        border: solid 1px black;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
</style>
