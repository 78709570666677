export const methodsMixin = {
    methods: {
        removeOtherFromArray(arr) {
            if (arr.includes("Other/Please Specify - غير ذلك")) {
                let index = arr.indexOf("Other/Please Specify - غير ذلك");
                arr.splice(index, 1);
            }
            return arr;
        },
        addValueIfOtherIsChecked(arr, val, isChecked) {
            if (isChecked) {
                arr.push(val);
            }
            return arr;
        },
    }
}
