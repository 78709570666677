<template>
  <ab-content-container>
    <div class="mx-auto">
      <router-view></router-view>
      <div class="activity-navigation" v-if="isActivityPage">
        <ActivityNavigation></ActivityNavigation>
      </div>
    </div>
  </ab-content-container>
</template>

<script>
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import ActivityNavigation from "../Profile/Navigation/ActivityNavigation.vue";

export default {
  name: "Activities",
  components: {
    AbContentContainer,
    ActivityNavigation,
  },
  data() {
    return {
      activitiesColumnData: [],
      sliceRange: 10,
      searchActivities: [],
      shouldShowTable: false,
      search: "",
    };
  },
  created() {
    window.scrollTo(0, 0);
  },

  mounted() {
    let lastScrollTop = 0;
    let navBar = document.querySelector(".nav-bar.nav-bar-border-bottom");
    document.addEventListener("scroll", () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (!st > lastScrollTop && navBar) {
        navBar.style.marginTop = "0";
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    });
  },
  updated() {},
  computed: {
    isActivityPage() {
      return this.$route.path === "/activities";
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    flex: 1 1 0px;
  }
}
.activity-beneficiaries-table {
  width: 90vw !important;
  margin: 25px auto 0 auto;
}
.current-activity-icon,
.new-activity-icon {
  font-size: 3.5rem;
}
.activity-navigation {
  margin-top: -2.5rem;
}
</style>
