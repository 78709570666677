<template>
  <div>
		<ab-content-container>
      <!-- Start of SocioDemo -->
      <pulse-loader v-if="isLoading" :loading="isLoading" class="loader" color="#2399A4"></pulse-loader>
      <div>
        <p class="text-xl font-semibold">Case Management: <span>{{ Gcode }}</span></p>
        <p class="text-xs text-gray-500 italic">This is where we manage the case</p>
      </div>

      <div class="mt-12 rounded max-w-2xl mx-auto"> 
        <ab-collapse ref="socioDemoCollapse" label="Social Demographic Information">
          <div class="mt-1" ref="reportDate">
            <p class="font-bold text-sm">{{ reportDateQuestion }}</p>
            <ab-input v-model="form.reportDate" class="inline-block" type="date"></ab-input>
          </div>
          
          <div class="mt-12" ref="displacementStatus">
            <p class="font-bold text-sm mb-1">{{ disStatusQuestion }}</p>
            <ab-radio class="mb-2" :key="index" :label="statusAnswer" :value="statusAnswer"
              v-model="form.displacementStatus"
              v-for="(statusAnswer, index) in disStatusAnswers">
            </ab-radio>
          </div>

          <div class="mt-12" ref="education">
            <p class="font-bold text-sm mb-1">{{ educationQuestion }}</p>
            <ab-radio class="mb-2" :key="index" :label="education" :value="education"
              v-for="(education, index) in educationAnswers"
              v-model="form.education">
            </ab-radio>
          </div>
          
          <div class="mt-12" ref="livingArrangements">
            <p class="font-bold text-sm mb-1">{{ livingArrangementsQuestion }}</p>
            <ab-radio class="mb-2" :key="index" :label="livingArrangementAnswer" :value="livingArrangementAnswer"
              v-for="(livingArrangementAnswer, index) in livingArrangementsAnswers"
              v-model="form.livingArrangements">
            </ab-radio>
          </div>
          
          <div class="mt-12" ref="workStatus">
            <p class="font-bold text-sm mb-1">{{ workStatusQuestion }}</p>
            <ab-radio class="mb-2" :key="index" :label="workStatus" :value="workStatus"
              v-for="(workStatus, index) in workStatusAnswers"
              v-model="form.workStatus">
            </ab-radio>
          </div>
          
          <div v-if="shouldShowWorkType" class="mt-12">
            <p class="font-bold text-sm mb-1">{{ workTypeQuestion }}</p>
            <ab-input v-model="form.typeWork" class="inline-block" placeholder="Type of work">
            </ab-input>
          </div>
          
          <div class="mt-12" ref="housingCondition">
            <p class="font-bold text-sm mb-1">{{ housingConditionQuestion }}</p>
            <ab-radio class="mb-2" :key="index" :label="housingCondition" :value="housingCondition"
              v-for="(housingCondition, index) in housingConditionAnswers"
              v-model="form.housingCondition">
            </ab-radio>
          </div>
          
            <div class="mt-12" ref="accomodationType">
            <p class="font-bold text-sm mb-1">{{ accomodationTypeQuestion }}</p>
            <ab-radio class="mb-2" :key="index" :label="accomodationTypeAnswer" :value="accomodationTypeAnswer"
              v-for="(accomodationTypeAnswer, index) in accomodationTypeAnswers"
              v-model="accomodationType">
            </ab-radio>
            <ab-input v-if="shouldShowAccomodationTypeOther" v-model="accomodationTypeOther"
              :full="true" class="inline-block" label="Please specify" 
              placeholder="Accomodation Type">
            </ab-input>
          </div>
                      
          <div class="mt-12" ref="socEcoStatus">
            <p class="font-bold text-sm mb-1">{{ socecoStatusQuestion }}</p>
            <ab-radio class="mb-2" :key="index" :label="socecoStatus" :value="socecoStatus"
              v-for="(socecoStatus, index) in socecoStatusAnswers"
              v-model="form.socEcoStatus">
            </ab-radio>
          </div>

          <div class="mt-12" ref="healthStatus">
            <p class="font-bold text-sm mb-1">{{ healthStatusQuestion }}</p>
            <ab-radio :key="index" :label="healthStatusAnswer" :value="healthStatusAnswer" class="mb-2"
              v-model="healthStatus" v-for="(healthStatusAnswer, index) in healthStatusAnswers">
            </ab-radio>
              <ab-input v-if="shouldShowhealthStatus(healthStatus)" v-model="healthStatusOther"
              :full="true" class="inline-block" label="Please specify" 
              placeholder="Health Status">
            </ab-input>
          </div>

        </ab-collapse>
         <ab-collapse ref="interviewType" class="mt-1" label="Type of Interview">
          <div class="mt-1" >
            <p class="font-bold text-sm mb-1">{{ interviewTypeQuestion }}</p>
          <ab-radio class="mb-2" :key="index" :label="interview" :value="interview"
            v-model="caseManagement.interviewType" v-for="(interview, index) in interviewTypeAnswers">
          </ab-radio>
          </div>
        </ab-collapse>

        <ab-collapse ref="caseType" class="mt-1 list" label="Type of Case" >
         <div>
            <ab-radio :key="index" :label="typeAnswer" v-for="(typeAnswer, index) in caseTypes"
            :value="typeAnswer" v-model="caseManagement.caseType" class="mb-2" :disabled="isEditCaseMode">
          </ab-radio>
         </div>
        </ab-collapse>
      <!-- End of SocioDemo -->

      <!-- Start of CaseManagement -->
      <ab-collapse ref="caseManagement" :label="caseManagement.caseType" class="mt-1">
        <section>       
          <div ref="outsideReferral" v-if="shouldShowCpNonGgbOrShowAll() || showNonGbv()" class="mt-1">
            <p class="font-bold text-sm mb-1">{{ referralQuestion }}</p>
            <ab-radio v-for="(ref, index) in referralAnswers" 
              :key="index" v-model="outsideReferral" :label="ref" :value="ref" class="mb-2">
            </ab-radio>
            
            <ab-input v-if="shouldShowReferralInput" v-model="otherReferral" :full="true" 
              class="inline-block" label="Please specify" placeholder="Survivor referral">
            </ab-input>

          </div>
          <div v-if="shouldShowCpNonGgbOrShowAll()" class="mt-12" ref="incidentType">
            <p class="font-bold text-sm mb-1">{{ incidentTypeQuestion }}</p>
            <ab-radio v-for="(type, index) in incidentTypeAnswers" :key="index" 
              v-model="caseManagement.incident.incidentType" :label="type" :value="type" class="mb-2">
            </ab-radio>
          </div>

          <!-- <div v-if="shouldShowCpNonGgbOrShowAll()" class="mt-12" ref="workingCondition">
            <p class="font-bold text-sm mb-1">{{ workingConditionQuestion }}</p>
            <ab-radio v-for="(workingConditionAnswer, index) in workingConditionAnswers" :key="index" 
              v-model="caseManagement.workingCondition" :label="workingConditionAnswer" :value="workingConditionAnswer" class="mb-2">
            </ab-radio>
          </div> -->
          <div v-if="showCpOrAllAndIncidentIsExploitation" class="mt-12" ref="financialBenefit">
            <p class="font-bold text-sm mb-1">{{ financialBenefitQuestion }}</p>
            <ab-radio v-for="(financialBenefitAnswer, index) in financialBenefitAnswers" :key="index" 
              v-model="caseManagement.financialBenefit" :label="financialBenefitAnswer" :value="financialBenefitAnswer" class="mb-2">
            </ab-radio>
          </div>

          <div v-if="showCpOrAllAndIncidentIsExploitation" class="mt-12" ref="workPerformed">
            <p class="font-bold text-sm mb-1">{{ workPerformedQuestion }}</p>
            <ab-radio v-for="(workPerformedAnswer, index) in workPerformedAnswers" :key="index" 
              v-model="caseManagement.workPerformed" :label="workPerformedAnswer" :value="workPerformedAnswer" class="mb-2">
            </ab-radio>
          </div>

          <div v-if="showCpOrAllAndIncidentIsExploitation" class="mt-12" ref="harmsAndThreats">
            <p class="font-bold text-sm mb-1">{{ harmsAndThreatsQuestion }}</p>
            <ab-radio v-for="(harmsAndThreatsAnswer, index) in harmsAndThreatsAnswers" :key="index" 
              v-model="caseManagement.harmsThreat" :label="harmsAndThreatsAnswer" :value="harmsAndThreatsAnswer" class="mb-2">
            </ab-radio>
          </div>

          <div v-if="shouldShowCpNonGgbOrShowAll()" class="mt-12" ref="incidentTraditionalPractice">
            <p class="font-bold text-sm mb-1">{{ incidentTraditionQuestion }}</p>
            <ab-radio v-for="(tradition, index) in incidentTraditionAnswers"
              :key="index" v-model="incidentTraditionalPractice" :label="tradition" :value="tradition" 
              class="mb-2">
            </ab-radio>

            <ab-input v-if="shouldShowIncidentTraditionInput" v-model="otherIncidentTraditionalPractice"
              :full="true" class="inline-block" label="Please specify" 
              placeholder="Incident traditional practice">
            </ab-input>

          </div>
          <div v-if="shouldShowCpNonGgbOrShowAll()" class="mt-12" ref="incidentTrade">
            <p class="font-bold text-sm mb-1">{{ incidentTradeQuestion }}</p>
            <ab-radio v-for="(trade, index) in incidentTradeAnswers" 
              :key="index" v-model="incidentTrade" :label="trade" :value="trade" class="mb-2">
            </ab-radio>
          </div>

          <div v-if="showAll()" class="mt-12" ref="incidentFirstDate">
            <p class="font-bold text-sm mb-1">{{ incidentDateQuestion }}</p>
            <ab-input v-model="caseManagement.incident.incidentFirstDate"
              :full="true" class="inline-block" type="date">
            </ab-input>
          </div>

          <div v-if="showAll()" class="mt-12" ref="incidentLastDate">
            <p class="font-bold text-sm mb-1">{{ actualIncidentQuestion }}</p>
            <ab-input v-model="caseManagement.incident.incidentLastDate"
              :full="true" class="inline-block" type="date">
            </ab-input>
          </div>
          
          <!-- <div v-if="showAll()" class="mt-12" ref="survivorMinorInfo">
            <p class="font-bold text-sm mb-1">{{ survivorUnaccimpaniedQuestion }}</p>
            <ab-radio v-for="(minorInfo, index) in survivorUnaccimpaniedAnswers" class="mb-2"
              :key="index" v-model="caseManagement.incident.survivorMinorInfo" :label="minorInfo" :value="minorInfo">
            </ab-radio>
          </div> -->

          <div v-if="showAll()" class="mt-12" ref="incidentDisplacement">
            <p class="font-bold text-sm mb-1">{{ incidentDisplacementQuestion }}</p>
            <ab-radio v-for="(displacement, index) in incidentDisplacementAnswers" class="mb-2"
              :key="index" v-model="caseManagement.incident.incidentDisplacement" :label="displacement" 
              :value="displacement">
            </ab-radio>
          </div>

          <div v-if="showAll()" class="mt-12" ref="incidentDayPeriod">
            <p class="font-bold text-sm mb-1">{{ incidentTimeQuestion }}</p>
            <ab-radio v-for="(time, index) in incidentTimeAnswers" class="mb-2"
              :key="index" v-model="caseManagement.incident.incidentDayPeriod" :label="time" :value="time">
            </ab-radio>
          </div>

          <div v-if="showAll()" class="mt-12" ref="incidentLocation">
            <p class="font-bold text-sm mb-1">{{ incidentLocationQuestion }}</p>
            <ab-radio v-for="(location, index) in incidentLocationAnswers" class="mb-2"
              :key="index" v-model="incidentLocation" :label="location" :value="location">
            </ab-radio>
            <ab-input v-if="shouldShowIncidentLocationInput" v-model="otherIncidentLocation"
              :full="true" class="inline-block" label="Please specify" placeholder="Incident Location">
            </ab-input>
          </div>

          <div v-if="showAll()" class="mt-12" ref="incidentArea">
            <p class="font-bold text-sm mb-1">{{ incidentAreaQuestion }}</p>
            <ab-radio v-for="(area, index) in incidentAreaAnswers"
              :key="index" v-model="incidentArea" :label="area" :value="area" class="mb-2">
            </ab-radio>

            <ab-input v-if="shouldShowIncidentAreaInput" v-model="otherIncidentArea" :full="true"
              class="inline-block" label="Please specify" placeholder="Incident area">
            </ab-input>
          </div>
      <!-- 22 -->
      <div v-if="showAll()" class="mt-12" ref="incidentGovernate">
        <p class="font-bold text-sm mb-1">{{ incidentGovernateQuestion }}</p>
        <ab-radio v-for="(governate, index) in filteredGovernoratesAnswers"
                  :key="index"
                  v-model="incidentGovernate"
                  :label="governate"
                  :value="governate"
                  class="mb-2"></ab-radio>
        <ab-input v-if="shouldShowIncidentGovernateInput"
                  v-model="otherIncidentGovernate"
                  :full="true"
                  class="inline-block"
                  label="Please specify"
                  placeholder="Incident Governate"></ab-input>
      </div>
        
          <div v-if="showAll()" class="mt-12" ref="incidentVillage">
            <p class="font-bold text-sm mb-1">{{ incidentVillageQuestion }}</p>
            <ab-input v-model="caseManagement.incident.incidentVillage" :full="true"
              class="inline-block" label="Incident Village" placeholder="Village name">
            </ab-input>
          </div>

          <div v-if="showAll()" class="mt-12" ref="incidentFrequency">
            <p class="font-bold text-sm mb-1">{{ incidentFrequencyQuestion }}</p>
            <ab-radio v-for="(frequency, index) in incidentFrequencyAnswers" class="mb-2"
              :key="index" v-model="caseManagement.incident.incidentFrequency" :label="frequency" :value="frequency">
            </ab-radio>
          </div>

          <div v-if="showAll()" class="mt-12" ref="incidentDetails">
            <p class="font-bold text-sm mb-1">{{ incidentDescriptionQuestion }}</p>
            <textarea v-model="caseManagement.incident.incidentDetails"
              class="w-full h-24 border border-solid border-secondary rounded-sm">
            </textarea>
          </div>
          
          <div v-if="showAll()" class="mt-12" ref="incidentAbductionType">
            <p class="font-bold text-sm mb-1">{{ incidentAbductionQuestion }}</p>
            <ab-radio v-for="(abduction, index) in incidentAbductionAnswers" class="mb-2"
              :key="index" v-model="incidentAbductionType" :label="abduction" :value="abduction">
            </ab-radio>
            <ab-input v-if="shouldShowAbductionTypeInput" v-model="otherIncidentAbductionType"
              :full="true" class="inline-block" label="Please specify" placeholder="Abduction Type">
            </ab-input>
          </div>
        <!--       Change YES answers from backend to be one and change answer selection in ammendement/edit here-->
          <div v-if="showAll()" class="mt-12" ref="incidentReportedElsewhere">
            <p class="font-bold text-sm mb-1">{{ incidentReportedQuestion }}</p>
            <ab-radio v-for="(reported, index) in incidentReportedAnswers" :key="index"
              v-model="incidentReportedElsewhere" :label="reported" :value="reported" class="mb-2">
            </ab-radio>
            <ab-input v-if="shouldShowIncidentReportedElsewhereInput" v-model="otherIncidentReportedElsewhere"
              :full="true" class="inline-block" label="Please specify" placeholder="Incident report">
            </ab-input>
          </div>

          <div v-if="showAll()" class="mt-12" ref="survivorPreviousGBV">
            <p class="font-bold text-sm mb-1">{{ incidentPreviousQuestion }}</p>
            <ab-radio v-for="(previous, index) in incidentPreviousAnswers" :key="index" class="mb-2"
              v-model="caseManagement.survivor.survivorPreviousGBV" :label="previous" :value="previous">
            </ab-radio>
          </div>

          <div v-if="showAll()" class="mt-12" ref="survivorSupportNetwork">
            <p class="font-bold text-sm mb-1">{{ incidentSupportNetworkQuestion }}</p>
            <ab-radio v-for="(support, index) in incidentSupportNetworkAnswers" :key="index"
              v-model="survivorSupportNetwork" :label="support" :value="support" class="mb-2">
            </ab-radio>
            <ab-input v-if="shouldShowSupportNetworkInput" v-model="otherSurvivorSupportNetwork"
              :full="true" class="inline-block" label="Please specify" placeholder="Support network">
            </ab-input>
          </div>

          <div v-if="showAll()" class="mt-12" ref="survivorRiskAssesmentLevel">
            <p class="font-bold text-sm mb-1">{{ incidentRiskAssessmentQuestion }}</p>
            <ab-radio v-for="(risk, index) in incidentRiskAssessmentAnswers" :value="risk"
              :key="index" v-model="caseManagement.survivor.survivorRiskAssesmentLevel" :label="risk" class="mb-2">
            </ab-radio>
          </div>
          <div v-if="showAll() && caseManagement.caseType == 'GBV'" class="mt-12" ref="perpNumber">
            <p class="font-bold text-sm mb-1">{{ allegPerpNumberQuestion }}</p>
            <ab-radio v-for="(perpNumber, index) in allegPerpNumberAnswers" :key="index" :label="perpNumber"
              v-model="caseManagement.perpetrator.number" :value="perpNumber" class="mb-2">
            </ab-radio> 
          </div>

          <div v-if="showAll()" class="mt-12" ref="perpRelationship">
            <p class="font-bold text-sm mb-1">{{ allegPerpRelationshipQuestion }}</p>
            <div v-for="(rs, index) in allegPerpRelationshipAnswers" :key="index" class="mt-4">
              <label :for="rs" class="flex items-center text-sm font-light">
                <input v-model="caseManagement.perpetrator.relationship" :value="rs" type="checkbox">
                <span class="ml-2">{{ rs }}</span>
              </label>
            </div>
            <ab-input v-if="shouldShowAllegPerpRelationshipInput" v-model="caseManagement.otherPerpRelationship"
              :full="true" class="inline-block" label="Please specify" placeholder="Relationship">
            </ab-input>
          </div>

          <div v-if="showAll()" class="mt-12" ref="perpSex">
            <p class="font-bold text-sm mb-1">{{ allegPerpSexQuestion }}</p>
            <ab-radio v-for="(sex, index) in allegPerpSexAnswers" 
              v-model="caseManagement.perpetrator.sex"   
              :key="index" :label="sex" :value="sex" class="mb-2">
            </ab-radio>
          </div>

          <div v-if="showAll()" class="mt-12" ref="perpAge">
            <p class="font-bold text-sm mb-1">{{ allegPerpAgeQuestion }}</p>
            <ab-radio v-for="(age, index) in allegPerpAgeAnswers" v-model="caseManagement.perpetrator.age"
              :key="index" :label="age" :value="age" class="mb-2">
            </ab-radio>
          </div>
          
          <div v-if="showAll()" class="mt-12" ref="perpMainOccupation">
            <p class="font-bold text-sm mb-1">{{ allegPerpMainOccupQuestion }}</p>
            <ab-radio v-for="(occupation, index) in allegPerpMainOccupAnswers"
              :key="index" v-model="mainOccupation" :label="occupation" :value="occupation" class="mb-2">
            </ab-radio>
            <ab-input v-if="shouldShowMainOccupationInput" v-model="otherMainOccupation" :full="true"
              class="inline-block" label="Please specify"  placeholder="Main occupation">
            </ab-input>
          </div>

          <div v-if="showAll()" class="mt-12" ref="emotionalStateBeginning">
            <p class="font-bold text-sm mb-1">{{ assessmentPointEmoStateStartQuestion }}</p>
            <div v-for="(emoStart, index) in assessmentPointEmoStateStartAnswers" :key="index" class="mt-4">
              <label :for="emoStart" class="flex items-center text-sm font-light">
                <input v-model="caseManagement.assesmentPoint.emotionalStateBeginning" :value="emoStart" type="checkbox">
                <span class="ml-2">{{ emoStart }}</span>
              </label>
            </div>
            <ab-input v-if="shouldShowEmoStateStartInput" v-model="caseManagement.otherEmoStateBeginning" :full="true"
              class="inline-block mt-4" label="Please specify" placeholder="Beginning emotional State">
            </ab-input>
          </div>

          <div v-if="showAll()" class="mt-12" ref="emotionalStateEnding">
            <p class="font-bold text-sm mb-1">{{ assessmentPointEmoStateEndQuestion }}</p>
            <div v-for="(emoEnd, index) in assessmentPointEmoStateEndAnswers" :key="index" class="mt-4">
              <label :for="emoEnd" class="flex items-center text-sm font-light">
                <input v-model="caseManagement.assesmentPoint.emotionalStateEnding" :value="emoEnd" type="checkbox">
                <span class="ml-2">{{ emoEnd }}</span>
              </label>
            </div>
            <ab-input v-if="shouldShowEmoStateEndInput" v-model="caseManagement.otherEmoStateEnd" :full="true"
              class="inline-block mt-4" label="Please specify" placeholder="Ending emotional State">
            </ab-input>
          </div>

          <div v-if="showAll()" class="mt-12" ref="rapeHealthInform">
            <p class="font-bold text-sm mb-1">{{ assessmentPointExplainedQuestion }}</p>
            <ab-radio v-for="(explained, index) in assessmentPointExplainedAnswers" :key="index" class="mb-2"
              v-model="caseManagement.assesmentPoint.rapeHealthInform" :label="explained" :value="explained">
            </ab-radio>
          </div>
          <div v-if="showAll()" class="mt-12" ref="consent">
            <p class="font-bold text-sm mb-1">{{ assessmentPointShareDataQuestion }}</p>
            <ab-radio v-for="(shareData, index) in assessmentPointShareDataAnswers"
              :key="index" v-model="consent" :label="shareData" :value="shareData" class="mb-2">
            </ab-radio>
          </div>
        </section>
      </ab-collapse>
      <!-- End of CaseManagement -->
    </div>
    <!-- Start of Submission -->
     <div class="flex flex-col items-center justify-center mx-auto mt-4" ref="reportDate">
      <button v-if="!isLoading && !isSubmitting"
        class="py-2 px-20 bg-primary text-white rounded mt-2 active:transform active:translate-y-1 active:outline-none hover:bg-secondary"
        @click="!editCase ? submitCase(form.reportDate) : saveCase(form.reportDate)"> Submit
      </button>
      <ab-button v-if="!isLoading" :cancel="true" class="mt-2" label="Cancel" @click="routeToNavigation()"></ab-button>
    </div>
    <!-- End of Submission -->
    </ab-content-container>
  </div>
</template>
<script>
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import AbCollapse from "@/components/abaad/AbCollapse/AbCollapse";
import PulseLoader from "vue-spinner/src/PulseLoader";
import moment from "moment";
import _ from "lodash";
import AbButton from "@/components/abaad/AbButton/AbButton";
import vue from 'vue';
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { utilMixin } from "@/mixins/forms/utilMixin";

import { required,
  minValue,
  maxValue,
  requiredIf
 } from 'vuelidate/lib/validators';

import { mapGetters, mapActions } from 'vuex';
vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);
export default {
	name: 'socio-demo',
  mixins: [toasterMixin, utilMixin],
	data() {
		return {
      // socioDemo data
      isReferralInputOther: false,
      consent: "",
      incidentTrade: "",
      incidentReported: "",
      incidentPrevious: "",
      survivorMinorInfo: "",
      lastSixMonths: "",
      healthStatus: "",
      healthStatusOther: "",
			isLoading: null,
      isSubmitting: null,
      socioDemoData: {},
      gbvData: {},
      form: {
        gbvmis: "",
        reportDate: "",
        displacementStatus: "",
        education: "",
        workStatus: "",
        socEcoStatus: "",
        healthStatus: "",
        typeWork: "",
        livingArrangements: "",
        housingCondition: "",
        accomodationType: "",
        healthStatusOther: ""
      },
      disStatus: "",
      otherDisStatus: "",
      resultDisStatus: "",
      caseManagement: {
        workingCondition: "",
        financialBenefit: "",
        workPerformed: "",
        harmsThreat: "",
        otherEmoStateBeginning: "",
        otherEmoStateEnd: "",
        otherPerpRelationship: "",
        assesmentPoint: {
          consent: null,
          emotionalStateBeginning: [],
          emotionalStateEnding: [],
          rapeHealthInform: "",
          safeToLeave: "",
          safetyAction: ""
        },
        caseType: "GBV",
        gbvmis: "",
        incident: {
          incidentAbductionType: "",
          incidentArea: "",
          incidentDayPeriod: "",
          incidentDetails: "",
          incidentDisplacement: "",
          incidentFirstDate: "",
          incidentFrequency: "",
          incidentGovernate: "",
          incidentLastDate: "",
          incidentPrevious: null,
          incidentLocation: "",
          incidentReported: "",
          incidentReportedElsewhere: "",
          incidentTrade: null,
          incidentTraditionalPractice: "",
          incidentType: "",
          incidentVillage: ""
        },
        interviewType: "",
        outsideReferral: "",
        perpetrator: {
          age: "",
          mainOccupation: "",
          number: null,
          relationship: [],
          sex: ""
        },
        survivor: {
          referral: "",
          survivorMinorInfo: "",
          survivorPreviousGBV: "",
          survivorRiskAssesmentLevel: "",
          survivorSupportNetwork: ""
        },
        submittedDate: "",
      },
      error: "",
      mainOccupation: "",
      otherMainOccupation: "",
      resultMainOccupation: "",
      relationship: "",
      otherRelationship: "",
      resultRelationship: "",
      emoStateBeginning: [],
      otherEmoStateBeginning: [],
      resultEmoStateBeginning: [],
      incidentAbductionType: "",
      otherIncidentAbductionType: "",
      resultIncidentAbductionType: "",
      incidentArea: "",
      otherIncidentArea: "",
      resultIncidentArea: "",
      incidentGovernate: "",
      otherIncidentGovernate: "",
      resultIncidentGovernate: "",
      incidentLocation: "",
      otherIncidentLocation: "",
      resultIncidentLocation: "",
      survivorSupportNetwork: "",
      otherSurvivorSupportNetwork: "",
      resultSurvivorSupportNetwork: "",
      incidentTraditionalPractice: "",
      otherIncidentTraditionalPractice: "",
      resultIncidentTraditionalPractice: "",
      outsideReferral: "",
      otherReferral: "",
      resultReferral: "",
      incidentReportedElsewhere: "",
      otherIncidentReportedElsewhere: "",
      resultIncidentReportedElsewhere: "",
      emoStateEnd: [],
      otherEmoStateEnd: [],
      resultEmoStateEnd: [],
      perRelationship: [],
      resultPerpRelationship: [],
      filteredGovernoratesAnswers: [],
      accomodationType: "",
      accomodationTypeOther: "",
      resultAccomodationType: "",
      resultHealthStatus: ""

		}
	},
  validations() {
    return {
      form: {
        reportDate: {
          required,
          minValue: (value) => {
             let returnValue;
            if(!!this.allServices.counseling && this.lastCounselingSessionDate) {
              returnValue = value >= this.lastCounselingSessionDate;
            } else {
              returnValue = value >= this.lastSixMonths;
            }
            
            return returnValue;
          },
          maxValue: (value) => {
            return value < this.tomorrowsDate
          },
        },
        displacementStatus: {
          required
        },
        education: {
          required
        },
        livingArrangements: {
          required
        },
        workStatus: {
          required
        },
        housingCondition: {
          required
        },
        accomodationType: {
          required
        },
        socEcoStatus: {
          required
        },
      },
      healthStatus: {
        required
      },
      outsideReferral: {
        required: requiredIf(() => this.shouldShowCpNonGgbOrShowAll() || this.showNonGbv())
      },
      incidentTraditionalPractice: {
        required: requiredIf(() => this.shouldShowCpNonGgbOrShowAll())
      },
      incidentTrade: {
        required: requiredIf(() => this.shouldShowCpNonGgbOrShowAll())
      },
      incidentLocation: {
        required: requiredIf(() => this.showAll())
      },
      incidentAbductionType: {
        required: requiredIf(() => this.showAll())
      },
      incidentReportedElsewhere: {
        required: requiredIf(() => this.showAll())
      },
      survivorSupportNetwork: {
        required: requiredIf(() => this.showAll())
      },
      mainOccupation: {
        required: requiredIf(() => this.showAll())
      },
      consent: {
        required: requiredIf(() => this.showAll())
      },
      caseManagement: {
        incident: {
          incidentFirstDate: {
            required: requiredIf(() => this.showAll())
          },
          incidentLastDate: {
            required: requiredIf(() => this.showAll())
          },
          // survivorMinorInfo: {
          //   required: requiredIf(() => this.showAll())
          // },
          incidentDisplacement: {
            required: requiredIf(() => this.showAll())
          },
          incidentDayPeriod: {
            required: requiredIf(() => this.showAll())            
          },
          incidentArea: {
            required: requiredIf(() => this.showAll())
          },
          incidentGovernate: {
            required: requiredIf(() => this.showAll())
          },
          incidentVillage: {
            required: requiredIf(() => this.showAll())
          },
          incidentFrequency: {
            required: requiredIf(() => this.showAll())
          },
          incidentDetails: {
            required: requiredIf(() => this.showAll())
          }
        },
        survivor: {
          survivorPreviousGBV: {
            required: requiredIf(() => this.showAll())
          },
          survivorRiskAssesmentLevel: {
            required: requiredIf(() => this.showAll())
          },
        },
        perpetrator: {
          number: {
            required: requiredIf(() => this.showAll())
          },
          relationship: {
            required: requiredIf(() => this.showAll())
          },
          sex: {
            required: requiredIf(() => this.showAll())
          },
          age: {
            required: requiredIf(() => this.showAll())
          },
        },
        assesmentPoint: {
          emotionalStateBeginning: {
            required: requiredIf(() => this.showAll())
          },
          emotionalStateEnding: {
            required: requiredIf(() => this.showAll())
          },
          rapeHealthInform: {
            required: requiredIf(() => this.showAll())
          },
        },
        interviewType: {
          required
        },
        caseType: {
          required
        },
        // workingCondition: {
        //   required: requiredIf(() => this.showCpOrAllAndIncidentIsExploitation)
        // },
        financialBenefit: {
          required: requiredIf(() => this.showCpOrAllAndIncidentIsExploitation)
        },
        workPerformed: {
          required: requiredIf(() => this.showCpOrAllAndIncidentIsExploitation)
        },
        harmsThreat: {
          required: requiredIf(() => this.showCpOrAllAndIncidentIsExploitation)
        }
      }
    } 
  },
	components: {
    PulseLoader,
		AbContentContainer,
    AbInput,
    AbRadio,
    AbCollapse,
    AbButton
	},
  props: {
    editSocDemo: {
      type: Object,
      required: false,
    },
    editCase: {
      type: Object,
      required: false,
    },
  },
  created() {
    this.isLoading = false;
    this.caseManagement.gbvmis = this.$route.params.id;
    this.getSocioDemoData();
    this.getGbvData();

    this.getAllServices().then(() => {
      if(!!this.allServices && !!this.allServices.counseling) {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      } else {
        this.lastSixMonths = moment().subtract(6, 'months').format("YYYY-MM-DD");
      }
    })
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD");
    
  },
	methods: {
    ...mapActions([
      'fetchAllServices',
      'getSocioDemoQAData',
      'getGbvCaseQAData',
      'updateCaseData',
      'saveSocioDemoData',
      'updateSocioDemoData',
      'saveGBVData',
      'saveNonGBVData',
      'saveNonCPGBVData',
      'saveCPGBVData']),
    
    catchErrors() {
      this.errors = [];
      if(!this.submitDateExists()) {
        this.errors.push({text: "Submitted Date is Required.", pos: 0});
      }
      if(this.submitDateHasError()) {
        if(!!this.allServices.counseling && this.latestCounselingServiceDate) {
          this.errors.push({text: "Beneficiary has a Counselling Service, Submitted Date must be greater than last Counselling and less than tomorrow.", pos: 0});
        } else {
          this.errors.push({text: "Submitted Date Must be Between Six Months and Today's Date", pos: 0});
        }
      }
      if(!this.form.displacementStatus) {
        let el = this.$refs?.displacementStatus;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Displacement Status Field is Required', pos: elPosition})
      }
      if(!this.form.education) {
        let el = this.$refs?.education;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Education Field is Required', pos: elPosition})
      }          
      if(!this.form.livingArrangements) {
        let elPosition = this.$refs?.livingArrangements?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Living Arrangements Field is Required', pos: elPosition})
      }
      if(!this.form.workStatus) {
        let elPosition = this.$refs?.workStatus?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Work Status Field is Required', pos: elPosition})
      }
      if(!this.form.housingCondition) {
        let elPosition = this.$refs?.housingCondition?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Housing Conodition Field is Required', pos: elPosition})
      }
      if(!this.form.accomodationType) {
        let elPosition = this.$refs?.accomodationType?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Accomodation Type Field is Required', pos: elPosition})
      }
      if(!this.form.socEcoStatus) {
        let elPosition = this.$refs?.socEcoStatus?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Socioeconomic Status Field is Required', pos: elPosition})
      }
      if(!this.healthStatus) {
        let elPosition = this.$refs?.healthStatus?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Health Status Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.interviewType) {
        let el = this.$refs?.interviewType?.$el;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Interview Type Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.caseType) {
        let el = this.$refs?.caseType;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Case Type Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.outsideReferral) {
        let el = this.$refs?.outsideReferral;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Referral Field is Required', pos: elPosition})
      }
      // if(!this.caseManagement.workingCondition && this.showCpOrAllAndIncidentIsExploitation) {
      //   let el = this.$refs?.workingCondition;
      //   let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
      //   this.errors.push({text: 'Working Condition Field is Required', pos: elPosition})
      // }
      if(!this.caseManagement.financialBenefit && this.showCpOrAllAndIncidentIsExploitation) {
        let el = this.$refs?.financialBenefit;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Financial Benefit Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.workPerformed && this.showCpOrAllAndIncidentIsExploitation) {
        let el = this.$refs?.workPerformed;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Work Performed Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.harmsThreat && this.showCpOrAllAndIncidentIsExploitation) {
        let el = this.$refs?.harmsAndThreats;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Harms & Threats Field is Required', pos: elPosition})
      }
      // 
      if(!this.incidentTraditionalPractice && this.shouldShowCpNonGgbOrShowAll()) {
        let el = this.$refs?.incidentTraditionalPractice;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Incident Traditional Practice Field is Required', pos: elPosition})
      }
      if(!this.incidentTrade && this.shouldShowCpNonGgbOrShowAll()) {
        let el = this.$refs?.incidentTrade;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Incident Trade Field is Required', pos: elPosition})
      }
      if(!this.incidentLocation && this.showAll()) {
        let el = this.$refs?.incidentLocation;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Incident Location Field is Required', pos: elPosition})
      }
      if(!this.incidentAbductionType && this.showAll()) {
        let el = this.$refs?.incidentAbductionType;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Incident Abduction Type Field is Required', pos: elPosition})
      }
      if(!this.incidentReportedElsewhere && this.showAll()) {
        let el = this.$refs?.incidentReportedElsewhere;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Incident Reported Elsewhere Field is Required', pos: elPosition})
      }
      if(!this.survivorSupportNetwork && this.showAll()) {
        let el = this.$refs?.survivorSupportNetwork;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Survivor Support Network Field is Required', pos: elPosition})
      }
      if(!this.mainOccupation && this.showAll()) {
        let el = this.$refs.perpMainOccupation;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Perpetrator's Main Occupation Field is Required", pos: elPosition})
      }
      // incident
      if(!this.caseManagement.incident.incidentArea && this.showAll()) {
        let el = this.$refs.incidentArea;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Incident Area Field is Required", pos: elPosition})
      }
      if(!this.caseManagement.incident.incidentGovernate && this.caseManagement.incident.incidentArea && this.showAll()) {
        let el = this.$refs.incidentGovernate;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Incident Governate Field is Required", pos: elPosition})
      }
      if(!this.caseManagement.incident.incidentVillage && this.showAll()) {
        let el = this.$refs.incidentVillage;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Incident Village Field is Required", pos: elPosition})
      } 
      if(!this.caseManagement.incident.incidentFrequency && this.showAll()) {
        let el = this.$refs.incidentFrequency;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Incident Frequency Field is Required", pos: elPosition})
      }
      if(!this.caseManagement.incident.incidentDetails && this.showAll()) {
        let el = this.$refs.incidentDetails;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Account of Incident Details Field is Required", pos: elPosition})
      }
      if(!this.caseManagement.incident.incidentFirstDate && this.showAll()) {
         let el = this.$refs.incidentFirstDate;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Incident First Date Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.incident.incidentLastDate && this.showAll()) {
         let el = this.$refs.incidentLastDate;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Incident Last Date Field is Required', pos: elPosition})
      }
      // if(!this.caseManagement.incident.survivorMinorInfo && this.showAll()) {
      //    let el = this.$refs.survivorMinorInfo;
      //   let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
      //   this.errors.push({text: 'Survivor Minor Information Field is Required', pos: elPosition})
      // }
      if(!this.caseManagement.incident.incidentDisplacement && this.showAll()) {
         let el = this.$refs.incidentDisplacement;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Incident Displacement Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.incident.incidentDayPeriod && this.showAll()) {
        let el = this.$refs.incidentDayPeriod;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Incident Day Period Field is Required', pos: elPosition})
      }
      if(!this.consent && this.showAll()) {
        let el = this.$refs.consent;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Consent Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.survivor.survivorPreviousGBV && this.showAll()) {
        let el = this.$refs.survivorPreviousGBV;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Survivor is Previous GBV Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.survivor.survivorRiskAssesmentLevel && this.showAll()) {
        let el = this.$refs.survivorRiskAssesmentLevel;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Survivor Risk Assesment Level is Required', pos: elPosition})
      }
      if(!this.caseManagement.assesmentPoint.emotionalStateBeginning.length && this.showAll()) {
        let el = this.$refs.emotionalStateBeginning;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Emotional State Beginning Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.assesmentPoint.emotionalStateEnding.length && this.showAll()) {
        let el = this.$refs.emotionalStateEnding;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Emotional State Ending Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.assesmentPoint.rapeHealthInform && this.showAll()) {
        let el = this.$refs.rapeHealthInform;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Rape Health Information Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.perpetrator.number && this.showAll()) {
        let el = this.$refs.perpNumber;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Perpetrator Number Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.perpetrator.relationship.length && this.showAll()) {
        let el = this.$refs.perpRelationship;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Perpetrator RelationShip Information Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.perpetrator.sex && this.showAll()) {
        let el = this.$refs.perpSex;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Perpetrator Gender Information Field is Required', pos: elPosition})
      }
      if(!this.caseManagement.perpetrator.age && this.showAll()) {
        let el = this.$refs.perpAge;
        let elPosition = el?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: 'Perpetrator Age Information Field is Required', pos: elPosition})
      }
      this.handleArrayErrorsAndScrollToQuestion(this.errors);
    },
    routeToNavigation() {
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    async getSocioDemoData() {
      this.isLoading = true;
      await this.getSocioDemoQAData().then(() => {
        this.isLoading = false;
        this.socioDemoData = this.socioDemoQA;
        // this.socioDemoData.healthstatus.answers.push("Other specify - غيره حدد")
      });
      if (!!this.editSocDemo) {
        this.populateEditSocDemo();
        this.expandSections();
      }
    },
    async getAllServices() {
      await this.fetchAllServices(this.$route.params.id);
    },
    async populateEditSocDemo() {
      this.form = this.editSocDemo;
      this.form.reportDate = moment(new Date(this.editSocDemo.reportDate)).format("YYYY-MM-DD");
      this.form.livingArrangements = this.editSocDemo.livingArrangements;
      this.caseManagement = this.editCase;
      this.ammendEditCaseOtherInput(
        this.accomodationTypeAnswers,
        this.editSocDemo.accomodationType,
      ).then((result) => {
        this.accomodationTypeOther = result[1];
        this.accomodationType = result[0];
      });
       this.ammendEditCaseOtherInput(
        this.healthStatusAnswers,
        this.editSocDemo.healthStatus,
      ).then((result) => {
        this.healthStatusOther = result[1];
        this.healthStatus = result[0];
      });
    },
    async getGbvData() {
      this.isLoading = true;
      await this.getGbvCaseQAData().then(() => {
        this.isLoading = false;
        this.gbvData = this.gbvCaseQA;
      })
      if (!!this.editCase) {
        this.populateEditCase();
      }
      
    },
    // Start SocioDemo Methods 
    async saveCase() {
      this.expandSections();
      setTimeout(() => {

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isSubmitting = true;
          const form = { ...this.form };

          if (!this.form.workStatus.toLowerCase().includes('working')) {
            delete form.typeWork;
          }
          const caseManagement = this.chooseCase(this.caseManagement.caseType);
          try {
            form.gbvmis = this.$route.params.id;
            if (this.hasEmptyField({ ...form, ...caseManagement })) {
                return;
            }
            this.getUpdateRoute();
            this.updateSocioDemoData(form)
          } catch (e) {
              console.log(e.message);
          } finally {
              this.isSubmitting = false;
              this.routeToNavigation();
          }
        }
      },100)
    },
    expandSections() {
      let refs = ['socioDemoCollapse', 'interviewType', 'caseType', 'caseManagement'];
      refs.forEach((ref) => {
        const isExpanded = this.$refs[ref].$el.classList.contains('expanded')
        if(!isExpanded) {
          this.$refs[ref].$el.firstChild.click();
        }
      })
    },
    async submitCase(reportDate) {
      this.expandSections();
      setTimeout(() => {
        this.$v.$touch();
        if (this.$v.$invalid) {
        this.catchErrors();
      } else {
        this.isSubmitting = true;
        const form = { ...this.form };

        if (!this.form.workStatus.toLowerCase().includes('working')) {
          delete form.typeWork;
        }
        this.caseManagement = this.addReportDateToCaseManagement(this.caseManagement, reportDate);

        const caseManagement = this.chooseCase(this.caseManagement.caseType);

        try {
          form.gbvmis = this.$route.params.id;
          if (this.hasEmptyField({ ...form, ...caseManagement })) {
            return;
          }
          const submitData = this.getPostRoute();
           this.saveSocioDemoData(form)
            .then(response => {
                this.handleSuccess();
                return response
            })
            .catch(error => {
                this.handleErrors(error);
            });
            submitData.then(() => {
                localStorage.setItem("hasCase", true);
            })
            .then(() => this.$router.push({ path: `/bene/${this.routeId()}` }))
            .catch(error => {
                this.handleErrors(error)
            })
            .finally(() => this.isSubmitting = false);
        } catch (e) {
            this.handleErrors(e)
        } finally {
            this.isSubmitting = false;
        }
      }
      },100)
      
    },
    submitDateExists() {
      return !!this.form.reportDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    dateHasError() {
      if(!!this.allServices.counseling && this.latestCounselingServiceDate) {
          return this.validateDateOn(this.latestCounselingServiceDate);
      } else {
          return this.validateDateOn(this.lastSixMonths);
      }
    },
    validateDateOn(dateToValidateOn) {
        var formattedUserInput = moment(this.form.reportDate).format("YYYY-MM-DD");
        var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
        var userInputGreaterDateToValidateOn = moment(formattedUserInput).isSameOrAfter(dateToValidateOn);
  
        let dateHasValidationError;
        if (userInputLessThanTomorrowDate && userInputGreaterDateToValidateOn) {
          dateHasValidationError = false;
        } else {
          dateHasValidationError = true;
        }
  
        return dateHasValidationError;
    },
    hasEmptyField() {
        let output = "";
        // const errorOutput = { ...this.errorOutput };

        // if (!this.form.workStatus.toLowerCase().includes("working")) {
        //     delete errorOutput.typeWork;
        // }

        // for (const [key, val] of Object.entries(obj)) {
        //     if (typeof val === 'object') {
        //         for (const [key, val] of Object.entries(val)) {
        //             if (typeof val === 'boolean') continue;
        //             if (_.isEmpty(val) || _.isNull(val)) {
        //                 output += `Question number ${errorOutput[key]} must be filled. \n`;
        //             }
        //         }
        //     }
        //     if (_.isEmpty(val) || _.isNull(val)) {
        //         if (typeof val === 'boolean') continue;
        //         output += `Question number ${errorOutput[key]} must be filled. \n`;
        //     }
        // }

        this.error = output;

        if (_.isEmpty(output)) {
            return false;
        }

        if (!_.isEmpty(output)) {
            window.scrollTo(0, 0);
            throw new Error(output);
        }

        return true;
    },
    addReportDateToCaseManagement(caseManagment, reportDate) {
        caseManagment.submittedDate = reportDate;

        return caseManagment
    },
    addValAndConvertToString(arr, val) {
        if (!arr) return "";
        if (!!val) {
            arr.push(val);
        }
        return arr.toString().trim();
    },
    convertToString(arr) {
        if (!arr) return '';
        return arr.toString().trim();
    },
    removeOtherFromArray(arr) {
        if (arr.includes("Other/Please Specify - غير ذلك")) {
            let index = arr.indexOf("Other/Please Specify - غير ذلك");
            arr.splice(index, 1);
        }
        return arr;
    },
    async getPostRoute() {
        let submittedData, caseType;
        const cm = JSON.parse(JSON.stringify(this.caseManagement));
        caseType = this.caseManagement.caseType;
        this.caseManagement.assesmentPoint.emotionalStateBeginning = this.addValueIfOtherIsChecked(this.caseManagement.assesmentPoint.emotionalStateBeginning,
            this.caseManagement.otherEmoStateBeginning,
            this.caseManagement.assesmentPoint.emotionalStateBeginning.includes("Other/Please Specify - غير ذلك")
        )
        this.caseManagement.assesmentPoint.emotionalStateEnding = this.addValueIfOtherIsChecked(this.caseManagement.assesmentPoint.emotionalStateEnding,
            this.caseManagement.otherEmoStateEnd,
            this.caseManagement.assesmentPoint.emotionalStateEnding.includes("Other/Please Specify - غير ذلك")
        )
        this.caseManagement.outsideReferral = this.resultReferral;
        this.caseManagement.perpetrator.relationship = this.addValueIfOtherIsChecked(this.caseManagement.perpetrator.relationship,
            this.caseManagement.otherPerpRelationship,
            this.caseManagement.perpetrator.relationship.includes("Other/Please Specify - غير ذلك")
        )
        this.removeOtherFromArray(this.caseManagement.assesmentPoint.emotionalStateBeginning);
        this.removeOtherFromArray(this.caseManagement.assesmentPoint.emotionalStateEnding);
        this.removeOtherFromArray(this.caseManagement.perpetrator.relationship);

        caseType = this.caseManagement.caseType;
        switch (caseType) {
            case "GBV":
                submittedData = await this.saveGBVData(this.caseManagement);
                break;
            case "NONGBV":
                submittedData = await this.saveNonGBVData(this.caseManagement);
                break;
            case "NONCPNONGBV":
                submittedData = await this.saveNonCPGBVData(this.caseManagement);
                break;
            case "CPGBV":
                submittedData = await this.saveCPGBVData(this.caseManagement);
        }
        this.caseManagement = cm;
        return submittedData;
    },
    getUpdateRoute() {
        let submittedData;
        const cm = JSON.parse(JSON.stringify(this.caseManagement));
        if (this.caseManagement.caseType.toLowerCase() === "gbv" ||
            this.caseManagement.caseType.toLowerCase() === "cpgbv") {
            this.caseManagement.assesmentPoint.emotionalStateBeginning =
                this.addValueIfOtherIsChecked(this.caseManagement.assesmentPoint.emotionalStateBeginning,
                    this.caseManagement.otherEmoStateBeginning,
                    this.caseManagement.assesmentPoint.emotionalStateBeginning.includes("Other/Please Specify - غير ذلك")
                );
            this.caseManagement.assesmentPoint.emotionalStateEnding =
                this.addValueIfOtherIsChecked(this.caseManagement.assesmentPoint.emotionalStateEnding,
                    this.caseManagement.otherEmoStateEnd,
                    this.caseManagement.assesmentPoint.emotionalStateEnding.includes("Other/Please Specify - غير ذلك")
                );
            this.caseManagement.perpetrator.relationship =
                this.addValueIfOtherIsChecked(this.caseManagement.perpetrator.relationship,
                    this.caseManagement.otherPerpRelationship,
                    this.caseManagement.perpetrator.relationship.includes("Other/Please Specify - غير ذلك")
                );
            this.removeOtherFromArray(this.caseManagement.assesmentPoint.emotionalStateBeginning);
            this.removeOtherFromArray(this.caseManagement.assesmentPoint.emotionalStateEnding);
            this.removeOtherFromArray(this.caseManagement.perpetrator.relationship);

        }
        this.caseManagement = this.nullifyFieldsBasedOnCase(this.caseManagement);
        this.updateCaseData(this.caseManagement);
        this.caseManagement = cm;
        return submittedData;
    },
    
    addValueIfOtherIsChecked(arr, val, isChecked) {
        if (isChecked) {
            arr.push(val);
        }
        return arr;
    },
    chooseCase(type) {
        if (type.toLowerCase() === "gbv" || type.toLowerCase() === "cpgbv") {
          return this.chooseGBV(this.caseManagement);
        }
        if (type.toLowerCase() === "noncpnongbv") {
            return this.chooseNonGBV(this.caseManagement);
        }
        if (type.toLowerCase() === "cpnongbv") {
            return this.chooseCPNONGBV(this.caseManagement);
        }
    },
    removeLastDuplicateIndex(array) {
        if (array[array.length - 1] === array[array - 2]) {
            return array.splice(-1, 1).filter(String);
        }
        return array;
    },
    chooseCPNONGBV(data) {
        let cpNonGbv = {};
        cpNonGbv.outsideReferral = data.outsideReferral;
        cpNonGbv.incident = {};
        cpNonGbv.incident.incidentType = data.incident.incidentType;
        cpNonGbv.incident.incidentTraditionalPractice = data.incident.incidentTraditionalPractice;
        cpNonGbv.incident.incidentTrade = data.incident.incidentTrade;
        cpNonGbv.submittedDate = data.submittedDate;

        return cpNonGbv;
    },
    chooseNonGBV(data) {
        let nonGbv = {};
        nonGbv.survivor = {};
        nonGbv.survivor.referral = data.survivor.referral;
        nonGbv.caseType = data.caseType;
        nonGbv.interviewType = data.interviewType;
        nonGbv.submittedDate = data.submittedDate;

        return nonGbv;
    },
    chooseGBV(data) {
        let gbv = {};
        // gbv.outsideReferral = data.outsideReferral;
        gbv.incident = {};
        gbv.survivor = {};
        gbv.survivor.referral = data.survivor.referral;
        gbv.perpetrator = {};
        gbv.assesmentPoint = {};
        gbv.caseType = data.caseType;
        gbv.interviewType = data.interviewType;
        gbv.incident.incidentType = data.incident.incidentType;
        gbv.incident.incidentTraditionalPractice = data.incident.incidentTraditionalPractice;
        gbv.incident.incidentTrade = data.incident.incidentTrade;
        gbv.incident.incidentFirstDate = data.incident.incidentFirstDate;
        gbv.incident.incidentLastDate = data.incident.incidentLastDate;
        gbv.incident.incidentReported = data.incident.incidentReported;
        gbv.survivor.survivorMinorInfo = data.survivor.survivorMinorInfo;
        gbv.outsideReferral = data.outsideReferral;
        gbv.incident.incidentDisplacement = data.incident.incidentDisplacement;
        gbv.incident.incidentDayPeriod = data.incident.incidentDayPeriod;
        gbv.incident.incidentLocation = data.incident.incidentLocation;
        gbv.incident.incidentArea = data.incident.incidentArea;
        gbv.incident.incidentGovernate = data.incident.incidentGovernate;
        gbv.incident.incidentVillage = data.incident.incidentVillage;
        gbv.incident.incidentFrequency = data.incident.incidentFrequency;
        gbv.incident.incidentDetails = data.incident.incidentDetails;
        gbv.incident.incidentAbductionType = data.incident.incidentAbductionType;
        gbv.incident.incidentReportedElsewhere = data.incident.incidentReportedElsewhere;
        gbv.survivor.survivorPreviousGBV = data.survivor.survivorPreviousGBV;
        gbv.survivor.survivorSupportNetwork = data.survivor.survivorSupportNetwork;
        gbv.survivor.survivorRiskAssesmentLevel = data.survivor.survivorRiskAssesmentLevel;
        gbv.perpetrator.number = data.perpetrator.number;
        gbv.perpetrator.relationship = data.perpetrator.relationship;
        gbv.perpetrator.sex = data.perpetrator.sex;
        gbv.perpetrator.age = data.perpetrator.age;
        gbv.perpetrator.mainOccupation = data.perpetrator.mainOccupation;
        gbv.assesmentPoint.emotionalStateBeginning = data.assesmentPoint.emotionalStateBeginning;
        gbv.assesmentPoint.emotionalStateEnding = data.assesmentPoint.emotionalStateEnding;
        gbv.assesmentPoint.rapeHealthInform = data.assesmentPoint.rapeHealthInform;
        gbv.assesmentPoint.consent = data.assesmentPoint.consent;
        gbv.submittedDate = data.submittedDate;

        return gbv;
    },
    nullifyFieldsBasedOnCase(data) {
        //
        if (data.caseType.toLowerCase() === "noncpnongbv") {
            this.nullifyCPGBV(data);
        }

        return data;
    },
    routeId() {
        return this.$route.params.id;
    },
    nullifyCPGBV(data) {
        let survRef = data.survivor.referral;
        data.survivor = null;
        data.perpetrator = null;
        data.assesmentPoint = null;
        data.incident.incidentFirstDate = null;
        data.incident.incidentLastDate = null;
        data.incident.incidentReported = null;
        data.incident.incidentDisplacement = null;
        data.incident.incidentDayPeriod = null;
        data.incident.incidentLocation = null;
        data.incident.incidentArea = null;
        data.incident.incidentGovernate = null;
        data.incident.incidentVillage = null;
        data.incident.incidentFrequency = null;
        data.incident.incidentDetails = null;
        data.incident.incidentAbductionType = null;
        data.incident.incidentReportedElsewhere = null;

        data.survivor = {};
        data.survivor.referral = survRef;
    },
    // End SocioDemo Methods 

    // Start CaseManagement Methods 
    caseTypeGbvOrCpgbv() {
      // check this after done migrating
      let caseType = this.editCase.caseType;
      return caseType.toLowerCase() === "gbv" || caseType.toLowerCase() === "cpgbv" 
    },
    populateEditCase() {
      if (this.caseTypeGbvOrCpgbv()) {
        this.caseManagement.incident.incidentFirstDate = moment(new Date(this.editCase.incident.incidentFirstDate)).format("YYYY-MM-DD");
        this.caseManagement.incident.incidentLastDate = moment(new Date(this.editCase.incident.incidentLastDate)).format("YYYY-MM-DD");
        // this.caseManagement.incident.survivorMinorInfo = this.editCase.survivor.survivorMinorInfo ? 'Yes - نعم' : 'No -  لا';

        this.ammendEditCaseInput('incidentLocation', 'answers', 'incident', 'incidentLocation')
          .then((result) => {
            this.otherIncidentLocation = result[1];
            this.incidentLocation = result[0];
          });

        this.ammendEditCaseInput('incidentArea', 'answers', 'incident', 'incidentArea')
          .then((result) => {
            this.otherIncidentArea = result[1];
            this.incidentArea = result[0];
          });

        this.filteredGovernoratesAnswers = this.getFilteredGovernates(this.caseManagement.incident.incidentArea);
        // 22
        this.ammendEditCaseInput(null, null, 'incident', 'incidentGovernate', null, this.filteredGovernoratesAnswers)
          .then((result) => {
            this.otherIncidentGovernate = result[1];
            this.incidentGovernate = result[0];
          });

        this.ammendEditCaseInput('incidentAbduction', 'answers', 'incident', 'incidentAbductionType')
          .then((result) => {
            this.otherIncidentAbductionType = result[1];
            this.incidentAbductionType = result[0];
          });

        this.ammendEditCaseInput('incidentReported', 'answers', 'incident', 'incidentReportedElsewhere',
            'Yes, other organization(GBVMIS or non-GBVMIS), specify: نعم، وغيرها حدد')
          .then((result) => {
            this.otherIncidentReportedElsewhere = result[1];
            this.incidentReportedElsewhere = result[0];
          });

        this.ammendEditCaseInput('incidentSupportNetwork', 'answers', 'survivor', 'survivorSupportNetwork')
          .then((result) => {
            this.otherSurvivorSupportNetwork = result[1];
            this.survivorSupportNetwork = result[0];
          });

        this.ammendEditCaseInput('allegPerpMainOccup', 'answers', 'perpetrator', 'mainOccupation')
          .then((result) => {
            this.otherMainOccupation = result[1];
            this.mainOccupation = result[0];
          });

        this.consent = this.editCase.assesmentPoint.consent ? 'Yes - نعم' : 'No -  لا';

        this.ammendEditCaseInput('allegPerpMainOccup', 'answers', 'perpetrator', 'mainOccupation')
          .then((result) => {
            this.otherMainOccupation = result[1];
            this.mainOccupation = result[0];
          });

        this.caseManagement.perpetrator.relationship = this.editCase.perpetrator.relationship;
        this.ammendEditCaseCheckbox('allegPerpRelationshup', 'answers', this.editCase.perpetrator.relationship)
          .then((result) => {
            this.caseManagement.perpetrator.relationship.push(result[0]);
            this.caseManagement.otherPerpRelationship = result[1];
          });

        this.caseManagement.assesmentPoint.emotionalStateBeginning = this.editCase.assesmentPoint.emotionalStateBeginning;
        this.ammendEditCaseCheckbox('assessmentPointEmoStateStart', 'answers', this.editCase.assesmentPoint.emotionalStateBeginning)
          .then((result) => {
            this.caseManagement.assesmentPoint.emotionalStateBeginning.push(result[0]);
            this.caseManagement.otherEmoStateBeginning = result[1];
          });

        this.caseManagement.assesmentPoint.emotionalStateEnding = this.editCase.assesmentPoint.emotionalStateEnding;
        this.ammendEditCaseCheckbox('assessmentPointEmoStateEnd', 'answers', this.editCase.assesmentPoint.emotionalStateEnding)
          .then((result) => {
            this.caseManagement.assesmentPoint.emotionalStateEnding.push(result[0]);
            this.caseManagement.otherEmoStateEnd = result[1];
          });
      }      
      this.ammendEditCaseOtherInput(
        this.referralAnswers,
        this.editCase.outsideReferral)
        .then((result) => {
          this.otherReferral = result[1];
          this.outsideReferral = result[0];
        });

      this.ammendEditCaseInput('incidentTradition', 'answers', 'incident', 'incidentTraditionalPractice')
        .then((result) => {
          this.otherIncidentTraditionalPractice = result[1];
          this.incidentTraditionalPractice = result[0];
        });

      this.incidentTrade = this.editCase.incident.incidentTrade ? 'Yes - نعم' : 'No -  لا';
    },

    getFilteredGovernates(val) {
      if (val.toLowerCase().includes("lebanon")) {
        return this.lebaneseGovernorates();
      }
      if (val.toLowerCase().includes("syria")) {
        return this.syrianGovernorates();
      }
      return this.otherGovernorates();
    },
    // End CaseManagement Methods 
    showNonGbv() {
      return this.caseManagement?.caseType?.toLowerCase() === "noncpnongbv";
    },
    showCpNonGbv() {
      return this.caseManagement?.caseType?.toLowerCase() === "cpnongbv";
    },
    showAll() {
      let caseType;
      if(!!this.caseManagement.caseType) 
         caseType = this.caseManagement.caseType
        return caseType.toLowerCase() === "gbv" || caseType.toLowerCase() === "cpgbv";      
    },
    shouldShowhealthStatus(formHealthStatus) {
      return formHealthStatus.includes('Other/Please Specify - غير ذلك')
    },
    shouldShowCpNonGgbOrShowAll() {
      return this.showCpNonGbv() || this.showAll()
    },
    caseIsGBV() {
      return this.caseManagement.caseType.includes("GBV")
    },
   
    lebaneseGovernorates() {
      if(!!this.incidentGovernateAnswers) {
        let gov = this.incidentGovernateAnswers;
        return [
          gov[0], gov[1], gov[2],
          gov[3], gov[4], gov[5],
          gov[20]
        ];
      }
      return [];
    },
    syrianGovernorates() {
      if(!!this.incidentGovernateAnswers) {
        let gov = this.incidentGovernateAnswers;
        return [
          gov[6], gov[7], gov[8],
          gov[9], gov[10], gov[11],
          gov[12], gov[13], gov[14],
          gov[15], gov[16], gov[17],
          gov[18], gov[19], gov[20]
        ];
      }
      return [];
    },
    otherGovernorates() {
      if(!!this.incidentGovernateAnswers) { 
        let gov = this.incidentGovernateAnswers;
        return [gov[20]];
      }
      return []
    },
	},
	computed: {
    ...mapGetters(['socioDemoQA', 'gbvCaseQA', 'allServices']),
    // Start SocioDemo Computed 
		Gcode() {
			return `${localStorage.getItem("beniName")} - ${this.$route.params.id}`;
    },
    caseTypes() {
      return ["GBV", "NONCPNONGBV"];
    },
    interviewTypes() {
      return ["Phone call", "Home visit", "Face to face"];
    },
    isEditCaseMode() {
      return !!this.editCase;
    },
    showCpOrAllAndIncidentIsExploitation() {
      return this.showCpNonGbv() || this.showAll() && this.caseManagement.incident.incidentType.includes('Exploitation & Risk of Trafficking')
    },
    // mainOccupation() {
    //   return this.caseManagement?.perpetrator?.mainOccupation;
    // },
    reportDateQuestion() {
      return this.socioDemoData?.reportDate?.question[0];
    },
    disStatusQuestion() {
      return this.socioDemoData?.disStatus?.question[0];
    },
    disStatusAnswers() {
      return this.socioDemoData?.disStatus?.answers;
    },
    educationQuestion() {
      return this.socioDemoData?.education?.question[0];
    },
    educationAnswers() {
      return this.socioDemoData?.education?.answers;
    },
    healthStatusQuestion() {
      return this.socioDemoData?.healthstatus?.question[0];
    },
    healthStatusAnswers() {
      return this.socioDemoData?.healthstatus?.answers;
    },
    socecoStatusQuestion() {
      return this.socioDemoData?.socecostatus?.question[0];
    },
    socecoStatusAnswers() {
      return this.socioDemoData?.socecostatus?.answers;
    },
    workStatusQuestion() {
      return this.socioDemoData?.workstatus?.question[0];
    },
    workStatusAnswers() {
      return this.socioDemoData?.workstatus?.answers;
    },
    workTypeQuestion() {
      return this.socioDemoData?.worktype?.question[0];
    },
    interviewTypeQuestion() {
      return this.socioDemoData?.interviewType?.question[0];
    },
    interviewTypeAnswers() {
      return this.socioDemoData?.interviewType?.answers;
    },
    shouldShowWorkType() {
      return this.form.workStatus?.toLowerCase().includes("working");
    },
    // End SocioDemo Computed

    // Start CaseManagement Computed
    actualIncidentQuestion() {
      return this.gbvData?.actualIncident?.question[0];
    },
    allegPerpAgeQuestion() {
      return this.gbvData?.allegPerpAge?.question[0];
    },
    allegPerpAgeAnswers() {
      return this.gbvData?.allegPerpAge?.answers;
    },
    allegPerpMainOccupQuestion() {
      return this.gbvData?.allegPerpMainOccup?.question[0];
    },
    allegPerpMainOccupAnswers() {
      return this.gbvData?.allegPerpMainOccup?.answers;
    },
    allegPerpNumberQuestion() {
      return this.gbvData?.allegPerpNumber?.question[0];
    },
    allegPerpNumberAnswers() {
      return this.gbvData?.allegPerpNumber?.answers;
    },
    allegPerpRelationshipQuestion() {
      return this.gbvData?.allegPerpRelationshup?.question[0];
    },
    allegPerpRelationshipAnswers() {
      return this.gbvData?.allegPerpRelationshup?.answers;
    },
    allegPerpSexQuestion() {
      return this.gbvData?.allegPerpSex?.question[0];
    },
    allegPerpSexAnswers() {
      return this.gbvData?.allegPerpSex?.answers;
    },
    assessmentPointEmoStateEndQuestion() {
      return this.gbvData?.assessmentPointEmoStateEnd?.question[0];
    },
    assessmentPointEmoStateEndAnswers() {
      return this.gbvData?.assessmentPointEmoStateEnd?.answers;
    },
    assessmentPointEmoStateStartQuestion() {
      return this.gbvData?.assessmentPointEmoStateStart?.question[0];
    },
    assessmentPointEmoStateStartAnswers() {
      return this.gbvData?.assessmentPointEmoStateStart?.answers;
    },
    assessmentPointExplainedQuestion() {
      return this.gbvData?.assessmentPointExplained?.question[0];
    },
    assessmentPointExplainedAnswers() {
      return this.gbvData?.assessmentPointExplained?.answers;
    },
    assessmentPointShareDataQuestion() {
      return this.gbvData?.assessmentPointShareData?.question[0];
    },
    assessmentPointShareDataAnswers() {
      return this.gbvData?.assessmentPointShareData?.answers;
    },
    incidentAbductionQuestion() {
      return this.gbvData?.incidentAbduction?.question[0];
    },
    incidentAbductionAnswers() {
      return this.gbvData?.incidentAbduction?.answers;
    },
    incidentAreaQuestion() {
      return this.gbvData?.incidentArea?.question[0];
    },
    incidentAreaAnswers() {
      return this.gbvData?.incidentArea?.answers;
    },
    incidentDateQuestion() {
      return this.gbvData?.incidentDate?.question[0];
    },
    incidentDateAnswers() {
      return this.gbvData?.incidentDate?.answers;
    },
    incidentDescriptionQuestion() {
      return this.gbvData?.incidentDescription?.question[0];
    },
    incidentDisplacementQuestion() {
      return this.gbvData?.incidentDisplacement?.question[0];
    },
    incidentDisplacementAnswers() {
      return this.gbvData?.incidentDisplacement?.answers;
    },
    incidentFrequencyQuestion() {
      return this.gbvData?.incidentFrequency?.question[0];
    },
    incidentFrequencyAnswers() {
      return this.gbvData?.incidentFrequency?.answers;
    },
    incidentGovernateQuestion() {
      return this.gbvData?.incidentGovernate?.question[0];
    },
    incidentGovernateAnswers() {
      return this.gbvData?.incidentGovernate?.answers;
    },
    incidentLocationQuestion() {
      return this.gbvData?.incidentLocation?.question[0];
    },
    incidentLocationAnswers() {
      return this.gbvData?.incidentLocation?.answers;
    },
    incidentPreviousQuestion() {
      return this.gbvData?.incidentPrevious?.question[0];
    },
    incidentPreviousAnswers() {
      return this.gbvData?.incidentPrevious?.answers;
    },
    incidentReportedQuestion() {
      return this.gbvData?.incidentReported?.question[0];
    },
    incidentReportedAnswers() {
      return this.gbvData?.incidentReported?.answers;
    },
    incidentRiskAssessmentQuestion() {
      return this.gbvData?.incidentRiskAssessment?.question[0];
    },
    incidentRiskAssessmentAnswers() {
      return this.gbvData?.incidentRiskAssessment?.answers;
    },
    incidentSupportNetworkQuestion() {
      return this.gbvData?.incidentSupportNetwork?.question[0];
    },
    incidentSupportNetworkAnswers() {
      return this.gbvData?.incidentSupportNetwork?.answers;
    },
    incidentTimeQuestion() {
      return this.gbvData?.incidentTime?.question[0];
    },
    incidentTimeAnswers() {
      return this.gbvData?.incidentTime?.answers;
    },
    incidentTradeQuestion() {
      return this.gbvData?.incidentTrade?.question[0];
    },
    incidentTradeAnswers() {
      return this.gbvData?.incidentTrade?.answers;
    },
    incidentTraditionQuestion() {
      return this.gbvData?.incidentTradition?.question[0];
    },
    incidentTraditionAnswers() {
      return this.gbvData?.incidentTradition?.answers;
    },
    incidentTypeQuestion() {
      return this.gbvData?.incidentType?.question[0];
    },
    incidentTypeAnswers() {
      return this.gbvData?.incidentType?.answers;
    },
    incidentVillageQuestion() {
      return this.gbvData?.incidentVillage?.question[0];
    },
    referralQuestion() {
      return this.gbvData?.referral?.question[0];
    },
    referralAnswers() {
      return this.gbvData?.referral?.answers;
    },
    // survivorUnaccimpaniedQuestion() {
    //   return this.gbvData?.survivorUnaccimpanied?.question[0];
    // },
    // survivorUnaccimpaniedAnswers() {
    //   return this.gbvData?.survivorUnaccimpanied?.answers;
    // },
    shouldShowMainOccupationInput() {
      return this.mainOccupation?.toLowerCase().includes("please specify");
    },
    shouldShowRelationshipInput() {
      return this.relationship?.toLowerCase().includes("please specify");
    },
    shouldShowEmoStateStartInput() {
      return this.caseManagement?.assesmentPoint?.emotionalStateBeginning?.includes("Other/Please Specify - غير ذلك");
    },
    shouldShowAllegPerpRelationshipInput() {
      return this.caseManagement?.perpetrator?.relationship?.includes("Other/Please Specify - غير ذلك");
    },
    shouldShowEmoStateEndInput() {
      return this.caseManagement?.assesmentPoint?.emotionalStateEnding?.includes("Other/Please Specify - غير ذلك");
    },
    shouldShowAbductionTypeInput() {
      return this.incidentAbductionType?.toLowerCase().includes("please specify");
    },
    shouldShowIncidentAreaInput() {
      return this.incidentArea?.toLowerCase().includes("please specify");
    },
     shouldShowReferralInput() {     
      return this.outsideReferral.includes("Other/Please Specify");
    },
    shouldShowIncidentLocationInput() {
      return this.incidentLocation?.toLowerCase().includes("please specify");
    },
    shouldShowSupportNetworkInput() {
      return this.survivorSupportNetwork?.toLowerCase().includes("please specify");
    },
    shouldShowIncidentTraditionInput() {
      return this.incidentTraditionalPractice?.toLowerCase().includes("please specify");
    },
    shouldShowIncidentReportedElsewhereInput() {
      return this.incidentReportedElsewhere?.toLowerCase().includes("yes");
    },
    nationality() {
      return this.gbvAnswers?.incident?.incidentArea?.toLowerCase();
    },
    livingArrangementsQuestion() {
      return this.socioDemoData?.livingArrangements?.question[0];
    },
    livingArrangementsAnswers() {
      return this.socioDemoData?.livingArrangements?.answers;
    },
    housingConditionQuestion() {
      return this.socioDemoData?.housingCondition?.question[0];
    },
    housingConditionAnswers() {
      return this.socioDemoData?.housingCondition?.answers;
    },
    accomodationTypeQuestion() {
      return this.socioDemoData?.accomodationType?.question[0];
    },
    accomodationTypeAnswers() {
      return this.socioDemoData?.accomodationType?.answers;
    },
    // workingConditionQuestion() {
    //   return this.gbvData?.workingCondition?.question[0];
    // },
    // workingConditionAnswers() {
    //   return this.gbvData?.workingCondition?.answers;
    // },
    financialBenefitQuestion() {
      return this.gbvData?.financialBenefit?.question[0];
    },
    financialBenefitAnswers() {
      return this.gbvData?.financialBenefit?.answers;
    },
    workPerformedQuestion() {
      return this.gbvData?.workPerformed?.question[0];
    },
    workPerformedAnswers() {
      return this.gbvData?.workPerformed?.answers;
    },
    harmsAndThreatsQuestion() {
      return this.gbvData?.harmsThreat?.question[0];
    },
    harmsAndThreatsAnswers() {
      return this.gbvData?.harmsThreat?.answers;
    },
    shouldShowIncidentGovernateInput() {
      return this.incidentGovernate.toLowerCase().includes("please specify");
    },
    shouldShowAccomodationTypeOther() {
      return this.accomodationType.includes("Other/Please Specify");
    },
	},
  watch: {
    // Start of SocioDemo watchers
    countryOrigin(val) {
      this.resultCountryOrigin = val;
    },
    otherCountryOrigin(val) {
      this.resultCountryOrigin = val;
    },
    resultCountryOrigin(val) {
      this.socioDemoData.countryOrigin = val;
    },
    disStatus(val) {
      this.resultDisStatus = val;
    },
    otherDisStatus(val) {
      this.resultDisStatus = val;
    },
    resultDisStatus(val) {
      this.socioDemoData.disStatus = val;
    },
  // End of SocioDemo watchers

  // Start of CaseManagement watchers
    consent(val) {
      if (val.toLowerCase().includes("yes")) {
        this.caseManagement.assesmentPoint.consent = true;
        return;
      }
      this.caseManagement.assesmentPoint.consent = false;
    },
    incidentTrade(val) {
      if (val.toLowerCase().includes("yes")) {
        this.caseManagement.incident.incidentTrade = true;
        return;
      }
      this.caseManagement.incident.incidentTrade = false;
    },
    incidentReported(val) {
      if (val.toLowerCase().includes("yes" || "")) {
        this.caseManagement.incident.incidentReoprted = true;
        return;
      }
      this.caseManagement.incident.incidentReoprted = false;
    },
    // survivorMinorInfo(val) {
    //   if (val.toLowerCase().includes("yes")) {
    //     this.caseManagement.survivor.survivorMinorInfo = true;
    //     return;
    //   }
    //   this.caseManagement.survivor.survivorMinorInfo = true;
    // },
    mainOccupation(val) {
      this.resultMainOccupation = val;
    },
    otherMainOccupation(val) {
      this.resultMainOccupation = val;
    },
    resultMainOccupation(val) {
      this.caseManagement.perpetrator.mainOccupation = val;
    },
    relationship(val) {
      this.resultRelationship = val;
    },
    otherRelationship(val) {
      this.resultRelationship = val;
    },
    resultRelationship(val) {
      this.caseManagement.perpetrator.relationship = val;
    },
    emoStateBeginning(val) {
      this.resultEmoStateBeginning = val;
    },
    otherEmoStateBeginning(val) {
      this.resultEmoStateBeginning.push(val);
    },
    resultEmoStateBeginning(val) {
      this.caseManagement.assesmentPoint.emotionalStateBeginning = val;
    },
    perpRelationship(val) {
      this.resultPerpRelationship = val;
    },
    otherPerpRelationship(val) {
      this.resultPerRelationship.push(val);
    },
    resultPerpRelationship(val) {
      this.caseManagement.perpetrator.relationship = val;
    },
    incidentAbductionType(val) {
      this.resultIncidentAbductionType = val;
    },
    otherIncidentAbductionType(val) {
      this.resultIncidentAbductionType = val;
    },
    resultIncidentAbductionType(val) {
      this.caseManagement.incident.incidentAbductionType = val;
    },
    incidentGovernate(val) {
      this.resultIncidentGovernate = val;
    },
    otherIncidentGovernate(val) {
      this.resultIncidentGovernate = val;
    },
    resultIncidentGovernate(val) {
      this.caseManagement.incident.incidentGovernate = val;
    },
    incidentLocation(val) {
      this.resultIncidentLocation = val;
    },
    otherIncidentLocation(val) {
      this.resultIncidentLocation = val;
    },
    resultIncidentLocation(val) {
      this.caseManagement.incident.incidentLocation = val;
    },
    survivorSupportNetwork(val) {
      this.resultSurvivorSupportNetwork = val;
    },
    otherSurvivorSupportNetwork(val) {
      this.resultSurvivorSupportNetwork = val;
    },
    resultSurvivorSupportNetwork(val) {
      this.caseManagement.survivor.survivorSupportNetwork = val;
    },
    incidentTraditionalPractice(val) {
      this.resultIncidentTraditionalPractice = val;
    },
    otherIncidentTraditionalPractice(val) {
      this.resultIncidentTraditionalPractice = val;
    },
    resultIncidentTraditionalPractice(val) {
      this.caseManagement.incident.incidentTraditionalPractice = val;
    },
    outsideReferral(val) {
      this.resultReferral = val;
    },
    otherReferral(val) {
      this.resultReferral = val;
    },
    resultReferral(val) {
      this.caseManagement.outsideReferral = val;
    },
    emoStateEnd(val) {
      this.resultEmoStateEnd = val;
    },
    resultEmoStateEnd(val) {
      this.caseManagement.assesmentPoint.emotionalStateEnding = val;
    },
    incidentReportedElsewhere(val) {
      this.resultIncidentReportedElsewhere = val;
    },
    otherIncidentReportedElsewhere(val) {
      this.resultIncidentReportedElsewhere = val;
    },
    resultIncidentReportedElsewhere(val) {
      this.caseManagement.incident.incidentReportedElsewhere = val;
    },
    incidentArea(val) {
      this.resultIncidentArea = val;
      if (!!val && val.toLowerCase().includes("lebanon")) {
        this.filteredGovernoratesAnswers = this.lebaneseGovernorates();
        return;
      }
      if (!!val && val.toLowerCase().includes("syria")) {
        this.filteredGovernoratesAnswers = this.syrianGovernorates();
        return;
      }
      this.filteredGovernoratesAnswers = this.otherGovernorates();
    },
    otherIncidentArea(val) {
      this.resultIncidentArea = val;
    },
    resultIncidentArea(val) {
      this.caseManagement.incident.incidentArea = val;
    },
    accomodationType(val) {
      this.resultAccomodationType = val;
    },
    accomodationTypeOther(val) {
      this.resultAccomodationType = val;
    },
    resultAccomodationType(val) {
      this.form.accomodationType = val;
    },
    healthStatus(val) {
      this.resultHealthStatus = val;
    },
    healthStatusOther(val) {
      this.resultHealthStatus = val;
    },
    resultHealthStatus(val) {
      this.form.healthStatus = val;
    }
  }

}
</script>
<style lang="scss" scoped>
div.error {
  color: red;
}
</style>