<template>
  <div class="row pt-10 max-w-4xl mx-auto">
    <ab-content-container>
      <div v-if="routeToUserInfo || routeToUserManagement || routeToPromoteUse">
        <router-view> </router-view>
      </div>
      <div class="mb-4 col-span-3" v-else>
        <pulse-loader
          :loading="isLoading"
          class="loader"
          color="#fff"
        ></pulse-loader>
      </div>

      <div v-if="isActivityNavigation">
        <div class="mb-4 col-span-3">
          <p class="text-xl font-semibold">
            Portfolio: <span>{{ beneficiaryData.abCode }}</span>
          </p>
          <p class="text-xs text-gray-500 italic">
            This is where we manage the portfolio
          </p>
        </div>
        <div class="row">
          <div class="navigation-buttons mt-24">
            <router-link :to="routeToUserInfo">
              <main-button
                label="User Info"
                icon="uil uil-folder"
              ></main-button>
            </router-link>
            <div @click="routeToPromoteUser()">
              <main-button
                label="Promote To CM"
                icon="uil uil-plus"
              ></main-button>
            </div>
            <main-button label="Export" icon="uil uil-import"></main-button>
            <router-link :to="routeToUserManagement">
              <main-button label="Manage" icon="uil uil-edit"></main-button>
            </router-link>
          </div>
        </div>
      </div>
    </ab-content-container>
  </div>
</template>

<script>
import MainButton from "@/features/Profile/Button/MainButton.vue";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";

export default {
  name: "ActivityBeneficiaryNavigation",
  components: {
    MainButton,
    AbContentContainer,
    PulseLoader,
  },
  data() {
    return {
      isLoading: null,
      beneficiaryData: {},
    };
  },
  created() {
    this.beneficiaryData = JSON.parse(
      localStorage.getItem("ActivityBeneficiary")
    );
  },
  mounted() {},
  methods: {
    routeToPromoteUser() {
      console.log("🚀 ~ this.beneficiaryData:", this.beneficiaryData);
      this.$router.push({
        name: 'PromoteBeneficiary',
        path: `${this.$route.params.id}/promote-user`,
        params: {
          editService: this.beneficiaryData
        }
      });
    },
  },
  computed: {
    buttonClass() {
      return !this.hasCase
        ? "flex flex-col justify-center items-center bg-gray-600 py-4 px-3 rounded cursor-not-allowed"
        : "flex flex-col justify-center items-center bg-primary py-4 px-3 rounded shadow-xl cursor-pointer hover:bg-secondary";
    },
    href() {
      return this.hasCase
        ? `https://api.abaadmena.online/v1/export/all/${this.$route.params.id}`
        : "#";
    },
    routeToUserInfo() {
      return `/activities/activity-beneficiary/${this.$route.params.id}/user-info`;
    },
    routeToUserManagement() {
      return `${this.$route.params.id}/user-management`;
    },

    isActivityPage() {
      return this.$route.path === "/activities";
    },
    isActivityNavigation() {
      return (
        this.$route.path ===
        `/activities/activity-beneficiary/${this.$route.params.id}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.flex.flex-col.justify-center.items-center.bg-gray-600.rounded.cursor-not-allowed {
  cursor: not-allowed;
}
.activity-nav-header {
  width: 70vw;
}
#uilUil-Plus {
  p {
    margin-bottom: 8px;
  }
}
p.text-sm.export.font-semibold.text-white.transform.self-start {
  margin: 0 auto;
}
.main-nav {
  margin: auto;
}
@media (max-width: 850px) {
  .main-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div.button-group {
    .flex-col {
      flex-direction: row;
    }
  }
}
.navigation-buttons {
  display: flex;
  margin: auto;
  gap: 1rem;
}
</style>
