<template>
  <div class="form-control">
    <!-- <label :for="label"> -->
      <i class="uil uil-search relative"></i>
      <input
        :name="searchButtonId"
        :type="type"
        @input="change($event)"
        :id="label"
        class="
          ab-search
          border
          form-control
          text-gray-700
          focus:outline-none
          text-sm
          font-light
          p-2
          pl-8
        "
        :placeholder="placeholder"
        :disabled="disabled"
      />
    <!-- </label> -->
  </div>
</template>

<script>
import { EventBus } from "@/Events/event-bus.js";
export default {
  name: "ab-search",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: String,
    },
    searchButtonId: {
      type: String,
      required: false
    }
  },
  methods: {
    change(e) {
      this.$emit("input", e.target.value);
      this.onSearch(e.target.value);
    },
    onSearch(term) {
      EventBus.$emit("onSearch", term);
    },
  },
};
</script>

<style lang="scss">
.w-45 {
    width: 45vw;
  }
@media (min-width: 600px) {
  .w-45 {
    width: 55vw;
  }
}
input.ab-search {
  padding-left: 40px !important;
  border-radius: 25px;
  height: 45px;
  max-width: 660px;
}
.form-control {
  border-color: #fff !important;
}
.ab-search-wrapper {
  margin: auto;
}
.uil.uil-search {
  left: 25px;
}

@media (max-width: 600px) {
  .w-45 {
    width: 80vw;
  }
}
@media(min-width: 1250px) {
  .w-45 {
    width: 55vw;
  }
}
</style>
