<template>
  <label
    class="flex items-center rounded cursor-pointer hover:bg-primary"
    :class="{
      'border border-solid border-secondary p-3 ': !isTableMode,
      'bg-secondary text-white': shouldBeChecked,
      disabled: disabledClass,
    }"
  >
    <input
      type="radio"
      :checked="shouldBeChecked"
      :value="value"
      @change="updateInput"
      :disabled="disabled"
    />
    <span class="ml-4 text-sm font-light">{{ label }}</span>
  </label>
</template>
<script>
export default {
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    value: {
      type: String,
    },
    modelValue: {
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isTableMode: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    shouldBeChecked() {
      return this.modelValue == this.value;
    },
    disabledClass() {
      // This can be extended to add multiple checks and aggregate different classes
      return this.disabled ? "disabled" : "";
    },
  },
  methods: {
    updateInput() {
      this.$emit("change", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
label.disabled {
  color: black;
  background-color: darkgrey;
}
label.disabled:hover {
  color: black;
  background-color: darkgrey;
  cursor: not-allowed;
}
label.table-mode {
  padding-left: 5px;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="radio"]:checked {
  border: 1px solid white;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: rgb(110, 110, 110);
}
</style>
