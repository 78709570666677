<template>
  <tr class="capitalize text-gray-700 cursor-pointer hover:bg-primary">
    <td
      class="table-input-cell"
      v-for="field in fields"
      :key="field.name"
      :ref="
        'participant_' +
          parseInt(participant.participantIndex + 1) +
          '_' +
          field.name
      "
    >
      <ab-input
        oneThird
        :isTableMode="true"
        v-if="field.component == 'ab-input'"
        :type="field.type"
        :placeholder="
          'Participant ' +
            parseInt(participant.participantIndex + 1) +
            ' ' +
            field.label
        "
        v-model="participantModel[field.name]"
      ></ab-input>
      <div v-if="field.component == 'ab-radio'" class="gender-radio">
        <ab-radio
          class="table-mode"
          :isTableMode="true"
          :value="'Male'"
          :label="'Male'"
          v-model="participantModel[field.name]"
        ></ab-radio>
        <ab-radio
          class="table-mode"
          :isTableMode="true"
          :value="'Female'"
          :label="'Female'"
          v-model="participantModel[field.name]"
        ></ab-radio>
        <ab-radio
          class="table-mode"
          :isTableMode="true"
          :value="'Other'"
          :label="'Other'"
          v-model="participantModel[field.name]"
        ></ab-radio>
      </div>
      <div class="countries-select" v-if="field.component == 'ab-select'">
        <ab-select
          class="country-select"
          ref="nationality-select"
          :isTableMode="true"
          :type="field.type"
          :options="countries"
          :selectedOption="participantModel.nationality"
          :placeHolder="'Select country'"
          v-model="participantModel[field.name]"
        ></ab-select>
        <span class="font-semibold text-sm mt-1 flex">
          <div class="flags-wrapper">
            <div class="flags">
              <div class="flag-wrapper">
                <img
                  @click="setCommonNationality('Lebanon')"
                  src="../../assets/images/lb.png"
                  alt=""
                />
              </div>
              <div class="flag-wrapper">
                <img
                  @click="setCommonNationality('Palestine')"
                  src="../../assets/images/ps.png"
                  alt=""
                />
              </div>
              <div class="flag-wrapper">
                <img
                  @click="setCommonNationality('Syria')"
                  src="../../assets/images/sy.png"
                  alt=""
                />
              </div>
              <div class="flag-wrapper">
                <div
                  @click="setCommonNationality('PRL')"
                  class="pr-nationality"
                >
                  <span>PRL</span>
                </div>
              </div>
              <div class="flag-wrapper">
                <div
                  @click="setCommonNationality('PRS')"
                  class="pr-nationality"
                >
                  <span>PRS</span>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </td>
  </tr>
</template>
<script>
import vue from "vue";
import AbInput from "../../components/abaad/AbInput/AbInput.vue";
import AbSelect from "../../components/abaad/AbSelect/AbSelect.vue";
import AbRadio from "../../components/abaad/AbRadio/AbRadio.vue";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { required, requiredIf } from "vuelidate/lib/validators";
import { EventBus } from "@/Events/event-bus.js";

vue.use("required", required);
vue.use("requiredIf", requiredIf);
export default {
  components: { AbInput, AbSelect, AbRadio },
  name: "TrainingParticipant",
  mixins: [toasterMixin],
  props: {
    participant: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
    },
    editService: {
      type: Object,
    },
  },
  data() {
    return {
      fields: [
        {
          name: "fullName",
          value: "text",
          component: "ab-input",
          label: "Name",
        },
        {
          name: "phoneNumber",
          value: "number",
          component: "ab-input",
          label: "Phone Number",
        },
        {
          name: "email",
          value: "text",
          component: "ab-input",
          label: "Email",
        },
        {
          name: "title",
          value: "text",
          component: "ab-input",
          label: "Title",
        },
        {
          name: "organization",
          value: "text",
          component: "ab-input",
          label: "Organization",
        },
        {
          name: "gender",
          value: "",
          component: "ab-radio",
          label: "Gender",
        },
        {
          name: "nationality",
          value: "select",
          component: "ab-select",
          label: "Nationality",
        },
      ],
      participantModel: {
        fullName: "",
        phoneNumber: "",
        email: "",
        title: "",
        organization: "",
        gender: "",
        nationality: "",
      },
      shouldShow: true,
    };
  },
  validations() {
    return {
      participantModel: {
        fullName: {
          required,
        },
        phoneNumber: {
          required,
        },
      },
    };
  },
  created() {
    this.participantModel = this.participant;
    if (!!this.editService) {
      this.participantModel = this.editService;
      this.participantModel.gender = this.editService.gender;
    }
  },

  updated() {
    this.$emit("participantdata", {
      changedData: this.participantModel,
      participantIndex: this.participant.participantIndex,
    });
  },
  methods: {
    setCommonNationality(value) {
      if (!!this.$refs["nationality-select"])
        this.$refs["nationality-select"][0]._data.selected = value;
      this.participantModel.nationality = value;
    },
    catchErrors() {
      if (!this.participantModel.fullName) {
        this.$refs[
          this.participantFullNameIdentifier
        ][0]?.children[0]?.children[0]?.children[1]?.classList.add("error");
      }
      if (!this.participantModel.phoneNumber) {
        this.$refs[
          this.participantPhoneNumberIdentifier
        ][0]?.children[0]?.children[0]?.children[1]?.classList.add("error");
      }
    },
  },
  computed: {
    participantFullNameIdentifier() {
      return (
        "participant_" +
        parseInt(this.participant.participantIndex + 1) +
        "_fullName"
      );
    },
    participantPhoneNumberIdentifier() {
      return (
        "participant_" +
        parseInt(this.participant.participantIndex + 1) +
        "_phoneNumber"
      );
    },
  },
  watch: {
    "participantModel.fullName"(newVal) {
      if (!!newVal) {
        EventBus.$emit("participant_name_added", {
          participantField: this.participantFullNameIdentifier,
          index: parseInt(this.participant.participantIndex + 1),
        });
        this.$refs[
          this.participantFullNameIdentifier
        ][0]?.children[0]?.children[0]?.children[1]?.classList.remove("error");
      } else {
        EventBus.$emit("participant_name_missing", {
          participantField: this.participantFullNameIdentifier,
          index: parseInt(this.participant.participantIndex + 1),
        });
        this.$refs[
          this.participantFullNameIdentifier
        ][0]?.children[0]?.children[0]?.children[1]?.classList.add("error");
      }
    },
    "participantModel.phoneNumber"(newVal) {
      if (!!newVal) {
        EventBus.$emit("participant_phone_added", {
          participantField: this.participantPhoneNumberIdentifier,
          index: parseInt(this.participant.participantIndex + 1),
        });
        this.$refs[
          this.participantPhoneNumberIdentifier
        ][0]?.children[0]?.children[0]?.children[1]?.classList.remove("error");
      } else {
        EventBus.$emit("participant_phone_missing", {
          participantField: this.participantPhoneNumberIdentifier,
          index: parseInt(this.participant.participantIndex + 1),
        });
        this.$refs[
          this.participantPhoneNumberIdentifier
        ][0]?.children[0]?.children[0]?.children[1]?.classList.add("error");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
select:hover {
  cursor: pointer;
}
.flags-wrapper {
  display: flex;
  margin-top: -2rem;
  margin-left: auto;
  margin-right: 3rem;
  .flags {
    display: flex;
    .flag-wrapper {
      width: 20px;
      margin: 0 5px 0 5px;
      img {
        border-radius: 50%;
        cursor: pointer;
      }
      .pr-nationality {
        border: solid 1px black;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
</style>
