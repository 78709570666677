import axios from "@/http/http-common";
import Vue from "vue";

class EcaServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/financial/formoptions/eca", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewEcaService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/financial/create/eca", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateEcaService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/financial/update/eca", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new EcaServiceService();
