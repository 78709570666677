import http from "../../http/http-common";
import Vue from "vue";

class BenisDataService {
    async getAll() {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return http.get("/beni/", {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    async addBeni(beni) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return http.post("/beni/create", beni, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    async getSingleBene(id) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return http.post("/beni/id/" + id,null, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    async updateSingleBene(beni) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return http.post("/beni/update", beni, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

}

export default new BenisDataService();
