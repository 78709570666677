export const utilMixin = {
  methods: {
    async ammendEditCaseInput(targetSearchBase, targetSearchList, origSelectBase, origSelectString, otherChoice, answersList) {
      let foundAnswer = false;
      let resultArray = [];
      const answersToSearch = !answersList ? this.gbvData[targetSearchBase][targetSearchList] : answersList;

      for (let ans of answersToSearch) {
        // console.log(`Searching for [${this.editCase.survivor.referral}] against [${ans}]`);
        foundAnswer = !!this.editCase &&
            !!this.editCase[origSelectBase] &&
            !!this.editCase[origSelectBase][origSelectString] &&
            ans === this.editCase[origSelectBase][origSelectString];
        if (foundAnswer) break;
      }

      resultArray[0] = !!this.editCase ? this.editCase[origSelectBase][origSelectString] : ''``;
      resultArray[1] = '';
      if (!foundAnswer) {
        // no answer found
        resultArray[0] = !otherChoice ? 'Other/Please Specify - غير ذلك' : otherChoice;
        resultArray[1] = this.editCase[origSelectBase][origSelectString];
      }

      return resultArray;
    },
    async ammendEditCaseOtherInput(availableAnswers, selectedAnswer, otherChoice) {

      let foundAnswer = false;
      let resultArray = [];

      for (let ans of availableAnswers) {
        foundAnswer = ans === selectedAnswer;
        if (foundAnswer) break;
      }

      resultArray[0] = selectedAnswer;
      resultArray[1] = '';
      if (!foundAnswer) {
        // no answer found
        resultArray[0] = !otherChoice ? 'Other/Please Specify - غير ذلك' : otherChoice;
        resultArray[1] = selectedAnswer;
      }

      return resultArray;
    },
    async ammendEditCaseCheckbox(targetSearchBase, targetSearchList, selectedAnswers, otherChoice) {
      let resultArray = [];
      let shouldAmmendExtra = null;

      for (let choice of selectedAnswers) {
        let answerFound = false;
        for (let ans of this.gbvData[targetSearchBase][targetSearchList]) {
          if (ans === choice) {
            answerFound = true;
            break;
          }
        }
        if (!answerFound) {
          shouldAmmendExtra = choice;
          selectedAnswers = selectedAnswers.splice(selectedAnswers.indexOf(choice), 1);
        }
      }

      if (!!shouldAmmendExtra) {
        // no answer found
        resultArray[0] = !otherChoice ? 'Other/Please Specify - غير ذلك' : otherChoice;
        resultArray[1] = shouldAmmendExtra;
      }

      return resultArray;
    },
  }
};
