<template>
  <ab-content-container :with-footer="true" class="pt-4">
     <div class="offset-lg-3 mt-3 mb-3">
        <p class="text-xl font-semibold">New Basic Information Form</p>
        <p class="text-xs text-gray-500 italic"> This is the starting point of every usecase </p>
       </div>
    <form class="mt-3 md:max-w-5xl lg:max-w-3xl xl:max-w-2xl grid grid-cols-1 md:grid-cols-2 gap-8 mx-auto"  
        @submit.prevent="!editBeni ? addBeni() : saveBeneficiary()">
      <ab-input
        label="Full Name"
        type="text"
        placeholder="e.g: John Sam Doe"
        v-model="beni.fullName"
        :required="true"
      ></ab-input>
      <ab-input
        label="Mother's Name"
        v-model="beni.motherName"
        placeholder="e.g: Julia"
        :required="true"
      ></ab-input>
      <ab-input
        label="Phone Number"
        v-model="beni.phoneNumber"
        placeholder="e.g: 81775599"
        :required="true"
      ></ab-input>
      <ab-input
        label="Additional Phone Number"
        placeholder="e.g: 81775599"
      ></ab-input>
      <ab-input
        type="date"
        label="Date of Birth"
        v-model="beni.dateBirth"
        :required="true"
      ></ab-input>
      <ab-input
        type="text"
        label="Address"
        v-model="beni.address"
        :required="false"
      ></ab-input>
      <!--      Gender-->
      <section class="w-64 text-gray-700 mx-auto">
        <p class="text-xs">Gender <span class="text-red-500">*</span></p>
        <div
          class="
            text-sm
            flex
            justify-around
            border border-solid
            rounded
            border-gray-300
            py-3
          "
        >
          <label class="flex items-center" for="male">
            <input type="radio" id="male" value="male" v-model="beni.gender" />
            <span class="ml-2">Male</span>
          </label>
          <label class="flex items-center" for="female">
            <input
              type="radio"
              id="female"
              value="female"
              v-model="beni.gender"
            />
            <span class="ml-2">Female</span>
          </label>
        </div>
      </section>
      <!--      Marital Status-->
      <ab-select
        :options="maritalStatuses"
        :required="true"
        v-model="beni.maritalStatus"
        :selected-option="beni.maritalStatus"
        label="Marital Status"
      ></ab-select>
      <ab-input
        type="number"
        label="Order To Mother"
        v-model="beni.orderToMother"
        placeholder="e.g: 2"
        :required="true"
      ></ab-input>
      <p v-if="!!error">{{ error }}</p>
      <ab-select
        label="Country of Origin"
        :required="true"
        :options="countries"
        :selected-option="beni.countryOrigin"
        v-model="beni.countryOrigin"
      ></ab-select>
      <ab-input
        v-if="beni.countryOrigin == 'Lebanon'"
        type="string"
        label="National Id Number"
        v-model="beni.nationalIDNumber"
        :required="false"
      ></ab-input>
      <ab-input
        label="Place of Birth"
        v-model="beni.placeBirth"
        placeholder="e.g: Beirut"
        :required="true"
      ></ab-input>

     <div class="w-64 fill-content mx-auto disability-wrapper">
        <label class="switch">
          <span class="disability text-xs text-gray-800">Disability</span>
          <input type="checkbox" class="slide-toggle-checkbox" v-model="isDisabledSelected">
          <div class="slider round"></div>
        </label>
     </div>
     <div v-if="hasDisability()" class="w-64 fill-content mx-auto">
        <span class="text-xs text-gray-800">Disabilities</span>
        <multiselect 
          v-model="disabilityValue" label="name" track-by="name" placeholder="Add Disability"  
          :searchable="false"  :options="disabilityOptions"  :multiple="true"  :taggable="true">
        </multiselect>  
     </div>
      <div class="w-64 flex items-center mx-auto">
        <label class="switch">
          <span class="disability text-xs text-gray-800">UNHCR Registered</span>
          <input type="checkbox" class="slide-toggle-checkbox" v-model="beni.isUNHCRRegistered">
          <div class="slider round"></div>
        </label>
      </div>
      <div class="w-64 flex items-center mx-auto">
        <ab-input
          v-if="beni.isUNHCRRegistered"
          label="UNHCR/ID Number"
          :disabled="shouldDisableUNHCRNumber"
          v-model="beni.unhcrNumber"
          placeholder="e.g: 99102000345"
        ></ab-input>
      </div>
      <div class="md:col-span-2 mx-auto">
        <pulse-loader color="#2399A4" :loading="isLoading"></pulse-loader>
        <button
          v-if="!isLoading && !this.editBeni" :disabled="!!warning"
          class="py-2 px-20 align-self-end bg-primary text-white rounded mt-2 active:transform
            active:translate-y-1 active:outline-none hover:bg-secondary">
          Submit
        </button>
        <ab-button
          v-if="!!this.editBeni"
          class="mt-2"
          label="Save"
          style="color: green"
        ></ab-button>
        <ab-button
          v-if="!!this.editBeni"
          :cancel="true"
          label="Cancel"
          class="mt-2"
          @click="getBeneficiaryPorfilePath()"
        ></ab-button>
      </div>
    </form>
  </ab-content-container>
</template>
<script>
import _ from "lodash";
import BenisDataService from "./BenisDataService";
import AbInput from "@/components/abaad/AbInput/AbInput";
// import AbCheckbox from "@/components/abaad/AbCheckbox/AbCheckbox";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import AbSelect from "@/components/abaad/AbSelect/AbSelect";
import AbButton from "@/components/abaad/AbButton/AbButton";
import PulseLoader from "vue-spinner/src/PulseLoader";
import moment from "moment";
import Multiselect from 'vue-multiselect'
import { required, requiredIf } from 'vuelidate/lib/validators';
import UserService from "@/shared/UserService";
import vue from "vue";
vue.use('required', required);
vue.use('requiredIf', requiredIf);
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js"
export default {
  name: "create-beni",
  mixins: [toasterMixin],
  components: {
    AbInput,
    // AbCheckbox,
    AbContentContainer,
    AbButton,
    AbSelect,
    PulseLoader,
    Multiselect
  },
  props: {
    editBeni: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      warning: "",
      beni: {
        countryOrigin: "",
        dateBirth: "",
        disability: [],
        fullName: "",
        gender: "",
        isUNHCRRegistered: false,
        maritalStatus: "",
        motherName: "",
        orderToMother: null,
        phoneNumber: "",
        placeBirth: "",
        unhcrNumber: "",
        address: "",
        nationalIDNumber: "",
      },
      disabilityValue: [{ name: "Mental", disability: "Mental"}],
      disabilityOptions: [
      { name: "Mental", disability: "Mental"},
      { name: "Physical", disability: "Physical"},
      { name: "Visual", disability: "Visual"},
      { name: "Hearing", disability: "Hearing"},
      { name: "Speaking", disability: "Speaking"}],
      countries: [],
      maritalStatuses: [
        "Single",
        "Married/Cohabitating",
        "Divorced/Seperated",
        "Widowed",
      ],
      disabilities: [],
      disabilityYesNo: ["Yes", "No"],
      isDisabledSelected: false,
      errors: {},
      error: "",
      isLoading: false
    };
  },
  validations() {
    return {
      beni: {
        fullName: {
          required
        },
        motherName: {
          required
        },
        phoneNumber: {
          required
        },
        dateBirth: {
          required
        },
        gender: {
          required
        },
        maritalStatus: {
          required
        },
        orderToMother: {
          required
        },
        countryOrigin: {
          required
        },
        placeBirth: {
          required
        },
        unhcrNumber: {
          required: requiredIf(()=> this.isUNHCRRegistered)
        },
      }
    }
  },
  mounted() {
    this.getCountries();
    // asd
    if (!!this.editBeni) {
      this.populateEditBeni();
    }
    window.scrollTo(0, 0);
    this.disabilityValue = [];
  },
  methods: {
    addBeni() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
      this.ammendDisabilities();
      this.isLoading = true;
      let beni = this.beni;
      BenisDataService.addBeni(beni)
        .then((response) => {
          let id = response.data.gbvmis;
          let name = response.data.fullName;
          localStorage.setItem("hasCase", false);
          localStorage.setItem("beniName", name);
          this.handleSuccessResponse(response);
          setTimeout(() => {
            this.$router.push(`/bene/${id}`);
          }, 2000)
        
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() =>{
          this.isLoading = false
        });
      }
    },
    ammendDisabilities() {
      this.disabilityValue.forEach((disability) => {
        this.beni.disability.push(disability.name)
      })
    },
    handleSuccessResponse(response) {
      if(response.status == 201) {
          let successMessage = "Beneficiary with GBVMIS " + response.data.gbvmis + " created.";
          this.handleSuccess(successMessage);
      }
    },
    hasDisability() {
      return this.isDisabledSelected ? true : false;
    },
    async getCountries() {
      this.countries = await UserService.getCountries();
    },
    populateEditBeni() {
      if (!!this.editBeni) {
        this.beni = this.editBeni;
        this.beni.address = this.address;
        this.beni.dateBirth = moment(new Date(this.beni.dateBirth)).format(
          "YYYY-MM-DD"
        );
      }
    },
    catchErrors() {
      this.errors = [];

      if(!this.beni.fullName) {
        this.errors.push("Full Name Field is Required.")
      }
      if(!this.beni.motherName) {
        this.errors.push("Mother Name Field is Required.")
      }
      if(!this.beni.phoneNumber) {
        this.errors.push("Phone Number Field is Required.")
      }
      if(!this.beni.dateBirth) {
        this.errors.push("Date of Birth Field is Required.")
      }
      if(!this.beni.gender) {
        this.errors.push("Gender Field is Required.")
      }
      if(!this.beni.maritalStatus) {
        this.errors.push("Marital Status Field is Required.")
      }
      if(!this.beni.orderToMother) {
        this.errors.push("Order To Mother Field is Required.")
      }
      if(!this.beni.countryOrigin) {
        this.errors.push("Country of Origin Field is Required.")
      }
      if(!this.beni.placeBirth) {
        this.errors.push("Place of Birth Field is Required.")
      }
      if(!this.beni.unhcrNumber) {
        this.errors.push("Unhcr Number Field is Required.")
      }
      this.handleArrayErrors(this.errors);
    },
    saveBeneficiary() {
      if (!this.isUNHCRRegistered) {
        this.beni.unhcrNumber = "";
      }
      BenisDataService.updateSingleBene(this.beni)
        .then((res) => {
          this.$router.push(`/bene/${res.data.gbvmis}`);
        })
        .catch((error) => {
          window.scrollTo(0, 0);
          this.errors =
            error.response.data.errorMessages ?? error.response.data.details;
        })
        .finally(() => (this.isLoading = false));
    },
    getBeneficiaryPorfilePath() {
      this.$router.push({ path: `/bene/${this.editBeni.gbvmis}` });
    },
  },
  computed: {
    shouldDisableUNHCRNumber() {
      if (!this.beni.isUNHCRRegistered) {
        return true;
      }
      return false;
    },
    isUNHCRRegistered() {
      return this.beni.isUNHCRRegistered;
    },
    unhcrNumber() {
      return this.beni.unhcrNumber;
    },
    countryOrigin() {
      return this.beni.countryOrigin;
    },
    // resultDisability() {
    //   let disability;
    //   if (!!this.isDisabledSelected && this.isDisabledSelected) {
    //     disability = this.beni.disability;
    //   } else {
    //     disability = [];
    //   }

    //   return disability;
    // },
  },
  watch: {
    isDisabledSelected() {
      this.disabilityValue = [];
    },
    isUNHCRRegistered() {
      this.beni.unhcrNumber = "";
    },
    unhcrNumber(val) {
      if (!_.isEmpty(val)) {
        this.warning = "";
        return;
      }
      this.warning = "UNHCR number must be filled";
    },
    countryOrigin(val) {
      if (val == "Lebanon") {
        return;
      }
      this.beni.nationalIDNumber = "";
    },
  },
};
</script>
<style lang="scss">
.disability-wrapper{
  position: relative;
  top: 20px;
}
.disability.text-xs.text-gray-800 {
  position: relative;
  top: -5px;
  left: 40px;
white-space: nowrap;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform .2s ease;
  }
  .multiselect__tags {
    min-height: 40px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
  }
  .multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #999 transparent transparent;
    content: "";
}
.multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 3;
    -webkit-overflow-scrolling: touch;
}
.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #8DD4DE;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
.multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all .2s ease;
    border-radius: 5px;
}
.multiselect__tag:hover {
  background-color: #2399a4;
  cursor: pointer;
}
.multiselect__tag-icon:after {
    content: "\D7";
    color: #fff;
    font-size: 14px;
}
.multiselect__tags-wrap {
    display: inline;
}

.dependant-text {
  position: relative;
  top: -3px;
}

.multiselect__element {
    display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  width: 16rem;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: #2399a4;
    color: #fff;
  }
}
.switch {
  display: inline-block;
  height: 17px;
  position: relative;
  width: 30px;
}

.switch input {
  display:none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 13px;
  left: 1px;
  position: absolute;
  transition: .4s;
  width: 15px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(13px);
}

.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 50%;
}
@media (min-width: 992px) {
  .offset-lg-3 {
      margin-left: 23% !important;
  }
} 

</style>