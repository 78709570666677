<template>
  <div class="wrapper">
    <div v-if="!visible">
      <ab-not-found
        @click="disableClick()"
        v-if="!fields || !acitivity"
      ></ab-not-found>
    </div>
    <div v-else>
      <div class="table-tools">
        <div class="pagination-wrapper">
          <b-pagination
            v-if="visible"
            :total-rows="filtered.length"
            class="pagination mb-2"
            v-model="currentPage"
            :per-page="perPage"
          >
            <template #first-text
              ><span class="text-ab-secondary">First</span></template
            >
            <template #prev-text
              ><span class="text-ab-secondary">Prev</span></template
            >
            <template #next-text
              ><span class="text-ab-secondary">Next</span></template
            >
            <template #last-text
              ><span class="text-ab-secondary">Last</span></template
            >
          </b-pagination>
        </div>
         <div class="change-view">
        <button class="btn btn-primary" @click="toggleTableView()">
          Change Table View
        </button>
      </div>
      </div>
      <div class="table-wrapper shadow-lg">
        <b-table
          ref="table"
          class="shadow-sm bg-white table b-table-stacked-sm"
          hover
          :responsive="true"
          :bordered="true"
          :fields="columns"
          :stickyHeader="true"
          :striped="true"
          :per-page="perPage"
          :current-page="currentPage"
          :items="filtered"
          :stacked="isStacked"
          :selectMode="'single'"
          @row-selected="onRowSelected"
          :selectable="true"
        >
          <b-thead> </b-thead>

          <template #thead-top>
            <tr>
              <th class="thead text-center font-medium" colspan="6">
                Introduction
              </th>
              <th class="thead text-center font-medium" colspan="2">
                Overall Statement
              </th>
              <th class="thead text-center font-medium" colspan="3">Content</th>
              <th class="thead text-center font-medium" colspan="5">
                Wellbeing
              </th>

              <th class="thead text-center font-medium" colspan="4">
                Facilitator
              </th>
              <th class="thead text-center font-medium" colspan="4">
                Logistics
              </th>
              <th class="thead text-center font-medium" colspan="2">
                Feedback & Response
              </th>
              <th class="thead text-center font-medium" colspan="1">
                Suggesstions
              </th>
            </tr>
          </template>
          <template #thead-bottom>
            <tr class="b-header">
              <th
                v-for="column in columns"
                :key="column.key"
                class="border thead text-center font-normal"
              >
                {{ column.label }}
              </th>
            </tr>
          </template>

          <template v-slot:cell(delete)="data">
            <b-button
              class="btn btn-sm delete-row items-center p-3 danger shadow-xl cursor-pointer hover:bg-hover"
              @click="deleteRow($event, data.item)"
            >
              <i class="uil-user-minus"></i>
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import AbNotFound from "@/components/abaad/AbNotFound/AbNotFound";
import ActivityService from "@/features/Activities/ActivityService";
import { EventBus } from "@/Events/event-bus.js";

export default {
  name: "AbTable",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: false,
    },
    activity: {
      type: Object,
      required: false,
    },
    training: {
      type: Object,
      required: false,
    }
  },
  components: {
    AbNotFound,
  },
  data() {
    return {
      activityCopy: {},
      perPage: 7,
      isStacked: false,
      currentPage: 1,
      sliceRange: 10,
      searchActivities: [],
      search: "",
      edit: "",
      stickyHeader: true,
      filters: {
        submittedDate: "",
        introduction: "",
        nationality: "",
        age: "",
        center: "",
        comments: "",
        communicationChannels: "",
        complaintsMechanism: "",
        contentInteresting: "",
        contentUseful: "",
        environmentSafe: "",
        facilitatorAttitude: "",
        facilitatorKnowledge: "",
        facilitatorPresentation: "",
        facilitatorRespect: "",
        overallStatement: "",
        powerPointActivities: "",
        refreshment: "",
        sessionsUseful: "",
        suggestions: "",
        timing: "",
      },
    };
  },
  created() {
    if (!!this.fields) {
      this.visible = true;
    } else {
      this.visible = false;
    }
    EventBus.$on("table_view_change", () => {
      this.toggleTableView();
    });
  },
  methods: {
    rearrangeRowData(row) {
      let keyOrder = [
        "submittedDate",
        "delete",
        "type",
        "nationality",
        "age",
        "center",
        "introduction",
        "overallStatement",
        "comments",
        "postSessionEager",
        "postSessionTeach",
        "postSessionHelp",
        "postSessionFriend",
        "postSessionRelation",
        "contentInteresting",
        "contentUseful",
        "sessionsUseful",
        "facilitatorAttitude",
        "facilitatorKnowledge",
        "facilitatorPresentation",
        "facilitatorRespect",
        "environmentSafe",
        "powerPointActivities",
        "timing",
        "refreshment",
        "complaintsMechanism",
        "communicationChannels",
        "suggestions ",
      ];
      keyOrder.forEach((k) => {
        console.log("🚀 ~ k:", k);
        const v = row[k];
        delete row[k];
        row[k] = v;
      });
      return row;
    },
    deleteRow($event, row, index) {
      $event.preventDefault();
      $event.stopPropagation();
      this.deleteForm(row, index);
    },

    getDateAndTime(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    checkIfMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    deleteForm(row, index) {
      if (confirm("Are you sure you want to delete form ?")) {
        if (this.activity.awarnessSatisfactionList.length == 1) {
          this.activity.awarnessSatisfactionList = [];
        }
        if (this.activity.awarnessSatisfactionList.length > 1) {
          this.activity.awarnessSatisfactionList.splice(index, 1);
        }
        this.fields = this.activity.awarnessSatisfactionList;
        ActivityService.updateActivity(this.activity);
      }
    },
    toggleTableView() {
      if (this.isStacked == false) {
        this.isStacked = true;
      } else {
        this.isStacked = false;
      }
    },
    getPssSatisfactionRoute(activity) {
      return {
        path: "edit",
        name: "PssSatisfactionForm",
        params: { activity: activity, editService: true },
      };
    },
    getAwarenessSatisfactionRoute(activity) {
      return {
        path: "edit",
        name: "AwarenessSatisfactionForm",
        params: { activity: activity, editService: true },
      };
    },
    async onRowSelected(data) {
      console.log("🚀 ~ data:", data);
      let name;
      if (data[0].type.includes("PSS")) {
        name = "PssSatisfactionForm";
      }
      if (data[0].type.includes("AWARENESS")) {
        name = "AwarenessSatisfactionForm";
      }
      let formData = this.getActivityFormById(data[0].id);
      this.$router.push({
        name: name,
        params: {
          activity: this.activity,
          editService: formData,
          formId: data[0].id,
        },
      });
    },
    getActivityFormById(id) {
      console.log(
        "🚀 ~ this.activity.awarnessSatisfactionList:",
        this.activity.awarnessSatisfactionList
      );
      return this.activity.awarnessSatisfactionList.find((x) => x.id === id);
    },
  },
  computed: {
    filtered() {
      return this.fields;
    },
  },
};
</script>
<style lang="scss" scoped>
.table-tools {
  display: flex;
  .pagination-wrapper {
    margin-left: auto;
  }
  .pagination-wrapper {
    margin-right: auto;
  }
}
table.table.b-table.table-hover.table-bordered.table-sm.b-table-selectable.b-table-select-single {
  height: 85vh !important;
  thead th div {
    font-size: 12px;
  }
}
.table-wrapper {
  overflow-x: scroll;
  overflow-y: auto;
  width: 90vw;
  height: 75vh;
}
.btn.btn-secondary {
  background-color: #fff;
  border-color: #8dd4de;
  color: #8dd4de;
}
.text-ab-secondary {
  background-color: #fff;
  border-color: #8dd4de;
  color: #8dd4de;
}
.page-item.disabled .page-link,
.page-item .page-link {
  border-color: #8dd4de;
}

.th-class {
  background-color: #fff;
  font-weight: normal !important;
}
button.btn.delete-row.btn-secondary {
  background-color: #dc3545;
  color: white;
  border-color: none;
  font-size: 16px;
}
button.btn.delete-row.btn-secondary:hover {
  background-color: #dc3545;
  color: white;
  border-color: none;
  font-size: 16px;
}
.table-row {
  cursor: pointer;
}
.change-view {
  float: right;
}
</style>
