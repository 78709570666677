<template>
  <div>
    <pulse-loader
      color="#2399A4"
      :loading="isLoading"
      class="loader"
      v-if="isLoading"
    ></pulse-loader>
    <div v-else>
      <ab-content-container>
        <p class="font-semibold text-xl">Shelter Service</p>
        <form @submit.prevent="
            !editService ? addShelterService() : updateShelterService()" class="flex flex-col mx-auto max-w-2xl">
          <div class="mt-6">
            <p class="text-sm mb-1 font-semibold">Date of Referral</p>
            <ab-input
              type="date"
              :full="true"
              v-model="submittedDate"
            ></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ referToSafeHouseQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in referToSafeHouseAnswers"
              v-model="referral"
            ></ab-radio>
          </div>
          
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ referralResponseQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in referralResponseAnswers"
              v-model="referralResponse"
            ></ab-radio>
          </div>

          <div class="mt-12" v-if="referralResponseDeclined()">
            <p class="font-semibold text-sm mb-1">{{ referralResponseWhyQuestion }}</p>
            <textarea v-model="referralResponseWhy" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12" v-if="isReferralExternal() && isReferralApproved()">
            <p class="font-semibold text-sm mb-1">{{ agencyQuestion }}</p>
            <textarea v-model="agency" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ referralDetailsQuestion }}</p>
            <textarea v-model="referralDetails" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <div class="flex flex-col justify-center items-center" v-if="!isLoading">
              <ab-button @click="!editService ? addShelterService() : updateShelterService()"
                class="mt-2 submit-btn"
                label="submit">
              </ab-button>
              <ab-button @click="routeToNavigation()" class="mt-2" :cancel="true" label="cancel">
              </ab-button>
            </div>
          </div>
        </form>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import vue from "vue";
import ShelterServiceService from "./ShelterServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { mapActions, mapGetters } from 'vuex'
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-shelter-service",
  mixins: [utilMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getShelterServiceQA();
    this.gbvmis = this.$route.params.id;
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 
  },
  data() {
    return {
      gbvmis: "",
      referral: "",
      referralDetails: "",
      referralResponse: "",
      referralResponseWhy: "",
      agency: "",
      submittedDate: "",
      shelterServiceQA: {},
      latestCounselingServiceDate: "",
      tomorrowsDate: "",
      errors: {},
      error: "",
      isLoading: false,
    };
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: (value) => value < this.tomorrowsDate,
      },
      referral: {
        required
      },
      referralResponse: {
        required
      }
    }
  },
  methods: {
    ...mapActions(['fetchAllServices']),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getShelterServiceQA() {
      this.isLoading = true;
      ShelterServiceService.getQaData()
        .then((response) => {
          this.shelterServiceQA = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD");
      this.gbvmis = this.editService.gbvmis;
      this.referral = this.editService.referToSafeHouse;
      this.referralResponse = this.editService.referralResponse;
      this.referralResponseWhy = this.editService.referralResponseWhy;
      this.referralDetails = this.editService.referralDetails;
      this.agency = this.editService.agency;
    },
    addShelterService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
      this.isLoading = true;
      const newShelterService = this.getNewShelterService();
      ShelterServiceService.addNewShelterService(newShelterService)
        .then(() => this.$router.push({ path: `/bene/${this.gbvmis}` }))
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
      }
    },
    updateShelterService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
      this.isLoading = true;
      ShelterServiceService.updateShelterService(this.getNewShelterService())
        .then(() => {
          this.$router.push({
            path: `/bene/${this.$route.params.id}/management`,
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
      }
    },
    getNewShelterService() {
      let newShelterService = {};
      if (!!this.editService) newShelterService.id = this.editService.id;
      newShelterService.gbvmis = this.gbvmis;
      newShelterService.referToSafeHouse = this.referral;
      newShelterService.referralResponse = this.referralResponse;
      newShelterService.referralResponseWhy = this.referralResponseWhy;
      newShelterService.referralDetails = this.referralDetails;
      newShelterService.agency = this.agency;
    
      newShelterService.submittedDate = new Date(this.submittedDate).toJSON();

      newShelterService.serviceType = "shelter";

      return newShelterService;
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
     submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    catchErrors() {
      this.errors = [];
      
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
       if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }
      if(!this.referral) {
        this.errors.push("Referral is Required.")
      }
      if(!this.referralResponse) {
        this.errors.push("Referral Response is Required.")
      }
      
      this.handleArrayErrors(this.errors);
    },
    referralResponseDeclined() {
      return !!this.referralResponse && this.referralResponse.includes('Declined/Not Accepted');
    },
    isReferralExternal() {
      return !!this.referral && this.referral.includes('Yes\\External Referral');
    },
    isReferralApproved() {
      return !!this.referralResponse &&  this.referralResponse.includes('Approved');
    }
  },
  computed: {
    ...mapGetters(['allServices']),
    referralResponseQuestion() {
      return this.shelterServiceQA.referralResponse.question[0];
    },
    referralResponseAnswers() {
      return this.shelterServiceQA.referralResponse.answers;
    },
    referralResponseWhyQuestion() {
      return this.shelterServiceQA.referralResponseWhy.question[0];
    },
    referToSafeHouseQuestion() {
      return this.shelterServiceQA.referral.question[0];
    },
    referToSafeHouseAnswers() {
      return this.shelterServiceQA.referral.answers;
    },
    referralDetailsQuestion() {
      return this.shelterServiceQA.referralDetails.question[0];
    },
    referralDetailsAnswers() {
      return this.shelterServiceQA.referralDetails.answers;
    },
    agencyQuestion() {
      return this.shelterServiceQA.agency.question[0];
    }
  },
};
</script>
