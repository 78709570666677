import Home from "../features/Home.vue";
import BeniList from "../features/beneficiaries/BeniList";
import Profile from "../features/Profile/Profile.vue";
import CreateBeni from "../features/beneficiaries/CreateBeni.vue";
import SocioDemo from "../features/Profile/Form/CaseManagement/SocioDemo.vue";
import ShelterService from "../features/Profile/Form/Services/Shelter/ShelterService.vue";
import Navigation from "../features/Profile/Navigation/Navigation.vue";
import MedicalService from "../features/Profile/Form/Services/Medical/MedicalService.vue";
import PsychService from "../features/Profile/Form/Services/Psych/PsychService.vue";
import PsychologistService from "../features/Profile/Form/Services/Psychologist/PsychologistService.vue";
import ProtectionService from "../features/Profile/Form/Services/Protection/ProtectionService.vue";
import CounselingService from "../features/Profile/Form/Services/Counseling/CounselingService.vue";
import ActionService from "../features/Profile/Form/Services/Action/ActionService.vue";
import ActionServiceEditMode from "../features/Profile/Form/Services/Action/ActionServiceEditMode.vue";
import LegalService from "../features/Profile/Form/Services/Legal/LegalService";
import ClosureService from "../features/Profile/Form/Services/Closure/ClosureService";
import SafetyService from "../features/Profile/Form/Services/Saftey/SafetyService";
import ViewCaseManagement from "../features/Profile/Form/CaseManagement/ViewCaseManagement.vue";
import ExternalReferral from "../features/Profile/Form/Services/ExternalReferral/ExternalReferralService.vue";
import Attachments from "../features/Profile/Attachment/Attachments";
import Management from "../features/Profile/Management/Management";
import Activities from "../features/Activities/Activities";
// import ActivityNavigation from "../features/Profile/Navigation/ActivityNavigation.vue";
import FamilyComp from "../features/Profile/FamilyComposition/FamilyComp";
import PcapService from "../features/Profile/Form/FinancialServices/PCAP/PcapService";
import EcaService from "../features/Profile/Form/FinancialServices/ECA/EcaService";
import PcapFollowupService from "../features/Profile/Form/FinancialServices/PcapFollowup/PcapFollowupService";
import PcapFeedbackService from "../features/Profile/Form/FinancialServices/PcapFeedback/PcapFeedbackService";
import CounselingServiceReadOnly from "../features/Profile/Form/Services/Counseling/CounselingServiceReadOnly.vue";
import { authGuard } from "../auth";
import NewActivity from "@/features/Activities/NewActivity";
import CurrentActivities from "@/features/Activities/CurrentActivities";
import CompletedActivities from "@/features/Activities/CompletedActivities";
import SingleActivity from "@/features/Activities/SingleActivity";
import NewSession from "@/features/Activities/NewSession";
import SingleSession from "@/features/Activities/SingleSession";
import RecordLog from "@/components/RecordLog/RecordLog";
import AddBeneficiaryComponent from "@/features/Activities/AddBeneficiary";
// EditBeneficiaryComponent;
import EditBeneficiaryComponent from "@/features/Activities/EditBeneficiary";
import ActivityBeneficiaryNavigation from "@/features/Activities/ActivityBeneficiaryNavigation";
import ActivityUserInfo from "@/features/Activities/ActivityUserInfo";
import ManageActivityBeneficiary from "@/features/Activities/ManageActivityBeneficiary";
import PromoteBeneficiary from "@/features/Activities/PromoteBeneficiary";
import PssSatisfactionForm from "@/features/Activities/PssSatisfactionForm.vue";
import AwarenessSatisfactionForm from "@/features/Activities/AwarenessSatisfactionForm.vue";
import EvaluationForms from "@/features/Activities/EvaluationForms/EvaluationForms.vue";
import NewTraining from "@/features/Training/NewTraining.vue";
import TrainingComponent from "@/features/Training/Training.vue";
import currentTraining from "@/features/Training/CurrentTraining.vue";
import completedTraining from "@/features/Training/CompletedTraining.vue";
import SingleTraining from "@/features/Training/SingleTraining.vue";
import TrainingFeedback from "@/features/Training/TrainingFeedback.vue";
import FeedbackEvaluationForms from "@/features/Training/FeedbackEvaluationForms.vue";
const routes = [
  {
    path: "/",
    component: Home,
    beforeEnter: authGuard,
  },
  {
    path: "/bene",
    component: BeniList,
  },
  {
    name: "/bene/create",
    path: "/bene/create",
    component: CreateBeni,
    props: true,
  },
  {
    path: "/recordlog",
    component: RecordLog,
  },
  {
    path: "/bene/:id",
    component: Profile,
    children: [
      {
        path: "",
        component: Navigation,
      },
      {
        path: "view-socio-demo",
        component: ViewCaseManagement,
      },
      {
        name: "socio-demo",
        path: "socio-demo",
        component: SocioDemo,
        props: true,
      },
      {
        path: "familycomp",
        component: FamilyComp,
      },
      {
        path: "shelter",
        name: "new-shelter",
        component: ShelterService,
        props: true,
      },
      {
        path: "medical",
        name: "new-medical",
        component: MedicalService,
        props: true,
      },
      {
        path: "psych",
        name: "new-psych",
        component: PsychService,
        props: true,
      },
      {
        path: "psychologist",
        name: "new-psychologist",
        component: PsychologistService,
        props: true,
      },
      {
        path: "protection",
        name: "new-protection",
        component: ProtectionService,
        props: true,
      },
      {
        path: "counseling",
        name: "new-counseling",
        component: CounselingService,
        props: true,
      },
      {
        path: "counseling/readonly",
        name: "readonly-counseling",
        component: CounselingServiceReadOnly,
        props: true,
      },
      {
        path: "legal",
        name: "new-legal",
        component: LegalService,
        props: true,
      },
      {
        path: "action",
        name: "new-action",
        component: ActionService,
        props: true,
      },
      {
        path: "action/edit",
        name: "edit-action",
        component: ActionServiceEditMode,
        props: true,
      },
      {
        path: "closure",
        name: "new-closure",
        component: ClosureService,
        props: true,
      },
      {
        path: "safety",
        name: "new-safety",
        component: SafetyService,
        props: true,
      },
      {
        path: "attachments",
        component: Attachments,
      },
      {
        path: "management",
        component: Management,
      },
      {
        path: "financial/pcap",
        name: "new-pcap",
        component: PcapService,
        props: true,
      },
      {
        path: "financial/eca",
        name: "new-eca",
        component: EcaService,
        props: true,
      },
      {
        path: "external-referral",
        name: "new-externalreferral",
        component: ExternalReferral,
        props: true,
      },
      {
        path: "financial/pcapfollowup",
        name: "new-pcap-followup",
        component: PcapFollowupService,
        props: true,
      },
      {
        path: "financial/pcapfeedback",
        name: "new-pcap-feedback",
        component: PcapFeedbackService,
        props: true,
      },
    ],
  },
  {
    path: "/training",
    component: TrainingComponent,
    children: [
      {
        path: "new",
        component: NewTraining,
        name: "NewTraining",
        props: true,
      },
      {
        path: "current",
        component: currentTraining,
        name: "CurrentTraining",
        props: true,
      },
      {
        path: "completed",
        component: completedTraining,
        name: "CompletedTraining",
        props: true,
      },
      {
        path: ":id",
        name: "SingleTraining",
        component: SingleTraining,
        props: true,
      },
      {
        path: ":id/view",
        name: "FeedbackEvaluationForms",
        component: FeedbackEvaluationForms,
        props: true,
      },
      {
        name: "TrainingFeedback",
        path: ":id/feedback/new",
        component: TrainingFeedback,
        props: true,
      },            
      {
        name: "TrainingFeedbackEdit",
        path: ":id/feedback/edit/:feedbackId",
        component: TrainingFeedback,
        props: true,
      },
      {
        path: ":id/edit",
        name: "create-new-training",
        component: NewTraining,
        props: true,
      },
    ],
  },

  {
    path: "/activities",
    component: Activities,
    children: [
      
      {
        name: "AddBeneficiaryComponent",
        path: "add-beneficiary",
        component: AddBeneficiaryComponent,
        props: true,
      },
      {
        path: "new",
        component: NewActivity,
      },
      {
        name: "create-new-activity",
        path: "edit",
        component: NewActivity,
        props: true,
      },
      {
        path: "current",
        component: CurrentActivities,
      },
      {
        path: "completed",
        component: CompletedActivities,
      },
      {
        path: ":id",
        name: "singleActivity",
        component: SingleActivity,
      },
      {
        path: "activity-beneficiary/:id",
        component: ActivityBeneficiaryNavigation,
        children: [
          {
            path: "user-info",
            name: "ActivityUserInfo",
            component: ActivityUserInfo,
          },
          {
            path: "user-management",
            name: "ManageActivityBeneficiary",
            component: ManageActivityBeneficiary,
          },
          {
            path: "promote-user",
            name: "PromoteBeneficiary",
            component: PromoteBeneficiary,
            props: true,
          },
          
          {
            path: "user-management",
            name: "EditBeneficiaryComponent",
            component: EditBeneficiaryComponent,
            props: true,
          },
        ],
      },
      {
        path: ":id/pss-satisfaction-form",
        name: "PssSatisfactionForm",
        component: PssSatisfactionForm,
        props: true,
      },
      {
        path: ":id/awareness-satisfaction-form",
        name: "AwarenessSatisfactionForm",
        component: AwarenessSatisfactionForm,
        props: true,
      },
      {
        path: ":id/view",
        name: "EvaluationForms",
        component: EvaluationForms,
        props: true,
      },
      {
        path: ":id/edit",
        name: "EvaluationForms",
        component: EvaluationForms,
        props: true,
      },
      {
        path: "sessions/new",
        name: "newSession",
        component: NewSession,
      },
      {
        path: "sessions/:id",
        name: "singleSession",
        component: SingleSession,
        props: (route) => ({
          ...route.params,
        }),
      },
    ],
  },
];

export default routes;
