import axios from "@/http/http-common";
import Vue from "vue";

class ExternalReferralServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/externalreferral", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewExternalReferralService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/create/externalreferral", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateExternalReferralService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/update/externalreferral", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new ExternalReferralServiceService();
