import axios from "@/http/http-common";
import Vue from "vue";

class PcapServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/financial/formoptions/pcap", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewPcapService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/financial/create/pcap", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updatePcapService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/financial/update/pcap", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new PcapServiceService();
