<template>
  <pulse-loader
    v-if="isLoading"
    :loading="isLoading"
    class="loader"
    color="#2399A4"
  ></pulse-loader>
  <ab-content-container v-else>
    <div id="promote-beneficiary">
      <p class=" font-semibold text-xl">
        Promote Acitivity Beneficiary Details
      </p>
      <p class=" text-xs text-gray-500 italic">
        This is where we input the required to promote a beneficiary
      </p>
    </div>
    <ab-collapse
      class="mt-3"
      :expand="false"
      ref="collapseBeneficiaryInfo"
      label="Beneficiary Information"
    >
      <form
        class="flex flex-col mx-auto max-w-2xl"
        @submit.prevent="promoteBene()"
      >
        <div class="mt-6" ref="fullName">
          <span class="font-semibold text-sm mb-1 flex">
            {{ fullNameQuestion }}
          </span>
          <ab-input
            :full="true"
            type="text"
            placeholder="e.g: John Sam Doe"
            v-model="beneficiary.fullName"
            :required="false"
          ></ab-input>
        </div>
        <div class="mt-6" ref="phoneNumber">
          <span class="font-semibold text-sm mb-1 flex">
            {{ phoneNumberQuestion }}
          </span>
          <ab-input
            :full="true"
            type="number"
            placeholder="e.g: 71555555"
            v-model="beneficiary.phoneNumber"
            :required="false"
          ></ab-input>
        </div>
        <div class="mt-6" ref="dateBirth">
          <span class="font-semibold text-sm mb-1 flex">
            Date of Birth
          </span>
          <ab-input
            :full="true"
            type="date"
            v-model="beneficiary.dateBirth"
            :required="false"
          ></ab-input>
        </div>
        <div class="mt-12" ref="gender">
          <span class="font-semibold text-sm mb-1 flex">
            {{ genderQuestion }}
          </span>
          <ab-radio
            v-for="(genderAnswer, key) in genderAnswers"
            :key="key"
            :label="genderAnswer"
            :value="genderAnswer"
            class="mb-2"
            :required="false"
            v-model="beneficiary.gender"
          ></ab-radio>
        </div>
        <!-- end gender -->
        <div class="other" v-if="shouldShowGenderOther()">
          <p class="font-semibold text-sm mb-1">Specify</p>
          <ab-input :full="true" v-model="genderOther"></ab-input>
        </div>

        <div class="mt-6 maritalStatus" ref="maritalStatus">
          <span class="font-semibold text-sm mb-1 flex">
            {{ maritalStatusQuestion }}
          </span>
          <ab-select
            :full="true"
            :options="maritalStatusAnswers"
            :required="false"
            v-model="beneficiary.maritalStatus"
            :selected-option="beneficiary.maritalStatus"
          ></ab-select>
        </div>

        <div v-if="!editService" class="mt-6" ref="countryOrigin">
          <span class="font-semibold text-sm mb-1 flex">
            {{ countryOriginQuestion }}
            <div class="flags-wrapper">
              <div class="flags">
                <div class="flag-wrapper">
                  <img
                    @click="setCommonNationality('Lebanon')"
                    src="../../assets/images/lb.png"
                    alt=""
                  />
                </div>
                <div class="flag-wrapper">
                  <img
                    @click="setCommonNationality('Palestine')"
                    src="../../assets/images/ps.png"
                    alt=""
                  />
                </div>
                <div class="flag-wrapper">
                  <img
                    @click="setCommonNationality('Syria')"
                    src="../../assets/images/sy.png"
                    alt=""
                  />
                </div>
                <div class="flag-wrapper">
                    <div  @click="setCommonNationality('PRL')" class="pr-nationality">
                      <span>PRL</span>
                    </div>
                  </div>
                  <div class="flag-wrapper">
                    <div  @click="setCommonNationality('PRS')" class="pr-nationality">
                      <span>PRS</span>
                    </div>
                    </div>
              </div>
            </div>
          </span>

          <ab-select
            ref="nationality-select"
            :required="false"
            :full="true"
            :placeHolder="'Select Country'"
            :options="countries"
            v-model="beneficiary.countryOrigin"
          ></ab-select>
        </div>
        <div v-if="!!editService" class="mt-6" ref="countryOrigin">
          <span class="font-semibold text-sm mb-1 flex">
            {{ countryOriginQuestion }}
            <div class="flags-wrapper">
              <div class="flags">
                <div class="flag-wrapper">
                  <img
                    @click="setCommonNationality('Lebanon')"
                    src="../../assets/images/lb.png"
                    alt=""
                  />
                </div>
                <div class="flag-wrapper">
                  <img
                    @click="setCommonNationality('Palestine')"
                    src="../../assets/images/ps.png"
                    alt=""
                  />
                </div>
                <div class="flag-wrapper">
                  <img
                    @click="setCommonNationality('Syria')"
                    src="../../assets/images/sy.png"
                    alt=""
                  />
                </div>
                <div class="flag-wrapper">
                    <div  @click="setCommonNationality('PRL')" class="pr-nationality">
                      <span>PRL</span>
                    </div>
                  </div>
                  <div class="flag-wrapper">
                    <div  @click="setCommonNationality('PRS')" class="pr-nationality">
                      <span>PRS</span>
                    </div>
                    </div>
              </div>
            </div>
          </span>
          <ab-select
            :required="false"
            :full="true"
            :options="countries"
            :selectedOption="beneficiary.countryOrigin"
            v-model="beneficiary.countryOrigin"
          ></ab-select>
        </div>
        <div class="mt-6 fill-content w-full" ref="DisabilityValue">
          <span class="font-semibold text-sm mb-1 flex">
            {{ disabilityQuestion }}
          </span>
          <multiselect
            v-model="disability"
            label="name"
            track-by="name"
            placeholder="Add Disability"
            :searchable="false"
            :options="disabilityOptions"
            :multiple="true"
            :taggable="true"
            @select="addDisability"
            @remove="removeDisability"
          >
          </multiselect>
        </div>
        <div class="mt-6 flex items-center w-full" ref="isUNHCRRegistered">
          <label class="switch">
            <span class="disability text-xs text-gray-800">{{
              isUNHCRRegisteredQuestion
            }}</span>
            <input
              type="checkbox"
              class="slide-toggle-checkbox"
              v-model="beneficiary.isUNHCRRegistered"
            />
            <div class="slider round"></div>
          </label>
        </div>
        <div
          class="mt-6 flex items-center w-full"
          v-if="beneficiary.isUNHCRRegistered"
          ref="unhcrNumber"
        >
          <span class="font-semibold text-sm mb-1 flex">
            UNHCR/ID Number
          </span>
          <ab-input
            :full="true"
            label=""
            :disabled="shouldDisableUNHCRNumber"
            v-model="beneficiary.unhcrNumber"
            placeholder="e.g: 99102000345"
          ></ab-input>
        </div>
        <div class="mt-6">
          <span class="font-semibold text-sm mb-1 flex">
            {{ occupancyQuestion }}
          </span>
          <ab-input
            :full="true"
            type="text"
            v-model="beneficiary.occupancy"
            :required="false"
          ></ab-input>
        </div>
        <div class="mt-6" ref="referredFrom">
          <p class="font-semibold text-sm mb-1">{{ referredFromQuestion }}</p>
          <ab-radio
            v-for="(answer, index) in referredFromAnswers"
            :key="index"
            v-model="beneficiary.referredFrom"
            :label="answer"
            :value="answer"
            class="mb-2"
          ></ab-radio>
          <!-- end referThirdParty -->
          <div
            class="other"
            v-if="shouldShowreferredFrom()"
            ref="referredFromOther"
          >
            <p class="font-semibold text-sm mb-1">Specify</p>
            <ab-input
              :full="true"
              v-model="beneficiary.referredFromOther"
            ></ab-input>
          </div>
        </div>
        <div class="mt-6" ref="geographicCharacteristics">
          <p class="font-semibold text-sm mb-1">
            {{ geographicCharacteristicsQuestion }}
          </p>
          <ab-radio
            v-for="(answer, index) in geographicCharacteristicsAnswers"
            :key="index"
            v-model="beneficiary.geographicCharacteristics"
            :label="answer"
            :value="answer"
            class="mb-2"
          ></ab-radio>
        </div>
        <!-- Submit -->
        <div class="mt-12">
          <div
            class="flex flex-col justify-center items-center"
            v-if="!isLoading"
          ></div>
        </div>
        <!-- end Submit -->
      </form>
    </ab-collapse>
    <ab-collapse
      class="mt-3"
      :expand="true"
      ref="collapseBeneficiaryInfo"
      label="Additional Information"
    >
      <div class="mt-6" ref="motherName">
        <p class="font-semibold text-sm mb-1">
          Mother Name
        </p>
        <ab-input
          :full="true"
          type="text"
          placeholder="e.g: Samia Sam Doey"
          v-model="beneficiary.motherName"
          :required="false"
        ></ab-input>
      </div>
      <div class="mt-6" ref="placeBirth">
        <p class="font-semibold text-sm mb-1">
          Place Birth
        </p>
        <ab-input
          :full="true"
          type="text"
          placeholder="e.g: Beirut"
          v-model="beneficiary.placeBirth"
          :required="false"
        ></ab-input>
      </div>
      <div class="mt-6" ref="orderToMother">
        <p class="font-semibold text-sm mb-1">
          Order to Mother
        </p>
        <ab-input
          :full="true"
          type="number"
          v-model="beneficiary.orderToMother"
          :required="false"
        ></ab-input>
      </div>
    </ab-collapse>
    <div class="flex flex-col items-center justify-center mx-auto mt-4">
      <ab-button @click="promoteBene()" class="mt-2" label="Promote User To CM">
      </ab-button>
      <ab-button
        id="cancel"
        @click="$router.go(-1)"
        class="mt-2"
        :cancel="true"
        label="cancel"
      ></ab-button>
    </div>
    <div>
      <b-modal centered id="modal" title="Beneficiary Promoted Successfully">
        <p class="my-2">
          This Beneficiary has been promoted successfully with GBVMIS:
          <span ref="message"> {{ gbvmis }}</span>
        </p>

        <template #modal-footer="{}">
          <b-button
            class="btn btn-primary"
            size="md"
            variant="success"
            @click="goToProfile(gbvmis)"
          >
            Go To Profile
          </b-button>
           <b-button
            class="btn btn-primary"
            size="md"
            variant="success"
            @click="goToHome()"
          >
            Go To Home
          </b-button>
        </template>
      </b-modal>
    </div>
  </ab-content-container>
</template>
<script>
import vue from "vue";
import moment from "moment";

import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";

import AbCollapse from "../../components/abaad/AbCollapse/AbCollapse.vue";
import AbContentContainer from "../../components/abaad/AbContentContainer/AbContentContainer.vue";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbInput from "@/components/abaad/AbInput/AbInput.vue";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbSelect from "@/components/abaad/AbSelect/AbSelect";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";

import Multiselect from "vue-multiselect";
import UserService from "@/shared/UserService";

import AddBeneficiaryDataService from "./AddActivityBeneficiaryDataService";

import {
  required,
  minValue,
  maxValue,
  requiredIf,
  minLength,
} from "vuelidate/lib/validators";

vue.use("required", required);
vue.use("minLength", minLength);
vue.use("minValue", minValue);
vue.use("maxValue", maxValue);
vue.use("requiredIf", requiredIf);
export default {
  name: "PromoteBeneficiary",
  components: {
    AbCollapse,
    AbContentContainer,
    PulseLoader,
    AbInput,
    AbSelect,
    AbRadio,
    AbButton,
    Multiselect,
  },
  mixins: [utilMixin, toasterMixin],
  data() {
    return {
      qaData: {},
      errors: [],
      isLoading: false,
      gbvmis: "",
      beneficiary: {
        motherName: "",
        placeBirth: "",
        orderToMother: "",
        fullName: "",
        phoneNumber: null,
        dateBirth: "",
        gender: "",
        maritalStatus: "",
        countryOrigin: "",
        isUNHCRRegistered: false,
        unhcrNumber: null,
        referredFrom: "",
        referredFromOther: "",
        geographicCharacteristics: "",
        occupancy: "",
        disability: [],
      },
      genderOther: "",
      disability: [],
      countries: [],
      disabilityOptions: [],
    };
  },
  props: {
    noFooterButtons: {
      type: Boolean,
      required: false,
    },
    editService: {
      type: Object,
      required: false,
    },
    isPromote: {
      type: Boolean,
      required: false,
    },
  },
  validations() {
    return {
      beneficiary: {
        fullName: {
          required,
          minLength: minLength(3),
        },
        // ! ask joesph about date range
        dateBirth: {
          required,
        },
        phoneNumber: {
          required,
        },
        gender: {
          required,
        },
        maritalStatus: {
          required,
        },
        countryOrigin: {
          required,
        },

        unhcrNumber: {
          required: requiredIf(
            () =>
              this.beneficiary.isUNHCRRegistered &&
              !this.beneficiary.unhcrNumber
          ),
        },
        referredFrom: {
          required,
        },
        referredFromOther: {
          required: requiredIf(
            () =>
              !this.beneficiary.referredFromOther &&
              this.beneficiary.referredFrom.includes("Other")
          ),
        },
        // !Check with joseph if required
        geographicCharacteristics: {
          required,
        },
        occupancy: {
          required: false,
        },
        motherName: {
          required,
        },
        placeBirth: {
          required,
        },
        orderToMother: {
          required,
        },
      },
    };
  },
  created() {
    this.getCountries();
    this.getQaData();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    copyText() {
      setTimeout(() => {
        const storage = document.createElement("textarea");
        storage.value = this.$refs.message.innerHTML;
        this.$refs.reference.appendChild(storage);
        storage.select();
        storage.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.$refs.reference.removeChild(storage);
      }, 200);
    },
    goToHome() {
      this.routeToHome();
    },
    goToProfile(gbvmis) {
      this.$router.push({
        path: `/bene/${gbvmis}`
      })
    },
    isNewBene() {
      return !this.editService && !this.isPromote;
    },
    isEditBene() {
      return !!this.editService;
    },
    isPromoteBene() {
      return !!this.isPromote && !!this.editService;
    },
    getQaData() {
      this.isLoading = true;
      AddBeneficiaryDataService.getQaData()
        .then((response) => {
          this.qaData = response.data;
          this.prepareDisabilityArray();

          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async getCountries() {
      this.countries = await UserService.getCountries();
    },
    prepareDisabilityArray() {
      this.disabilityAnswers.forEach((disabilityItem) => {
        this.disabilityOptions.push({ name: disabilityItem });
      });
    },
    addDisability(data) {
      if (!this.beneficiary.disability.includes(data.name))
        this.beneficiary.disability.push(data.name);
      else this.removeDisability(data);
    },
    removeDisability(data) {
      this.beneficiary.disability = this.beneficiary.disability.filter(
        (e) => e !== data.name
      );
    },

    getnewActivityBeneficiary() {
      let newActivityBeneficiary = {};
      if (!!this.editService) newActivityBeneficiary.id = this.editService.id;
      if (this.shouldShowGenderOther()) {
        newActivityBeneficiary.gender = this.genderOther;
      } else {
        newActivityBeneficiary.gender = this.beneficiary.gender;
      }
      if (this.shouldShowreferredFrom()) {
        newActivityBeneficiary.referredFrom = this.beneficiary.referredFromOther;
      } else {
        newActivityBeneficiary.referredFrom = this.beneficiary.referredFrom;
      }
      if (this.beneficiary.isUNHCRRegistered)
        newActivityBeneficiary.unhcrNumber = this.beneficiary.unhcrNumber;

      newActivityBeneficiary.fullName = this.beneficiary.fullName;
      newActivityBeneficiary.phoneNumber = this.beneficiary.phoneNumber;
      newActivityBeneficiary.dateBirth = this.beneficiary.dateBirth;
      newActivityBeneficiary.maritalStatus = this.beneficiary.maritalStatus;
      newActivityBeneficiary.disability = this.beneficiary.disability;
      newActivityBeneficiary.isUNHCRRegistered = this.beneficiary.isUNHCRRegistered;
      newActivityBeneficiary.occupancy = this.beneficiary.occupancy;
      newActivityBeneficiary.geographicCharacteristics = this.beneficiary.geographicCharacteristics;
      newActivityBeneficiary.countryOrigin = this.beneficiary.countryOrigin;
      newActivityBeneficiary.abCode = this.beneficiary.abCode;
      newActivityBeneficiary.createdBy = this.beneficiary.createdBy;
      newActivityBeneficiary.createdByCenter = this.beneficiary.createdByCenter;
      newActivityBeneficiary.createdDate = this.beneficiary.createdDate;

      return newActivityBeneficiary;
    },
    setCommonNationality(value) {
      if (!!this.$refs["nationality-select"])
        this.$refs["nationality-select"]._data.selected = value;
      this.beneficiary.countryOrigin = value;
    },
    populateEditService() {
      this.beneficiary.gender = this.ammendEditCaseOtherInput(
        this.genderAnswers,
        this.editService.gender,
        "Other"
      ).then((result) => {
        this.genderOther = result[1];
        this.beneficiary.gender = result[0];
      });
      this.beneficiary.referredFrom = this.ammendEditCaseOtherInput(
        this.referredFromAnswers,
        this.editService.referredFrom,
        "Other"
      ).then((result) => {
        this.beneficiary.referredFromOther = result[1];
        this.beneficiary.referredFrom = result[0];
      });
      this.beneficiary.isUNHCRRegistered = this.editService.isUNHCRRegistered;
      this.beneficiary.unhcrNumber = this.editService.unhcrNumber;
      this.beneficiary.fullName = this.editService.fullName;
      this.beneficiary.phoneNumber = this.editService.phoneNumber;
      this.beneficiary.dateBirth = moment(
        new Date(this.editService.dateBirth)
      ).format("YYYY-MM-DD");
      this.beneficiary.maritalStatus = this.editService.maritalStatus;
      this.editService.disability.forEach((disability) => {
        this.disability.push({ name: disability });
        this.beneficiary.disability.push(disability);
      });
      this.beneficiary.occupancy = this.editService.occupancy;
      this.beneficiary.geographicCharacteristics = this.editService.geographicCharacteristics;
      this.setCommonNationality(this.editService.countryOrigin);

      // this.beneficiary.countryOrigin = this.editService.countryOrigin;
      this.beneficiary.abCode = this.editService.abCode;
      this.beneficiary.createdBy = this.editService.createdBy;
      this.beneficiary.createdByCenter = this.editService.createdByCenter;
      this.beneficiary.createdDate = this.editService.createdDate;
    },
    promoteBene() {
      if (!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          let id = JSON.parse(localStorage.getItem("ActivityBeneficiary")).id;
          AddBeneficiaryDataService.promoteNewActivityBeneficiary(
            this.beneficiary,
            id
          ).then((response) => {
            console.log("🚀 ~ this.$bvModal:", this.$bvModal);
            this.$bvModal.show("modal");
            this.gbvmis = response.data.gbvmis;
            console.log("🚀 ~ response:", response);
            this.copyText();
            localStorage.setItem(
              "ActivityBeneficiary",
              JSON.stringify(response.data)
            );
          });
        }
      }
    },
    routeToHome() {
      return this.$router.push({
        path: `/`,
      });
    },
    shouldShowreferredFrom() {
      if (this.beneficiary.referredFrom.includes("Other")) {
        return true;
      } else {
        return false;
      }
    },
    routeToNavigation() {
      history.go(-1);
    },
    shouldShowGenderOther() {
      return this.beneficiary.gender.includes("Other");
    },
    catchErrors() {
      this.errors = [];

      if (!this.beneficiary.fullName) {
        let elPosition =
          this.$refs?.fullName?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Full Name is Required.",
          pos: elPosition,
        });
      }

      if (this.beneficiary.fullName && this.beneficiary.fullName.length < 3) {
        let elPosition =
          this.$refs?.fullName?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Full Name Must be atleast 3 characters.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.phoneNumber) {
        let elPosition =
          this.$refs?.phoneNumber?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Phone Number is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.dateBirth) {
        let elPosition =
          this.$refs?.dateBirth?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Date Of Birth is Required.",
          pos: elPosition,
        });
      }

      if (!this.beneficiary.gender) {
        let elPosition =
          this.$refs?.gender?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Gender is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.maritalStatus) {
        let elPosition =
          this.$refs?.maritalStatus?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Marital Status is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.countryOrigin) {
        let elPosition =
          this.$refs?.countryOrigin?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "countryOrigin is Required.",
          pos: elPosition,
        });
      }
      if (this.beneficiary.isUNHCRRegistered && !this.beneficiary.unhcrNumber) {
        let elPosition =
          this.$refs?.unhcrNumber?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "UNHCR is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.referredFrom) {
        let elPosition =
          this.$refs?.referredFrom?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "referredFrom is Required.",
          pos: elPosition,
        });
      }
      if (
        !this.beneficiary.referredFromOther &&
        this.beneficiary.referredFrom.includes("Other")
      ) {
        let elPosition =
          this.$refs?.referredFromOther?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "RefferalOther is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.geographicCharacteristics) {
        let elPosition =
          this.$refs?.geographicCharacteristics?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Geographical Characteristics is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.motherName) {
        let elPosition =
          this.$refs?.motherName?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Mother Name is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.placeBirth) {
        let elPosition =
          this.$refs?.placeBirth?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Birth Place is Required.",
          pos: elPosition,
        });
      }
      if (!this.beneficiary.orderToMother) {
        let elPosition =
          this.$refs?.orderToMother?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Order To Mother is Required.",
          pos: elPosition,
        });
      }
      this.handleArrayErrorsAndScrollToQuestion(this.errors);
    },
  },

  computed: {
    maritalStatusQuestion() {
      return this.qaData.maritalStatus.question[0];
    },
    maritalStatusAnswers() {
      return this.qaData.maritalStatus.answers;
    },
    countryOriginQuestion() {
      return this.qaData.countryOrigin.question[0];
    },
    geographicCharacteristicsQuestion() {
      return this.qaData.geographicCharacteristics.question[0];
    },
    geographicCharacteristicsAnswers() {
      return this.qaData.geographicCharacteristics.answers;
    },
    shouldDisableUNHCRNumber() {
      if (!this.beneficiary.isUNHCRRegistered) {
        return true;
      }
      return false;
    },
    genderQuestion() {
      return this.qaData.gender.question[0];
    },
    genderAnswers() {
      return this.qaData.gender.answers;
    },
    fullNameQuestion() {
      return this.qaData.fullName.question[0];
    },
    phoneNumberQuestion() {
      return this.qaData.phoneNumber.question[0];
    },
    disabilityQuestion() {
      return this.qaData.disability.question[0];
    },
    isUNHCRRegisteredQuestion() {
      return this.qaData.unhcrRegistered.question[0];
    },
    isUNHCRRegisteredAnswers() {
      return this.qaData.unhcrRegistered.answers;
    },
    occupancyQuestion() {
      return this.qaData.occupancy.question[0];
    },
    referredFromQuestion() {
      return this.qaData.referredFrom.question[0];
    },
    referredFromAnswers() {
      return this.qaData.referredFrom.answers;
    },
    disabilityAnswers() {
      console.log("🚀 ~ this.qaData.disability", this.qaData.disability);
      return this.qaData.disability.answers;
    },
  },
};
</script>
<style lang="scss" scoped>
.flags-wrapper {
  display: flex;
  margin-left: auto;
  .flags {
    display: flex;
    .flag-wrapper {
      width: 25px;
      margin: 0 5px 0 5px;
      img {
        border-radius: 50%;
        cursor: pointer;
      }
      .pr-nationality {
        border: solid 1px black;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
</style>
