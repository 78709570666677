<template>
  <div>
    <pulse-loader
      color="#2399A4"
      :loading="isLoading"
      class="loader"
      v-if="isLoading"
    ></pulse-loader>
    <div v-else>
      <ab-content-container>
        <p class="font-semibold text-xl">Counseling Session</p>
        <form @submit.prevent="!editService ? addCounselingService() : updateCounselingService()" 
          class="flex flex-col mx-auto max-w-2xl">
          
          <div class="mt-6">
            <p class="text-sm mb-1 font-semibold">Date of Counseling</p>
            <ab-input :full="true" type="date" v-model="submittedDate"></ab-input>
          </div>
          
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ typeInterventionQuestion }}</p>
            <ab-radio class="mb-2" :key="index" :label="answer" :value="answer"
              v-for="(answer, index) in typeInterventionAnswers"
              v-model="typeIntervention"></ab-radio>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ overviewQuestion }}</p>
            <textarea v-model="overview" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ actionTakenQuestion }}</p>
            <textarea v-model="actionTaken" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>
         
          <div>
            <div class="counseling-session" :key="counselingSession.id" v-for="counselingSession in counselingSessions">
              <router-link :to="routeToCounselingReadonly(counselingSession)">
                <div class="session flex items-center bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary">
                  <i class="uil uil-headphones-alt text-white text-3xl"></i>
                  <p class="text-md ml-2 text-white">
                    Counseling -
                    {{ formatSubmittedDate(counselingSession.submittedDate) }}
                  </p>
                </div>
              </router-link>
            </div>
          </div>
          <div class="mt-12">
            <div class="flex flex-col justify-center items-center" v-if="!isLoading">
              <ab-button
                @click="!editService ? addCounselingService() : updateCounselingService()" class="mt-2 submit-btn" label="submit"></ab-button>
              <ab-button @click="routeToNavigation()" class="mt-2" :cancel="true" label="cancel"></ab-button>
            </div>
          </div>
        </form>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import CounselingServiceService from "./CounselingServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import moment from "moment";
import vue from "vue";
import { utilMixin } from "@/mixins/forms/utilMixin";
import {toasterMixin} from "@/components/abaad/AbToaster/toaster.js" 
import {mapGetters, mapActions} from "vuex";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-counseling-service",
  mixins: [utilMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
    beni: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getCounselingServiceQA();
    this.gbvmis = this.$route.params.id;
    this.caseData = this.getCaseData();
    this.latestCaseSubmitDate =  moment(this.caseData.submittedDate).format('YYYY-MM-DD');
    this.getLastTwoCounselingSessions(); 
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        if(!!this.allServices.counseling && !!this.allServices.counseling.length) {
          let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
          this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
        }
      })
    }
  },
  data() {
    return {
      gbvmis: "",
      typeIntervention: "",
      actionTaken: "",
      overview: "",
      submittedDate: "",
      counselingServiceQA: {},
      caseData: {},
      errors: [],
      error: "",
      isLoading: false,
      counselingSessions: {},
      latestCaseSubmitDate: "",
      latestCounselingServiceDate: ""
    };
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: value => {
          let returnValue;
          if(!!this.allServices && !!this.allServices.counseling && this.allServices.counseling.length) {
            returnValue = value >= this.latestCounselingServiceDate;
          } else {
            returnValue =  value >= this.latestCaseSubmitDate; 
          }
          return returnValue;
        },
        maxValue: (value) => value < this.tomorrowsDate,
      },
      typeIntervention: {
        required
      }
    }
  },
  methods: {
    ...mapActions(['fetchLatestIncident', 'fetchAllServices']),
    getCaseData() {
      let cases = JSON.parse(localStorage.getItem('cases'))
      return cases[cases.length - 1];
    },
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    getLastTwoCounselingSessions() {
      let callParams = {
        gbvmis: this.gbvmis,
        serviceType: "counseling",
        number: 2,
      };
      return CounselingServiceService.getServiceSessions(callParams).then(
        (successResponse) => {
          if(!!successResponse.data) {
            this.counselingSessions = successResponse.data;
          }
        },
        (errorResponse) => {
          console.error(errorResponse);
        }
      );
    },
    formatSubmittedDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    routeToCounselingReadonlyMethod() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/readonly/counseling`,
          params: {
            counselingSessions: this.counselingSessions,
            counselingServiceQA: this.counselingServiceQA,
          },
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getCounselingServiceQA() {
      this.isLoading = true;
      CounselingServiceService.getQaData()
        .then((response) => {
          this.counselingServiceQA = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD");
      this.typeIntervention = this.editService.typeIntervention;
      this.overview = this.editService.overview;
      this.actionTaken = this.editService.actionTaken
    },
    addCounselingService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
        this.isLoading = true;
        const newCounselingService = this.getNewCounselingService();
        CounselingServiceService.addNewCounselingService(newCounselingService)
          .then(() => {
            this.$router.push({ path: `/bene/${this.gbvmis}` });
          })
          .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => (this.isLoading = false));
      } 
    },
    catchErrors() {
      this.errors = [];
      
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
       if(this.submitDateHasError()) {
        if(!!this.allServices.counseling && this.latestCounselingServiceDate) {
            this.errors.push("Beneficiary has a Counselling Service, Submitted Date must be greater than last Counselling and less than tomorrow.");
        } else {
            this.errors.push("Submitted Date Must be Between Latest Case Management Date and Today.");
        }
      }
      if(!this.typeIntervention) {
        this.errors.push("Type Intervention is Required.");
      }
      
      this.handleArrayErrors(this.errors);
    },
    updateCounselingService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
      this.isLoading = true;
      CounselingServiceService.updateCounselingService(this.getNewCounselingService())
        .then(() => {
          this.$router.push({
            path: `/bene/${this.$route.params.id}/management`,
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
      }
    },
    getNewCounselingService() {
      let newCounselingService = {};
      if (!!this.editService) newCounselingService.id = this.editService.id;
      newCounselingService.gbvmis = this.gbvmis;
      newCounselingService.typeIntervention = this.typeIntervention;
      newCounselingService.overview = this.overview;
      newCounselingService.actionTaken = this.actionTaken;
      newCounselingService.submittedDate = new Date(this.submittedDate).toJSON();
      newCounselingService.serviceType = "counseling";

      return newCounselingService;
    },
    routeToCounselingReadonly(counselingSession) {
      return {
        name: "readonly-counseling",
        params: {
          counselingServiceQA: this.counselingServiceQA,
          counselingSessions: this.counselingSessions,
          counselingSession: counselingSession,
        },
      };
    },
    dateHasError() {
      if(!!this.allServices && this.allServices.counseling && !!this.allServices.counseling.length) {
        return this.validateDateOn(this.latestCounselingServiceDate);
      } else {
        return this.validateDateOn(this.latestCaseSubmitDate);
      }
    },
    validateDateOn(dateToValidateOn) {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterDateToValidateOn = moment(formattedUserInput).isSameOrAfter(dateToValidateOn);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterDateToValidateOn) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError;
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
  },
  computed: {
    ...mapGetters(["incident", "allServices"]),
    typeInterventionQuestion() {
      return this.counselingServiceQA.typeIntervention.question[0];
    },
    typeInterventionAnswers() {
      return this.counselingServiceQA.typeIntervention.answers;
    },
    overviewQuestion() {
      return this.counselingServiceQA.overview.question[0];
    },
    actionTakenQuestion() {
      return this.counselingServiceQA.actionTaken.question[0];
    },
    actionTakenAnswers() {
      return this.counselingServiceQA.actionTaken.answers;
    }
  },
};
</script>

<style lang="scss" scoped>
.counseling-session {
  margin: 5px;
  height: 75px;
  .session {
    height: 100%;
  }
}

.date-error {
  input {
    border-color: red !important;
    color: red !important;
  }
  .p {
    color: red;
  }
}
i {
  margin-left: 10px;
  // font-size: 1rem;
  font-weight: normal;
}
@media (max-width: 850px) {
  i {
    font-size: 2rem;
  }
}
.date-has-error-wrapper {
  border: solid 1px red !important;
  color: red !important;
  background-color: #fbe4e4;
  border-radius: 5px;
  padding: 0 10px;
  margin-top: 12px;
  font-size: 14px;
}
</style>
