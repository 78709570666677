<template>
  <ab-content-container>
    <pulse-loader
      color="#2399A4"
      :loading="isLoading"
      class="loader"
      v-if="isLoading"
    ></pulse-loader>
    <div v-else>
      <div>
        <form @submit.prevent="!editService ? addActionService() : updateActionService()" class="flex flex-col mx-auto ">
          <div class="flex justify-between">
            <p class="font-semibold text-xl">Action Plan</p>
          </div>
          <div class="mt-12">
            <p class="text-sm font-semibold mb-1">Date of Action Plan Submission</p>
            <ab-input v-model="submittedDate" :full="true" type="date"></ab-input>
          </div>

          <div class="mt-12">
            <p class="text-sm font-semibold mb-1">Objective / {{ stepsQuestion }}</p>
            <textarea v-model="timeFrame" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">Steps / {{ stepsQuestion }}</p>
            <textarea v-model="steps" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="text-sm font-semibold mb-1"> {{ resourcesAvailableQuestion }}</p>
            <textarea v-model="resourcesAvailable" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

           <div class="mt-12">
            <p class="text-sm font-semibold mb-1">{{ taskQuestion }}</p>
            <textarea v-model="task" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="text-sm font-semibold mb-1">{{ expectedChallengesQuestion }}</p>
            <textarea v-model="expectedChallenges" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="text-sm font-semibold mb-1">{{ indicatorsAchievementQuestion }}</p>
            <textarea v-model="indicatorsAchievement" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="text-sm font-semibold mb-1">{{ evaluationProgressQuestion }}</p>
            <textarea v-model="evaluationProgress" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div v-if="!!actionServices" class="prev-action-plans">
            <div class="action-service" :key="actionService.id" v-for="actionService in actionServices">
              <router-link :to="routeToActionServiceEditMode(actionService)">
                <div class="flex items-center bg-primary w-full p-3 rounded shadow-xl cursor-pointer hover:bg-secondary">
                  <i class="uil uil-headphones-alt text-white text-3xl"></i>
                  <p class="text-md ml-2 text-white">Action - {{ formatSubmittedDate(actionService.submittedDate) }}</p>
                </div>
              </router-link>
            </div>
          </div>

          <div class="mt-12">
            <div class="flex flex-col justify-center items-center" v-if="!isLoading">
              <ab-button @click="!editService ? addActionService() : updateActionService()" class="mt-2" label="submit"></ab-button>
              <ab-button @click="routeToNavigation()" class="mt-2" :cancel="true" label="cancel"></ab-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ab-content-container>
</template>
<script>
import vue from "vue";
import ActionServiceService from "./ActionServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import { utilMixin } from "@/mixins/forms/utilMixin";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";


vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-action-service",
  mixins: [utilMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getActionServiceQA();
    this.gbvmis = this.$route.params.id;
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 

    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
        if(!!this.allServices.action && !!this.allServices.action.length){
          this.actionServices = this.allServices.action;
        }
      })
    }
    if (!this.editService) {
      this.getIfBeniHaveActionPlan();
    }
  },
  mounted() {
  },
  data() {
    return {
      gbvmis: "",
      steps: "",
      timeFrame: "",
      resourcesAvailable: "",
      expectedChallenges: "",
      indicatorsAchievement: "",
      evaluationProgress: "",
      task: "",
      submittedDate: "",
      actionServiceQA: {},
      beniHasPrevActionPlan: false,
      editableActionPlan: false,
      latestCounselingService: {},
      actionServices: [],
      errors: {},
      error: "",
      isLoading: false,
    };
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: (value) => value < this.tomorrowsDate,
      }
    }
  },
  methods: {
    ...mapActions(["fetchAllServices"]),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    routeToActionServiceEditMode(actionService) {
      return {
        name: "edit-action",
        params: {
          serviceQA: this.actionServiceQA,
          actionServices: this.actionServices,
          actionService: actionService,
        },
      };
    },
    formatSubmittedDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getIfBeniHaveActionPlan() {
      return !!this.allServices.action && this.allServices.action.length;
    },
    toggleEditableActionPlan() {
      this.editableActionPlan = true;
    },
    getActionServiceQA() {
      this.isLoading = true;
      ActionServiceService.getQaData()
        .then((response) => {
          this.actionServiceQA = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD");
      this.steps = this.editService.steps;
      this.timeFrame = this.editService.timeFrame;
      this.resourcesAvailable = this.editService.resourcesAvailable;
      this.expectedChallenges = this.editService.expectedChallenges;
      this.indicatorsAchievement = this.editService.indicatorsAchievement;
      this.evaluationProgress = this.editService.evaluationProgress;
      this.task = this.editService.task;
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    addActionService() {
      if(!!this.$v) {
         this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
        this.isLoading = true;
        const newActionService = this.getNewActionService();
        ActionServiceService.addNewActionService(newActionService)
          .then(() => this.$router.push({ path: `/bene/${this.gbvmis}` }))
          .catch((error) =>{            
            this.errors = [];
            let errors = error.response.data.errorMessages;
            errors.forEach((error) => {
              this.errors.push(error);
            })
          })
          .finally(() => {
            this.isLoading = false
            if(confirm("Would you like to submit another action plan?")) {
              setTimeout(() => {
                this.$router.push({path: `${this.$route.params.id}/action`});
                this.$forceUpdate();
              }, 100);
            }
            
          });
     }
      }
    },
    updateActionService() {
      if(!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          ActionServiceService.updateActionService(this.getNewActionService())
            .then(() => {
              this.$router.push({
                path: `/bene/${this.$route.params.id}/management`,
              });
            })
            .catch((error) => console.log(error))
            .finally(() => (this.isLoading = false));
        }
      }
    },
    catchErrors() {
      this.errors = [];
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
       if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }
      this.handleArrayErrors(this.errors);
    },
    getNewActionService() {
      let newActionService = {};
      if (!!this.editService) newActionService.id = this.editService.id;
      newActionService.gbvmis = this.gbvmis;
      newActionService.steps = this.steps;
      newActionService.timeFrame = this.timeFrame;
      newActionService.resourcesAvailable = this.resourcesAvailable;
      newActionService.expectedChallenges = this.expectedChallenges;
      newActionService.indicatorsAchievement = this.indicatorsAchievement;
      newActionService.evaluationProgress = this.evaluationProgress;
      newActionService.task = this.task;
      newActionService.submittedDate = new Date(this.submittedDate).toJSON();

      newActionService.serviceType = "action";

      return newActionService;
    }, 
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    }
  },
  computed: {
    ...mapGetters(["allServices"]),
    stepsQuestion() {
      return this.actionServiceQA.steps.question[0];
    },
    timeFrameQuestion() {
      return this.actionServiceQA.timeFrame.question[0];
    },
    resourcesAvailableQuestion() {
      return this.actionServiceQA.resourcesAvailable.question[0];
    },
    expectedChallengesQuestion() {
      return this.actionServiceQA.expectedChallenges.question[0];
    },
    indicatorsAchievementQuestion() {
      return this.actionServiceQA.indicatorsAchievement.question[0];
    },
    evaluationProgressQuestion() {
      return this.actionServiceQA.evaluationProgress.question[0];
    },
    taskQuestion() {
      return this.actionServiceQA.task.question[0];
    },
  },
};
</script>
<style lang="scss" scoped>
.action-service,
.prev-action-plans {
  margin: 10px 0;
}
</style>
