<template>
  <div>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#2399A4"
    ></pulse-loader>
    <div v-else>
      <ab-content-container :with-footer="true" class="pt-2">
        <ab-errors :errors="errors" class="mb-10"></ab-errors>
        <div
          v-if="!isOldSessionDone && !isOldSessionEdit"
          class="flex flex-col mx-auto max-w-2xl"
          @submit.prevent="addSession()"
        >
          <div class="title-wrapper">
            <p class="font-semibold text-xl">{{ getSectionTitle() }}</p>
            <p class="pull-left text-xs text-gray-500 italic">
              This is where we add a new activity session
            </p>
          </div>
          <div class="mt-6 flex justify-between items-center">
            <ab-input
              v-model="submittedDate"
              label="Submit Date"
              type="date"
            ></ab-input>
            <ab-input
              v-model="topic"
              :required="true"
              :label="topicQuestion"
              type="text"
            ></ab-input>
          </div>
          <div class="px-8 mt-8">
            <p class="text-xs text-gray-800 mb-1">Comments</p>
            <textarea
              v-model="comments"
              :placeholder="'Enter Comments here...'"
              class="
                w-full
                h-24
                border border-solid
                p-2
                text-sm
                font-light
                border-gray-300
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <GridMultiSelect
              v-model="selectedBeneficiaries"
              :items="allBeneficiaries"
              :searchable="true"
              :title="this.selectAttendeesText"
              empty-message="No Attendees Selected"
              item-key="customMapId"
              item-label="customMapId"
              style="min-height: 150px"
            >
              <template v-slot:selected-item="{ selectedItem }">
                <SingleAttendee
                  :selected-item="selectedItem"
                  @updateSelectedItem="updateSelectedItem"
                ></SingleAttendee>
              </template>
            </GridMultiSelect>
          </div>
          <div class="mt-12">
            <div
              v-if="!isLoading"
              class="flex flex-col justify-center items-center"
            >
              <ab-button
                v-if="!isOldSessionDone"
                :label="this.submitButtonLabel"
                class="mt-2"
                @click="addSession()"
              ></ab-button>
              <ab-button
                v-if="isOldSession && !isOldSessionDone"
                class="mt-2"
                label="Set to Done"
                style="color: green"
                @click="closeSession()"
              ></ab-button>
              <ab-button
                :cancel="true"
                :label="this.backButtonLabel"
                class="mt-2"
                @click="getActivityRoutePush()"
              ></ab-button>
            </div>
          </div>
        </div>
        <div
          v-if="isOldSessionDone && !isOldSessionEdit"
          class="flex flex-col mx-auto max-w-2xl"
        >
          <div class="title-wrapper">
            <p class="font-semibold text-xl">{{ getSectionTitle() }}</p>
            <p class="pull-left text-xs text-gray-500 italic">
              This is where we view an activity session
            </p>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <ab-card question="Submit Date" :answer="submittedDate"></ab-card>
            <ab-card :question="topicQuestion" :answer="topic"></ab-card>
            <ab-card question="Comments" :answer="comments"></ab-card>
          </div>
          <div class="mt-12">
            <GridMultiSelect
              v-model="selectedBeneficiaries"
              :items="allBeneficiaries"
              :searchable="true"
              :title="this.selectAttendeesText"
              empty-message="No Attendees Selected"
              item-key="customMapId"
              item-label="customMapId"
              style="min-height: 150px"
              @item-removed="enableItemInList"
            >
              <template v-slot:selected-item="{ selectedItem }">
                <SingleAttendee
                  :isDisabled="true"
                  :selected-item="selectedItem"
                  @updateSelectedItem="updateSelectedItem"
                ></SingleAttendee>
              </template>
            </GridMultiSelect>
          </div>
          <div class="flex flex-col justify-center items-center">
            <ab-button
              :label="'Edit Session'"
              class="mt-2"
              @click="editMode()"
            ></ab-button>
            <ab-button
              :cancel="true"
              :label="'Back To Activity'"
              class="mt-2 btn"
              @click="getActivityRoutePush()"
            ></ab-button>
          </div>
        </div>
        <div v-if="isOldSessionEdit" class="flex flex-col mx-auto max-w-2xl">
          <div class="title-wrapper">
            <p class="font-semibold text-xl">
              {{ "Edit " + getSectionTitle() }}
            </p>
            <p class="pull-left text-xs text-gray-500 italic">
              This is where we edit an activity session
            </p>
          </div>
          <div>
            <div class="mt-6 flex justify-between items-center">
              <ab-input
                v-model="submittedDate"
                label="Submit Date"
                type="date"
              ></ab-input>
              <ab-input
                v-model="topic"
                :required="true"
                :label="topicQuestion"
                type="text"
              ></ab-input>
            </div>
            <div class="px-8 mt-8">
              <p class="text-xs text-gray-800 mb-1">Comments</p>
              <textarea
                v-model="comments"
                :placeholder="'Enter Comments here...'"
                class="
                w-full
                h-24
                border border-solid
                p-2
                text-sm
                font-light
                border-gray-300
                rounded-sm
              "
              ></textarea>
            </div>
          </div>
          <div class="mt-12">
            <GridMultiSelect
              v-model="selectedBeneficiaries"
              :items="allBeneficiaries"
              :searchable="true"
              :title="this.selectAttendeesText"
              empty-message="No Attendees Selected"
              item-key="customMapId"
              item-label="customMapId"
              style="min-height: 150px"
            >
              <template v-slot:selected-item="{ selectedItem }">
                <SingleAttendee
                  :selected-item="selectedItem"
                  @updateSelectedItem="updateSelectedItem"
                ></SingleAttendee>
              </template>
            </GridMultiSelect>
          </div>
          <div class="flex flex-col justify-center items-center">
            <ab-button
              :cancel="false"
              :label="'Save Session Info'"
              class="mt-2 btn btn-primary"
              @click="editSession()"
            ></ab-button>

            <ab-button
              class=" btn btn-primary"
              @click="viewMode()"
              :label="'Cancel'"
              :cancel="true"
            ></ab-button>
            <ab-button
              :cancel="true"
              :label="'Back To Activity'"
              class="btn btn-secondary"
              @click="getActivityRoutePush()"
            ></ab-button>
          </div>
        </div>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import SingleAttendee from "./SingleAttendee";
import ActivityService from "@/features/Activities/ActivityService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import AbErrors from "@/components/abaad/AbErrors/AbErrors";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import GridMultiSelect from "vue-gridmultiselect";
import "vue-gridmultiselect/dist/vue-gridmultiselect.css";
import moment from "moment";
import AbCard from "@/components/abaad/AbCard/AbCard";
import AddActivityBeneficiaryDataService from "./AddActivityBeneficiaryDataService.js";
// import _ from "lodash"
export default {
  name: "create-new-session",
  props: ["currentActivity"],
  components: {
    AbInput,
    AbContentContainer,
    AbErrors,
    AbButton,
    PulseLoader,
    GridMultiSelect,
    SingleAttendee,
    AbCard,
  },
  created() {
    window.scrollTo(0, 0);
    this.isLoading = true;
    const existingActivity = JSON.parse(
      localStorage.getItem("currentActivity")
    );
    ActivityService.getSingleActivity(existingActivity.id).then((response) => {
      localStorage.setItem("currentActivity", response.data);
    });

    if (this.isOldSession) {
      this.topic = this.currentSession.topic;
      this.comments = this.currentSession.comments;
      this.submittedDate = new Date(this.currentSession.submittedDate);
      // this.selectedBeneficiaries = this.getGListFromActivityAttendeesList(this.currentSession);
      this.selectedBeneficiaries = this.getGListFromActivityAttendeesList(
        this.currentSession
      );
    } else if (
      existingActivity !== null &&
      existingActivity !== undefined &&
      existingActivity &&
      typeof existingActivity &&
      Object.keys(existingActivity).length > 0
    ) {
      // this is the case where we have to populate for a NEW session, existing attendees suggestions
      this.selectedBeneficiaries = this.getSuggestedAttendees(existingActivity);
    }

    this.getNewSessionQA();
    this.getAllBeneficiaries();
  },
  mounted() {
    AddActivityBeneficiaryDataService.getAllActivitiesBenis().then(
      (response) => {
     
        if(this.currentSession && this.currentSession.attendees && this.currentSession.attendees.length)
        this.currentSession.attendees.forEach((attendee) => {
          if (!this.currentActivity.aggUniAttendees[attendee.abCode]) {
            const singleAddedAtendee = response.data.filter((bene) =>{
                return bene.abCode == attendee.abCode}
            );
            const newAttendee = {
              $isDisabled: true,
              abCode: attendee.abCode,
              customMapId:  singleAddedAtendee[0].fullName + " - " + attendee.abCode,
              fullName: singleAddedAtendee[0].fullName,
              isInfluencer: attendee.isInfluencer,
              isPresent: attendee.isPresent
            };      
            this.selectedBeneficiaries.push(newAttendee);
            this.currentActivity.aggUniAttendees[attendee.abCode] = singleAddedAtendee[0];
          }
        });
      }
    );

  },

  beforeCreate() {

  },
  data() {
    return {
      topic: "",
      comments: "",
      submittedDate: "",
      autoUpdate: true,
      selectAttendeesText: "Please select attendees",
      selectedBeneficiaries: [],
      allBeneficiaries: [],
      newSessionQA: {},
      isOldSessionEdit: false,
      errors: {},
      error: "",
      isLoading: false,
      SectionTitle: "New Session Form",
    };
  },
  methods: {
    editSession() {
      let postData = {
        activityId: this.currentActivity.id,
        attendees: this.selectedBeneficiaries,
        comments: this.comments,
        id: this.currentSession.id,
        status: "OPEN",
        submittedDate: new Date(this.submittedDate),
        topic: this.topic,
        createdBy: this.currentSession.createdBy || this.currentActivity.createdBy,
        createdDate: this.getSessionCreatedDate(),
      };
      console.log("🚀 ~ file: NewSession.vue:358 ~ editSession ~ postData:", postData)
      ActivityService.updateSession(postData).then(() => {
        this.$router.push({
          path: "/activities/" + this.currentActivity.id,
        });
      });
    },
    getSessionCreatedDate() {
      const currentSession = localStorage.getItem('currentSession');
      return JSON.parse(currentSession).createdDate;

    },
    editMode() {
      this.isOldSessionEdit = true;
    },
    viewMode() {
      this.isOldSessionEdit = false;
    },
    changeSubmittedDate(e) {
      this.submittedDate = e.target.value;
    },
    updateSelectedItem(selectedItem) {
      this.removeItemOnce(this.selectedBeneficiaries, selectedItem);
      this.selectedBeneficiaries.push(selectedItem);
    },
    enableItemInList(removedItem) {
      this.allBeneficiaries.find((bene) => {
        if (bene.abCode == removedItem.abCode) {
          bene.$isDisabled = false;
        }
        return bene;
      });
    },
    removeItemOnce(arr, value) {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    },
    initAllBeneFields() {
      this.allBeneficiaries = this.allBeneficiaries.map((bene) => {
        if (bene.isInfluencer === undefined || bene.isInfluencer === null) {
          bene.isInfluencer = false;
        }
        if (bene.isPresent === undefined || bene.isPresent === null) {
          bene.isPresent = true;
        }
        return bene;
      });
    },
    remove(item) {
      const index = this.selectedBeneficiaries.indexOf(item);
      if (index >= 0) this.selectedBeneficiaries.splice(index, 1);
    },
    getAllBeneficiaries() {
      this.isLoading = true;
      ActivityService.getAllBeneficiariesLimited()
        .then((response) => {
          this.allBeneficiaries = JSON.parse(JSON.stringify(response.data));
          const selectedAbCodes = new Set(
            this.selectedBeneficiaries.map((selected) => selected.abCode)
          );
          this.allBeneficiaries.forEach((bene) => {
            if (selectedAbCodes.has(bene.abCode)) {
              bene.$isDisabled = true;
            }
          });
          this.initAllBeneFields();
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    getNewSessionQA() {
      this.isLoading = true;
      ActivityService.getSessionQaData()
        .then((response) => {
          this.newSessionQA = JSON.parse(JSON.stringify(response.data));
        })
        .catch((error) => console.log(error));
      // There's a potentially slower API firing off, it should handle the state of loading
      // .finally(() => this.isLoading = false);
    },
    addSession() {
      this.isLoading = true;
      const newSession = this.getNewSession();
      this.saveSessionRequest(newSession);
    },
    getNewSession() {
      let newSession = {};
      newSession.topic = this.topic;
      newSession.status = "OPEN";
      newSession.comments = this.comments;
      newSession.activityId = this.$route.params.activityId;
      newSession.submittedDate = new Date(this.submittedDate);
      newSession.attendees = this.getGListFromBList(this.selectedBeneficiaries);
      if (this.isOldSession) {
        newSession.id = this.currentSession.id;
      }

      return newSession;
    },
    closeSession() {
      this.isLoading = true;
      const sessionToClose = this.getNewSession();
      sessionToClose.status = "DONE";
      this.saveSessionRequest(sessionToClose);
    },
    saveSessionRequest(newSession) {
      return ActivityService.addNewSession(newSession)
        .then((response) => {
          localStorage.setItem('currentSession', JSON.stringify(response.data));
          this.getActivityRoutePush();
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    getActivityRoute() {
      return {
        name: "singleActivity",
        params: { id: this.$route.params.activityId },
      };
    },
    getActivityRoutePush() {
      this.$router.push(this.getActivityRoute());
    },
    getGListFromBList(blist) {
      return blist.map((item) => {
        return item;
      });
    },

    getGListFromActivityAttendeesList(currentSession) {
      return currentSession.attendees
        .filter((item) => {
          return !!this.currentActivity.aggUniAttendees[item.abCode];
        })
        .map((item) => {
          const atteendeeFullInfo = this.currentActivity.aggUniAttendees[
            item.abCode
          ];
          return {
            customMapId:
              atteendeeFullInfo.fullName + " - " + atteendeeFullInfo.abCode,
            fullName: atteendeeFullInfo.fullName,
            abCode: item.abCode,
            $isDisabled: true,
            isInfluencer:
              item.isInfluencer !== undefined && item.isInfluencer !== null
                ? item.isInfluencer
                : false,
            isPresent:
              item.isPresent !== undefined && item.isPresent !== null
                ? item.isPresent
                : true,
          };
        });
    },
    getSuggestedAttendees(existingActivity) {
      let suggAtt = [];
      if (
        existingActivity.aggUniAttendees !== null &&
        existingActivity.aggUniAttendees !== undefined &&
        existingActivity.aggUniAttendees.length !== 0
      ) {
        for (const property in existingActivity.aggUniAttendees) {
          let att = existingActivity.aggUniAttendees[property];
          let res = {
            customMapId: att.fullName + " - " + att.abCode,
            fullName: att.fullName,
            abCode: att.abCode,
            isInfluencer: false,
            isPresent: true,
          };
          suggAtt.push(res);
        }
      }
      return suggAtt;
    },
    getSectionTitle() {
      if (this.isOldSessionDone) {
        this.SectionTitle = "Session Details";
      }

      return this.SectionTitle;
    },
  },
  computed: {
    topicQuestion() {
      return this.newSessionQA.topic.question[0];
    },
    commentsQuestion() {
      return this.newSessionQA.comments.question[0];
    },
    attendeesQuestion() {
      return this.newSessionQA.attendees.question[0];
    },
    isOldSession() {
      return (
        this.currentActivity !== undefined &&
        this.currentActivity !== null &&
        !this.currentActivity.isNaN &&
        typeof this.currentActivity === "object" &&
        Object.keys(this.currentActivity).length > 0
      );
    },
    isOldSessionDone() {
      return this.isOldSession && this.currentSession.status !== "DONE";
    },

    currentSession() {
      let res = {};
      if (this.isOldSession) {
        let sessions = this.currentActivity.sessions;
        sessions.forEach((session) => {
          if (session.id === this.$route.params.id) {
            res = session;
          }
        });
      }
      return res;
    },
    submitButtonLabel() {
      return this.isOldSession ? "save" : "submit";
    },
    backButtonLabel() {
      return this.isOldSessionDone ? "Back" : "cancel";
    },
  },
  watch: {
    selectedBeneficiaries() {
      return this.selectedBeneficiaries;
    },
    submittedDate(val) {
      this.submittedDate = moment(val).format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped>
.green .gridmultiselect__header {
  background-color: #a0c334;
}
.title-wrapper {
  margin-left: -2rem;
  margin-top: 0.5rem;
}
.cancel-edit {
  margin-left: auto;
}
</style>
