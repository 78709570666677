import axios from "@/http/http-common";
import Vue from "vue";

class LegalServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/legalservice", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewLegalService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/create/legal", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateLegalService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/update/legal", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new LegalServiceService();
