import axios from "@/http/http-common";
import Vue from "vue";

class BaseServiceService {
    async getServiceById(id) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.post("/services/read/id/" + id, null, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

}

export default new BaseServiceService();
