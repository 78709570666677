<template>
  <!-- <ab-content-container> -->
  <div class="mt-12">
    <div class="title-wrapper">
      
      <p class="font-semibold text-xl">Manage Evaluation Forms</p>
       
      <p class="pull-left text-xs text-gray-500 italic">
        This is where we manage the activity evaluation forms
      </p>
    
    </div>

    <ab-table
      :columns="awarenessFormColumns"
      :fields="satisfactionList"
      :activity="activityCopy"
    ></ab-table>
  </div>
  <!-- </ab-content-container> -->
</template>

<script>
// import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import AbTable from "@/components/abaad/AbTable/AbTable.vue";
import moment from "moment";
import ActivityService from "@/features/Activities/ActivityService";
import { EventBus } from "@/Events/event-bus.js";
export default {
  name: "EvaluationForms",
  components: {
    // AbContentContainer,
    AbTable,
  },
  data() {
    return {
      activityCopy: {},
    };
  },
  props: {
    activity: {
      required: false,
      type: Object,
    },
  },
  beforeCreate() {
    this.activityCopy = JSON.parse(localStorage.getItem("currentActivity"));
  },
  created() {
    if (!!this.activity) {
      this.activityCopy = this.activity;
    } else {
      ActivityService.getSingleActivity(
        JSON.parse(localStorage.getItem("currentActivity")).id
      ).then((res) => {
        console.log("🚀 ~ res:", res.data);
        localStorage.setItem("currentActivity", JSON.stringify(res.data));
      });
    }
    this.activityCopy = JSON.parse(localStorage.getItem("currentActivity"));
    this.satisfactionList = JSON.parse(
      localStorage.getItem("currentActivity")
    ).awarnessSatisfactionList;
    console.log("🚀 ~ this.satisfactionList:", this.satisfactionList);
  },
  methods: {
    toggleTableView() {
      EventBus.$emit("table_view_change");
    }
  },
  computed: {
    // satisfactionList() {
    //   return this.activityCopy.awarnessSatisfactionList;
    // },
    awarenessFormColumns() {
      return [
        {
          key: "submittedDate",
          class: ["justify-center text-center font-medium"],
          isRowHeader: true,
          stickyColumn: true,
          label: "Submit Date",
          sortable: true,
          formatter: (value) => {
            return moment(value).format("DD MMM YYYY");
          },
        },
        {
          key: "delete",
          class: ["justify-center text-center font-medium"],
          isRowHeader: true,
          stickyColumn: true,
          label: "Delete",
        },
        {
          key: "type",
          class: ["justify-center text-center font-medium"],
          isRowHeader: true,
          stickyColumn: true,
          label: "Type",
          sortable: true,
        },
        {
          key: "nationality",
          class: ["justify-center text-center font-normal"],
          label: "Nationality",
          sortable: true,
        },
        {
          key: "age",
          class: ["justify-center text-center font-normal"],
          label: "Age",
          sortable: true,
        },
        {
          key: "center",
          class: ["justify-center text-center font-normal"],
          label: "Center",
          sortable: true,
        },
        {
          key: "overallStatement",
          class: ["justify-center text-center font-normal"],
          label: "Overall Statement",
          sortable: true,
        },
        {
          key: "comments",
          class: ["justify-center text-center font-normal"],
          label: "Comments",
          sortable: true,
        },

        {
          key: "postSessionEager",
          class: ["justify-center text-center font-normal"],
          label: "Session Eagarity",
          sorable: true,
        },
        {
          key: "postSessionTeach",
          class: ["justify-center text-center font-normal"],
          label: "Session Learning",
          sorable: true,
        },
        {
          key: "postSessionHelp",
          class: ["justify-center text-center font-normal"],
          label: "Session Help",
          sorable: true,
        },
        {
          key: "postSessionFriend",
          class: ["justify-center text-center font-normal"],
          label: "Session Friendships",
          sorable: true,
        },
        {
          key: "postSessionRelation",
          class: ["justify-center text-center font-normal"],
          label: "Session Networking",
          sorable: true,
        },
        {
          key: "contentInteresting",
          class: ["justify-center text-center font-normal"],
          label: "Content Interest",
          sortable: true,
        },
        {
          key: "contentUseful",
          class: ["justify-center text-center font-normal"],
          label: "Content Usefullness",
          sortable: true,
        },
        {
          key: "sessionsUseful",
          class: ["justify-center text-center font-normal"],
          label: "Session Usefullness",
          sortable: true,
        },

        {
          key: "facilitatorAttitude",
          class: ["justify-center text-center font-normal"],
          label: "Facilitator Attitude",
          sortable: true,
        },
        {
          key: "facilitatorKnowledge",
          class: ["justify-center text-center font-normal"],
          label: "Facilitator Knowledge",
          sortable: true,
        },
        {
          key: "facilitatorPresentation",
          class: ["justify-center text-center font-normal"],
          label: "Facilitator Presentation",
          sortable: true,
        },
        {
          key: "facilitatorRespect",
          class: ["justify-center text-center font-normal"],
          label: "Facilitator Respece",
          sortable: true,
        },

        {
          key: "environmentSafe",
          class: ["justify-center text-center font-normal"],
          label: "Environment Safety",
          sortable: true,
        },
        {
          key: "powerPointActivities",
          class: ["justify-center text-center font-normal"],
          label: "Power Points",
          sortable: true,
        },
        {
          key: "timing",
          class: ["justify-center text-center font-normal"],
          label: "Timing",
          sortable: true,
        },
        {
          key: "refreshment",
          class: ["justify-center text-center font-normal"],
          label: "Refreshments",
          sortable: true,
        },

        {
          key: "complaintsMechanism",
          class: ["justify-center text-center font-normal"],
          label: "Complaints Mechanism",
          sortable: true,
        },
        {
          key: "communicationChannels",
          class: ["justify-center text-center font-normal"],
          label: "Communication Channels",
          sortable: true,
        },

        {
          key: "suggestions",
          class: ["justify-center text-center font-normal"],
          label: "Suggestions",
          sortable: true,
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>

</style>