<template>
  <button
    v-if="!disabled"
    @click="click()"
    :class="
      `py-2 px-20 ${
        cancel ? 'cancel' : 'bg-primary'
      } text-white rounded mt-2 active:transform active:translate-y-1 active:outline-none hover:${
        cancel ? 'cancel-hover' : 'bg-secondary'
      }`
    "
  >
    {{ label }}
  </button>

  <div class="disabled-button-wrapper" v-else v-b-tooltip.hover :title="tooltip">
    <button
      :disabled="disabled"
      @click="click()"
      :class="
        `py-2 px-20 ${
          cancel ? 'cancel' : 'bg-primary'
        } text-white rounded mt-2 active:transform active:translate-y-1 active:outline-none hover:${
          cancel ? 'cancel-hover' : 'bg-secondary'
        }
        ${
          disabled ? 'disabled' : ''
        }
       `
      "
    >
      {{ label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    preventEventClick: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      required: false,
      type: String,
    },
  },
  methods: {
    click(e) {
      if (this.preventEventClick) {
        e.preventDefault();
      }
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss">
.cancel {
  background-color: #fff !important;
  color: black !important;
  border: solid 1px !important;
}
.cancel:hover {
  background-color: #ddd !important;
  color: white !important;
  border: solid 1px #ddd !important;
}
button:disabled {
  pointer-events: none;
  background-color: #2399A4 !important;
}
.disabled-button-wrapper {
  cursor: not-allowed;

}
</style>
