<template>
  <div :class="{'expanded': isOpen} ">
    <div @click="toggle()" :class="`collapsable flex items-center sticky top-0 border-b border-solid border-gray-500 cursor-pointer p-3 text-white ${hasErrors ? 'bg-red-500' : 'bg-primary hover:bg-secondary'} ${borderRadius? 'round-corner': ''}`">
      <i :class="`uil uil-${icon} text-xl`"></i>
      <p class="ml-6 uppercase text-sm font-semibold">{{ label }}</p>
    </div>
    <div :class="{'py-2 px-4': noPadding == false}" v-if="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ab-collapse",
  props: {
    label: {
      type: String,
      required: true
    },
    hasErrors: {
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: false,
      required: false
    },
    noPadding: {
      type: Boolean
    },
    borderRadius: {
      type: Boolean
    }
  },
  mounted() {    
    if(!!this.expand) {
      this.toggle();
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    icon() {
      return this.isOpen ? "minus" : "plus";
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>
<style scoped>
  .collapsable {
    margin: 5px 0;
  }
  .round-corner {
    border-radius: 5px;
  }
</style>
