<template>
  <ab-content-container>
    <pulse-loader
      :loading="isLoading"
      class="loader"
      color="#2399A4"
      v-if="isLoading"
    ></pulse-loader>
    <div v-else>
      <div>
        <!-- <ab-errors :errors="errors" class="mb-10"></ab-errors> -->
        <p class="font-semibold text-xl">Mental Health Service</p>
         <form
          @submit.prevent="!editService ? addPsychService() : updatePsychService()"
          class="flex flex-col mx-auto max-w-2xl">
         
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">Date of Referral</p>
            <ab-input
              :full="true"
              type="date"
              v-model="submittedDate"
            ></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ isReferredQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in psychServiceQA.isReferred.answers"
              v-model="isReferred"
            ></ab-radio>
          </div>
          
           <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ isReferralResponseQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in psychServiceQA.isReferralResponse.answers"
              v-model="isReferralResponse"
            ></ab-radio>
              
              <ab-input
              v-if="isReferralResponse.includes('Declined/Not Accepted')"
              v-model="isReferralWhy"
              :full="true"
              class="inline-block mt-4"
              label="Please specify"
            ></ab-input>
          </div>

          <!-- refereal why -->
          
          <div class="mt-12" v-if="isReferred.includes('Yes\\External Referral')">   
            <p class="font-semibold text-sm mb-1">
              {{ agencyQuestion }}
            </p>
            <textarea
              v-model="agency"
              class=" w-full h-24 border border-solid border-secondary rounded-sm">
            </textarea>
          </div>

          <div class="mt-12" v-if="isReferred.includes('Yes\\Internal referral -Service provided by our agency')">
            <p class="font-semibold text-sm mb-1">Which Center</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in psychServiceQA.center.answers"
              v-model="center"
            ></ab-radio>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ referralDetailsQuestion }}
            </p>
            <textarea
              v-model="referralDetails"
              class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <div
              class="flex flex-col justify-center items-center"
              v-if="!isLoading"
            >
              <ab-button
                @click="!editService ? addPsychService() : updatePsychService()"
                class="mt-2 submit-btn"
                label="submit"
              ></ab-button>
              <ab-button
                @click="routeToNavigation()"
                class="mt-2"
                :cancel="true"
                label="cancel"
              ></ab-button>
            </div>
          </div> 
        </form>
      </div>
    </div>
  </ab-content-container>
</template>
<script>
import vue from 'vue';
import PsychServiceService from "./PsychServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import moment from "moment";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';
import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { mapActions, mapGetters } from 'vuex'

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-psych-service",
  mixins: [utilMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getPsychServiceQA();
    this.gbvmis = this.$route.params.id;
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate = moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD");
  },
  data() {
    return {
      gbvmis: "",
      latestCounselingServiceDate: "",
      referral: "",
      isReferralResponse: "",
      isReferralWhy: "",
      referralDetails: "",
      submittedDate: "",
      psychServiceQA: {},
      isReferred: "",
      center: "",
      agency: "",
      errors: {},
      error: "",
      isLoading: false,
    };
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: (value) => {
          return value >= this.latestCounselingServiceDate;
        }, 
        maxValue: (value) => {
          return value < this.tomorrowsDate;
        }
      },
      isReferred: {
        required
      },
      isReferralResponse: {
        required
      }
    }
  },
  methods: {
    ...mapActions(['fetchAllServices']),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getPsychServiceQA() {
      this.isLoading = true;
      PsychServiceService.getQaData()
        .then((response) => {
          this.psychServiceQA = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD");
      this.gbvmis = this.editService.gbvmis;
      this.isReferralResponse = this.editService.isReferralResponse;
      this.isReferralWhy = this.editService.isReferralWhy;
      this.referralDetails = this.editService.referralDetails;
      this.isReferred = this.editService.isReferred;
      this.center = this.editService.center;
      this.agency = this.editService.agency;
    },
    addPsychService() {
       if(!!this.$v) {
         this.$v.$touch();
        if (this.$v.$invalid) {
         this.catchErrors();
        } else {
          this.isLoading = true;
          const newPsychService = this.getNewPsychService();
          PsychServiceService.addNewPsychService(newPsychService)
            .then(() => this.$router.push({ path: `/bene/${this.gbvmis}` }))
            .catch((error) => {
              console.log(error)
            })
            .finally(() => (this.isLoading = false));
        }
       }
    },
    catchErrors() {
      this.errors = [];
      if(!this.referralDate) {
        this.errors.push("Referral Date is required.")        
      }
      if(!!this.referralDate && this.dateHasError()) {
          this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }
      if(!this.isReferred) {
        this.errors.push("Referral is required.")        
      }
      if(!this.isReferralResponse) {
        this.errors.push("Referral Response is required.")        
      }
      this.handleArrayErrors(this.errors)
    },

    updatePsychService() {
      if(!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          PsychServiceService.updatePsychService(this.getNewPsychService())
            .then(() => {
              this.$router.push({
                path: `/bene/${this.$route.params.id}/management`,
              });
            })
            .catch((error) => console.log(error))
            .finally(() => (this.isLoading = false));
        }  
      }
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    getNewPsychService() {
      let newPsychService = {};
      if (!!this.editService) newPsychService.id = this.editService.id;
      newPsychService.gbvmis = this.gbvmis;
      newPsychService.isReferralResponse = this.isReferralResponse;
      newPsychService.isReferralWhy = this.isReferralWhy;
      newPsychService.referralDetails = this.referralDetails;
      newPsychService.submittedDate = moment(this.submittedDate);
      newPsychService.isReferred = this.isReferred;
      newPsychService.center = this.center;
      newPsychService.agency = this.agency;
      newPsychService.submittedDate = this.submittedDate 

      newPsychService.serviceType = "psych";

      return newPsychService;
    },
  },
  computed: {
    ...mapGetters(['allServices']),
    isReferredQuestion() {
      return this.psychServiceQA.isReferred.question[0];
    },
    isReferralResponseQuestion() {
      return this.psychServiceQA.isReferralResponse.question[0];  
    },
    centerQuestion() {
      return this.psychServiceQA.center.question[0];
    },
    agencyQuestion() {
      return this.psychServiceQA.agency.question[0];
    },
    referralDetailsQuestion() {
      return this.psychServiceQA.referralDetails.question[0];
    }
  },
};
</script>
