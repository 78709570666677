<template>
  <div>
    <pulse-loader
      color="#2399A4"
      :loading="isLoading"
      class="loader"
      v-if="isLoading"
    ></pulse-loader>
    <div v-else>
       <ab-content-container>
        <form @submit.prevent="updateActionService()" class="flex flex-col mx-auto max-w-2xl">
          <div class="flex justify-between">
            <p class="font-semibold text-xl">Action Service</p>
          </div>
          <div class="mt-12">
            <p class="text-sm font-semibold mb-1">Submit Date</p>
            <ab-input v-model="submittedDate" :full="true" type="date"></ab-input>
          </div>

          <div class="mt-12">
            <p class="text-sm font-semibold mb-1">Objective / {{ stepsQuestion }}</p>
            <textarea v-model="timeFrame" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">Steps / {{ stepsQuestion }}</p>
            <textarea v-model="steps" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="text-sm font-semibold mb-1"> {{ resourcesAvailableQuestion }}</p>
            <textarea v-model="resourcesAvailable" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

           <div class="mt-12">
            <p class="text-sm font-semibold mb-1">{{ taskQuestion }}</p>
            <textarea v-model="task" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="text-sm font-semibold mb-1">{{ expectedChallengesQuestion }}</p>
            <textarea v-model="expectedChallenges" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="text-sm font-semibold mb-1">{{ indicatorsAchievementQuestion }}</p>
            <textarea v-model="indicatorsAchievement" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <p class="text-sm font-semibold mb-1">{{ evaluationProgressQuestion }}</p>
            <textarea v-model="evaluationProgress" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

          <div class="mt-12">
            <div class="flex flex-col justify-center items-center" v-if="!isLoading">
              <ab-button @click="updateActionService()" class="mt-2" label="submit"></ab-button>
              <ab-button @click="routeToActionService()" class="mt-2" :cancel="true" label="cancel"></ab-button>
            </div>
          </div>

        </form>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import vue from "vue";
import ActionServiceService from "./ActionServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import { utilMixin } from "@/mixins/forms/utilMixin";
import moment from "moment";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "action-service-edit-mode",
  mixins: [utilMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    PulseLoader,
  },
  props: {
    actionServices: {
      required: false,
      type: Array,
    },
    serviceQA: {
      required: false,
      type: Object,
    },
    actionService: {
      required: false,
      type: Object,
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.gbvmis = this.$route.params.id;
    this.actionServiceQA = this.serviceQA;
    let counselingSessions = this.$store.getters.allServices.counseling;
    this.latestCounselingServiceDate = moment(counselingSessions[counselingSessions.length -1].submittedDate).format("YYYY-MM-DD");
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 
},
mounted() {
  this.populateEditService();
},
  data() {
    return {
      gbvmis: "",
      errors: {},
      error: "",
      isLoading: false,
      submittedDate: "",
      steps: "",
      timeFrame: "",
      resourcesAvailable: "",
      expectedChallenges: "",
      indicatorsAchievement: "",
      evaluationProgress: "",
      task: "",
    };
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: (value) => value < this.tomorrowsDate,
      }
    }
  },
  methods: {
    async populateEditService() {
      this.submittedDate = this.getSubmittedDate;
      this.steps = this.actionService.steps;
      this.resourcesAvailable = this.actionService.resourcesAvailable;
      this.expectedChallenges = this.actionService.expectedChallenges;
      this.indicatorsAchievement = this.actionService.indicatorsAchievement;
      this.evaluationProgress = this.actionService.evaluationProgress;
      this.timeFrame = this.actionService.timeFrame;
      this.task = this.actionService.task;
    },
    routeToActionService() {
      return this.$router.push({
        path: `/bene/${this.$route.params.id}/action`,
      });
    },
    updateActionService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
      this.isLoading = true;
      ActionServiceService.updateActionService(this.getNewActionService())
        .then(() => {
          this.$router.push({
            path: `/bene/${this.$route.params.id}/action`,
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
      }
    },
    getNewActionService() {
      let newActionService = {};
      if (!!this.actionService) newActionService.id = this.actionService.id;
      newActionService.gbvmis = this.gbvmis;
      newActionService.steps = this.steps;
      newActionService.timeFrame = this.timeFrame;
      newActionService.resourcesAvailable = this.resourcesAvailable;
      newActionService.expectedChallenges = this.expectedChallenges;
      newActionService.indicatorsAchievement = this.indicatorsAchievement;
      newActionService.evaluationProgress = this.evaluationProgress;
      newActionService.task = this.task;
      newActionService.submittedDate = new Date(this.submittedDate).toJSON();
      newActionService.serviceType = "action";

      return newActionService;
    },
    catchErrors() {
      this.errors = [];
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
       if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }
      this.handleArrayErrors(this.errors);
    },
     dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    }
  },
  computed: {
    getSubmittedDate() {
      return moment(this.actionService.submittedDate).format("YYYY-MM-DD")
    },
    stepsQuestion() {
      return this.actionServiceQA.steps.question[0];
    },
    timeFrameQuestion() {
      return this.actionServiceQA.timeFrame.question[0];
    },
    resourcesAvailableQuestion() {
      return this.actionServiceQA.resourcesAvailable.question[0];
    },
    expectedChallengesQuestion() {
      return this.actionServiceQA.expectedChallenges.question[0];
    },
    indicatorsAchievementQuestion() {
      return this.actionServiceQA.indicatorsAchievement.question[0];
    },
    evaluationProgressQuestion() {
      return this.actionServiceQA.evaluationProgress.question[0];
    },
    taskQuestion() {
      return this.actionServiceQA.task.question[0];
    },
  },
};
</script>
