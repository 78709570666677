import axios from "@/http/http-common";
import Vue from "vue";

class ClosureServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/closureservice", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewClosureService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/create/closure", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateClosureService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/update/closure", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  
  async getAbaadCenters() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/centers/all", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async transferBene(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/beni/transfer", null, {
      params: {
          assignedCenter: data.assignedCenter,
          assignedUserId: data.assignedUserId,
          gbvmis: data.gbvmis
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
  }

  async getSubmittedClosure(gbvmis) {
    const accessToken = await new Vue().$auth.getTokenSilently();
      return axios.get("/services/read/latest", {
        params: {
          gbvmis: gbvmis,
          number: 1,
          serviceType: 'closure'
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      })
  }
}



export default new ClosureServiceService();
