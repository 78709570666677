<template>
  <div>
    <pulse-loader
      :loading="isLoading"
      color="#2399A4"
      class="loader"
      v-if="isLoading"
    ></pulse-loader>
    <div v-else>
      <ab-content-container>
        <p class="font-semibold text-xl">Legal Service</p>
        <form @submit.prevent="!editService ? addLegalService() : updateLegalService()" class="flex flex-col mx-auto max-w-2xl">
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">Date of Referral</p>
            <ab-input :full="true" type="date" v-model="submittedDate"></ab-input>
          </div>
         
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ referToLegalAssistanceQuestion }}</p>
            <ab-radio
              class="mb-2"
              :key="index"
              :label="answer"
              :value="answer"
              v-for="(answer, index) in referToLegalAssistanceAnswers"
              v-model="referToLegalAssistance"
            ></ab-radio>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ typeLegalServicesQuestion }}</p>
            <ab-radio
              class="mb-2"
              :key="index"
              :label="answer"
              :value="answer"
              v-for="(answer, index) in typeLegalServicesAnswers"
              v-model="typeLegalServices"
            ></ab-radio>
          </div>

          <div class="mt-12" v-if="isInternalReferral()">
            <p class="font-semibold text-sm mb-1">{{ meanLegalServicesQuestion }}</p>
            <ab-radio
              class="mb-2"
              :key="index"
              :label="answer"
              :value="answer"
              v-for="(answer, index) in meanLegalServicesAnswers"
              v-model="meanLegalServices"
            ></ab-radio>
          </div>
          
          <div class="mt-12" v-if="isExternalReferral()">
            <p class="font-semibold text-sm mb-1">{{ legalReferralResponseQuestion }}</p>
            <ab-radio 
              class="mb-2"
              :key="index" 
              :label="answer" 
              :value="answer" 
              v-for="(answer, index) in legalReferralResponseAnswers" 
              v-model="legalReferralResponse"
            ></ab-radio>
          </div>

          <div class="mt-12" v-if="legalReferralResponse.includes('Declined/Not Accepted')">
            <p class="font-semibold text-sm mb-1">{{ legalReferralResponseWhyQuestion }}</p>
            <ab-input v-model="legalReferralResponseWhy" :full="true"></ab-input>
          </div>
          
          <div class="mt-12" v-if="isExternalReferral()">
            <p class="font-semibold text-sm mb-1">{{ agencyQuestion }}</p>
            <ab-radio :key="index" :label="answer" :value="answer" class="mb-2" v-for="(answer, index) in agencyAnswers" v-model="agency"></ab-radio>
          </div>
          
          <div class="mt-12" v-if="isExternalReferral() && agency.includes('Other (Please Specify)')">
              <p class="font-semibold text-sm mb-1">{{ "Other, Please Specify?" }}</p>
              <ab-input v-model="agencyOther" :full="true"></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ referralDetailsQuestion }}</p>
            <textarea v-model="referralDetails" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>
          <div class="mt-12">
            <div class="flex flex-col justify-center items-center" v-if="!isLoading">
              <ab-button @click="!editService ? addLegalService() : updateLegalService()" class="mt-2 submit-btn" label="submit"></ab-button>
              <ab-button @click="routeToNavigation()" class="mt-2" :cancel="true" label="cancel"></ab-button>
            </div>
          </div>
        </form>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import LegalServiceService from "./LegalServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import moment from "moment";
import vue from "vue";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { mapGetters, mapActions } from 'vuex';
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-legal-service",
  mixins: [utilMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getLegalServiceQA();
    this.gbvmis = this.$route.params.id;
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 
  },
  data() {
    return {
      gbvmis: "",
      referToLegalAssistance: "",
      typeLegalServices: "",
      submittedDate: "",
      meanLegalServices: "",
      legalReferralResponse: "",
      legalReferralResponseWhy: "",
      agency: "",
      agencyOther: "",
      referralDetails: "",
      legalServiceQA: {},
      tomorrowsDate: "",
      errors: {},
      error: "",
      isLoading: false,
    };
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: (value) => value < this.tomorrowsDate,
      },
      referToLegalAssistance: {
        required
      },
      typeLegalServices: {
        required
      },
      meanLegalServices: {
        required: requiredIf(() => this.isInternalReferral())
      },
      legalReferralResponse: {
        required: requiredIf(() => this.isExternalReferral())
      },
      agency: {
        required: requiredIf(() => this.isExternalReferral())
      }
    }
  },
  methods: {
    ...mapActions(['fetchAllServices']),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    getLegalServiceQA() {
      this.isLoading = true;
      LegalServiceService.getQaData()
        .then((response) => {
          this.legalServiceQA = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD");
      this.referToLegalAssistance = this.editService.referToLegalAssistance;
      this.typeLegalServices = this.editService.typeLegalServices;
      this.meanLegalServices = this.editService.meanLegalServices;
      this.referralDetails = this.editService.referralDetails;
      this.legalReferralResponse = this.editService.legalReferralResponse;
      this.legalReferralResponseWhy = this.editService.legalReferralResponseWhy;
      this.agency = this.ammendEditCaseOtherInput(
        this.agencyAnswers,
        this.editService.agency,
        "Other (Please Specify)"
      ).then((result) => {
        this.agencyOther = result[1];
        this.agency = [result[0]];
      });
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    addLegalService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
        this.isLoading = true;
        const newLegalService = this.getNewLegalService();
        LegalServiceService.addNewLegalService(newLegalService)
          .then((response) => {
            this.$router.push({ path: `/bene/${this.gbvmis}` })
            this.handleSuccessResponse(response);
          })
          .catch((error) => console.log(error))
          .finally(() => (this.isLoading = false));
      }
      
    },
    updateLegalService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
        this.isLoading = true;
        LegalServiceService.updateLegalService(this.getNewLegalService())
          .then((response) => {
            this.$router.push({
              path: `/bene/${this.$route.params.id}/management`,
            });
            this.handleSuccessResponse(response);
          })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
      }
    },
    handleSuccessResponse(response) {
      if(response.status == 201) {
        let successMessage = "Form Submitted Sucessfully.";
        this.handleSuccess(successMessage);
      }
    },
    getNewLegalService() {
      let newLegalService = {};
      if (!!this.editService) newLegalService.id = this.editService.id;
      newLegalService.gbvmis = this.gbvmis;
      newLegalService.legalReferralResponse = this.legalReferralResponse;
      newLegalService.legalReferralResponseWhy = this.legalReferralResponseWhy;
      newLegalService.agency = this.resultAgencyOther;
      // newLegalService.agencyOther = this.agencyOther;
      newLegalService.referToLegalAssistance = this.referToLegalAssistance;
      newLegalService.typeLegalServices = this.typeLegalServices;
      newLegalService.meanLegalServices = this.meanLegalServices;
      newLegalService.referralDetails = this.referralDetails;
      newLegalService.submittedDate = new Date(this.submittedDate).toJSON();

      newLegalService.serviceType = "legal";

      return newLegalService;
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    isInternalReferral() {
      return this.referToLegalAssistance.includes('Yes\\Internal referral-Service provided by our agency');
    },
    isExternalReferral() {
      return this.referToLegalAssistance.includes('Yes\\External Referral');
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    catchErrors() {
      this.errors = [];
      
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
       if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }
      if(!this.referToLegalAssistance) {
        this.errors.push("Referral to Legal Assistance is Required");
      }
      if(!this.typeLegalServices) {
        this.errors.push("Type of Legal Services is Required");
      }
      if(!this.meanLegalServices && this.isInternalReferral()) {
        this.errors.push("Means of Legal Service Field is Required");
      }
      if(!this.legalReferralResponse && this.isExternalReferral()) {
        this.errors.push("Legal Referral Response Field is Required");
      }
      if((this.isExternalReferral() && !this.agency) || 
         (this.isExternalReferral() && this.agency && 
         this.agency.includes("Other (Please Specify)") && !this.agencyOther)) {
        this.errors.push("Agency Field is Required");
      }
      
      this.handleArrayErrors(this.errors);
    }
  },
  watch: {
    agency(val) {
      this.resultAgencyOther = val;
    },
    agencyOther(val) {
      // this val the code that will use as data to post
      this.resultAgencyOther = val;
    }
  },
  computed: {
    ...mapGetters(['allServices']),
    referToLegalAssistanceQuestion() {
      return this.legalServiceQA.referToLegalAssistance.question[0];
    },
    referToLegalAssistanceAnswers() {
      return this.legalServiceQA.referToLegalAssistance.answers;
    },
    typeLegalServicesQuestion() {
      return this.legalServiceQA.typeLegalServices.question[0];
    },
    typeLegalServicesAnswers() {
      return this.legalServiceQA.typeLegalServices.answers;
    },
    meanLegalServicesQuestion() {
      return this.legalServiceQA.meanLegalServices.question[0];
    },
    meanLegalServicesAnswers() {
      return this.legalServiceQA.meanLegalServices.answers;
    },
    legalReferralResponseQuestion() {
      return this.legalServiceQA.legalReferralResponse.question[0];
    },
    legalReferralResponseAnswers() {
      return this.legalServiceQA.legalReferralResponse.answers;      
    },
    legalReferralResponseWhyQuestion() {
      return this.legalServiceQA.legalReferralResponseWhy.question[0];      
    },
    agencyQuestion() {
      return this.legalServiceQA.agency.question[0];      
    },
    agencyAnswers() {
      return this.legalServiceQA.agency.answers;      
    },
    referralDetailsQuestion() {
      return this.legalServiceQA.referralDetails.question[0];
    }
  },
};
</script>
