<template>
  <button :id="camelize(icon)" :class="buttonClass" :disabled="disabled">
    <i :class="`${icon} icon text-white text-5xl transform translate-y-3 hover:animate-spin`"></i>
    <p class="text-sm font-semibold text-white">{{ label }}</p>
  </button>
</template>

<script>
// import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // optional for styling

export default {
  name: "main-button",
  props: {
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // addTooltipForDisabledButton(id, string) {
    //   tippy("#" + id, {
    //     content: string,
    //     delay: 0,
    //   });
    // },
    camelize(str) {
      return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
    },
  },
  created() {
  },
  mounted() {
    // var elmentId = this.$el.id;
    // if (this.disabled) {
    //   this.addTooltipForDisabledButton(
    //     elmentId,
    //     "The case needs to be managed first, before providing any service."
    //   );
    // }
  },
  computed: {
    buttonClass() {
      return this.disabled
      // session flex items-center bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary
        ? "main-button flex flex-col justify-content items-center bg-gray-600 rounded cursor-not-allowed"
        : "main-button flex flex-col justify-content items-center btn btn-primary rounded shadow-xl cursor-pointer ";
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 850px) {
  .main-button,.btn.btn-primary,
  .main-button:disabled {
    width: 100% !important;
    height: 75px !important;
    flex:0 0 auto;
    align-items: start;
    i.uil {
      margin: -10px 0 0 0;
      font-size: 3rem;
    }
    p {
      margin-left: 20%;
      margin-top: -20px;
      font-size: 1rem;
    }
  }
}
@media (max-width: 600px) {

  .main-button.cursor-not-allowed {
    i.uil.icon {
      margin: 0 10px;
    }
    p {
      // margin: -3px 15px;
    }
  }
}
@media (max-width: 1200) {
  .main-button,.btn.btn-primary {
    width: 130px;
    height: 130px;
  } 
}
.main-button {
  width: 130px;
  height: 130px;
  
  &.btn.btn-primary {
    width: 130px;
    height: 130px;
  }

  &:hover {
    background: #2399a4;
  }
  i.icon {
    pointer-events: none;
    align-items: center;
    justify-content: center;  
    display: flex;
    margin: auto;
  }
}
</style>
