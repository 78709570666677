<template>
  <div :class="`${full ? 'w-full' : 'fill-content'} ${!isTableMode ? 'mx-auto' : 'table-mode'} `">
    <label :class="`flex flex-col ${full ? 'w-full' : ''}`" :for="label">
      <span class="text-xs text-gray-800"
        >{{ label }}
        <span v-if="!!required" class="text-red-500"> *</span>
      </span>
      <input
        :type="type"
        @input="change($event)"
        :id="label"
        :value="value"
        :min="computedMin"
        :max="computedMax"
        :class="`border 
        ${half ? 'half-width' : ''} 
        ${full ? 'w-full' : 'w-64'}
        ${oneThird? 'width-one-third': ''}
         border-solid border-gray-300 rounded text-gray-700 focus:outline-none text-sm font-light p-2`"
        :placeholder="placeholder"
        :disabled="disabled"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: "ab-input",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    half: {
      type: Boolean,
      default: false,
    },
    oneThird: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      required: false,
    },
    min: {
      type: String,
      required: false,
    },
    max: {
      type: String,
      required: false,
    },
    isTableMode: {
      type: Boolean,
      required: false
    },
    value: {},
  },
  methods: {
    change(e) {
      this.$emit("input", e.target.value);

    },
  },
  computed: {
    computedMin() {
      return this.type == "date" && !!this.min ? this.min : "";
    },
    computedMax() {
      return this.type == "date" && !!this.max ? this.max : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.half-width {
  width: 170px !important;
}
 input[type='text'].width-one-third {
   width: 130px !important;
   font-size: 13px;
 }
@media (max-width: 1280px) {
  input[type="text"].width-one-third,
  input[type='date'].width-one-third.w-64 {
    width: 100% !important;
    min-width: 130px;
    margin-left: auto;
  }
}


input[type='date'].width-one-third {
  width: 140px !important;
}
.w-full {
  width: 100% !important;
  margin: auto !important;
}
.table-mode {
  padding: 0
}
.error {
    border-color:red !important; 
  }
</style>
