<template>
  <ab-content-container class="pt-12">
    <pulse-loader
      v-if="isLoading"
      class="loader"
      :loading="isLoading"
      color="#2399a4"
    ></pulse-loader>

    <div class="max-w-2xl mx-auto" v-else>
      <!-- Details  -->
      <p class="font-semibold text-lg title"></p>
      <div class="title-wrapper">
        <p class="font-semibold text-xl">Activity Details</p>
        <p class="pull-left text-xs text-gray-500 italic">
          This is where we view and manage an activity
        </p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
        <ab-card question="Name" :answer="act.name"></ab-card>
        <!-- <ab-card v-if="!act.type" question="Type" :answer="'New'"></ab-card>
        <ab-card v-if="act.type" question="Type" :answer="act.type"></ab-card> -->
        <ab-card
          question="Number of Sessions"
          :answer="act.sessionsNum"
        ></ab-card>
        <ab-card
          v-if="act.status"
          question="Status"
          :answer="act.status"
        ></ab-card>
        <ab-card
          v-if="act.center"
          question="Center"
          :answer="act.center"
        ></ab-card>
        <ab-card question="Region" :answer="act.region"></ab-card>
        <ab-card question="Gateway" :answer="act.gateway"></ab-card>
        <ab-card question="Donor" :answer="act.donor"></ab-card>
        <ab-card
          v-if="act.conductedBy"
          question="Conducted By"
          :answer="act.conductedBy"
        ></ab-card>
        <ab-card
          v-if="act.comments"
          question="Comments"
          :answer="act.comments"
        ></ab-card>
      </div>
      <br />

      <p class="font-semibold text-xl text-gray-800 mb-6">Sessions</p>
      <div>
        <div v-if="this.act.sessions.length > 0">
          <div
            class="wide-button-holder"
            v-for="(item, index) in act.sessions"
            :key="item.id"
          >
            <div @click="getSessionRoute(item)">
              <div
                class="wide-button flex mb-2 mt-2 items-center p-3 bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
              >
                <i class="uil uil-presentation text-white text-3xl"></i>
                <p class="text-md ml-2 font-semibold text-white">
                  {{ `${index++ + 1}-${item.topic}` }}
                </p>
                <b-button
                  @click="deleteSession($event, item, index)"
                  class="delete-session items-center danger rounded shadow-sm cursor-pointer hover:bg-secondary"
                  ><i class="uil uil-trash"></i
                ></b-button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="act.sessionsNum > act.sessions.length">
          <router-link :to="getNewSessionRoute()">
            <div
              class="wide-button flex mb-2 mt-2 items-center p-3 bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
            >
              <i class="uil uil-plus text-white text-3xl"></i>
              <p class="text-md ml-2 font-semibold text-white">
                Add New Session
              </p>
            </div>
          </router-link>
        </div>
      </div>
      <br />
      <p class="font-semibold text-xl text-gray-800 mb-6">Manage Activity</p>
      <div class="manage-activity">
        <div class="edit-activity">
          <div @click="getEditActivityRoute(act)">
            <div
              class="wide-button flex mb-2 mt-2 items-center p-3 bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
            >
              <i class="uil uil-edit text-white text-3xl"></i>
              <p class="text-md ml-2 font-semibold text-white">
                Edit Activity
              </p>
            </div>
          </div>
        </div>
        <div class="delete-activity">
          <div @click="deleteActivity()">
            <div
              class="wide-button flex mb-2 mt-2 items-center p-3 danger w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
            >
              <i class="uil uil-trash text-white text-3xl"></i>
              <p class="text-md ml-2 font-semibold text-white">
                Delete Activity
              </p>
            </div>
          </div>
        </div>
      </div>

      <br />
      <p class="font-semibold text-xl text-gray-800 mb-6">Evaluation Forms</p>
      <div class="evaluation-forms">
        <div class="satisfaction-form">
          <router-link :to="getPssSatisfactionRoute(act)">
            <div
              class="wide-button flex mb-2 mt-2 items-center p-3 bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
            >
              <i class="uil uil-confused text-white text-3xl"></i>
              <p class="text-md ml-2 font-semibold text-white">
                Add Pss Satisfaction Form
              </p>
            </div>
          </router-link>
        </div>
        <div class="awareness-form">
          <router-link :to="getAwarenessSatisfactionRoute(act)">
            <div
              class="wide-button flex mb-2 mt-2 items-center p-3 bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
            >
              <i class="uil uil-smile-beam text-white text-3xl"></i>
              <p class="text-md ml-2 font-semibold text-white">
                Add Awareness Satisfaction Form
              </p>
            </div>
          </router-link>
        </div>
        <div class="view-evaluation-forms">
          <div @click="viewEvaluationForms(act)">
            <div
              class="wide-button flex mb-2 mt-2 items-center p-3 bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
            >
              <i class="uil uil-table text-white text-3xl"></i>
              <p class="text-md ml-2 font-semibold text-white">
                View Activity Evaluation Forms
              </p>
            </div>
          </div>
        </div>
      </div>
      <ab-button
        @click="
          $router.push({
            path: 'current',
          })
        "
        class="block mx-auto mt-10"
        :cancel="true"
        label="Back"
      ></ab-button>
    </div>
  </ab-content-container>
</template>

<script>
import ActivityService from "@/features/Activities/ActivityService";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbCard from "@/components/abaad/AbCard/AbCard";
import AbButton from "@/components/abaad/AbButton/AbButton";

export default {
  components: {
    AbContentContainer,
    PulseLoader,
    AbCard,
    AbButton,
  },
  name: "SingleActivity",
  data() {
    return {
      act: {},
      isLoading: false,
    };
  },
  created() {
    this.getActivityInfo();
  },
  methods: {
    getActivityInfo() {
      this.isLoading = true;
      ActivityService.getSingleActivity(this.$route.params.id)
        .then((response) => {
          this.act = JSON.parse(JSON.stringify(response.data));
          localStorage.setItem(
            "currentActivity",
            JSON.stringify(response.data)
          );
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    routeToActivityNavigation() {
      // TODO: needs refactor
      return this.$router.push({ path: `/activities/current` });
    },
    getSessionRoute(session) {
      return this.$router.push({
        name: "singleSession",
        params: { id: session.id, activityId: this.$route.params.id },
      });
    },
    getNewSessionRoute() {
      return {
        name: "newSession",
        params: { activityId: this.$route.params.id },
      };
    },
    getPssSatisfactionRoute(activity) {
      return {
        name: "PssSatisfactionForm",
        params: { activity: activity },
      };
    },
    getAwarenessSatisfactionRoute(activity) {
      return {
        name: "AwarenessSatisfactionForm",
        params: { activity: activity },
      };
    },
    getEditActivityRoute(activity) {
      return this.$router.push({
        path: "edit",
        name: "create-new-activity",
        params: { editService: activity },
      });
    },
    deleteActivity() {
      if (window.confirm("Are you sure you want to delete this activity ?")) {
        ActivityService.deleteActivity(this.$route.params.id).then(() => {
          this.routeToActivityNavigation();
          this.$router.go();
        });
      }
    },
    viewEvaluationForms(activity) {
      return this.$router.push({
        path: "view",
        name: "EvaluationForms",
        params: { activity: activity },
      });
    },
    deleteSession($e, item) {
      $e.stopPropagation();
      if (window.confirm("Are you sure you want to delete this session ?")) {
        ActivityService.deleteSession(item.id).then(() => {
          if (this.act.sessions.length > 1) {
            const index = this.act.sessions.indexOf(item);
            if (index >= 0) this.act.sessions.splice(index, 1);
          } else {
            this.act.sessions = [];
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.border .border-solid .border-gray-500 .col-span-3 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.wide-button-holder {
  .wide-button.flex.mb-2.mt-2.items-center.p-3.bg-primary.w-full.rounded.shadow-xl.cursor-pointer {
    padding: 0.75rem !important;
  }
}
.evaluation-forms,
.manage-activity {
  display: flex;
  flex-wrap: wrap;

  .satisfaction-form,
  .awareness-form,
  .edit-activity,
  .delete-activity {
    flex-basis: 49%;
    margin: 0 2px 0 2px;
  }
}
.view-evaluation-forms {
  width: 100%;
}
.danger {
  background-color: #dc3545;
}
.delete-session {
  display: flex;
  margin-left: auto;
  color: white;
}

.title-wrapper {
  margin-top: -1.9rem;
  margin-left: -1.9rem;
}
</style>
