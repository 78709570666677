<template>
  <ab-content-container>
    <pulse-loader :loading="isLoading" class="loader" color="#fff"></pulse-loader>
    <div class="mb-4 col-span-3">
      <p class="text-xl font-semibold">
        Portfolio: <span>{{ Gcode }}</span>
      </p>
      <p class="text-xs text-gray-500 italic">
        This is where we manage the portfolio
      </p>
    </div>
    <div class="main-nav max-w-2xl grid grid-cols-1 md:grid-cols-5 gap-2 pt-2">
      <router-link :to="routeToSocioDemoForm">
        <main-button
          label="Case Management"
          icon="uil uil-folder"
        ></main-button>
      </router-link>
      <router-link :to="routeToCaseManagement">
        <main-button
          :disabled="!isNewIncidentEnabled"
          label="New Incident"
          icon="uil uil-plus"
        ></main-button>
      </router-link>
      <router-link :to="routeToFamilyComp">
        <main-button
          :disabled="!hasCase"
          label="Family Composition"
          icon="uil uil-user-arrows"
        ></main-button>
      </router-link>
      <router-link :to="routeToCounselingService">
        <main-button
          :disabled="isCounselingDisabled"
          label="Counseling"
          icon="uil uil-headphones-alt"
        ></main-button>
      </router-link>
        <router-link :to="routeToActionService">
        <main-button
          :disabled="isBeniServiceDisabled"
          label="Action Plan"
          icon="uil uil-create-dashboard"
        ></main-button>
      </router-link>
      <router-link :to="routeToShelterService">
        <main-button
          :disabled="isBeniServiceDisabled"
          label="Shelter"
          icon="uil uil-house-user"
        ></main-button>
      </router-link>
      <router-link :to="routeToMedicalService">
        <main-button
          :disabled="isBeniServiceDisabled"
          label="Medical"
          icon="uil uil-medkit"
        ></main-button>
      </router-link>
      <router-link :to="routeToPsychService">
        <main-button
          :disabled="isBeniServiceDisabled "
          label="Mental Health"
          icon="uil uil-heart"
        ></main-button>
      </router-link>
      <router-link :to="routeToPsychologistService">
        <main-button
          :disabled="isBeniServiceDisabled "
          label="Psychotherapy"
          icon="uil uil-comments-alt"
        ></main-button>
      </router-link>
      <router-link :to="routeToProtectionService">
        <main-button
          :disabled="isBeniServiceDisabled "
          label="Protection"
          icon="uil uil-shield"
        ></main-button>
      </router-link>
      <router-link :to="routeToLegalService">
        <main-button
          :disabled="isBeniServiceDisabled "
          label="Legal"
          icon="uil uil-balance-scale"
        ></main-button>
      </router-link>
      <router-link :to="routeToSafetyService">
        <main-button
          :disabled="isBeniServiceDisabled"
          label="Safety"
          icon="uil uil-coffee"
        ></main-button>
      </router-link>
      <router-link :to="routeToPcapFinancialService">
        <main-button
          :disabled="isBeniServiceDisabled "
          label="PCAP"
          icon="uil uil-dollar-alt"
        ></main-button>
      </router-link>
      <!-- <router-link :to="routeToEcaFinancialService">
        <main-button
          :disabled="true"
          label="ECA"
          icon="uil uil-bill"
        ></main-button>
      </router-link> -->
      <router-link :to="routeToPcapFollowupFinancialService">
        <main-button
          :disabled="isBeniServiceDisabled "
          label="PCAP-Followup"
          icon="uil uil-comment-alt-shield"
        ></main-button>
      </router-link>
      <router-link :to="routeToPcapFeedbackFinancialService">
        <main-button
          :disabled="isBeniServiceDisabled"
          label="PCAP-Feedback"
          icon="uil uil-comment-edit"
        ></main-button>
      </router-link>
      <router-link :to="routeToExternalReferralService">
        <main-button
          :disabled="isBeniServiceDisabled"
          label="External Referral"
          icon="uil uil-external-link-alt"
        ></main-button>
      </router-link>
      <router-link :to="routeToClosureService">
        <main-button
          :disabled="hasClosure || !hasCase || !hasCounseling"
          label="Closure"
          icon="uil uil-comment-verify"
        ></main-button>
      </router-link>
      <hr class="border border-solid border-gray-500 col-span-5" />
      <a
        :disabled="!hasCase"
        v-auth-href
        :href="href"
        id="export"
        :class="'main-button flex flex-col justify-content items-center btn btn-primary rounded shadow-xl cursor-pointer '"
        class="main-button items-center">
        <i :class="`uil uil-import icon text-white text-5xl transform translate-y-3 hover:animate-spin`"></i>
        <p class="text-sm export font-semibold text-white transform self-start">Export</p>
      </a>
      <router-link :to="routeToAttachments">
        <main-button :disabled="!hasCase" label="Attachments" icon="uil uil-paperclip"></main-button>
      </router-link>
      <router-link
        v-if="userInfo.role === 'SUDO' || userInfo.role === 'DATAMANAGER'" :to="routeToManagement">
        <main-button label="Management" icon="uil uil-edit"></main-button>
      </router-link>
    </div>
  </ab-content-container>
</template>

<script>
import MainButton from "@/features/Profile/Button/MainButton.vue";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import Vue from "vue";
import VueAuthHref from "vue-auth-href";
import UserService from "@/shared/UserService";
import tippy from "tippy.js";
import moment from "moment";
import PulseLoader from "vue-spinner/src/PulseLoader";

// import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "navigation",
  components: {
    MainButton,
    AbContentContainer,
    PulseLoader
  },
  data() {
    return {
      isLoading: null,
      hasCase: null,
      fileSrc: "",
      userInfo: {},
      beni: {},
      isNewIncidentEnabled: false,
      isLastIncidentSubmittedToday: false,
      // hasClosure: null,
      // hasShelter: null,
      // hasCounseling: null,
    };
  },
  created() {
    UserService.getUserInfo();
    let userInfo = localStorage.getItem('userInfo')
    this.userInfo = JSON.parse(userInfo);
    this.gbvmis = this.$route.params.id;
    this.getCases();
    this.setCase();
    window.scrollTo(0, 0);
    let token = "";
    new Vue().$auth.getTokenSilently().then((res) => {
      token = res;
    });
    // setTimeout 0 needed here, weird error pops(strict mode caller/argument/caller) with Auth0 library that requires it
    setTimeout(() => {
      const options = {
        token: () => token, // Note that this MUST BE a function that returns the token.
        // other options here (full list of options described below)
      };
      Vue.use(VueAuthHref, options);
    }, 0);
    // check with bob
    this.fetchAllServices(this.gbvmis.toString());
  },
  mounted() {
    setTimeout(() => {
      this.$forceUpdate();
    }, 500);
    if(!this.hasCase) document.getElementById("export").classList.add('disabled')
    // this.addExportTooltip();
    // this.enableBeniOnNewIncidentCycle;
  },
  methods: {
    ...mapActions(["fetchAllServices"]),
    async getCases() {
      const accessToken = await new Vue().$auth.getTokenSilently();
      let response = await fetch(
        `https://api.abaadmena.online/v1/survivorinfo/get/${this.gbvmis.toString()}`,
        {
          method: "get",
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
          }),
        }
      );
      let result = await response.json();      
      localStorage.setItem("cases", JSON.stringify(result));
      result.length
        ? localStorage.setItem("hasCase", true)
        : localStorage.setItem("hasCase", false);
      this.isNewIncidentEnabled = this.canCreateNewIncident();
    },
    canCreateNewIncident() {
      /*need to check on 3 things: 
      multiple cases ?
      24 hours passed on last case ?
      last closure date > case date ? 
      */
      let closureService = this.getClosureService();
      let hasLatestCaseBeenClosed = !!closureService && !this.isLatestCaseSubmittedDateGreaterThanServiceDate(moment(closureService.submittedDate).format('YYYY-MM-DD'));
      let latestCase = this.getLatestCase();
      if(this.hasNoCases()) {
        return true;
      }
      else if(this.hasMultipleCases()) {
        let hoursFromCreatedDate = moment().diff(moment(latestCase.createdDate),"hours");
        return this.hasMultipleCases() && hoursFromCreatedDate >= 24 && hasLatestCaseBeenClosed;
      } else {
        let hoursFromCreatedDate = moment().diff(moment(latestCase.createdDate),"hours");
        return hoursFromCreatedDate >= 24;
      }
    },
    setCase() {
      localStorage.getItem("hasCase") === "true"
        ? (this.hasCase = true)
        : (this.hasCase = false);
    },
    addExportTooltip() {
      var exportBtn = document.getElementById("export");
      if (exportBtn.classList.contains("cursor-not-allowed")) {
        tippy("#export", {
          content:
            "The case needs to be managed first, before providing any service.",
          delay: 0,
        });
      }
    },
    hasNoCases() {
      let cases = JSON.parse(localStorage.getItem("cases"));
      return cases.length == 0;
    },
    hasMultipleCases() {
      let cases = JSON.parse(localStorage.getItem("cases"));
      return cases.length > 1;
    },
    getService(serviceType) {      
      if(!!this.allServices && !!this.allServices[serviceType]) {
        let service = this.allServices[serviceType]; 
        return service[service.length - 1];
      }
      
      return null;
    },
    getShelterService() {
      return this.getService('shelter');
    },
    getClosureService() {
      return this.getService('closure');
    },
    getCounselingService() {
      return this.getService('counseling');
    },
    isLatestCaseSubmittedDateGreaterThanServiceDate(serviceDate) {
      let latestCaseSubmitDate = moment(this.getLatestCase().submittedDate).format('YYYY-MM-DD');
      
      return latestCaseSubmitDate > serviceDate;
    },
    getLatestCase() {
      let cases = JSON.parse(localStorage.getItem("cases"));

      return cases[cases.length - 1];
    }
  },
  computed: {
    ...mapGetters(["allServices", "incident"]),
    isBeniServiceDisabled() {
      return !this.hasCaseAndHasCounseling || this.hasShelter || this.hasClosure;
    },
    enableBeniOnNewIncidentCycle() {
      return this.isLastIncidentSubmittedToday && this.hasShelter && this.hasClosure && this.hasCounseling;
    },
    isCounselingDisabled() {
      return !this.hasCase || this.hasShelter || this.hasClosure;
    },
    hasCaseAndHasCounseling() {
      return this.hasCase && this.hasCounseling;
    },
    // hasShelter() {
    //   let shelterService = this.getService('shelter');
    //   if(!shelterService) {
    //     return false;
    //   } else if(this.hasMultipleCases() && this.isLatestCaseSubmittedDateGreaterThanServiceDate(moment(shelterService.submittedDate).format('YYYY-MM-DD'))) {
    //     return false;
    //   }
    //   return (shelterService.referToSafeHouse.includes("Yes\\Internal referral") ||
    //     shelterService.referToSafeHouse.includes("Yes\\External Referral"))
    // },
    hasCounseling() {
      let counselingService = this.getCounselingService();
      if(!counselingService) {
        return false
      } else if(this.hasMultipleCases() && this.isLatestCaseSubmittedDateGreaterThanServiceDate(moment(counselingService.submittedDate).format('YYYY-MM-DD'))) {
        return false;
      }
      return true;
    },
    hasClosure() {
      let closureService = this.getClosureService();
      if(!!closureService && closureService.closureType.includes('Closed/Transferred case WITHIN')) {
        return false;
      }
      if(!closureService) {
        return false;
      } else if(this.hasMultipleCases() && this.isLatestCaseSubmittedDateGreaterThanServiceDate(moment(closureService.submittedDate).format('YYYY-MM-DD'))) {
        return false;
      }
      return true;
    },
    hasShelter() {
      let shelterService = this.getShelterService();
      
      if(!shelterService) {
        return false;
      // } else if(!!shelterService && !shelterService.referralResponse.includes('Approved')) {
      } else if(!!shelterService && !shelterService.referralResponse.includes('jaafeel')) {
        return false;
      } else if(this.hasMultipleCases() && this.isLatestCaseSubmittedDateGreaterThanServiceDate(moment(shelterService.submittedDate).format('YYYY-MM-DD'))) {
        return false;
      }
      return true;
    },
    buttonClass() {
      return !this.hasCase
        ? "flex flex-col justify-center items-center bg-gray-600 py-4 px-3 rounded cursor-not-allowed"
        : "flex flex-col justify-center items-center bg-primary py-4 px-3 rounded shadow-xl cursor-pointer hover:bg-secondary";
    },
    href() {
      return this.hasCase
        ? `https://api.abaadmena.online/v1/export/all/${this.$route.params.id}`
        : "#";
    },
    routeToCaseManagement() {
      return `${this.$route.params.id}/socio-demo`;
    },
    routeToSocioDemoForm() {
      return this.hasCase
        ? `${this.$route.params.id}/view-socio-demo`
        : `${this.$route.params.id}/socio-demo`;
    },
    routeToExternalReferralService() {
      return this.hasCase ? `${this.$route.params.id}/external-referral` : "#";
    },
    routeToShelterService() {
      return this.hasCase ? `${this.$route.params.id}/shelter` : "#";
    },
    routeToFamilyComp() {
      return this.hasCase ? `${this.$route.params.id}/familycomp` : "#";
    },
    routeToMedicalService() {
      return this.hasCase ? `${this.$route.params.id}/medical` : "#";
    },
    routeToPsychService() {
      return this.hasCase ? `${this.$route.params.id}/psych` : "#";
    },
    routeToProtectionService() {
      return this.hasCase ? `${this.$route.params.id}/protection` : "#";
    },
    routeToCounselingService() {
      return this.hasCase ? `${this.$route.params.id}/counseling` : "#";
    },
    routeToLegalService() {
      return this.hasCase ? `${this.$route.params.id}/legal` : "#";
    },
    routeToActionService() {
      return this.hasCase ? `${this.$route.params.id}/action` : "#";
    },
    routeToClosureService() {
      return this.hasCase ? `${this.$route.params.id}/closure` : "#";
    },
    routeToSafetyService() {
      return this.hasCase ? `${this.$route.params.id}/safety` : "#";
    },
    routeToAttachments() {
      return this.hasCase ? `${this.$route.params.id}/attachments` : "#";
    },
    routeToManagement() {
      return `${this.$route.params.id}/management`;
    },
    Gcode() {
      return `${localStorage.getItem("beniName")} - ${this.$route.params.id}`;
    },
    //Financials
    routeToPcapFinancialService() {
      return this.hasCase ? `${this.$route.params.id}/financial/pcap` : "#";
    },
    routeToEcaFinancialService() {
      return this.hasCase ? `${this.$route.params.id}/financial/eca` : "#";
    },
    routeToPcapFollowupFinancialService() {
      return this.hasCase
        ? `${this.$route.params.id}/financial/pcapfollowup`
        : "#";
    },
    routeToPcapFeedbackFinancialService() {
      return this.hasCase
        ? `${this.$route.params.id}/financial/pcapfeedback`
        : "#";
    },
    routeToPsychologistService() {
      return this.hasCase ? `${this.$route.params.id}/psychologist` : "#";
    },
  },
};
</script>
<style lang="scss" scoped>

.flex.flex-col.justify-center.items-center.bg-gray-600.rounded.cursor-not-allowed {
  cursor: not-allowed;
}
.uil.uil {
  // margin-bottom: 1rem;
}
#uilUil-Plus{
  p {
    margin-bottom: 8px;
  }
}
p.text-sm.export.font-semibold.text-white.transform.self-start {
    margin: 0 auto;
  }
.main-nav {
  margin: auto;
}
@media (max-width: 850px) {
  .main-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  

  div.button-group {
    .flex-col {
      flex-direction: row;
    }
  }
}
.export {
  // margin: auto;
}
</style>
