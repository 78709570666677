<template>
  <div>
    <div v-if="isAgreementEvalution" class="mt-6">
      <p class="font-semibold text-sm mb-1">
        {{ question }}
      </p>

      <ab-radio
        v-for="(value, key) in aggreementValues"
        :key="key"
        :label="value"
        :value="value"
        class="mb-2"
        :required="true"
        v-model="refCopy"
        @change="emitEvent()"
      >
      </ab-radio>
    </div>
    <div v-else class="mt-6">
      <p class="font-semibold text-sm mb-1">
        {{ question }}
      </p>

      <ab-radio
        v-for="(value, key) in radioValues"
        :key="key"
        :label="value"
        :value="value"
        class="mb-2"
        :required="true"
        v-model="refCopy"
        @change="emitEvent()"
      >
      </ab-radio>
    </div>
  </div>
</template>
<script>
import AbRadio from "@/components/abaad/AbRadio/AbRadio";

export default {
  name: "AbEvaluate",
  components: {
    AbRadio,
  },
  data() {
    return {
      radioValues: [
        "Agree - اوافق",
        "Neutral - حيادي",
        "Disagree - لا اوافق",
        "No Answer - لا جواب",
      ],
      aggreementValues: [
        "Strongly agree - أوافق بشدة",
        "Agree -  أوافق",
        "Neutral -	حيادي	",
        "Disagree - لا أوافق",
        "Strongly disagree - لا أوافق بشدة",
        "No Answer - لا جواب",
      ],
      refCopy: "",
    };
  },
  props: {
    question: {
      type: String,
      required: false,
    },
    refernce: {
      type: String,
      required: true,
    },
    model: {
      type: String,
    },
    isAgreementEvalution: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    this.refCopy = this.refernce;
    if (this.model) {
      this.refCopy = this.model;
    }
  },
  methods: {
    emitEvent() {
      this.$emit("abEvaluateValue", {
        model: this.refernce,
        value: this.refCopy,
      });
    },
  },
  watch: {
    show: function(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.$emit("input", null);
      }
    },
  },
};
</script>
