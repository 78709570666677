<template>
  <ab-content-container>
    <div class="mx-auto">
      <router-view></router-view>
      <div class="training-navigation" v-if="isTrainingPage">
        <TrainingNavigation></TrainingNavigation>
      </div>
    </div>
  </ab-content-container>
</template>

<script>
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import TrainingNavigation from "./TrainingNavigation.vue";

export default {
  name: "Training",
  components: {
    AbContentContainer,
    TrainingNavigation,
  },
  data() {
    return {};
  },
  created() {
    window.scrollTo(0, 0);
  },

  mounted() {
    let lastScrollTop = 0;
    let navBar = document.querySelector(".nav-bar.nav-bar-border-bottom");
    document.addEventListener("scroll", () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (!st > lastScrollTop && navBar) {
        navBar.style.marginTop = "0";
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    });
  },
  computed: {
    isTrainingPage() {
      return this.$route.path === "/training";
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    flex: 1 1 0px;
  }
}
.activity-beneficiaries-table {
  width: 90vw !important;
  margin: 25px auto 0 auto;
}
.current-activity-icon,
.new-activity-icon {
  font-size: 3.5rem;
}
.training-navigation {
  margin-top: -2.5rem;
}
</style>
