<template>
  <ab-content-container class="pt-12">
    <pulse-loader
      v-if="isLoading"
      class="loader"
      :loading="isLoading"
      color="#2399a4"
    ></pulse-loader>

    <div class="max-w-2xl mx-auto" v-else>
      <!-- Details  -->
      <p class="font-semibold text-lg title"></p>
      <div class="title-wrapper">
        <p class="font-semibold text-xl">Training Details</p>
        <p class="pull-left text-xs text-gray-500 italic">
          This is where we view and manage an training
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
        <ab-card
          question="Submitted Date"
          :answer="getDate(training.submittedDate)"
        ></ab-card>
        <ab-card
          question="Trainer Name"
          :answer="training.trainerName"
        ></ab-card>
        <ab-card question="Title" :answer="training.title"></ab-card>
        <ab-card question="Venue" :answer="training.venue"></ab-card>
        <ab-card
          question="Number Of Participants"
          :answer="participantsLength"
        ></ab-card>
        <!-- <ab-card question="Participants" :answer="training.participants.forEach((participant) => {return participant.fullName})"></ab-card> -->
      </div>
      <br />
      <p class="font-semibold text-md mb-2"></p>
      <ab-collapse
        :borderRadius="true"
        :noPadding="true"
        :label="'Participants'"
      >
        <div
          class="table-wrapper shadow-lg grid grid-cols-1 md:grid-cols-2 gap-3"
        >
          <b-table
            ref="table"
            class="shadow-sm bg-white table b-table-stacked-sm"
            hover
            :responsive="false"
            :bordered="true"
            :fields="columns"
            :stickyHeader="true"
            :striped="true"
            :items="training.participants"
          >
          </b-table>
        </div>
      </ab-collapse>
      <br />

      <p class="font-semibold text-xl text-gray-800 mb-6">Manage Training</p>
      <div class="manage-training">
        <div class="edit-training">
          <div @click="getEditTrainingRoute(training)">
            <div
              class="wide-button flex mb-2 mt-2 items-center p-3 bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
            >
              <i class="uil uil-edit text-white text-3xl"></i>
              <p class="text-md ml-2 font-semibold text-white">
                Edit training
              </p>
            </div>
          </div>
        </div>
        <div class="delete-training">
          <div @click="deleteTraining()">
            <div
              class="wide-button flex mb-2 mt-2 items-center p-3 danger w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
            >
              <i class="uil uil-trash text-white text-3xl"></i>
              <p class="text-md ml-2 font-semibold text-white">
                Delete training
              </p>
            </div>
          </div>
        </div>
      </div>

      <br />
      <p class="font-semibold text-xl text-gray-800 mb-6">
        Evaluation & Feedback
      </p>
      <div class="evaluation-forms">
        <div class="satisftrainingion-form">
          <router-link :props="{training}" :to="getTrainingFeedbackRoute(training)">
            <div
              class="wide-button flex mb-2 mt-2 items-center p-3 bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
            >
              <i class="uil uil-confused text-white text-3xl"></i>
              <p class="text-md ml-2 font-semibold text-white">
                Training Feedback
              </p>
            </div>
          </router-link>
        </div>
        <div class="view-evaluation-forms">
          <div @click="viewTrainingFeedbacks(training)">
            <div
              class="wide-button flex mb-2 mt-2 items-center p-3 bg-primary w-full rounded shadow-xl cursor-pointer hover:bg-secondary"
            >
              <i class="uil uil-table text-white text-3xl"></i>
              <p class="text-md ml-2 font-semibold text-white">
                View training Evaluation Forms
              </p>
            </div>
          </div>
        </div>
      </div>
      <ab-button
        @click="
          $router.push({
            path: 'current',
          })
        "
        class="block mx-auto mt-10"
        :cancel="true"
        label="Back"
      ></ab-button>
    </div>
  </ab-content-container>
</template>

<script>
import TrainingService from "@/features/Training/TrainingService";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbCard from "@/components/abaad/AbCard/AbCard";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbCollapse from "../../components/abaad/AbCollapse/AbCollapse.vue";
import moment from "moment";
export default {
  components: {
    AbContentContainer,
    PulseLoader,
    AbCard,
    AbButton,
    AbCollapse,
  },
  name: "SingleTraining",
  data() {
    return {
      training: {},
      isLoading: false,
      columns: [
        {
          key: "fullName",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          stickyColumn: true,
          label: "Full Name",
          sortable: true,
        },
        {
          key: "nationality",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          label: "Nationality",
          sortable: true,
        },
        {
          key: "phoneNumber",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          label: "Phone Number",
          sortable: true,
        },
        {
          key: "email",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          label: "Email",
          sortable: true,
        },
        {
          key: "title",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          label: "Title",
          sortable: true,
        },
        {
          key: "gender",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          label: "Gender",
          sortable: true,
        },
        {
          key: "organization",
          class: ["justify-center text-center font-normal"],
          isRowHeader: true,
          label: "Organization",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    this.getTrainingInfo();
  },
  computed: {
    participantsLength() {
      return this.training?.participants?.length
    }
  },
  methods: {
    getDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getTrainingInfo() {
      this.isLoading = true;
      TrainingService.getTrainingById(this.$route.params.id)
        .then((response) => {
          console.log("🚀 ~ response:", response);
          this.training = JSON.parse(JSON.stringify(response.data));
          localStorage.setItem(
            "CurrentTraining",
            JSON.stringify(response.data)
          );
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.training = JSON.parse(localStorage.getItem("CurrentTraining"));
          this.isLoading = false;
        });
    },
    routeToTrainingNavigation() {
      // TODO: needs reftrainingor
      return this.$router.push({ path: `/current` });
    },

    getTrainingFeedbackRoute(training) {
      console.log("🚀 ~ training:", training);
      return {
        name: "TrainingFeedback",
        path: "feedback/edit/:id",
        params: { training: training },
      };
    },

    getEditTrainingRoute(training) {
      return this.$router.push({
        params: { editService: training, id: training.id },
        path: "/edit/" + training.id,
        name: "create-new-training",
      });
    },
    deleteTraining() {
      if (window.confirm("Are you sure you want to delete this training ?")) {
        TrainingService.deleteTraining(this.$route.params.id).then(() => {
          this.routeTotrainingNavigation();
        });
      }
    },
    viewTrainingFeedbacks(training) {
      console.log("🚀 ~ training:", training);
      return this.$router.push({
        path: "view",
        name: "FeedbackEvaluationForms",
        params: { training: training },
      });
    },
    deleteSession($e, item) {
      $e.stopPropagation();
      if (window.confirm("Are you sure you want to delete this session ?")) {
        TrainingService.deleteSession(item.id).then(() => {
          if (this.training.feedbacks.length > 1) {
            const index = this.training.feedbacks.indexOf(item);
            if (index >= 0) this.training.feedbacks.splice(index, 1);
          } else {
            this.training.feedbacks = [];
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.border .border-solid .border-gray-500 .col-span-3 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.wide-button-holder {
  .wide-button.flex.mb-2.mt-2.items-center.p-3.bg-primary.w-full.rounded.shadow-xl.cursor-pointer {
    padding: 0.75rem !important;
  }
}
.evaluation-forms,
.manage-training {
  display: flex;
  flex-wrap: wrap;

  .satisftrainingion-form,
  .awareness-form,
  .edit-training,
  .delete-training {
    flex-basis: 49%;
    margin: 0 2px 0 2px;
  }
}
.view-evaluation-forms {
  width: 100%;
}
.danger {
  background-color: #dc3545;
}
.delete-session {
  display: flex;
  margin-left: auto;
  color: white;
}
.table-wrapper {
  max-height: 50vh;
  overflow: auto;
}
.title-wrapper {
  margin-top: -1.9rem;
  margin-left: -1.9rem;
}
</style>
