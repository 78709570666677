import Vue from "vue";
import axios from "@/http/http-common";

const state = {
    fnPcap: []
};
const getters = {
    fnPcap: (state) => state.fnPcap
};
const actions = {
    async fetchFinancialPcap({ commit }, data) {
        if(!!this.getters.fnPcap.length) {
            commit("setFinancialPcap", this.getters.fnPcap)           
        } else {
            const accessToken = await new Vue().$auth.getTokenSilently();
            
            return await axios.get("/management/editables/get/", {
                params:{
                    gbvmis: data.gbvmis,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }).then((response) => {
                let result = response.data.filter((obj) => {
                    return obj.type === data.fnPcap;
                });
                if(!!result.length) {
                    commit("setFinancialPcap", result);
                }
            })
           
        }
    }
};
const mutations = {
    setFinancialPcap: (state, fnPcap) => state.fnPcap = fnPcap
};

export default {
    state,
    getters,
    actions,
    mutations
}