import axios from "@/http/http-common";
import Vue from "vue";

class PcapFeedbackServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/financial/formoptions/pcapfeedback", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewPcapFeedbackService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/financial/create/pcapfeedback", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updatePcapFeedbackService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/financial/update/pcapfeedback", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new PcapFeedbackServiceService();
