import axios from "@/http/http-common";
import Vue from 'vue';

class RecordlogService {

    async getRecordlogsForLastXDays(days) {
        const accessToken = await new Vue().$auth.getTokenSilently();

        return axios.get("/recordlogs/filter/days", {
            params: {
                days: days,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

}

export default new RecordlogService();
