import axios from "@/http/http-common";
import Vue from "vue";

class ManagementService {
    async getManagementEditableItems(gcode) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.get(`/management/editables/get/`, {
            params: {
                gbvmis: gcode,
            },
            headers: {
                "accept": "*/*",
                Authorization: `Bearer ${accessToken}`,
            }
        });
    }

    async deleteBeneficiary(id) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.post('/beni/delete', null, {
            params: {
                id: id,
            },
            headers: {
                "accept": "*/*",
                Authorization: `Bearer ${accessToken}`,
            }
        });
    }

    async deleteSocialDemo(id) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.post('/socdemo/delete', null, {
            params: {
                id: id,
            },
            headers: {
                "accept": "*/*",
                Authorization: `Bearer ${accessToken}`,
            }
        });
    }

    async deleteCase(id) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.post('/survivorinfo/delete', null, {
            params: {
                id: id,
            },
            headers: {
                "accept": "*/*",
                Authorization: `Bearer ${accessToken}`,
            }
        });
    }

    async deleteService(id) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.post('/services/delete', null, {
            params: {
                id: id,
            },
            headers: {
                "accept": "*/*",
                Authorization: `Bearer ${accessToken}`,
            }
        });
    }

    async deleteFinancialService(id) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.post('/services/financial/delete', null, {
            params: {
                id: id,
            },
            headers: {
                "accept": "*/*",
                Authorization: `Bearer ${accessToken}`,
            }
        });
    }

}

export default new ManagementService();
