<template>
  <ab-content-container>
    <div :class="wrapperClasses">
      <router-link
        v-if="!shouldShowTable"
        :to="routeToAddBeneficiary"
        tag="div"
        :class="pageButtonClasses">
        <i class="uil uil-plus current-activity-icon" :class="buttonIconClasses"></i>
        <p :class="buttonTextClasses">Add beneficiary</p>
      </router-link>
      <router-link
        v-if="!shouldShowTable"
        :to="routeToNewActivity"
        tag="div"
        :class="pageButtonClasses" >
        <i :class="buttonIconClasses" class="uil uil-file-plus-alt new-activity-icon"></i>
        <p :class="buttonTextClasses">New Activity</p>
      </router-link>
      <router-link
        v-if="!shouldShowTable"
        :to="routeToCurrentActivities"
        tag="div"
        :class="pageButtonClasses">
        <i :class="buttonIconClasses" class="uil uil-credit-card-search current-activity-icon"></i>
        <p :class="buttonTextClasses">Current</p>
      </router-link>
       <router-link
        v-if="!shouldShowTable"
        :to="routeToCompletedActivities"
        tag="div"
        :class="pageButtonClasses">
        <i :class="buttonIconClasses" class="uil uil-check-circle current-activity-icon"></i>
        <p :class="buttonTextClasses">Completed</p>
      </router-link>
    </div>
  </ab-content-container>
</template>

<script>
import { EventBus } from "@/Events/event-bus.js";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
export default {
  name: "navigation",
  components: {
    AbContentContainer,
  },
  data() {
    return {
      hasCase: null,
      fileSrc: "",
      shouldShowTable: false,
    };
  },
  created() {
    EventBus.$on("shouldShowActivityTable", (shouldShowActivityTable) => {
      this.shouldShowTable = shouldShowActivityTable;
    });
  },
  computed: {
    routeToCurrentActivities() {
      return "/activities/current";
    },
    routeToNewActivity() {
      return "/activities/new";
    },
    routeToAddBeneficiary() {
      return "/activities/add-beneficiary";
    },
    routeToCompletedActivities() {
      return "/activities/completed";
    },
    pageButtonClasses() {
      return "page-button group flex flex-col justify-center items-center p-3 bg-primary rounded cursor-pointer hover:shadow-2xl hover:bg-secondary";
    },
    buttonIconClasses() {
      return "text-white main-icon transform translate-y-3 transition-all duration-300 group-hover:transform rotate-180";
    },
    buttonTextClasses() {
      return "mb-3 button-text transition-all duration-300 font-semibold text-white group-hover:translate-x-8";
    },
    wrapperClasses() {
      return "wrapper gap-2 content-wrap px-8 pt-4 md:px-20 md:pt-12 lg:px-20 lg:pt-16 xl:px-20 xl:pt-10";
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 600px) {
  .wrapper {
    flex-direction: column;
    flex: 1 1 0px;
  }
}

.current-activity-icon,
.new-activity-icon {
  font-size: 3.5rem;
}
</style>
