<template>
  <ab-content-container>
    <pulse-loader :loading="isLoading" class="loader" color="#2399A4" v-if="isLoading"></pulse-loader>
    <div v-else>
      <div>
        <p class="font-semibold text-xl">Psychotherapy In-house Session</p>
        <form @submit.prevent="!editService ? addPsychologistSession() : updatePsychologistService()"
          class="flex flex-col mx-auto max-w-2xl">
          <!-- submitDate -->
          <div class="mt-6" ref="submittedDate">
            <p class="text-sm font-semibold mb-1">Date of Session</p>
            <ab-input :full="true" type="date" v-model="submittedDate"></ab-input>
          </div>
          <!-- end submitDate -->

          <!-- typeTherapy -->
          <div class="mt-12" ref="typeTherapy">
            <p class="font-semibold text-sm mb-1">{{ typeTherapyQuestion }}</p>
            <ab-radio :key="key" :label="typeTherapyAnswer" :value="typeTherapyAnswer" class="mb-2" 
              v-model="typeTherapy" v-for="(typeTherapyAnswer, key) in typeTherapyAnswers">
            </ab-radio>
          </div>
          <!-- end typeTherapy -->

          <!-- sessionAttended -->
          <div class="mt-12"  ref="sessionAttended">
            <p class="font-semibold text-sm mb-1">{{ sessionAttendedQuestion }}</p>
            <ab-radio :key="key" :label="sessionAttenedAnswer" :value="sessionAttenedAnswer" class="mb-2" 
              v-model="sessionAttended" v-for="(sessionAttenedAnswer, key) in sessionAttendedAnswers"></ab-radio>
          </div>
          <!-- end sessionAttended -->

          <!-- sessionType -->
          <div class="mt-12"  ref="sessionType" v-if="beniAttendedSession">
            <p class="font-semibold text-sm mb-1"> {{ sessionTypeQuestion }}</p>
            <ab-radio :key="key" :label="sessionTypeAnswer" :value="sessionTypeAnswer" class="mb-2"
              v-model="sessionType" v-for="(sessionTypeAnswer, key) in sessionTypeAnswers"></ab-radio>
          </div>
          <!-- end sessionType -->

          <!--  demand -->
          <div class="mt-12"  ref="demand" v-if="beniAttendedSession">
            <p class="font-bold text-sm mb-1">{{ demandQuestion }}</p>
            <div v-for="(demandAnswer, index) in demandAnswers" :key="index" class="mt-4">
              <label :for="demandAnswer" class="flex items-center text-sm font-light">
                <input v-model="demand" :value="demandAnswer" type="checkbox" />
                <span class="ml-2">{{ demandAnswer }}</span>
              </label>
            </div>
            <ab-input v-if="demandIncludesOther" v-model="demandOther" :full="true" class="inline-block mt-4" label="Please specify"></ab-input>
          </div>
          <!-- end demand -->

          <!-- diagnosis -->
          <div class="mt-12" ref="diagnosis" v-if="beniAttendedSession">
            <p class="font-bold text-sm mb-1">{{ diagnosisQuestion }}</p>
            <div v-for="(diagnosisAnswer, index) in diagnosisAnswers" :key="index" class="mt-4">
              <label :for="diagnosisAnswer" class="flex items-center text-sm font-light">
                <input v-model="diagnosis" :value="diagnosisAnswer" type="checkbox"/>
                <span class="ml-2">{{ diagnosisAnswer }}</span>
              </label>
            </div>
            <ab-input
              v-if="diagnosisIncludesOther" v-model="diagnosisOther" :full="true" class="inline-block mt-4" label="Please specify"></ab-input>
          </div>
          <!-- end diagnosis -->

          <!-- neuroDisorder -->
          <div class="mt-12" ref="neuroDisorder" v-if="beniAttendedSession && neuroDisorderDiagnosis">
            <p class="font-bold text-sm mb-1">{{ neuroDisorderQuestion }}</p>
            <div v-for="(neuroDisorderAnswer, index) in neuroDisorderAnswers" :key="index" class="mt-4">
              <label :for="neuroDisorderAnswer" class="flex items-center text-sm font-light">
                <input v-model="neuroDisorder" :value="neuroDisorderAnswer" type="checkbox"/>
                <span class="ml-2">{{ neuroDisorderAnswer }}</span>
              </label>
            </div>
            <ab-input v-if="neuroDisorderIncludesOther" v-model="neuroDisorderOther" :full="true" class="inline-block mt-4" label="Please specify"></ab-input>
          </div>
          <!-- end neuroDisorder -->

          <!-- psychDisorder -->
          <div class="mt-12" ref="psychDisorder" v-if="beniAttendedSession && psychDisorderDiagnosis">
            <p class="font-bold text-sm mb-1">{{ psychDisorderQuestion }}</p>
            <div v-for="(psychDisorderAnswer, index) in psychDisorderAnswers" :key="index" class="mt-4">
              <label :for="psychDisorderAnswer" class="flex items-center text-sm font-light">
                <input v-model="psychDisorder" :value="psychDisorderAnswer" type="checkbox"/>
                <span class="ml-2">{{ psychDisorderAnswer }}</span>
              </label>
            </div>
            <ab-input v-if="psychDisorderIncludesOther" v-model="psychDisorderOther" :full="true" class="inline-block mt-4" label="Please specify"></ab-input>
          </div>
          <!-- end psychDisorder -->

          <!-- anxietyDisorder -->
          <div class="mt-12" ref="anxietyDisorder" v-if="beniAttendedSession && anxietyDisorderDiagnosis">
            <p class="font-bold text-sm mb-1">{{ anxietyDisorderQuestion }}</p>
            <div v-for="(anxietyDisorderAnswer, index) in anxietyDisorderAnswers" :key="index" class="mt-4">
              <label :for="anxietyDisorderAnswer" class="flex items-center text-sm font-light">
                <input v-model="anxietyDisorder" :value="anxietyDisorderAnswer" type="checkbox"/>
                <span class="ml-2">{{ anxietyDisorderAnswer }}</span>
              </label>
            </div>
            <ab-input v-if="anxietyDisorderIncludesOther" v-model="anxietyDisorderOther" :full="true" class="inline-block mt-4" label="Please specify"></ab-input>
          </div>
          <!-- end anxietyDisorder -->

          <!-- eatingDisorder -->
          <div class="mt-12"  ref="eatingDisorder"  v-if=" beniAttendedSession && eatingDisorderDiagnosis">
            <p class="font-bold text-sm mb-1">{{ eatingDisorderQuestion }}</p>
            <div v-for="(eatingDisorderAnswer, index) in eatingDisorderAnswers" :key="index" class="mt-4">
              <label :for="eatingDisorderAnswer" class="flex items-center text-sm font-light">
                <input v-model="eatingDisorder" :value="eatingDisorderAnswer" type="checkbox"/>
                <span class="ml-2">{{ eatingDisorderAnswer }}</span>
              </label>
            </div>
            <ab-input v-if="eatingDisorderIncludesOther" v-model="eatingDisorderOther" :full="true" class="inline-block mt-4" label="Please specify"></ab-input>
          </div>
          <!-- end eatingDisorder -->

          <!-- addictiveDisorder -->
          <div class="mt-12" ref="addictiveDisorder" v-if="beniAttendedSession && addictiveDisorderDiagnosis">
            <p class="font-bold text-sm mb-1">{{ addictiveDisorderQuestion }}</p>
            <div v-for="(addictiveDisorderAnswer, index) in addictiveDisorderAnswers" :key="index" class="mt-4">
              <label :for="addictiveDisorderAnswer" class="flex items-center text-sm font-light">
                <input v-model="addictiveDisorder" :value="addictiveDisorderAnswer" type="checkbox"/>
                <span class="ml-2">{{ addictiveDisorderAnswer }}</span>
              </label>
            </div>
            <ab-input v-if="addictiveDisorderIncludesOther" v-model="addictiveDisorderOther" :full="true" class="inline-block mt-4" label="Please specify"></ab-input>
          </div>
          <!-- end addictiveDisorder -->

          <!-- personalityDisorder -->
          <div class="mt-12" ref="personalityDisorder" v-if="beniAttendedSession && personalityDisorderDiagnosis">
            <p class="font-bold text-sm mb-1"> {{ personalityDisorderQuestion }}</p>
            <div
              v-for="(personalityDisorderAnswer, index) in personalityDisorderAnswers" :key="index" class="mt-4">
              <label :for="personalityDisorderAnswer" class="flex items-center text-sm font-light">
                <input v-model="personalityDisorder" :value="personalityDisorderAnswer" type="checkbox"/>
                <span class="ml-2">{{ personalityDisorderAnswer }}</span>
              </label>
            </div>
            <ab-input v-if="personalityDisorderIncludesOther" v-model="personalityDisorderOther" :full="true" class="inline-block mt-4" label="Please specify"></ab-input>
          </div>
          <!-- end personalityDisorder -->

          <!-- currentPsychTreatment -->
          <div class="mt-12" ref="currentPsychTreatment" v-if="beniAttendedSession">
            <p class="font-semibold text-sm mb-1">{{ currentPsychTreatmentQuestion }}</p>
            <ab-radio v-for="(currentPsychTreatmentAnswer, key) in currentPsychTreatmentAnswers" v-model="currentPsychTreatment"
              :key="key" :label="currentPsychTreatmentAnswer" :value="currentPsychTreatmentAnswer" class="mb-2"></ab-radio>
          </div>
          <!-- end currentPsychTreatment -->

          <!-- psychTherapistName -->
          <div class="mt-12" ref="psychTherapistName" v-if="beniAttendedSession && currentPsychTreatmentYes">
            <p class="font-semibold text-sm mb-1">{{ psychTherapistNameQuestion }}</p>
            <ab-input :full="true" v-model="psychTherapistName"></ab-input>
          </div>
          <!-- end psychTherapistName -->

          <!-- psychTreatment -->
          <div class="mt-12" ref="psychTreatment" v-if="beniAttendedSession && currentPsychTreatmentYes">
            <p class="font-semibold text-sm mb-1">{{psychTreatmentQuestion }}</p>
            <ab-input :full="true" v-model="psychTreatment"></ab-input>
          </div>
          <!-- end psychTreatment -->

          <!-- sessionOverview -->
          <div class="mt-12" v-if="beniAttendedSession">
            <p class="font-semibold text-sm mb-1">{{ sessionOverviewQuestion }}</p>
            <ab-input :full="true" v-model="sessionOverview"></ab-input>
          </div>
          <!-- end sessionOverview -->

          <!-- referThirdParty -->
          <div class="mt-12" ref="referThirdParty" v-if="beniAttendedSession">
            <p class="font-semibold text-sm mb-1">{{ referThirdPartyQuestion }}</p>
            <ab-radio v-for="(referThirdPartyAnswer, key) in referThirdPartyAnswers"
              :key="key" :label="referThirdPartyAnswer" :value="referThirdPartyAnswer" class="mb-2" 
              v-model="referThirdParty"
            ></ab-radio>
          </div>
          <!-- end referThirdParty -->
            <div class="other" v-if="shouldShowReferral">
              <p class="font-semibold text-sm mb-1">Specify</p>
              <ab-input :full="true" v-model="referThirdPartyComment"></ab-input>
            </div>
          <!-- referThirdPartyOther -->
          <!-- <div class="mt-12" v-if="shouldShowReferral">
            <label>Other, Please specify?</label>
            <ab-input placeholder="Please specify" :full="true" v-model="referThirdPartyOther"></ab-input>
            
          </div> -->
          <!-- referThirdPartyOther -->

          <!-- recommendations -->
          <div class="mt-12" v-if="beniAttendedSession">
            <p class="font-semibold text-sm mb-1">{{ recommendationsQuestion }}</p>
            <ab-input :full="true" v-model="recommendations"></ab-input>
          </div>
          <!-- end recommendations -->

          <!-- generalEvolution -->
          <div class="mt-12" ref="generalEvolution" v-if="beniAttendedSession">
            <p class="font-semibold text-sm mb-1">{{ generalEvolutionQuestion }}</p>
            <ab-radio v-for="(generalEvolutionAnswer, key) in generalEvolutionAnswers"
              :key="key" :label="generalEvolutionAnswer" :value="generalEvolutionAnswer" class="mb-2" v-model="generalEvolution"
            ></ab-radio>
          </div>
          <!-- end generalEvolution -->

          <!-- comments -->
          <div class="mt-12" v-if="beniAttendedSession">
            <p class="font-semibold text-sm mb-1">{{ commentsQuestion }}</p>
            <ab-input :full="true" v-model="comments"></ab-input>
          </div>
          <!-- end comments -->

          <!-- closureReason -->
          <div class="mt-12" ref="closureReason" v-if="sessionTypeIsClosure">
            <p class="font-bold text-sm mb-1">{{ closureReasonQuestion }}</p>
            <div v-for="(closureReasonAnswer, index) in closureReasonAnswers" :key="index" class="mt-4">
              <label :for="closureReasonAnswer" class="flex items-center text-sm font-light">
                <input v-model="closureReason" :value="closureReasonAnswer" type="checkbox"/>
                <span class="ml-2">{{ closureReasonAnswer }}</span>
              </label>
            </div>

            <ab-input v-if="closureReasonIncludesOther" v-model="closureReasonOther" :full="true" class="inline-block mt-4" label="Please specify"
            ></ab-input>
          </div>
          <!-- end closureReason -->

          <!-- Submit -->
          <div class="mt-12">
            <div class="flex flex-col justify-center items-center" v-if="!isLoading">
              <ab-button @click=" !editService ? addPsychologistSession() : updatePsychologistService()"
                class="mt-2 submit-btn" label="submit"></ab-button>
              <ab-button @click="routeToNavigation()" class="mt-2" :cancel="true" label="cancel"></ab-button>
            </div>
          </div>
          <!-- end Submit -->
        </form>
      </div>
    </div>
  </ab-content-container>
</template>
<script>
import vue from "vue";
import PsychologistDataService from "./PsycologistDataService";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import AbButton from "@/components/abaad/AbButton/AbButton";
import moment from "moment";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "new-psychologist",
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  mixins: [utilMixin, toasterMixin],
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      psychologistServiceQA: {},
      referThirdPartyComment: "",
      errors: {},
      isLoading: false,
      submittedDate: "",
      addictiveDisorder: [],
      addictiveDisorderOther: [],
      anxietyDisorder: [],
      anxietyDisorderOther: [],
      closureReason: [],
      closureReasonOther: "",
      comments: "",
      currentPsychTreatment: "",
      demand: [],
      demandOther: "",
      resultDemandOther: [],
      diagnosis: [],
      diagnosisOther: "",
      eatingDisorder: [],
      eatingDisorderOther: [],
      generalEvolution: "",
      neuroDisorder: [],
      neuroDisorderOther: [],
      personalityDisorder: [],
      personalityDisorderOther: [],
      psychDisorder: [],
      psychDisorderOther: [],
      psychSpecify: "",
      psychTherapistName: "",
      psychTreatment: "",
      recommendations: "",
      referThirdParty: "",
      referThirdPartyOther: "",
      sessionAttended: "",
      sessionOverview: "",
      sessionType: "",
      typeTherapy: "",
      gbvmis: "",
      lastCounselingSessionDate: "",
      tomorrowsDate: ""
    };
  },
  validations() {
    return {
       submittedDate: {
        required,
        minValue: value => value >= this.lastCounselingSessionDate,
        maxValue: (value) => value < this.tomorrowsDate,
      },
      typeTherapy: {
        required
      },
      sessionAttended: {
        required
      },
      sessionType: {
        required: requiredIf(() => this.beniAttendedSession)
      },
      demand: {
        required: requiredIf(() => this.beniAttendedSession)
      },
      diagnosis: {
        required: requiredIf(() => this.beniAttendedSession)
      },
      neuroDisorder: {
        required: requiredIf(() => this.beniAttendedSession && this.neuroDisorderDiagnosis)
      },
      psychDisorder: {
        required: requiredIf(() => this.beniAttendedSession && this.psychDisorderDiagnosis)
      },
      anxietyDisorder: {
        required: requiredIf(() => this.beniAttendedSession && this.anxietyDisorderDiagnosis)
      },
      eatingDisorder: {
        required: requiredIf(() => this.beniAttendedSession && this.eatingDisorderDiagnosis)
      },
      addictiveDisorder: {
        required: requiredIf(() => this.beniAttendedSession && this.addictiveDisorderDiagnosis)
      },
      personalityDisorder: {
        required: requiredIf(() => this.beniAttendedSession && this.personalityDisorderDiagnosis)
      },
      currentPsychTreatment: {
        required: requiredIf(() => this.currentPsychTreatmentYes)
      },
      referThirdParty: {
        required: requiredIf(() => this.beniAttendedSession)
      },
      generalEvolution: {
        required: requiredIf(() => this.beniAttendedSession)
      },
      closureReason: {
        required: requiredIf(() => this.sessionTypeIsClosure)
      }
    }
  },
  created() {
    this.isLoading = true;
    this.gbvmis = this.$route.params.id;
    this.getPsychologistQA();
    window.scrollTo(0,0);
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD");  
    
  },
  methods: {
    ...mapActions(['fetchAllServices']),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    async getPsychologistQA() {
      this.isLoading = true;
      PsychologistDataService.getQaData()
        .then((response) => {
          this.psychologistServiceQA = JSON.parse(
            JSON.stringify(response.data)
          );
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          (this.isLoading = false)
          });
    },
    addPsychologistSession() {
      if(!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          let newPsychologistService = {};
          newPsychologistService = this.getNewPsychologistService();
          PsychologistDataService.addNewPsychologistService(newPsychologistService)
            .then(() => this.$router.push({ path: `/bene/${this.gbvmis}` }))
            .catch((error) => {
              window.scrollTo(0, 0);
              this.errors =
                error.response.data.errorMessages ?? error.response.data.details;
          })
            .finally(() => (this.isLoading = false)
          );
        }
      }
    },
    getNewPsychologistService() {
      let newPsychologistService = {};
      if (!!this.editService) newPsychologistService.id = this.editService.id;
      
      newPsychologistService.referThirdParty = this.referThirdParty;
      if(this.shouldShowReferral) {
        newPsychologistService.referThirdPartyComment = this.referThirdPartyComment;      
      } else {
        newPsychologistService.referThirdPartyComment = null;
      }
      
      newPsychologistService.demand = this.adjustResultArray(
        this.demand,
        this.demandOther,
        this.demand.includes("Other")
      );
      newPsychologistService.diagnosis = this.adjustResultArray(
        this.diagnosis,
        this.diagnosisOther,
        this.diagnosis.includes("Other")
      );
      newPsychologistService.closureReason = this.adjustResultArray(
        this.closureReason,
        this.closureReasonOther,
        this.closureReason.includes("Other")
      );
      newPsychologistService.submittedDate = this.submittedDate;
      newPsychologistService.addictiveDisorder = this.adjustResultArray(
        this.addictiveDisorder,
        this.addictiveDisorderOther,
        this.addictiveDisorder.includes("Other")
      );
      newPsychologistService.anxietyDisorder = this.adjustResultArray(
        this.anxietyDisorder,
        this.anxietyDisorderOther,
        this.anxietyDisorder.includes("Other")
      );
      newPsychologistService.comments = this.comments;
      newPsychologistService.currentPsychTreatment =
        this.currentPsychTreatment.includes("Yes") ? true : false;
      newPsychologistService.generalEvolution = this.generalEvolution;
      newPsychologistService.neuroDisorder = this.adjustResultArray(
        this.neuroDisorder,
        this.neuroDisorderOther,
        this.neuroDisorder.includes("Other")
      );
      newPsychologistService.eatingDisorder = this.adjustResultArray(
        this.eatingDisorder,
        this.eatingDisorderOther,
        this.eatingDisorder.includes("Other")
      );
      newPsychologistService.personalityDisorder = this.adjustResultArray(
        this.personalityDisorder,
        this.personalityDisorderOther,
        this.personalityDisorder.includes("Other")
      );
      newPsychologistService.psychDisorder = this.adjustResultArray(
        this.psychDisorder,
        this.psychDisorderOther,
        this.psychDisorder.includes("Other")
      );
      // TODO; Check binding to conform with the backend payload
      newPsychologistService.psychSpecify = this.psychSpecify;
      newPsychologistService.psychTherapistName = this.psychTherapistName;
      newPsychologistService.psychTreatment = this.psychTreatment;
      newPsychologistService.recommendations = this.recommendations;
      newPsychologistService.sessionAttended = this.sessionAttended.includes(
        "Yes"
      )
        ? true
        : false;
      newPsychologistService.sessionOverview = this.sessionOverview;
      newPsychologistService.sessionType = this.sessionType;
      newPsychologistService.typeTherapy = this.typeTherapy;
      newPsychologistService.gbvmis = this.gbvmis;
      newPsychologistService.serviceType = "psychologist";

      return newPsychologistService;
    },
    adjustResultArray(initialArray, otherString, isChecked) {
      let adjustedPsychologistService = this.addValueIfOtherIsChecked(
        initialArray,
        otherString,
        isChecked
      );

      return this.removeOtherFromArray(adjustedPsychologistService);
    },
    addValueIfOtherIsChecked(arr, val, isChecked) {
      if (isChecked) {
        arr.push(val);
      }
      return arr;
    },
    removeOtherFromArray(arr) {
      if (arr.includes("Other")) {
        let index = arr.indexOf("Other");
        arr.splice(index, 1);
      }
      return arr;
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    updatePsychologistService() {
      if(!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {        
          PsychologistDataService.updatePsychService(this.getNewPsychologistService())
          .then(() => {
            this.$router.push({
              path: `/bene/${this.$route.params.id}/management`,
            });
          })
          .catch((error) => console.log(error))
          .finally(() => (this.isLoading = false));
        }
      }
    },
    populateEditService() {
      this.submittedDate = moment(new Date(this.editService.createdDate)).format("YYYY-MM-DD");
      this.eatingDisorder = this.editService.eatingDisorder;
      this.eatingDisorderOther = this.editService.eatingDisorderOther;
      this.generalEvolution = this.editService.generalEvolution;
      this.psychSpecify = this.editService.psychSpecify;
      this.psychTherapistName = this.editService.psychTherapistName;
      this.psychTreatment = this.editService.psychTreatment;
      this.recommendations = this.editService.recommendations;
      this.referThirdParty = this.editService.referThirdParty;
      this.referThirdPartyComment = this.editService.referThirdPartyComment;
      this.sessionAttended = this.editService.sessionAttended ? "Yes" : "No";
      this.sessionOverview = this.editService.sessionOverview;
      this.sessionType = this.editService.sessionType;
      this.typeTherapy = this.editService.typeTherapy;
      this.comments = this.editService.comments;
      this.currentPsychTreatment = this.editService.currentPsychTreatment
        ? "Yes"
        : "No";
      this.demand = this.ammendEditCaseOtherInput(
        this.demandAnswers,
        this.editService.demand[0],
        "Other"
      ).then((result) => {
        this.demandOther = result[1];
        this.demand = [result[0]];
      });
      this.ammendGenericOtherValues(
        this.diagnosisAnswers,
        "diagnosis",
        "diagnosisOther",
        "Other"
      );
      this.ammendGenericOtherValues(
        this.neuroDisorderAnswers,
        "neuroDisorder",
        "neuroDisorderOther",
        "Other"
      );
      this.ammendGenericOtherValues(
        this.psychDisorderAnswers,
        "psychDisorder",
        "psychDisorderOther",
        "Other"
      );
      this.ammendGenericOtherValues(
        this.addictiveDisorderAnswers,
        "addictiveDisorder",
        "addictiveDisorderOther",
        "Other"
      );
      this.ammendGenericOtherValues(
        this.personalityDisorderAnswers,
        "personalityDisorder",
        "personalityDisorderOther",
        "Other"
      );
      this.ammendGenericOtherValues(
        this.closureReasonAnswers,
        "closureReason",
        "closureReasonOther",
        "Other"
      );
      this.ammendGenericOtherValues(
        this.anxietyDisorderAnswers,
        "anxietyDisorder",
        "anxietyDisorderOther",
        "Other"
      );

    },
    ammendGenericOtherValues(itemAnswers, itemName, itemOther, other) {
      this.ammendEditCaseOtherInput(
        itemAnswers,
        this.editService[itemName],
        other
      ).then((result) => {
        let previouslySelectedAnswers = result[1];
        let answers = this.psychologistServiceQA[itemName].answers;
        this[itemName] = previouslySelectedAnswers;
        previouslySelectedAnswers.forEach((selectedAnswer, index) => {
          if (!answers.includes(selectedAnswer)) {
            this[itemName][index] = other;
            this[itemOther] = selectedAnswer;
          }
        });
      });
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    catchErrors() {
      this.errors = [];
      if(!this.submitDateExists()) {
        let elPosition = this.$refs.submittedDate.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Submitted Date is Required.", pos: elPosition});
      }
       if(this.submitDateHasError()) {
        let elPosition = this.$refs.submittedDate.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Submitted Date must be greater than last cousneling date and less than tomorrow inclusive", pos: elPosition});
      }
      if(!this.typeTherapy) {
        let elPosition = this.$refs.typeTherapy.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Type Therapy of Session Field is Required", pos: elPosition});
      }
      if(!this.sessionAttended) {
        let elPosition = this.$refs.sessionAttended.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Attending Session Field is Required", pos: elPosition});
      }
      if(!this.sessionType && this.beniAttendedSession) {
        let elPosition = this.$refs.sessionType.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Session Type Field is Required", pos: elPosition});
      }
      if(!this.demand.length && this.beniAttendedSession) {
        let elPosition = this.$refs.demand.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Initial Demand Field is Required", pos: elPosition});
      }
      if(!this.diagnosis.length  && this.beniAttendedSession) {
        let elPosition = this.$refs.diagnosis.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Diagnosis Field is Required", pos: elPosition});
      }
      if(!this.neuroDisorder.length && this.neuroDisorderDiagnosis) {
        let elPosition = this.$refs.neuroDisorder.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Neuro-Developmental Disorder is Required", pos: elPosition});
      }
      if(!this.psychDisorder.length && this.psychDisorderDiagnosis) {
        let elPosition = this.$refs.psychDisorder.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Psychotic Disorder is Required", pos: elPosition});
      }
      if(!this.anxietyDisorder.length && this.anxietyDisorderDiagnosis) {
        let elPosition = this.$refs.anxietyDisorder.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Anexiety Disorder is Required", pos: elPosition});
      }
      if(!this.personalityDisorder.length && this.personalityDisorderDiagnosis) {
        let elPosition = this.$refs.personalityDisorder.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Personality Disorder is Required", pos: elPosition});
      }
      if(!this.eatingDisorder.length && this.eatingDisorderDiagnosis) {
        let elPosition = this.$refs.eatingDisorder.getBoundingClientRect().top + window.scrollY - 60;  
        this.errors.push({text: "Eating Disorder is Required", pos: elPosition});
      }
      if(!this.addictiveDisorder.length && this.addictiveDisorderDiagnosis) {
        let elPosition = this.$refs.addictiveDisorder.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Addictive Disorder is Required", pos: elPosition});
      }
      if(!this.referThirdParty && this.beniAttendedSession) {
        let elPosition = this.$refs.referThirdParty.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "ThirdParty Referer Field is Required", pos: elPosition});
      }
      if(!this.generalEvolution && this.beniAttendedSession) {
        let elPosition = this.$refs.generalEvolution.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "General Evolution of Beneficiary is Required", pos: elPosition});
      }
      if(!this.closureReason && this.sessionTypeIsClosure) {
        let elPosition = this.$refs.closureReason.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Closure Reason is Required", pos: elPosition});
      }
      if(!this.currentPsychTreatment && this.beniAttendedSession) {
        let elPosition = this.$refs.currentPsychTreatment.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({text: "Current Psychotropic Treament Field is Required", pos: elPosition});
      }
      this.handleArrayErrorsAndScrollToQuestion(this.errors);
    },
  },
  computed: {
    ...mapGetters(['allServices']),
    beniAttendedSession() {
      return this.sessionAttended.includes('Yes');
    },
    neuroDisorderDiagnosis() {
      return this.diagnosis.includes('Neurodevelopmental disorders');
    },
    psychDisorderDiagnosis() {
      return this.diagnosis.includes('Psychotic disorders');
    },
    anxietyDisorderDiagnosis() {
      return this.diagnosis.includes('Anxiety disorders');
    },
    eatingDisorderDiagnosis() {
      return this.diagnosis.includes('Eating disorders');
    },
    addictiveDisorderDiagnosis() {
      return this.diagnosis.includes('Addictive disorders');
    },
    personalityDisorderDiagnosis() {
      return this.diagnosis.includes('Personality disorders');
    },
    addictiveDisorderIncludesOther() {
      return this.addictiveDisorder.includes('Other')
    },
    currentPsychTreatmentYes() {
      return this.currentPsychTreatment.includes('Yes');
    },
    demandIncludesOther() {
      return this.demand.includes('Other');
    },
    diagnosisIncludesOther() {
      return this.diagnosis.includes('Other')
    },
    neuroDisorderIncludesOther() {
      return this.neuroDisorder.includes('Other');
    },
    psychDisorderIncludesOther() {
      return this.psychDisorder.includes('Other')
    },
    anxietyDisorderIncludesOther() {
      return this.anxietyDisorder.includes('Other');
    },
    eatingDisorderIncludesOther() {
      return this.eatingDisorder.includes('Other');
    },
    personalityDisorderIncludesOther() {
      return this.personalityDisorder.includes('Other');
    },
    closureReasonIncludesOther() {
      return this.closureReason.includes('Other');
    },
    sessionTypeIsClosure() {
      return this.sessionType.includes('Closure Session');
    },
    typeTherapyQuestion() {
      return this.psychologistServiceQA.typeTherapy.question[0];
    },
    typeTherapyAnswers() {
      return this.psychologistServiceQA.typeTherapy.answers;
    },
    sessionAttendedQuestion() {
      return this.psychologistServiceQA.sessionAttended.question[0];
    },
    sessionAttendedAnswers() {
      return this.psychologistServiceQA.sessionAttended.answers;
    },
    sessionTypeQuestion() {
      return this.psychologistServiceQA.sessionType.question[0];
    },
    sessionTypeAnswers() {
      return this.psychologistServiceQA.sessionType.answers;
    },
    demandQuestion() {
      return this.psychologistServiceQA.demand.question[0];
    },
    demandAnswers() {
      return this.psychologistServiceQA.demand.answers;
    },
    diagnosisQuestion() {
      return this.psychologistServiceQA.diagnosis.question[0];
    },
    diagnosisAnswers() {
      return this.psychologistServiceQA.diagnosis.answers;
    },
    neuroDisorderQuestion() {
      return this.psychologistServiceQA.neuroDisorder.question[0]
    },
    neuroDisorderAnswers() {
      return this.psychologistServiceQA.neuroDisorder.answers;
    },
    eatingDisorderQuestion() {
      return this.psychologistServiceQA.eatingDisorder.question[0];
    },
    eatingDisorderAnswers() {
      return this.psychologistServiceQA.eatingDisorder.answers;
    },
    currentPsychTreatmentQuestion(){
      return this.psychologistServiceQA.currentPsychTreatment.question[0];  
    },
    currentPsychTreatmentAnswers(){
      return this.psychologistServiceQA.currentPsychTreatment.answers;      
    },
    psychTreatmentQuestion() {
      return this.psychologistServiceQA.psychTreatment.question[0]; 
    },
    psychTherapistNameQuestion() {
      return this.psychologistServiceQA.psychTherapistName.question[0];
    },
    psychDisorderQuestion() {
      return this.psychologistServiceQA.psychDisorder.question[0];
    },
    psychDisorderAnswers() {
      return this.psychologistServiceQA.psychDisorder.answers;
    },
    sessionOverviewQuestion() {
      return this.psychologistServiceQA.sessionOverview.question[0];
    },
    addictiveDisorderQuestion() {
      return this.psychologistServiceQA.addictiveDisorder.question[0];
    },
    addictiveDisorderAnswers() {
      return this.psychologistServiceQA.addictiveDisorder.answers;
    },
    personalityDisorderQuestion() {
      return this.psychologistServiceQA.personalityDisorder.question[0];
    },
    personalityDisorderAnswers() {
      return this.psychologistServiceQA.personalityDisorder.answers;
    },
    anxietyDisorderQuestion() {
      return this.psychologistServiceQA.anxietyDisorder.question[0];
    },
    anxietyDisorderAnswers() {
      return this.psychologistServiceQA.anxietyDisorder.answers;
    },
    referThirdPartyQuestion() {
      return this.psychologistServiceQA.referThirdParty.question[0];
    },
    referThirdPartyAnswers() {
      return this.psychologistServiceQA.referThirdParty.answers;
    },
    recommendationsQuestion() {
      return this.psychologistServiceQA.recommendations.question[0];
    },
    generalEvolutionQuestion(){
      return this.psychologistServiceQA.generalEvolution.question[0];
    },
    generalEvolutionAnswers(){
      return this.psychologistServiceQA.generalEvolution.answers;
    },
    commentsQuestion() {
      return this.psychologistServiceQA.comments.question[0];
    },
    closureReasonQuestion() {
      return this.psychologistServiceQA.closureReason.question[0];
    },
    closureReasonAnswers() {
      return this.psychologistServiceQA.closureReason.answers;
    },
    shouldShowReferral() {
      return this.beniAttendedSession && this.referThirdParty.includes('Other') ||
      (this.beniAttendedSession && this.referThirdParty.includes('Psychiatrist')) || 
      (this.beniAttendedSession && this.referThirdParty.includes('Mental Health Case Management'))
    }
  },
  watch: {
    diagnosisOther(oldVal, val) {
      this.resultDiagnosisOther = val;
    },
    diagnosis(val) {
      this.resultDiagnosisOther = val;
    },
    closureReason(val) {
      this.resultClosureReasonOther = val;
    },
    closureReasonOther(val) {
      this.resultClosureReasonOther = val;
    },
  },
};
</script>
