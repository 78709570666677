<template>
  <transition>
    <div
      :class="{
        'form-page-container': withFooter,
        'regular-page-container': !withFooter,
      }"
    >
      <div v-if="!isTableMode"
        :class="{ 'no-padding': noPadding}"
        class="content-wrap px-8 pt-4 md:px-20 md:pt-12 lg:px-20 lg:pt-16 xl:px-20 xl:pt-10"
      >
        <slot></slot>
      </div>
       <div v-else
        :class="{ 'no-padding': noPadding,  'table-mode': isTableMode}"
        class="content-wrap"
      >
        <slot></slot>
      </div>
      <footer v-if="withFooter">
        <img
          class="footer-img"
          src="@/assets/images/form-footer.jpeg"
          alt="form-footer"
        />
      </footer>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AbContentContainer',
  props: {
    withFooter: {
      default: false,
    },
    noPadding: {
      default: false,
    },
    isTableMode: {
      type: Boolean
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  position: relative;
  min-height: 40vh;
}

.form-page-container {
  position: relative;
  min-height: 93vh;
}

.content-wrap {
  padding-bottom: 3rem;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
}

.footer-img {
  display: block;
  height: 80px;
  margin-top: 40px;
}
.no-padding {
  padding: 0;
}
</style>
