import axios from "@/http/http-common";
import Vue from "vue";

class PcapFollowupServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/financial/formoptions/pcapfollowup", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewPcapFollowupService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/financial/create/pcapfollowup", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updatePcapFollowupService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/financial/update/pcapfollowup", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  async getPcapServices(gbvmis) {
    const accessToken = await new Vue().$auth.getTokenSilently();
            
    return await axios.get("/management/editables/get/", {
        params:{
            gbvmis: gbvmis,
        },
        headers: {
            Authorization: `Bearer ${accessToken}`,
        }
      })
  }
}

export default new PcapFollowupServiceService();
