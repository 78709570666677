<template>
  <div class="container-fluid">
    <div class="row header-wrapper">
      <div class="wrapper col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div
          class="nav-bar"
          :class="{ 'nav-bar-border-bottom': !isHomePage || isActivityPage || isTrainingPage }"
        >
          <b-dropdown
            toggle-class="user-menu"
            id="user-menu"
            variant="none"
            :text="getUserInitials()"
          >
            <b-dropdown-item @click="logout()">LogOut</b-dropdown-item>
          </b-dropdown>
          <b-icon
            id="main-go-back"
            v-if="!isHomePage || isActivityPage || isTrainingPage"
            @click="goBack()"
            class="go-back"
            icon="arrow-left-circle-fill"
          >
          </b-icon>
          <router-link
            v-if="!isHomePage || isActivityPage || isTrainingPage"
            to="/"
            @click.native="routeToHome"
          >
            <img
              class="abaad-navbar-en-ar"
              src="@/assets/images/abaad-logo-ar-en.png"
              alt=""
            />
            <p class="aiomis-navbar-logo">AIOMIS</p>
          </router-link>
        </div>
        <header v-if="isHomePage || isActivityPage || isTrainingPage" class="col-lg-12">
          <div
            :class="{ 'mt-24': isHomePage, 'mt-40': isActivityPage || isTrainingPage }"
            class="d-flex align-items-center justify-content-center"
          >
            <router-link to="/" @click.native="routeToHome">
              <p
                id="aimois-logo"
                :class="{ 'pull-down': isActivityPage || isTrainingPage}"
                class=" mt-1 font-semibold xs:text-4xl sm:text-4xl md:text-6xl"
              >
                AIOMIS
              </p>
            </router-link>
          </div>
          <div class="row search-wrapper" v-if="isHomePage && !isActivityPage && !isTrainingPage ">
            <div class="ab-search">
              <ab-search
                type="text"
                class="mt-1 d-flex align-items-center justify-content-center"
                placeholder="Search for beneficiary..."
              ></ab-search>
            </div>
          </div>
        </header>
      </div>
    </div>
  </div>
</template>

<script>
import AbSearch from "@/components/abaad/AbSearch/AbSearch";
import { EventBus } from "@/Events/event-bus.js";
export default {
  components: {
    AbSearch,
  },
  data() {
    return {
      userOptions: [],
      userOption: "",
    };
  },
  mounted() {
    let lastScrollTop = 0;
    let navBar = document.querySelector(".nav-bar.nav-bar-border-bottom");
    document.addEventListener("scroll", () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (!st > lastScrollTop && navBar) {
        navBar.style.marginTop = "0";
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    });
  },
  computed: {
    isHomePage() {
      return this.$route.path === "/" || this.$route.path === "/activities";
    },
    isActivityPage() {
      return this.$route.path === "/activities";
    },
    isTrainingPage() {
      console.log("🚀 ~ this.$route.path === /training:", this.$route.path === "/training");
      return this.$route.path === "/training";
    }
  },
  methods: {
    getUserInitials() {
      let userName = JSON.parse(localStorage.getItem("userInfo")).name;
      return userName
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
    routeToHome() {
      EventBus.$emit("toMain", true);
    },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // go back one page
    goBack() {
      this.$router.go(-1);
      // console.log("🚀 ~ this.userOnRegister()", this.userOnRegister());
      // console.log("🚀 ~ this.userOnProfile()", this.userOnProfile());
      // if (!this.isHomePage && this.$route.fullPath.includes("/activities/")) {
      //   EventBus.$emit("goingBackToActivities");
      //   this.$router.push("/activities");
      // }
      // if (!this.isHomePage && this.$route.fullPath.includes("/activities")) {
      //   this.$router.push("/");
      // }

      // if (
      //   this.userOnProfile() ||
      //   this.userOnRegister() ||
      //   (!this.isHomePage && this.$route.fullPath == "/activities")
      // ) {
      //   this.$router.push("/");
      // } else {
      //   this.$router.push("/bene/" + this.$route.params.id);
      // }
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    userOnProfile() {
      return this.$route.path == "/bene/" + this.$route.params.id;
    },
    userOnRegister() {
      return this.$route.path == "/bene/create";
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  padding: 0 !important;
  .nav-bar {
    height: 56px;
    display: flex;
  }
  a {
    margin-top: 7px;
  }
  .nav-bar-border-bottom {
    border-bottom: solid 1px #ddd;
    position: fixed;
    width: 99vw;
    background-color: #fff;
    z-index: 100;
  }
  .ab-search {
    margin-left: -10px;
  }
  .search-wrapper {
    max-width: 700px;
    margin: 0 auto;
  }
}
@media (min-width: 1200px) {
  #user-menu.dropdown.b-dropdown.btn-group {
    margin: 13px 1% 5px auto;
  }
}
#user-menu {
  background-color: #8dd4de;
  border-color: #8dd4de;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  margin: 13px 2% 5px auto;
  order: 3;

  &:hover {
    background-color: #2399a4;
    border-color: #2399a4;
  }
  ::after {
    display: none;
  }

  #user-menu__BV_toggle_.btn.dropdown-toggle.btn-none.user-menu {
    color: #fff !important;
    padding: 0;
    box-shadow: none;
  }

  ul {
    right: 10px;
  }
}

.go-back {
  font-size: 2rem;
  color: #8dd4de;
  margin: 13px 10px;
  width: 30px;
  height: 30px;
  &:hover {
    color: #2399a4;
    cursor: pointer;
  }
}

.router-link-active {
  .abaad-navbar-en-ar,
  .aiomis-navbar-logo {
    display: inline-block;
  }
}
.abaad-navbar-en-ar {
  width: 40px;
  height: 40px;
  margin: -15px 10px 0px;
}

.aiomis-navbar-logo {
  color: #8dd4de;
  font-size: 2rem;
  margin: -4px 5px;
  font-weight: 400;
  width: 10%;
  &:hover {
    color: #2399a4;
    cursor: pointer;
  }
}
.abaad-logo {
  width: 50px;
  height: 50px;
  margin: 0;
}
.logout {
  position: absolute;
  color: #8dd4de;
  top: 0;
  right: 0;
  color: black;
}

#aimois-logo {
  color: #8dd4de !important;
  font-size: 5rem;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@keyframes collapse {
  0% {
    height: 80%;
  }
  100% {
    height: 20%;
  }
}

@keyframes expand {
  0% {
    height: 20%;
  }
  100% {
    height: 80%;
  }
}

@keyframes fade-in-primary {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-secondary {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
