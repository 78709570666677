import axios from "@/http/http-common";
import Vue from "vue";

class ProtectionServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/protectionservice", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewProtectionService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/create/protection", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateProtectionService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/update/protection", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new ProtectionServiceService();
