import axios from "../http/http-common";
import Vue from "vue";
import http from "@/http/http-common";

class SocioDemoService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/socdemo", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getGbvData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/gbv", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getCpGbvData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/cpgbv", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getNonCpGbvData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/noncpgbv", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getNonGbvData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/nongbv", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async saveSocioDemo(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/socdemo/create", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateSocioDemo(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/socdemo/update", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async saveCPGBV(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/survivorinfo/create/cpgbv", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async saveGBV(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/survivorinfo/create/gbv", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async saveNonCPGBV(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/survivorinfo/create/noncpgbv", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async saveNonGBV(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/survivorinfo/create/nongbv", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updateCase(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/survivorinfo/update", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getSingleCase(id) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return http.post("/survivorinfo/id/" + id,null, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getSingleSocialDemo(id) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return http.post("/socdemo/id/" + id,null, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getRelevantCaseForSocialDemo(id) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return http.post("/socdemo/related/case/" + id,null, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getRelevantSocialDemoForCase(id) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return http.post("/survivorinfo/related/socdemo/" + id,null, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

}

export default new SocioDemoService();
