<template>
  <div>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#2399A4"
    ></pulse-loader>
    <div class="pt-2" v-else>
      <div>
        <div class="flex justify-between max-w-2xl mx-auto">
          <p class="font-semibold text-xl">Beneficiary Management</p>
        </div>
      </div>
      <div class="flex justify-center mt-8">
        <table
          class="shadow-lg table table-hover"
          title="Click on the item you wish to see/edit"
        >
          <caption>
            Click on the item you wish to see/edit<br /><br />
          </caption>
          <thead>
            <th class="border px-2 py-2 font-medium-bold">Type</th>
            <th class="border px-2 py-2 font-medium-bold">Created By</th>
            <th class="border px-2 py-2 font-medium-bold">Created At</th>
            <th class="border px-2 py-2 font-medium-bold">Submit Date</th>
            <th class="border px-2 py-2 font-medium-bold">Last Updated By</th>
            <th class="border px-2 py-2 font-medium-bold"></th>
          </thead>

          <tbody>
            <tr
              v-for="mem in managementEditableItems"
              :key="mem.id"
              @click="routeToItem($event, mem)"
            >
              <td class="table-cell border border-aqua px-2 py-2">
                {{ mem.type }}
              </td>
              <td class="table-cell border px-2 py-2">
                {{ checkIfNotEmpty(mem.createdBy) ? mem.createdBy.name : "-" }}
              </td>
              <td class="table-cell border px-2 py-2">
                {{
                  checkIfNotEmpty(mem.createdBy) ? mem.createdBy.center : "-"
                }}
              </td>
              <td class="table-cell border px-2 py-2">
                {{ getTime(mem.submittedDate) }}
              </td>
              <td class="table-cell border px-2 py-2">
                {{
                  checkIfNotEmpty(mem.updatedByLast)
                    ? mem.updatedByLast.name
                    : "-"
                }}
              </td>
              <td
                class="table-cell border px-4 py-2 table-button delete"
                @click="triggerDelete(mem)"
              >
                <span class="uil-user-minus"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ab-not-found
        v-if="!managementEditableItems.length && !isLoading"
      ></ab-not-found>
    </div>
    <div class="mt-8">
      <div class="flex justify-center items-center" v-if="!isLoading">
        <ab-button
          @click="routeToNavigation()"
          :cancel="true"
          label="Back"
        ></ab-button>
      </div>
    </div>
    <footer class="footer">
      <img
        class="footer-img"
        src="@/assets/images/form-footer.jpeg"
        alt="form-footer"
      />
    </footer>
  </div>
</template>

<script>
import BenisDataService from "@/features/beneficiaries/BenisDataService";
import SocioDemoService from "@/shared/SocioDemoService.js";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbNotFound from "@/components/abaad/AbNotFound/AbNotFound";
import ManagementService from "./ManagementService";
import BaseServiceService from "./BaseServiceService";
import FinancialBaseServiceService from "./FinancialBaseServiceService";
import PulseLoader from "vue-spinner/src/PulseLoader";

export default {
  name: "FamilyComp",
  components: {
    AbNotFound,
    AbButton,
    PulseLoader,
  },
  created() {
    this.gbvmis = this.$route.params.id;
    this.getManagementEditableItems();
  },
  updated() {
    let scrollPos = parseInt(localStorage.getItem("scroll_position"));
    if(!!scrollPos) {
      window.scrollTo(0, parseInt(scrollPos));
    }

    setTimeout(() => {
      localStorage.removeItem("scroll_position")
    }, 1000)
  },
  data() {
    return {
      gbvmis: "",
      managementEditableItems: [],
      newMember: {},
      errors: {},
      error: "",
      isLoading: false,
    };
  },
  methods: {
    routeToNavigation() {
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    routeToItem(event, mem) {
      let firstElm = event.target.firstChild;
      if (!event.target.outerHTML.startsWith("<td")) {
        firstElm = event.target.parentNode.firstChild;
      }
      const firstElmClasses =
        firstElm && firstElm.classList
          ? firstElm.classList.contains("uil-user-minus")
          : false;
      if (firstElmClasses) return;

      const rawType = mem.type.split("-")[0].trim();
      let caseToEdit, socDemoToEdit;
      switch (rawType) {
        case "Basic Info":
          BenisDataService.getSingleBene(mem.id).then((res) => {
            // return this.$router.push({path: `/activities`});
            this.$router.push({
              name: "/bene/create",
              params: { editBeni: res.data },
            });
          });
          break;
        case "Case":
          //getSingleCase
          SocioDemoService.getSingleCase(mem.id).then((res) => {
            caseToEdit = res.data;
            SocioDemoService.getRelevantSocialDemoForCase(mem.id).then(
              (res) => {
                socDemoToEdit = res.data;
                this.$router.push({
                  name: `socio-demo`,
                  params: {
                    id: this.$route.params.id,
                    editCase: caseToEdit,
                    editSocDemo: socDemoToEdit,
                  },
                });
              }
            );
          });
          break;
        case "Service":
          // eslint-disable-next-line no-case-declarations
          const serviceType =
            mem.type.split("-")[1].charAt(1).toLowerCase() +
            mem.type.split("-")[1].slice(2);
          BaseServiceService.getServiceById(mem.id).then((res) => {
            this.$router.push({
              name: "new-" + serviceType,
              params: { editService: res.data },
            });
          });
          break;
        case "Financial Service":
          // eslint-disable-next-line no-case-declarations
          const financialServiceType =
            mem.type.split("-")[1].charAt(1).toLowerCase() +
            mem.type.split("-")[1].slice(2);
          FinancialBaseServiceService.getFinancialServiceById(mem.id).then(
            (res) => {
              this.$router.push({
                name: "new-" + financialServiceType.replace(/\s+/g, "-"),
                params: { editService: res.data },
              });
            }
          );
          break;
        case "Social Demographic Info":
          SocioDemoService.getSingleSocialDemo(mem.id).then((res) => {
            socDemoToEdit = res.data;
            SocioDemoService.getRelevantCaseForSocialDemo(mem.id).then(
              (res) => {
                caseToEdit = res.data;
                this.$router.push({
                  name: `socio-demo`,
                  params: {
                    id: this.$route.params.id,
                    editCase: caseToEdit,
                    editSocDemo: socDemoToEdit,
                  },
                });
              }
            );
          });
          break;
      }
    },
    triggerDelete(mem) {
      localStorage.setItem("scroll_position", window.pageYOffset)
      if(confirm("Are you sure you want to delete this entry?")) {
        const rawType = mem.type.split('-')[0].trim();
        switch (rawType) {
          case "Basic Info":
            ManagementService.deleteBeneficiary(mem.id).then(() => {
              return this.$router.push({ path: "/" });
            });
            break;
          case "Social Demographic Info":
            ManagementService.deleteSocialDemo(mem.id).then(() => {
              localStorage.removeItem("hasCase");
              this.getManagementEditableItems();
            });
            break;
          case "Case":
            ManagementService.deleteCase(mem.id).then(() => {
              localStorage.removeItem("hasCase");
              this.getManagementEditableItems();
            });
            break;
          case "Service":
            ManagementService.deleteService(mem.id).then(() => {
              this.getManagementEditableItems();
            });
            break;
          case "Financial Service":
            ManagementService.deleteFinancialService(mem.id).then(() => {
              this.getManagementEditableItems();
            });
            break;
        }
      }
    },
    getManagementEditableItems() {
      this.isLoading = true;
      ManagementService.getManagementEditableItems(this.gbvmis)
        .then((response) => {
          this.managementEditableItems = JSON.parse(
            JSON.stringify(response.data)
          );
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    checkIfNotEmpty(obj) {
      return obj !== null && obj !== undefined && !!obj;
    },
    confrimDelete(string) {
      window.confirm(string);
    },
    getTime(timestamp) {
      var date = new Date(timestamp);

      return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.table > :not(:first-child) {
  border-top: 0 solid currentColor;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 180px;
}

.table-cell {
  cursor: pointer;
}

tr:hover {
  .delete {
    color: #fff;
  }
}

.table-button {
  font-size: 24px;
  color: #fff;
  cursor: pointer;

  &.delete {
    background-color: #dc3545;
  }
}
</style>
