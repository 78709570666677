<template>
  <ab-content-container class="pt-1">
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      color="#2399A4"
      class="loader"
    ></pulse-loader>
    <div v-else>
      <div class="max-w-2xl mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div v-for="(beniQField, index) in qaData" :key="beniQField.id">
            <div
              v-if="
                index != 'unhcrRegistered' &&
                  index != 'Occuancy' &&
                  index != 'fullname' &&
                  index != 'dob' &&
                  index != 'disability'
              "
            >
              <ab-card
                :answer="beniData[index]"
                :question="beniQField"
              ></ab-card>
            </div>
            <div v-if="beniQField == 'Full Name'">
              <ab-card
                :answer="beniData['fullName']"
                :question="beniQField"
              ></ab-card>
            </div>
            <div v-if="index === 'disability'">
              <ab-card
                :answer="getDisabilityValues(beniData[index])"
                :question="'Disability'"
              ></ab-card>
            </div>
            <div v-if="index === 'dob'">
              <ab-card
                :answer="formatDate(beniData['dateBirth'])"
                :question="beniQField"
              ></ab-card>
            </div>
            <ab-card
              v-if="index == 'Occuancy'"
              :answer="beniData[index].toString()"
              :question="beniQField"
            ></ab-card>
            <div v-if="index == 'unhcrRegistered'">
              <ab-card
                :answer="getIsUNHCRRegistered(beniData['isUNHCRRegistered'])"
                :question="beniQField"
              ></ab-card>
            </div>
          </div>
        </div>
        <button
          class="block mx-auto mt-10 py-2 px-20 cancel text-white rounded mt-2
           active:transform active:translate-y-1 active:outline-none hover:cancel-hover"
        @click="goBack()">
          Back
        </button>
      </div>
    </div>
  </ab-content-container>
</template>
<script>
import AbCard from "@/components/abaad/AbCard/AbCard";
import PulseLoader from "vue-spinner/src/PulseLoader";
import Vue from "vue";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer.vue";
import AddBeneficiaryDataService from "./AddActivityBeneficiaryDataService";
import moment from "moment";

export default {
  name: "ActivityUserInfo",
  components: {
    AbCard,
    PulseLoader,
    AbContentContainer,
  },
  data() {
    return {
      isLoading: false,
      beniData: {},
      qaData: {},
    };
  },
  beforeMount() {
    this.getQaData();
    this.getBeniData();
  },
  methods: {
    async getBeniData() {
      let beneficiaryId = JSON.parse(
        localStorage.getItem("ActivityBeneficiary")
      ).id;
      const accessToken = await new Vue().$auth.getTokenSilently();

      let response = await fetch(
        `https://api.abaadmena.online/v1/activities/benificiaries/id/${beneficiaryId}`,
        {
          method: "post",
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
          }),
        }
      );
      response.json().then((data) => {
        this.beniData = data;
        delete this.beniData.createdBy;
        delete this.beniData.createdByCenter;
        delete this.beniData.createdDate;
      });
    },
    getIsUNHCRRegistered(isUNHCTRegistered) {
      return isUNHCTRegistered ? "Yes" : "No";
    },
    getDisabilityValues(data) {
      let values = [];

      for (let i = 0; i < data?.length; i++) {
        if (data[i]) values.push(data[i] + " ");
      }

      return values.toString();
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getQuestions(data) {
      for (let property in data) {
        this.qaData[property] = data[property].question[0];
      }
    },
    goBack() {
        history.back();
    },
    async getQaData() {
      this.isLoading = true;
      await AddBeneficiaryDataService.getQaData()
        .then((response) => {
          this.qaData = response.data;
          console.log("🚀 ~ response.data;", response.data);
          this.getQuestions(response.data);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
