<template>
  <div class="pt-10 max-w-4xl mx-auto">
        <router-view>
        </router-view>
  </div>
</template>
<script>
export default {
  components: {
  }
};
</script>
