// import Vue from "vue";
// import axios from "@/http/http-common";
import SocioDemoService from "@/shared/SocioDemoService.js";

const state = {
	socioDemoQA: {},
	socioDemoData: {},
	gbvCaseQA: {},
};
const getters = {
	socioDemoQA: (state) => state.socioDemoQA,
	socioDemoData: (state) => state.socioDemoData,
	gbvCaseQA: (state) => state.gbvCaseQA
};
const actions = {
	async getSocioDemoQAData({ commit }) {
		if (Object.keys(this.getters.socioDemoQA).length === 0) {
			return await SocioDemoService.getQaData()
				.then((response) => {
					commit('setSocioDemoQA', response.data);
				})
				.catch((error) => console.log(error))
		} else {
			commit('setSocioDemoQA', this.getters.socioDemoQA);
		}
	},
	async getGbvCaseQAData({commit}) {
		return await SocioDemoService.getGbvData()
		.then((response) => {
			localStorage.setItem('gbvQuestionsAnswersData', JSON.stringify(response.data));
			commit('setGbvCaseQA', response.data)
		})
	},
	async updateCaseData({ commit }, caseManagement) {
		commit;
		return await SocioDemoService.updateCase(caseManagement);
	},
	async saveSocioDemoData({ commit }, socioDemo) {
         commit;
		return await SocioDemoService.saveSocioDemo(socioDemo);
	},
	async updateSocioDemoData({ commit }, socioDemo) {
         commit;
		return await SocioDemoService.updateSocioDemo(socioDemo);
	},
	async saveGBVData({ commit }, caseManagement) {
         commit;
		return await SocioDemoService.saveGBV(caseManagement);
	},
	async saveNonGBVData({ commit }, caseManagement) {
         commit;
		return await SocioDemoService.saveNonGBV(caseManagement);
	},
	async saveNonCPGBVData({ commit }, caseManagement) {
         commit;
		return await SocioDemoService.saveNonCPGBV(caseManagement);
	},
	async saveCPGBVData({ commit }, caseManagement) {
         commit;
		return await SocioDemoService.saveCPGBV(caseManagement);
	}
};
const mutations = {
	setSocioDemoData: (state, socioDemoData) => state.socioDemoData = socioDemoData,
	setSocioDemoQA: (state, socioDemoQA) => state.socioDemoQA = socioDemoQA,
	setGbvCaseQA: (state, gbvCaseQA) => state.gbvCaseQA = gbvCaseQA
};

export default {
	state,
	getters,
	actions,
	mutations
}