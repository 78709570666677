<template>
  <div>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#2399A4"
    ></pulse-loader>
    <div v-else>
      <ab-content-container>
        <p class="font-semibold text-xl">Medical Service</p>
        <form class="flex flex-col mx-auto max-w-2xl"
          @submit.prevent="!editService ? addMedicalService() : updateMedicalService()">
          <div class="mt-6">
            <p class="text-sm mb-1 font-semibold">Date of Referral</p>
            <ab-input v-model="submittedDate" :full="true" type="date"></ab-input>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ referralQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in medicalReferralAnswers"
              :key="index"
              v-model="medicalReferral"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ "Type of Medical Services - نوع الخدمات الطبية" }}</p>
            <ab-radio
              v-for="(answer, index) in medicalReferralTypeAnswers"
              :key="index"
              v-model="medicalReferralType"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
            <ab-input
              v-if="medicalReferralType.includes('Other (Please Specify)')"
              v-model="medicalReferralTypeOther"
              :full="true"
              label="Other (Please specify)"
            ></ab-input>
          </div>

          <div class="mt-12" v-if="medicalReferral.includes('Yes\\External Referral')">   
            <p class="font-semibold text-sm mb-1">
              {{ medicalReferralResponseQuestion }}
            </p>
              <ab-radio
              v-for="(answer, index) in medicalReferralResponseAnswers"
              :key="index"
              v-model="medicalReferralResponse"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>

          <div class="mt-12" v-if="medicalReferral.includes('Yes\\External Referral') && medicalReferralResponse.includes('Declined/Not Accepted')">
            <p class="font-semibod text-sm mb-1">{{medicalReferralResponseWhyQuestion}}</p>
            <ab-input v-model="medicalReferralAgency" :full="true"></ab-input>
          </div>

          <div class="mt-12" v-if="medicalReferral.includes('Yes\\External Referral') && medicalReferralResponse.includes('Approved')">
            <p class="font-semibold text-sm mb-1">{{medicalReferralAgencyQuestion}}</p>
            <ab-input v-model="medicalReferralAgency" :full="true"></ab-input>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{medicalReferralDetailsQuestion}}</p>
            <ab-input
              v-model="medicalReferralDetails"
              :full="true"
            ></ab-input>
          </div>

          <div class="mt-12">
            <div
              v-if="!isLoading"
              class="flex flex-col justify-center items-center"
            >
              <ab-button
                class="mt-2 submit-btn"
                label="submit"
                @click="
                  !editService ? addMedicalService() : updateMedicalService()
                "
              ></ab-button>
              <ab-button
                :cancel="true"
                class="mt-2"
                label="cancel"
                @click="routeToNavigation()"
              ></ab-button>
            </div>
          </div> 
        </form>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import vue from 'vue';
import MedicalServiceService from "./MedicalServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import moment from "moment";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex'
vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-medical-service",
  mixins: [utilMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getMedicalServiceQA();
    this.gbvmis = this.$route.params.id;
     if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 
  },
  data() {
    return {
      gbvmis: "",
      submittedDate: "",
      medicalReferral: "",
      medicalReferralType: "",
      medicalReferralTypeOther: "",
      medicalReferralResponse: "",
      medicalReferralResponseWhy: "",
      medicalReferralAgency: "",
      medicalReferralDetails: "",
      latestCounselingServiceDate: "",
      medicalServiceQA: {},
      otherReferralType: "",
      resultReferralType: "",
      errors: {},
      error: "",
      isLoading: false,
    };
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: (value) => {
          return value >= this.latestCounselingServiceDate;
        }, 
        maxValue: (value) => {
          return value < this.tomorrowsDate;
        }
      },
      medicalReferral: {
        required
      },
      medicalReferralType: {
        required
      },
      medicalReferralTypeOther: {
        rquired: requiredIf(() => this.medicalReferralTypeOtherAndNoAnswer())
      },
      medicalReferralResponse: {
        rquired: requiredIf(() => this.medicalReferralExternalAndNoResponse())
      }
    }
  },
  methods: {
    ...mapActions(['fetchAllServices']),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getMedicalServiceQA() {
      this.isLoading = true;
      MedicalServiceService.getQaData()
        .then((response) => {
          this.medicalServiceQA = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD");
      this.gbvmis = this.editService.gbvmis;
      this.medicalReferral = this.editService.medicalReferral;
      this.medicalReferralResponse = this.editService.medicalReferralResponse;
      this.medicalReferralAgency = this.editService.medicalReferralAgency;
      this.medicalReferralDetails = this.editService.medicalReferralDetails;
      this.medicalReferralType = this.ammendEditCaseOtherInput(
        this.medicalReferralTypeAnswers,
        this.editService.medicalReferralType,
        "Other (Please Specify)"
      ).then((result) => {
        this.medicalReferralTypeOther = result[1];
        this.medicalReferralType = [result[0]];
      });
    },
    addMedicalService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
        this.isLoading = true;
        const newMedicalService = this.getNewMedicalService();
        MedicalServiceService.addNewMedicalService(newMedicalService)
          .then(() => this.$router.push({ path: `/bene/${this.gbvmis}` }))
          .catch((error) => console.log(error))
          .finally(() => (this.isLoading = false));
      }
    },
    updateMedicalService() {
      this.isLoading = true;
      // nullify object values based on selection
      MedicalServiceService.updateMedicalService(this.getNewMedicalService())
        .then(() => {
          this.$router.push({
            path: `/bene/${this.$route.params.id}/management`,
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    catchErrors() {
      this.errors = [];

      if (!this.submittedDate) {
        this.errors.push("Submitted Date is Required");
      }
      if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }
      if (!this.medicalReferral) {
        this.errors.push("Medical Referral is Required");
      }
      if (!this.medicalReferralType) {
        this.errors.push("Medical Referral Type is Required");
      }
      if(this.medicalReferralTypeOtherAndNoAnswer()) {
        this.errors.push("Medical Referral Type Other is Required");
      }
      if (this.medicalReferralExternalAndNoResponse()) {
        this.errors.push("Referral Response is Required");
      }

      this.handleArrayErrors(this.errors)
    },
    getNewMedicalService() {
      let newMedicalService = {};
      if (!!this.editService) newMedicalService.id = this.editService.id;
      newMedicalService.gbvmis = this.gbvmis;
      newMedicalService.submittedDate = this.submittedDate;
      newMedicalService.medicalReferral = this.medicalReferral;
      newMedicalService.medicalReferralResponse = this.medicalReferralResponse;
      newMedicalService.medicalReferralTypeOther = this.resultMedicalReferralTypeOther;
      newMedicalService.medicalReferralAgency = this.medicalReferralAgency;
      newMedicalService.medicalReferralDetails = this.medicalReferralDetails;
      newMedicalService.medicalReferralType = this.resultMedicalReferralTypeOther
      newMedicalService.serviceType = "medical";

      return newMedicalService;
    },
   dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    medicalReferralTypeOtherAndNoAnswer() {
      return !!this.medicalReferralType && this.medicalReferralType.includes('Other (Please Specify)') && !this.medicalReferralTypeOther
    },
    medicalReferralExternalAndNoResponse() {
      return !this.medicalReferralResponse && this.medicalReferral.includes("Yes\\External Referral");
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
  },
  watch: {
    medicalReferralType(val) {
      this.resultMedicalReferralTypeOther = val;
    },
    medicalReferralTypeOther(val) {
      // this val the code that will use as data to post
      this.resultMedicalReferralTypeOther = val;
    },
    referralType(val) {
      this.resultReferralType = val;
    },
    otherReferralType(val) {
      this.resultReferralType = val;
    },
  },
  computed: {
    ...mapGetters(['allServices']),
    shouldShowOtherReferralTypeInput() {
      return this.referralType.toLowerCase().includes("please specify");
    },
    referralQuestion() {
      return this.medicalServiceQA.medicalReferral.question[0];
    },
    medicalReferralAnswers() {
      return this.medicalServiceQA.medicalReferral.answers;
    },
    medicalReferralTypeQuestion() {
      return this.medicalServiceQA.medicalReferralType.question[0];
    },
    medicalReferralTypeAnswers() {
      return this.medicalServiceQA.medicalReferralType.answers;
    },
    medicalReferralResponseQuestion() {
      return this.medicalServiceQA.medicalReferralResponse.question[0];
    },
    medicalReferralResponseAnswers() {
      return this.medicalServiceQA.medicalReferralResponse.answers;
    },
    medicalReferralAgencyQuestion() {
      return this.medicalServiceQA.medicalReferralAgency.question[0];
    },
    medicalReferralDetailsQuestion() {
      return this.medicalServiceQA.medicalReferralDetails.question[0];
    },
    medicalReferralResponseWhyQuestion() {
      return this.medicalServiceQA.medicalReferralResponseWhy.question[0];
    }
  }
};
</script>
