<template>
  <ab-content-container>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#fff"
    ></pulse-loader>
    <div class="max-w-2xl mx-auto" v-else>
      <div>
        <div class="flex flex-col mt-3 title-wrapper">
          <p class="text-xl font-semibold">Completed Training</p>
          <p class="text-xs text-gray-500 italic">
            This is where we see all trainings
          </p>
        </div>
        <div class="flex flex-col gap-3 mt-6" v-if="trainings.length > 0">
          <div v-for="item in trainings" :key="item.id">
            <div class="single-activity" @click="getTrainingRoute(item)">
              <div
                class="flex items-center bg-primary w-full p-3 rounded shadow-xl cursor-pointer hover:bg-secondary"
              >
                <i class="uil uil-file-landscape-alt text-white text-3xl"></i>
                <p class="text-md ml-2 text-white">
                  {{ "Training: " + item.title }}
                  - {{ "participants: " + item.participants.length }} -
                  {{
                    "Date: " + moment(item.submittedDate).format("YYYY-MM-DD")
                  }}
                </p>
              </div>
              <button
                type="button"
                @click="deleteTraining(item.id, $event, index)"
                class="btn remove"
              >
                <i class="uil uil-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-4">
          <ab-button
            :cancel="true"
            class="mt-1"
            label="Back"
            @click="routeToTraining()"
          ></ab-button>
        </div>
      </div>
    </div>
  </ab-content-container>
</template>

<script>
import TrainingService from "@/features/Training/TrainingService";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import AbButton from "@/components/abaad/AbButton/AbButton";
import PulseLoader from "vue-spinner/src/PulseLoader";

import moment from "moment";
export default {
  name: "CurrentActivities",
  components: {
    AbContentContainer,
    AbButton,
    PulseLoader,
  },
  data() {
    return {
      moment: moment,
      newTrainings: [],
      trainings: [],
      isLoading: false,
    };
  },
  created() {
    this.fetchTrainings();
  },
  methods: {
    routeToTraining() {
      this.$router.push("/training");
    },
    fetchTrainings() {
      this.isLoading = true;
      TrainingService.getAllTrainings()
        .then((response) => {
          response.data.forEach((training) => {
            console.log("🚀 ~ training:", training);
            this.trainings.push(training);
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    getTrainingRoute(training) {
      localStorage.setItem("CurrentTraining", JSON.stringify(training));
      return this.$router.push({
        name: "SingleTraining",
        params: { id: training.id },
      });
    },
    routeToActivityNavigation() {
      // TODO: needs refactor
      return this.$router.push({ path: `/training` });
    },
    deleteTraining(id, e, index) {
      e.stopPropagation();
      if (window.confirm("are you sure you want to delete this activity ?")) {
        TrainingService.deleteTraining(id).then(() => {
          this.trainings.splice(index, 1);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.single-activity {
  display: flex;
  .remove {
    background-color: #dc3545;
    margin-left: 5px;

    i {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.title-wrapper {
  margin-left: -2.5rem;
}
</style>
