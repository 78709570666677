import Vue from 'vue'
import App from './App.vue'
import store from "@/store"
import './assets/styles/index.css';
import router from './router/router';
import Paginate from 'vuejs-paginate';
import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "./auth";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false
// Pagination
Vue.component('paginate', Paginate);
// Auth
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
