<template>
  <div>
    <pulse-loader
      color="#2399A4"
      :loading="isLoading"
      class="loader"
      v-if="isLoading"
    ></pulse-loader>
    <div v-else>
      <ab-content-container>
        <p class="font-semibold text-xl">Protection Service</p>
        <form @submit.prevent="!editService ? addProtectionService() : updateProtectionService()" class="flex flex-col mx-auto max-w-2xl">
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">Date of Referral</p>
            <ab-input :full="true" type="date" v-model="submittedDate"></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ referToPoliceQuestion }}</p>
            <ab-radio 
              class="mb-2" :key="index" :label="answer" :value="answer"
              v-for="(answer, index) in referToPoliceAnswers"
              v-model="referToPolice">
            </ab-radio>
          </div>

          <div class="mt-12" v-if="isNotReferredToPolice()">
            <p class="font-semibold text-sm mb-1">{{referToPoliceWhyQuestion}}</p>
              <ab-radio class="mb-2" :key="index" :label="answer" :value="answer"
                v-for="(answer, index) in referToPoliceWhyAnswers"
                v-model="referToPoliceWhy"
            ></ab-radio>
          </div>

          <div class="mt-12" v-if="isReferredToPolice()">
            <p class="font-semibold text-sm mb-1">{{ policeStationQuestion }}</p>
            <ab-input v-model="policeStation" :full="true"></ab-input>
          </div>
          
           <div class="mt-12" v-if="isReferredToPolice()">
            <p class="font-semibold text-sm mb-1">{{ measuresTakenQuestion }}</p>
             <textarea v-model="measuresTaken" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>

           <div class="mt-12" v-if="isReferredToPolice()">
            <p class="font-semibold text-sm mb-1">Need of legal support and referral?</p>
            <ab-radio 
              class="mb-2" :key="index" :label="answer" :value="answer"
              v-for="(answer, index) in needLegalSupportAnswers"
              v-model="needLegalSupport">
            </ab-radio>
          </div>
          
          <div class="mt-12" v-if="isReferredToPolice()">
            <p class="font-semibold text-sm mb-1">{{ challengesFacedQuestion }}</p>
            <ab-radio class="mb-2" :key="index" :label="answer" :value="answer"
              v-for="(answer, index) in challengesFacedAnswers"
              v-model="challengesFaced"></ab-radio>
          </div>
          
          <div class="mt-12" v-if="isReferredToPolice() && survivorFacedChallenges()">
            <p class="font-semibold text-sm mb-1">{{ challengesFacedWhatQuestion }}</p>
             <textarea v-model="challengesFacedWhat" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>
        
           <div class="mt-12" v-if="ServiceDeclinedBySurvivor()">
            <p class="font-semibold text-sm mb-1">{{ declineReasonQuestion }}</p>
            <ab-radio class="mb-2" :key="index" :label="answer" :value="answer"
              v-for="(answer, index) in declineReasonAnswers"
              v-model="declineReason"></ab-radio>
          </div>

          <div class="mt-12" v-if="ServiceDeclinedBySurvivor() && suvivorDeclinedReasonOther()">
            <p class="font-semibold text-sm mb-1">Other, Please Specify</p>
              <textarea v-model="declineReasonOther" class="w-full h-24 border border-solid border-secondary rounded-sm"></textarea>
          </div>
  
          <div class="mt-12">
            <div class="flex flex-col justify-center items-center" v-if="!isLoading">
              <ab-button @click="!editService ? addProtectionService() : updateProtectionService()"
                class="mt-2 submit-btn" label="submit">
              </ab-button>
              <ab-button @click="routeToNavigation()" class="mt-2" :cancel="true" label="cancel"></ab-button>
            </div>
          </div>

        </form>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import vue from "vue";
import ProtectionServiceService from "./ProtectionServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import moment from "moment";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { mapActions, mapGetters } from 'vuex'
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "new-protection",
  mixins: [utilMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getProtectionServiceQA();
    this.gbvmis = this.$route.params.id;
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }

    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 
  },
  data() {
    return {
      gbvmis: "",
      submittedDate: "",
      referToPolice: "",
      policeStation: "",
      measuresTaken: "",
      needLegalSupport: "",
      challengesFaced: "",
      challengesFacedWhat: "",
      referToPoliceWhy: "",
      declineReason: "",
      declineReasonOther: "",
      tomorrowsDate: "",
      latestCounselingServiceDate: "",
      protectionServiceQa: {},
      errors: {},
      error: "",
      isLoading: false,
    };
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: value => value < this.tomorrowsDate
      },
      referToPolice: {
        required
      },
      needLegalSupport: {
        required: requiredIf(() => this.isReferredToPolice())
      },
      referToPoliceWhy: {
        required: requiredIf(() => this.isNotReferredToPoliceAndNoReason())
      },
      declineReason: {
        required: requiredIf(() => this.isNotReferredToPoliceAndDeclinedBySurvivor())
      }
    }
  },
  methods: {
    ...mapActions(['fetchAllServices']),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getProtectionServiceQA() {
      this.isLoading = true;
      ProtectionServiceService.getQaData()
        .then((response) => {
          this.protectionServiceQa = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD");
      this.referToPolice = this.editService.referToPolice;
      this.policeStation = this.editService.policeStation;
      this.measuresTaken = this.editService.measuresTaken;
      this.needLegalSupport = this.editService.needLegalSupport;
      this.challengesFaced = this.editService.challengesFaced;
      this.challengesFacedWhat = this.editService.challengesFacedWhat;
      this.referToPoliceWhy = this.editService.referToPoliceWhy;
      this.declineReason = this.ammendEditCaseOtherInput(
        this.declineReasonAnswers,
        this.editService.declineReason,
        "Other (Please Specify)")
        .then((result) => {
          this.declineReasonOther = result[1];
          this.declineReason = [result[0]];
        });
    },
    addProtectionService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
         this.catchErrors();
      } else {
        this.isLoading = true;
        const newProtectionService = this.getNewProtectionService();
        ProtectionServiceService.addNewProtectionService(newProtectionService)
          .then(() => this.$router.push({ path: `/bene/${this.gbvmis}` }))
          .catch((error) => console.log(error))
          .finally(() => (this.isLoading = false));
       }
    },
    updateProtectionService() {
        this.$v.$touch();
        if (this.$v.$invalid) {
         this.catchErrors();
        } else {
          this.isLoading = true;
          ProtectionServiceService.updateProtectionService(this.getNewProtectionService())
          .then((response) => {
            this.$router.push({ path: `/bene/${this.$route.params.id}/management`, });
            this.handleSuccessResponse(response);
          })
          .catch((error) => console.log(error))
          .finally(() => (this.isLoading = false));
        }
    },
    handleSuccessResponse(response) {
      if(response.status == 201) {
        let successMessage = "Form Submitted Sucessfully.";
        this.handleSuccess(successMessage);
      }
    },
    getNewProtectionService() {
      let newProtectionService = {};
      if (!!this.editService) newProtectionService.id = this.editService.id;
      newProtectionService.gbvmis = this.gbvmis;
      newProtectionService.submittedDate = new Date(this.submittedDate).toJSON();
      newProtectionService.referToPolice = this.referToPolice;
      newProtectionService.policeStation = this.policeStation;
      newProtectionService.measuresTaken = this.measuresTaken;
      newProtectionService.needLegalSupport = this.needLegalSupport;
      newProtectionService.challengesFaced = this.challengesFaced;
      newProtectionService.challengesFacedWhat = this.challengesFacedWhat;
      newProtectionService.referToPoliceWhy = this.referToPoliceWhy;
      newProtectionService.declineReason = this.resultDeclineReasonOther;
      newProtectionService.serviceType = "protection";

      return newProtectionService;
    },
    catchErrors() {
      this.errors = [];
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
      if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }
      if(!this.referToPolice) {
        this.errors.push("Referral to Police is Required.");
      }
      if(this.isNotReferredToPoliceAndNoReason()) {
        this.errors.push("Reason for Not Referring Survivor should be Filled.")
      }
      if(this.isReferredToPoliceWithLegalSupportEmpty()) {
        this.errors.push("Legal Support is Required");
      }
      if(this.isNotReferredToPoliceAndDeclinedBySurvivor()) {
        this.errors.push("Decline Reason is Required");
      }

      this.handleArrayErrors(this.errors);
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    isReferredToPolice() {
      return !!this.referToPolice && this.referToPolice.includes('Yes');
    },
    isNotReferredToPolice() {
      return !!this.referToPolice && this.referToPolice.includes('No');
    },
    ServiceDeclinedBySurvivor() {
      return this.isNotReferredToPolice() && !!this.referToPoliceWhy && this.referToPoliceWhy.includes('Service declined by survivor');
    },
    suvivorDeclinedReasonOther() {
      return !!this.declineReason && this.declineReason.includes('Other (Please Specify)');
    },
    suvivorDeclinedReasonOtherAndReasonDoesntExist() {
      return this.suvivorDeclinedReasonOther() && !this.declineReasonOther;
    },
    survivorFacedChallenges() {
      return !!this.challengesFaced && this.challengesFaced.includes('Yes');
    },
    suvivorDeclineReasonOther() {
      return !!this.declineReason && !this.declineReason.includes('Other (Please Specify)');
    },
    referralServiceIsUnAvailable() {
      return this.isNotReferredToPolice() && this.referToPoliceWhy.includes('Service unavailable');
    },
    isNotReferredToPoliceAndNoReason() {
      return this.isNotReferredToPolice() && !this.referToPoliceWhy;
    },
    isReferredToPoliceWithLegalSupportEmpty() {
      return this.isReferredToPolice() && !this.needLegalSupport;
    },
    isNotReferredToPoliceAndDeclinedBySurvivor() {
      return (
        this.isNotReferredToPolice() &&
        this.ServiceDeclinedBySurvivor());
    }
  },
  watch: {
    declineReason(val) {
      this.resultDeclineReasonOther = val;
    },
    declineReasonOther(val) {
      this.resultDeclineReasonOther = val;
    }
  },
  computed: {
    ...mapGetters(['allServices']),
    referToPoliceQuestion() {
      return this.protectionServiceQa.referToPolice.question[0];
    },
    referToPoliceAnswers() {
      return this.protectionServiceQa.referToPolice.answers;
    },
    policeStationQuestion() {
      return this.protectionServiceQa.policeStation.question[0];
    },
    measuresTakenQuestion() {
      return this.protectionServiceQa.measuresTaken.question[0];
    },
    needLegalSupportQuestion() {
      return this.protectionServiceQa.needLegalSupport.question[0];
    },
    needLegalSupportAnswers() {
      return this.protectionServiceQa.needLegalSupport.answers;
    },
    challengesFacedQuestion() {
      return this.protectionServiceQa.challengesFaced.question[0];
    },
    challengesFacedAnswers() {
      return this.protectionServiceQa.challengesFaced.answers;
    },
    challengesFacedWhatQuestion() {
      return this.protectionServiceQa.challengesFacedWhat.question[0];
    },
    referToPoliceWhyQuestion() {
      return "Why?";
    },
    referToPoliceWhyAnswers() {
      return this.protectionServiceQa.referToPoliceWhy.answers;
    },
    declineReasonQuestion() {
      return "Decline reason?";
    },
    declineReasonAnswers() {
      return this.protectionServiceQa.declineReason.answers;
    }
  },
};
</script>
