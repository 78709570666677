<template>
  <div>
    <StatisfactionForm :activity="activity" :form-model="'PSS'" :editService="$route.params.editService"/>
  </div>
</template>
<script>
import StatisfactionForm from "./StatisfactionForm/StatisfactionForm.vue";
export default {
  name: "PssSatisfactionForm",
  components: {
    StatisfactionForm,
  },
  props: {
    activity: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    window.scroll(0, 0);
  }
};
</script>