import Vue from "vue";
import axios from "@/http/http-common";

const state = {
    benis: [],
    searchBenis: [],
};
const getters = {
    allBenis: (state) => state.benis,
    searchBenis: (state) => state.searchBenis,
};
const actions = {
    async fetchAllBenis({ commit }) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.get("/beni/", {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response) => {
            commit('setBenis', response.data)
        })                
    },
    
    async fetchBeniByKeyword({ commit }, searchKeyword) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        return axios.get("/beni/search/" + searchKeyword, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response) => {
            commit('setSearchBenis', response.data)
        })                
    }

};
const mutations = {
    setBenis: (state, benis) => state.benis = benis,
    setSearchBenis: (state, searchBenis) => state.searchBenis = searchBenis,
};

export default {
    state,
    getters,
    actions,
    mutations
}