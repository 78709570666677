import axios from "@/http/http-common";
import Vue from "vue";

class PsychServiceService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/psychservice", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async addNewPsychService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/create/psych", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async updatePsychService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/update/psych", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default new PsychServiceService();
