<template>
  <div>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#2399A4"
    ></pulse-loader>
    <div v-else>
      <ab-content-container :with-footer="false" class="">
        <div class="max-w-2xl m-auto">
          <p class="mt-3 pull-left font-semibold text-xl">{{ title }} Activity Form</p>
           <p class="pull-left text-xs text-gray-500 italic">
            This is where we add a new activity
          </p>
        </div>
        


        <div class="mt-12 max-w-2xl m-auto">
            <form
            class="flex flex-col mx-auto max-w-2xl"
            @submit.prevent="
              !editService ? addActivityService() : updateActivityService()
            "
          >
            <div class="mt-12" ref="name">
              <p class="font-semibold text-sm mb-1">
                Activity Name
              </p>

              <ab-input v-model="name" type="text" :full="true"></ab-input>
            </div>
            <div class="mt-12" ref="submittedDate">
              <p class="font-semibold text-sm mb-1">
                {{ submittedDateQuestion }}
              </p>

              <ab-input
                v-model="submittedDate"
                type="date"
                :full="true"
              ></ab-input>
            </div>
            <div class="mt-12" ref="inHouse">
              <p class="font-semibold text-sm mb-1">{{ inHouseQuestion }}</p>
              <ab-radio
                v-for="(inHouseAnswer, key) in inHouseAnswers"
                :key="key"
                :label="inHouseAnswer"
                :value="inHouseAnswer"
                class="mb-2"
                :required="true"
                v-model="inHouse"
              ></ab-radio>
            </div>
            <div class="mt-12" ref="wgss" v-if="isActivityWGSS()">
              <p class="font-semibold text-sm mb-1">{{ wgssQuestion }}</p>
              <ab-radio
                v-for="(wgssAnswer, key) in wgssAnswers"
                :key="key"
                :label="wgssAnswer"
                :value="wgssAnswer"
                class="mb-2"
                :required="true"
                v-model="wgss"
              ></ab-radio>
            </div>

            <div class="mt-12" ref="mwh" v-if="isActivityMWH()">
              <p class="font-semibold text-sm mb-1">{{ mwhQuestion }}</p>
              <ab-radio
                v-for="(mwhAnswer, key) in mwhAnswers"
                :key="key"
                :label="mwhAnswer"
                :value="mwhAnswer"
                class="mb-2"
                :required="true"
                v-model="mwh"
              ></ab-radio>
            </div>

            <div
              class="mt-12"
              ref="prevention"
              v-if="isWGSSAndPreventionActivitiy()"
            >
              <p class="font-semibold text-sm mb-1">{{ preventionQuestion }}</p>
              <ab-radio
                v-for="(preventionAnswer, key) in preventionAnswers"
                :key="key"
                :label="preventionAnswer"
                :value="preventionAnswer"
                class="mb-2"
                :required="true"
                v-model="prevention"
              ></ab-radio>
            </div>

            <div
              class="mt-12"
              ref="response"
              v-if="isWGSSAndResponseActivitiy()"
            >
              <p class="font-semibold text-sm mb-1">{{ responseQuestion }}</p>
              <ab-radio
                v-for="(responseAnswer, key) in responseAnswers"
                :key="key"
                :label="responseAnswer"
                :value="responseAnswer"
                class="mb-2"
                :required="true"
                v-model="response"
              ></ab-radio>
            </div>

            <div
              class="mt-12"
              ref="provision"
              v-if="isWGSSAndProvision()"
            >
              <p class="font-semibold text-sm mb-1">{{ provisionQuestion }}</p>
              <ab-radio
                v-for="(provisionAnswer, key) in provisionAnswers"
                :key="key"
                :label="provisionAnswer"
                :value="provisionAnswer"
                class="mb-2"
                :required="true"
                v-model="provision"
              ></ab-radio>
            </div>

            <div
              class="mt-12"
              ref="awarness"
              v-if="isWGSSAndAwareness()"
            >
              <p class="font-semibold text-sm mb-1">{{ awarnessQuestion }}</p>
              <ab-radio
                v-for="(awarnessAnswer, key) in awarnessAnswers"
                :key="key"
                :label="awarnessAnswer"
                :value="awarnessAnswer"
                class="mb-2"
                :required="true"
                v-model="awarness"
              ></ab-radio>
            </div>

            <div
              class="mt-12"
              ref="pssActivities"
              v-if="isWGSSAndResponseActivitiy()"
            >
              <p class="font-semibold text-sm mb-1">
                {{ pssActivitiesQuestion }}
              </p>
              <ab-radio
                v-for="(pssActivitiesAnswer, key) in pssActivitiesAnswers"
                :key="key"
                :label="pssActivitiesAnswer"
                :value="pssActivitiesAnswer"
                class="mb-2"
                :required="true"
                v-model="pssActivities"
              ></ab-radio>
            </div>

            <div class="mt-12" ref="keyMessage" v-if="isWGSSAndProvision()">
              <p class="font-semibold text-sm mb-1">
                {{ keyMessageQuestion }}
              </p>

              <ab-input
                v-model="keyMessage"
                type="text"
                :full="true"
              ></ab-input>
            </div>

            <div class="mt-12" ref="sessionsNum">
              <p class="font-semibold text-sm mb-1">
                {{ sessionsNumQuestion }}
              </p>

              <ab-input
                v-model="sessionsNum"
                type="number"
                :full="true"
              ></ab-input>
            </div>

            <div class="mt-12" ref="gateway">
              <p class="font-semibold text-sm mb-1">
                {{ gatewayQuestion }}
              </p>
              <ab-radio
                v-for="(gatewayAnswer, key) in gatewayAnswers"
                :key="key"
                :label="gatewayAnswer"
                :value="gatewayAnswer"
                class="mb-2"
                :required="true"
                v-model="gateway"
              ></ab-radio>
            </div>

            <div class="mt-12" ref="donor">
              <p class="font-semibold text-sm mb-1">
                {{ donorQuestion }}
              </p>

              <ab-input v-model="donor" type="text" :full="true"></ab-input>
            </div>

            <div class="mt-12" ref="pssWorker">
              <p class="font-semibold text-sm mb-1">
                {{ pssWorkerQuestion }}
              </p>

              <ab-select
                v-if="!!editService"
                ref="pssWorkerSelect"
                :full="true"
                :options="pssWorkerAnswers"
                :selectedOption="pssWorker"
                v-model="pssWorker"
              ></ab-select>
              <ab-select
                v-else
                ref="pssWorkerSelect"
                :full="true"
                :options="pssWorkerAnswers"
                :placeHolder="'Select Worker'"
                v-model="pssWorker"
              ></ab-select>
            </div>

            <div class="mt-12" ref="center">
              <p class="font-semibold text-sm mb-1">
                {{ centerQuestion }}
              </p>
              <ab-select
                v-if="!!editService"
                ref="centerSelect"
                :full="true"
                :options="centerAnswers"
                :selectedOption="center"
                v-model="center"
              ></ab-select>
              <ab-select
                v-else
                ref="centerSelect"
                :full="true"
                :options="centerAnswers"
                :placeHolder="'Select Center'"
                v-model="center"
              ></ab-select>
            </div>

            <div class="mt-12" ref="region">
              <p class="font-semibold text-sm mb-1">
                {{ regionQuestion }}
              </p>
              <ab-select
                v-if="!!editService"
                ref="regionSelect"
                :full="true"
                :options="regionAnswers"
                v-model="region"
                :selectedOption="region"
              ></ab-select>
              <ab-select
                v-else
                ref="regionSelect"
                :full="true"
                :options="regionAnswers"
                :placeHolder="'Select region'"
                v-model="region"
              ></ab-select>
            </div>

            <div class="mt-12" ref="comments">
              <p class="font-semibold text-sm mb-1">
                {{ commentsQuestion }}
              </p>

              <ab-input v-model="comments" type="text" :full="true"></ab-input>
            </div>
          </form>
          <div
            v-if="!isLoading"
            class="flex flex-col w-full justify-center items-center"
          >
            <ab-button
              class="mt-2 submit-btn"
              :label="!editService ? 'Submit Activity' : 'Edit Activity'"
              @click="
                !editService ? addActivityService() : updateActivityService()
              "
            ></ab-button>
            <ab-button
              :cancel="true"
              class="mt-2"
              label="cancel"
              @click="routeToNavigation()"
            ></ab-button>
          </div>
        </div>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import vue from "vue";
import ActivityService from "@/features/Activities/ActivityService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import AbSelect from "@/components/abaad/AbSelect/AbSelect";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import moment from "moment";
import { required, requiredIf } from "vuelidate/lib/validators";
vue.use("required", required);
vue.use("requiredIf", requiredIf);

export default {
  name: "create-new-activity",
  mixins: [toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    PulseLoader,
    AbRadio,
    AbSelect,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    window.scroll(0, 0);
    this.getNewActivityQA();

    if (!!this.editService) {
      this.populateEditActivity();
    }
  },
  data() {
    return {
      awarnessSatisfactionList: [],
      id: "",
      incId: "",
      name: "",
      submittedDate: "",
      inHouse: "",
      wgss: "",
      mwh: "",
      prevention: "",
      response: "",
      provision: "",
      awarness: "",
      pssActivities: "",
      keyMessage: "",
      sessionsNum: "",
      gateway: "",
      donor: "",
      pssWorker: "",
      center: "",
      region: "",
      comments: "",
      newActivityServiceQA: {},
      errors: {},
      status: "",
      error: "",
      isLoading: false,
    };
  },
  validations() {
    return {
      name: { required },
      submittedDate: {
        required: requiredIf(() => !this.submittedDate),
      },
      inHouse: {
        required,
      },
      wgss: {
        required: requiredIf(() => this.inHouse.includes("WGSS")),
      },
      mwh: {
        required: requiredIf(() => this.inHouse.includes("MWH")),
      },
      prevention: {
        required: requiredIf(this.isWGSSAndPreventionActivitiy()),
      },
      response: {
        required: requiredIf(this.isWGSSAndResponseActivitiy()),
      },
      provision: {
        required: requiredIf(this.isWGSSAndProvision()),
      },
      awarness: {
        required: requiredIf(this.isWGSSAndAwareness()),
      },
      pssActivities: {
        required: requiredIf(this.isWGSSAndResponseActivitiy()),
      },
      keyMessage: {
        required: requiredIf(this.isWGSSAndProvision()),
      },
      sessionsNum: {
        required,
      },
      gateway: {
        required,
      },
      donor: {
        required,
      },
      pssWorker: {
        required,
      },
      center: {
        required,
      },
      region: {
        required,
      },
    };
  },
  methods: {
    routeToNavigation() {
      // TODO: needs refactor
      return this.$router.push({ path: `/activities` });
    },
    getNewActivityQA() {
      this.isLoading = true;
      ActivityService.getQaData()
        .then((response) => {
          this.newActivityServiceQA = JSON.parse(JSON.stringify(response.data));
          delete this.newActivityServiceQA["gateway"].answers.pop();
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    populateEditActivity() {
      this.name = this.editService.name;
      this.submittedDate = !!this.editService.submittedDate
        ? moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD")
        : null;
      this.inHouse = this.editService.inHouse;
      this.wgss = this.editService.wgss;
      this.mwh = this.editService.mwh;
      this.prevention = this.editService.prevention;
      this.response = this.editService.response;
      this.provision = this.editService.provision;
      this.awarness = this.editService.awarness;
      this.pssActivities = this.editService.pssActivities;
      this.keyMessage = this.editService.keyMessage;
      this.sessionsNum = this.editService.sessionsNum;
      this.gateway = this.editService.gateway;
      this.donor = this.editService.donor;
      this.pssWorker = this.editService.pssWorker;
      this.center = this.editService.center;
      this.region = this.editService.region;
      this.comments = this.editService.comments;
      if (!!this.editService.awarnessSatisfactionList) {
        this.awarnessSatisfactionList = this.editService.awarnessSatisfactionList;
      }
      console.log("🚀 ~ this.editService:", this.editService);

    },
    addActivityService() {
      if (!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          const newActivityService = this.getNewActivityService();
          ActivityService.addNewActivityService(newActivityService)
            .then((result) => {
              console.log("🚀 ~ result:", result);
              this.$router.push({ path: `/activities/${result.data.id}` });
            })
            .catch((error) => console.log(error))
            .finally(() => (this.isLoading = false));
        }
      }
    },
    updateActivityService() {
      if (!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          const newActivityService = this.getNewActivityService();
          newActivityService.startingDate = this.submittedDate;
          ActivityService.updateActivity(newActivityService)
            .then((result) => {
              this.$router.push({ path: `/activities/${result.data.id}` });
            })
            .catch((error) => console.log(error))
            .finally(() => (this.isLoading = false));
        }
      }
    },
    getNewActivityService() {
      let newActivityService = {};
      newActivityService.name = this.name;
      newActivityService.submittedDate = this.submittedDate;
      newActivityService.inHouse = this.inHouse;
      newActivityService.wgss = this.wgss;
      newActivityService.mwh = this.mwh;
      newActivityService.prevention = this.prevention;
      newActivityService.response = this.response;
      newActivityService.provision = this.provision;
      newActivityService.awarness = this.awarness;
      newActivityService.pssActivities = this.pssActivities;
      newActivityService.keyMessage = this.keyMessage;
      newActivityService.sessionsNum = parseInt(this.sessionsNum);
      newActivityService.gateway = this.gateway;
      newActivityService.donor = this.donor;
      newActivityService.pssWorker = this.pssWorker;
      newActivityService.center = this.center;
      newActivityService.region = this.region;
      newActivityService.comments = this.comments;

      if (!!this.editService) {
        newActivityService.status = this.editService.status;
        newActivityService.submittedDate = this.editService.submittedDate;
        newActivityService.id = this.editService.id;
        newActivityService.incId = this.editService.incId;
        if (!!this.editService.awarnessSatisfactionList) {
          newActivityService.awarnessSatisfactionList = this.editService.awarnessSatisfactionList;
        } else {
          newActivityService.awarnessSatisfactionList = [];
        }

      newActivityService.createdBy = this.editService.createdBy;
      newActivityService.createdDate = this.editService.createdDate;

      }

      console.log("🚀 ~ newActivityService:", newActivityService);
      return newActivityService;
    },
    isActivityWGSS() {
      return this.inHouse.includes("WGSS");
    },
    isActivityMWH() {
      return this.inHouse.includes("MWH");
    },
    isWGSSAndPreventionActivitiy() {
      return (
        this.isActivityWGSS() && this.wgss.includes("Prevention activities")
      );
    },
    isWGSSAndResponseActivitiy() {
      return this.isActivityWGSS() && this.wgss.includes("Response activities");
    },
    isWGSSAndProvision() {
      return (
        this.isActivityWGSS() &&
        this.prevention.includes("Provision of information")
      );
    },
    isWGSSAndAwareness() {
      return (
        this.isActivityWGSS() &&
        this.prevention.includes("Awareness and Other")
      );
    },

    catchErrors() {
      this.errors = [];
      if (!this.name) {
        let elPosition =
          this.$refs?.name?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Activity Name is Required.",
          pos: elPosition,
        });
      }
      if (!this.submittedDate) {
        let elPosition =
          this.$refs?.submittedDate?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Starting Date is Required.",
          pos: elPosition,
        });
      }
      if (!this.inHouse) {
        let elPosition =
          this.$refs?.inHouse?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "In House Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.wgss && this.inHouse.includes("WGSS")) {
        let elPosition =
          this.$refs?.wgss?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "WGSS Field is Required.",
          pos: elPosition,
        });
      }
      if (!this.mwh && this.inHouse.includes("MWH")) {
        let elPosition =
          this.$refs?.mwh?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "MWH Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.prevention && this.isWGSSAndPreventionActivitiy()) {
        let elPosition =
          this.$refs?.prevention?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Prevention Activity Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.response && this.isWGSSAndResponseActivitiy()) {
        let elPosition =
          this.$refs?.response?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Response Activity Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.provision && this.isWGSSAndProvision()) {
        let elPosition =
          this.$refs?.provision?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Provision Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.awarness && this.isWGSSAndAwareness()) {
        let elPosition =
          this.$refs?.awarness?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Awareness Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.pssActivities && this.isWGSSAndResponseActivitiy()) {
        let elPosition =
          this.$refs?.pssActivities?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "PSS Activities Field is Required.",
          pos: elPosition,
        });
      }

      if (
        !this.keyMessage &&
        this.isWGSSAndProvision()
      ) {
        let elPosition =
          this.$refs?.keyMessage?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Key Message Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.sessionsNum) {
        let elPosition =
          this.$refs?.sessionsNum?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Number of Sessions Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.gateway) {
        let elPosition =
          this.$refs?.gateway?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "Gateway Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.donor) {
        let elPosition =
          this.$refs?.donor?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Donor Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.pssWorker) {
        let elPosition =
          this.$refs?.pssWorker?.getBoundingClientRect().top +
          window.scrollY -
          60;
        this.errors.push({
          text: "PSS Worker Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.center) {
        let elPosition =
          this.$refs?.center?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Center Name Field is Required.",
          pos: elPosition,
        });
      }

      if (!this.region) {
        let elPosition =
          this.$refs?.region?.getBoundingClientRect().top + window.scrollY - 60;
        this.errors.push({
          text: "Region Field is Required.",
          pos: elPosition,
        });
      }

      this.handleArrayErrorsAndScrollToQuestion(this.errors);
    },
  },
  computed: {
    submittedDateQuestion() {
      return this.newActivityServiceQA.startingDate?.question[0];
    },
    inHouseQuestion() {
      return this.newActivityServiceQA.inHouse?.question[0];
    },
    wgssQuestion() {
      return this.newActivityServiceQA.wgss?.question[0];
    },
    mwhQuestion() {
      return this.newActivityServiceQA.mwh?.question[0];
    },
    preventionQuestion() {
      return this.newActivityServiceQA.prevention?.question[0];
    },
    responseQuestion() {
      return this.newActivityServiceQA.response?.question[0];
    },
    provisionQuestion() {
      return this.newActivityServiceQA.provision?.question[0];
    },
    awarnessQuestion() {
      return this.newActivityServiceQA.awarness?.question[0];
    },
    pssActivitiesQuestion() {
      return this.newActivityServiceQA.pssActivities?.question[0];
    },
    keyMessageQuestion() {
      return this.newActivityServiceQA.keyMessage?.question[0];
    },
    sessionsNumQuestion() {
      return this.newActivityServiceQA.sessionsNum?.question[0];
    },
    gatewayQuestion() {
      return this.newActivityServiceQA.gateway?.question[0];
    },
    donorQuestion() {
      return this.newActivityServiceQA.donor?.question[0];
    },
    pssWorkerQuestion() {
      return this.newActivityServiceQA.pssWorker?.question[0];
    },
    centerQuestion() {
      return this.newActivityServiceQA.center?.question[0];
    },
    regionQuestion() {
      return this.newActivityServiceQA.region?.question[0];
    },
    commentsQuestion() {
      return this.newActivityServiceQA.comments?.question[0];
    },
    inHouseAnswers() {
      return this.newActivityServiceQA.inHouse?.answers;
    },
    wgssAnswers() {
      return this.newActivityServiceQA.wgss?.answers;
    },
    mwhAnswers() {
      return this.newActivityServiceQA.mwh?.answers;
    },
    preventionAnswers() {
      return this.newActivityServiceQA.prevention?.answers;
    },
    responseAnswers() {
      return this.newActivityServiceQA.response?.answers;
    },
    provisionAnswers() {
      return this.newActivityServiceQA.provision?.answers;
    },
    awarnessAnswers() {
      return this.newActivityServiceQA.awarness?.answers;
    },
    pssActivitiesAnswers() {
      return this.newActivityServiceQA.pssActivities?.answers;
    },
    gatewayAnswers() {
      delete this.newActivityServiceQA.gateway?.answers["Other"];
      return this.newActivityServiceQA.gateway?.answers;
    },
    pssWorkerAnswers() {
      return this.newActivityServiceQA.pssWorker?.answers;
    },
    centerAnswers() {
      return this.newActivityServiceQA.center?.answers;
    },
    regionAnswers() {
      return this.newActivityServiceQA.region?.answers;
    },
    nameQuestionQuestion() {
      return this.newActivityServiceQA.name?.question[0];
    },
    typeQuestion() {
      return this.newActivityServiceQA.type?.question[0];
    },
    typeAnswers() {
      return this.newActivityServiceQA.type?.answers;
    },
    numberQuestion() {
      return this.newActivityServiceQA.number?.question[0];
    },
    title() {
      return this.$route.path.includes("edit") ? "Edit" : "New ";
    },
  },
  watch: {
    inHouse() {
      if (this.isActivityMWH()) {
        this.wgss = "";
        this.prevention = "";
        this.provision = "";
        this.awarness = "";
        this.keyMessage = "";
      }
      if (this.isActivityWGSS()) {
        this.mwh = "";
      }
    },
    wgss() {
      if (!this.isWGSSAndPreventionActivitiy()) {
        this.response = "";
        this.pssActivities = "";
      }
      if (this.isWGSSAndResponseActivitiy()) {
        this.prevention = "";
        this.provision = "";
        this.awarness = "";
        this.keyMessage = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pull-left {
margin-left: -2rem;
}
</style>