<template>
  <div>
    <div v-if="isLoading" class="flex justify-center mt-24">
      <pulse-loader color="#2399A4" :loading="isLoading"></pulse-loader>
    </div>
    <div class="table-tools">
      
      <div class="pagination-wrapper">
        <div class="per-page-records">
        <ab-select
          :options="['7', '15', '35', '100', '500']"
          :required="false"
          :full="false"
          v-model="perPage"
          :placeHolder="'Records Per Page'"
        ></ab-select>
      </div>
        <b-pagination
          v-if="beniColumnsData && !isLoading"
          class="pagination"
          v-model="currentPage"
          :total-rows="filtered.length"
          :per-page="perPage"
        >
          <template #first-text
            ><span class="text-ab-primary">First</span></template
          >
          <template #prev-text
            ><span class="text-ab-primary">Prev</span></template
          >
          <template #next-text
            ><span class="text-ab-primary">Next</span></template
          >
          <template #last-text
            ><span class="text-ab-primary">Last</span></template
          >
        </b-pagination>
      </div>
    </div>

    <b-table
      v-if="!isLoading"
      class="w-full shadow-lg bg-white table"
      hover
      responsive
      sort-icon-right
      :per-page="perPage"
      :current-page="currentPage"
      :bordered="true"
      :filter-included-fields="beniColumnsData"
      :items="filtered"
      ref="selectableTable"
      @row-selected="onRowSelected"
      selectable
      select-mode="single"
      :fields="beniColumnsData"
    >
      <template slot="top-row" slot-scope="{ fields }">
        <td class="filters" v-for="field in fields" :key="field.key">
          <input
            class="filter-column"
            v-model="filters[field.key]"
            :placeholder="field.label"
          />
        </td>
      </template>
    </b-table>

    <ab-not-found
      @click="disableClick()"
      v-if="!searchBenis.length && !isLoading"
    ></ab-not-found>
  </div>
</template>

<script>
import moment from "moment";
import { searchMixin } from "@/mixins/searchMixin.js";
import AbNotFound from "@/components/abaad/AbNotFound/AbNotFound";
import PulseLoader from "vue-spinner/src/PulseLoader";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import AbSelect from "../../components/abaad/AbSelect/AbSelect.vue";

export default {
  mixins: [searchMixin],
  props: {
    search: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      fields: [],
      benis: [],
      sliceRange: 10,
      isLoading: false,
      beniColumnsData: [],
      currentPage: 1,
      perPage: 7,
      filters: {
        gbvmis: "",
        fullname: "",
        gender: "",
        motherName: "",
        createdByCenter: "",
        phoneNumber: "",
        unhcrNumber: "",
        dateBirth: "",
        disability: "",
        countryOrigin: "",
        placeBirth: "",
        createdBy: {
          name: "",
        },
      },
    };
  },
  components: {
    AbNotFound,
    PulseLoader,
    AbSelect,
  },
  created() {
    console.log("hit");
    this.fetchBeniByKeyword(this.search);
    // uncomment below and replace searchBenis with filteredBenis in table to enable the old fetch all workflow
    // this.fetchAllBenis(this.search);
  },
  mounted() {
    this.benis = this.allBenis;
    if (this.checkIfMobile()) {
      this.beniColumnsData = [
        {
          key: "gbvmis",
          label: "GBVMIS",
          sortable: true,
        },
        {
          key: "fullName",
          label: "Name",
          sortable: true,
          filterByFormatted: true,
        },
        {
          key: "phoneNumber",
          label: "Phone",
          sortable: true,
        },
      ];
    } else {
      this.beniColumnsData = [
        {
          key: "createdBy.name",
          label: "Created By",
          sortable: true,
        },
        {
          key: "gbvmis",
          label: "GBVMIS",
          sortable: true,
        },
        {
          key: "fullName",
          label: "Name",
          sortable: true,
          filterByFormatted: true,
        },
        {
          key: "motherName",
          label: "Mother Name",
          sortable: true,
        },
        {
          key: "phoneNumber",
          label: "Phone",
          sortable: true,
        },
        {
          key: "nationalIDNumber",
          label: "National ID",
          sortable: true,
        },
        {
          key: "unhcrNumber",
          label: "UNHCR",
          sortable: true,
          formatter: (item) => {
            if (!!item) {
              return item;
            } else {
              return "N/A";
            }
          },
        },
        {
          key: "dateBirth",
          label: "Birth Date",
          sortable: true,
          formatter: (value) => {
            return this.getDateAndTime(value);
          },
        },
        {
          key: "countryOrigin",
          label: "Country of Origin",
          sortable: true,
        },
        {
          key: "placeBirth",
          label: "Place of Birth",
          sortable: true,
        },
      ];
    }
  },
  watch: {
    search(val) {
      this.getSearchBeneficiaries(val);
    },
  },
  methods: {
    ...mapActions(["fetchAllBenis", "fetchBeniByKeyword"]),
    getDateAndTime(date) {
      return moment(date).format("DD MMM YYYY");
    },
    disableClick() {},
    checkIfMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getSearchBeneficiaries: _.debounce(function(val) {
      this.fetchBeniByKeyword(val);
    }, 1000),
    formatDisability(value) {
      if (!!value && value.includes("false")) {
        return "N/A";
      }
      if (!!value && value.length) {
        return value.join(", ");
      }
      if (!value) {
        return;
      }
    },

    async onRowSelected(beniData) {
      let beneURL;
      let method;
      let route;
      console.log("🚀 ~ beniData.gbvmis", beniData.gbvmis);
      if (!!beniData[0].gbvmis) {
        beneURL = `https://api.abaadmena.online/v1/survivorinfo/get/${beniData[0].gbvmis}`;
        method = "get";
        route = { path: `/bene/${beniData[0].gbvmis}` };
      } else {
        beneURL = `https://api.abaadmena.online/v1/activities/benificiaries/id/${beniData[0].id}`;
        method = "post";
        route = {
          path: `/activities/activity-beneficiary/${beniData[0].abCode}`,
        };
      }
      console.log("🚀 ~ beniData", beniData);
      const accessToken = await new Vue().$auth.getTokenSilently();
      let response = await fetch(beneURL, {
        method: method,
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      });
      let result = await response.json();
      localStorage.setItem("ActivityBeneficiary", JSON.stringify(result));
      localStorage.setItem("case", JSON.stringify(result));
      result.length
        ? localStorage.setItem("hasCase", true)
        : localStorage.setItem("hasCase", false);
      localStorage.setItem("beniName", beniData[0].fullName);

      this.$router.push(route);
    },
    formatDate(benis) {
      return benis.map((beni) => {
        if (beni.createdDate) {
          beni.createdDate = moment(beni.createdDate).format("YYYY-MM-DD");
        }
        return beni;
      });
    },
  },
  computed: {
    ...mapGetters(["allBenis", "searchBenis"]),
    filtered() {
      const filtered = this.searchBenis.filter((item) => {
        return Object.keys(this.filters).every((key) => {
          // check on date filtering after three chars

          if (!!this.filters[key] && key.includes("createdBy.name")) {
            return String(item["createdBy"].name)
              .toLowerCase()
              .includes(String(this.filters[key]).toLowerCase());
          } else {
            return String(item[key])
              .toLowerCase()
              .includes(String(this.filters[key]).toLowerCase());
          }
        });
      });

      return filtered.length > 0
        ? filtered
        : [
            Object.keys(this.searchBenis).reduce(function(obj, value) {
              obj[value] = "";
              return obj;
            }, {}),
          ];
    },
  },
};
</script>
<style lang="scss">
.filters {
  max-width: 80px;
}
.filter-column {
  width: 100%;
  border-radius: 5px;
  border: solid 1px #ddd;
  font-size: 13px;
  outline: none;
  height: 25px;
  padding-left: 3px;
  &:active,
  &:focus {
    border: solid 2px #8dd4de;
  }
}
span.sr-only {
  display: none;
}
@media (max-width: 650px) {
  .table.b-table.table-hover.table-bordered.b-table-stacked-sm {
    .b-table-top-row {
      display: none;
    }
  }
}

.table.b-table.table-hover.table-bordered.b-table-stacked-sm {
  thead {
    tr {
      th {
        margin-bottom: 10px;
        padding: 10px 15px 10px 5px;

        div {
          margin-right: 5px;
          font-weight: 600;
        }
        span {
          margin-top: 10px;
        }
      }
    }
  }
  font-size: 14px;
  tbody > tr.b-table-top-row:hover {
    background-color: transparent !important;
  }
  tbody > tr {
    font-size: 14px;
  }
  tbody > tr:hover {
    background-color: #8dd4de !important;
  }
}
td[data-label="Id"] {
  max-width: 24ch;
  padding-left: 1px;
}
.page-item.active .page-link {
  background-color: #8dd4de;
  border-color: #8dd4de;
  box-shadow: none;
}
.page-link,
.page-link:hover {
  color: #000;
}

td.border {
  vertical-align: middle;
}

.table > :not(:first-child) {
  border-top: 0 solid currentColor;
}
.thead {
  padding: 1rem;
  font-size: 0.75rem;
}
.table-tools {
}
.pagination-wrapper {
  width: 70%;
  display: flex;
  margin-bottom: 0.5rem;
}
.pagination {
  width: 50%;
  margin-left: auto;
}
.per-page-records {
  display: inline-block;
  // display: flex;
  // margin-left: auto;
  // order: 2;
}
</style>
