<template>
  <ab-content-container>
    <pulse-loader
      :loading="isLoading"
      class="loader"
      color="#2399A4"
      v-if="isLoading"
    ></pulse-loader>
    <div v-else>
      <div>
      
        <p class="font-semibold text-xl">Financial Service - PCAP</p>
        <form
          @submit.prevent="!editService ? addPcapService() : updatePcapService()"
          class="flex flex-col mx-auto max-w-2xl" >
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">Submit Date</p>
            <ab-input
              :full="true"
              type="date"
              v-model="submittedDate"
            ></ab-input>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ supervisorApprovedQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in supervisorApprovedAnswers"
              v-model="supervisorApproved"
            ></ab-radio>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ supervisorNameQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in supervisorNameAnswers"
              v-model="supervisorName"
            ></ab-radio>
          </div>
          

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ monthInclusionQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in monthInclusionAnswers"
              v-model="monthInclusion"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ locationQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in locationAnswers"
              v-model="location"
            ></ab-radio>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ agencyQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in agencyAnswers"
              v-model="agency"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ unhcrNumberQuestion }}</p>
            <textarea
              v-model="unhcrNumber"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ individualCaseNumberQuestion }}
            </p>
            <textarea
              v-model="individualCaseNumber"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ mftDecisionQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in mftDecisionAnswers"
              v-model="mftDecision"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ nationalityQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in nationalityAnswers"
              v-model="nationality"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ phoneNumberQuestion }}</p>
            <textarea
              v-model="phoneNumber"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ addressQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in addressAnswers"
              v-model="address"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ categoryQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in categoryAnswers"
              v-model="category"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ referredQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in referredAnswers"
              v-model="referred"
            ></ab-radio>
          </div>
          <div class="mt-12" v-if="shouldReferredOtherInput">
            <p class="font-semibold text-sm mb-1">If Other, Please specify</p>
            <textarea
              v-model="referredOther"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ notesQuestion }}</p>
            <textarea
              v-model="notes"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ protectionQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in protectionAnswers"
              v-model="protection"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ covidSituationQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in covidSituationAnswers"
              v-model="covidSituation"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ beirutBlastSituationQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in beirutBlastSituationAnswers"
              v-model="beirutBlastSituation"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ specificSituationQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in specificSituationAnswers"
              v-model="specificSituation"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ cashAmountQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in cashAmountAnswers"
              v-model="cashAmount"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ timelineQuestion }}</p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in timelineAnswers"
              v-model="timeline"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ vulnerabilityProtectionQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in vulnerabilityProtectionAnswers"
              v-model="vulnerabilityProtection"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ economicConditionQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in economicConditionAnswers"
              v-model="economicCondition"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ harmConditionQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in harmConditionAnswers"
              v-model="harmCondition"
            ></ab-radio>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ assessmentUploadedQuestion }}
            </p>
            <ab-radio
              :key="index"
              :label="answer"
              :value="answer"
              class="mb-2"
              v-for="(answer, index) in assessmentUploadedAnswers"
              v-model="assessmentUploaded"
            ></ab-radio>
          </div>

          <div class="mt-12">
            <div
              class="flex flex-col justify-center items-center"
              v-if="!isLoading"
            >
              <ab-button
                @click="!editService ? addPcapService() : updatePcapService()"
                class="mt-2 submit-btn"
                label="submit"
              ></ab-button>
              <ab-button
                @click="routeToNavigation()"
                class="mt-2"
                :cancel="true"
                label="cancel"
              ></ab-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ab-content-container>
</template>
<script>
import PcapServiceService from "./PcapServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import vue from "vue";
import moment from "moment";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { methodsMixin } from "@/shared/financial/mixins/methodsMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { mapActions, mapGetters } from 'vuex';
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';

// import _ from "lodash";

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-financial-pcap-service",
  mixins: [utilMixin, methodsMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getPcapServiceQA();
    this.gbvmis = this.$route.params.id;
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 
  },
  data() {
    return {
      gbvmis: "",
      supervisorApproved: "",
      supervisorName: "",
      monthInclusion: "",
      location: "",
      agency: "",
      unhcrNumber: "",
      individualCaseNumber: "",
      mftDecision: "",
      nationality: "",
      phoneNumber: "",
      address: "",
      category: "",
      referred: "",
      //other
      referredOther: "",
      notes: "",
      protection: "",
      covidSituation: "",
      beirutBlastSituation: "",
      specificSituation: "",
      cashAmount: "",
      timeline: "",
      vulnerabilityProtection: "",
      economicCondition: "",
      harmCondition: "",
      assessmentUploaded: "",
      resultAssessmentUploaded: false,

      submittedDate: "",
      pcapServiceQA: {},
      errors: [],
      error: "",
      isLoading: false,
    };
  },
   validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: (value) => value < this.tomorrowsDate,
      }
    }
  },
  watch: {
    assessmentUploaded(val) {
      if (val.toLowerCase().includes("yes")) {
        this.resultAssessmentUploaded = true;
        return;
      }
      this.resultAssessmentUploaded = false;
    },
    referred(val) {
      this.resultReferred = val;
    },
    referredOther(val) {
      this.resultReferred = val;
    },
    resultReferred(val) {
      this.referred = val;
    },
  },
  methods: {
    ...mapActions(['fetchAllServices']),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getPcapServiceQA() {
      this.isLoading = true;
      PcapServiceService.getQaData()
        .then((response) => {
          this.pcapServiceQA = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = moment( new Date(this.editService.submittedDate)).format("YYYY-MM-DD");
      this.monthInclusion = this.editService.monthInclusion;
      this.location = this.editService.location;
      this.agency = this.editService.agency;
      this.unhcrNumber = this.editService.unhcrNumber;
      this.individualCaseNumber = this.editService.individualCaseNumber;
      this.mftDecision = this.editService.mftDecision;
      this.nationality = this.editService.nationality;
      this.phoneNumber = this.editService.phoneNumber;
      this.address = this.editService.address;
      this.category = this.editService.category;
      this.supervisorApproved = this.editService.supervisorApproved;
      this.supervisorName = this.editService.supervisorName;
      this.ammendEditCaseOtherInput(
        this.referredAnswers,
        this.editService.referred,
        "Other/Please Specify"
      ).then((result) => {
        this.referredOther = result[1];
        this.referred = result[0];
      });
      this.notes = this.editService.notes;
      this.protection = this.editService.protection;
      this.covidSituation = this.editService.covidSituation;
      this.beirutBlastSituation = this.editService.beirutBlastSituation;
      this.specificSituation = this.editService.specificSituation;
      this.cashAmount = this.editService.cashAmount;
      this.timeline = this.editService.timeline;
      this.vulnerabilityProtection = this.editService.vulnerabilityProtection;
      this.economicCondition = this.editService.economicCondition;
      this.harmCondition = this.editService.harmCondition;
      this.assessmentUploaded = this.editService.assessmentUploaded
        ? "Yes"
        : "No";
    },
    addPcapService() {
      if(!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          const newPcapService = this.getNewPcapService();
          PcapServiceService.addNewPcapService(newPcapService)
            .then(() => this.$router.push({ path: `/bene/${this.gbvmis}` }))
            .catch((error) => {
              window.scrollTo(0, 0);
              this.errors =
                error.response.data.errorMessages ?? error.response.data.details;
            })
            .finally(() => (this.isLoading = false));
          }
      }
    },
    updatePcapService() {
      if(!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          PcapServiceService.updatePcapService(this.getNewPcapService())
            .then(() => {
              this.$router.push({
                path: `/bene/${this.$route.params.id}/management`,
              });
            })
            .catch((error) => {
              this.handleErrors(error.response.data.errorMessages[0])
            })
            .finally(() => (this.isLoading = false));
        } 
      }
    },
    getNewPcapService() {
      let newPcapService = {};
      if (!!this.editService) newPcapService.id = this.editService.id;
      newPcapService.gbvmis = this.gbvmis;

      newPcapService.monthInclusion = this.monthInclusion;
      newPcapService.location = this.location;
      newPcapService.agency = this.agency;
      newPcapService.unhcrNumber = this.unhcrNumber;
      newPcapService.individualCaseNumber = this.individualCaseNumber;
      newPcapService.mftDecision = this.mftDecision;
      newPcapService.nationality = this.nationality;
      newPcapService.phoneNumber = this.phoneNumber;
      newPcapService.address = this.address;
      newPcapService.category = this.category;
      newPcapService.isSupervisorApproved = this.supervisorApproved;
      newPcapService.supervisorName = this.supervisorName;
      newPcapService.referred = this.resultReferred;
      // newPcapService.referredOther = this.referredOther;
      newPcapService.notes = this.notes;
      newPcapService.protection = this.protection;
      newPcapService.covidSituation = this.covidSituation;
      newPcapService.beirutBlastSituation = this.beirutBlastSituation;
      newPcapService.specificSituation = this.specificSituation;
      newPcapService.cashAmount = this.cashAmount;
      newPcapService.timeline = this.timeline;
      newPcapService.vulnerabilityProtection = this.vulnerabilityProtection;
      newPcapService.economicCondition = this.economicCondition;
      newPcapService.harmCondition = this.harmCondition;
      newPcapService.assessmentUploaded = this.resultAssessmentUploaded;

      newPcapService.submittedDate = new Date(this.submittedDate).toJSON();
      newPcapService.serviceType = "pcap";

      return newPcapService;
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    catchErrors() {
      this.errors = [];
      
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
       if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }

      this.handleArrayErrors(this.errors);

    },
  },
  computed: {
     ...mapGetters(['allServices']),
    // QA
    supervisorApprovedQuestion() {
      return this.pcapServiceQA.supervisorApproved.question[0];
    },
    supervisorApprovedAnswers() {
      return this.pcapServiceQA.supervisorApproved.answers;
    },
    supervisorNameQuestion() {
      return this.pcapServiceQA.supervisorName.question[0];
    },
    supervisorNameAnswers() {
      return this.pcapServiceQA.supervisorName.answers;
    },
    monthInclusionQuestion() {
      return this.pcapServiceQA.monthInclusion.question[0];
    },
    monthInclusionAnswers() {
      return this.pcapServiceQA.monthInclusion.answers;
    },
    locationQuestion() {
      return this.pcapServiceQA.location.question[0];
    },
    locationAnswers() {
      return this.pcapServiceQA.location.answers;
    },
    agencyQuestion() {
      return this.pcapServiceQA.agency.question[0];
    },
    agencyAnswers() {
      return this.pcapServiceQA.agency.answers;
    },
    unhcrNumberQuestion() {
      return this.pcapServiceQA.unhcrNumber.question[0];
    },
    individualCaseNumberQuestion() {
      return this.pcapServiceQA.individualCaseNumber.question[0];
    },
    mftDecisionQuestion() {
      return this.pcapServiceQA.mftDecision.question[0];
    },
    mftDecisionAnswers() {
      return this.pcapServiceQA.mftDecision.answers;
    },
    nationalityQuestion() {
      return this.pcapServiceQA.nationality.question[0];
    },
    nationalityAnswers() {
      return this.pcapServiceQA.nationality.answers;
    },
    phoneNumberQuestion() {
      return this.pcapServiceQA.phoneNumber.question[0];
    },
    addressQuestion() {
      return this.pcapServiceQA.address.question[0];
    },
    addressAnswers() {
      return this.pcapServiceQA.address.answers;
    },
    categoryQuestion() {
      return this.pcapServiceQA.category.question[0];
    },
    categoryAnswers() {
      return this.pcapServiceQA.category.answers;
    },
    referredQuestion() {
      return this.pcapServiceQA.referred.question[0];
    },
    referredAnswers() {
      return this.pcapServiceQA.referred.answers;
    },
    // referredOther: "",
    notesQuestion() {
      return this.pcapServiceQA.notes.question[0];
    },
    protectionQuestion() {
      return this.pcapServiceQA.protection.question[0];
    },
    protectionAnswers() {
      return this.pcapServiceQA.protection.answers;
    },
    covidSituationQuestion() {
      return this.pcapServiceQA.covidSituation.question[0];
    },
    covidSituationAnswers() {
      return this.pcapServiceQA.covidSituation.answers;
    },
    beirutBlastSituationQuestion() {
      return this.pcapServiceQA.beirutBlastSituation.question[0];
    },
    beirutBlastSituationAnswers() {
      return this.pcapServiceQA.beirutBlastSituation.answers;
    },
    specificSituationQuestion() {
      return this.pcapServiceQA.specificSituation.question[0];
    },
    specificSituationAnswers() {
      return this.pcapServiceQA.specificSituation.answers;
    },
    cashAmountQuestion() {
      return this.pcapServiceQA.cashAmount.question[0];
    },
    cashAmountAnswers() {
      return this.pcapServiceQA.cashAmount.answers;
    },
    timelineQuestion() {
      return this.pcapServiceQA.timeline.question[0];
    },
    timelineAnswers() {
      return this.pcapServiceQA.timeline.answers;
    },
    economicConditionQuestion() {
      return this.pcapServiceQA.economicCondition.question[0];
    },
    economicConditionAnswers() {
      return this.pcapServiceQA.economicCondition.answers;
    },
    vulnerabilityProtectionQuestion() {
      return this.pcapServiceQA.vulnerabilityProtection.question[0];
    },
    vulnerabilityProtectionAnswers() {
      return this.pcapServiceQA.vulnerabilityProtection.answers;
    },
    harmConditionQuestion() {
      return this.pcapServiceQA.harmCondition.question[0];
    },
    harmConditionAnswers() {
      return this.pcapServiceQA.harmCondition.answers;
    },
    assessmentUploadedQuestion() {
      return this.pcapServiceQA.assessmentUploaded.question[0];
    },
    assessmentUploadedAnswers() {
      return this.pcapServiceQA.assessmentUploaded.answers;
    },
    // Active data checks
    shouldReferredOtherInput() {
      return this.referred?.toLowerCase().includes("please specify");
    },
  },
};
</script>
