<template>
  <div v-if="errorsExist"
       class="border border-solid border-red-600 bg-red-200 p-5 rounded max-w-xl mx-auto flex items-center overflow-y-auto">
    <div class="flex h-full fit-content items-center mr-4">
      <i class="uil uil-exclamation-triangle text-red-600 text-2xl"></i>
    </div>
    <ul v-if="!!allErrors && allErrors.length" class="text-xs list-disc ml-4 text-red-600">
      <li v-for="(error, index) in allErrors"
          :key="index"
          class="my-1">{{ error }}</li>
    </ul>
    <p class="text-xs ml-4 text-red-600" v-if="!!singleError && singleError.length">{{ singleError }}</p>
    <pre>
      <p class="text-red-700 text-sm ml-4" v-if="!!error && error.length">{{ error }}</p>
    </pre>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    errors: {
      required: false
    },
    error: {
      type: String,
    }
  },
  computed: {
    allErrors() {
      const errors = [];
      if (typeof this.errors === "object") {
        for (const error in this.errors) {
          errors.push(this.errors[error]);
        }
      }
      return errors;
    },
    singleError() {
      let error = "";
      if (typeof this.errors === "string") {
        error = this.errors;
      }
      return error;
    },
    errorsExist() {
      return !_.isEmpty(Object.keys(this.errors ?? {})) || !!this.error;
    }
  }
};
</script>
