import Vue from "vue";
import axios from "@/http/http-common";

const state = {
    incident: {}
};
const getters = {
    incident: (state) => state.incident
};
const actions = {
    async fetchLatestIncident({ commit }, gbvmis) {
        const accessToken = await new Vue().$auth.getTokenSilently();
        const response = await axios.get("/socdemo/latest?gbvmis=" + gbvmis, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        })
        commit("setIncident", response.data);
    }

};
const mutations = {
    setIncident: (state, incident) => state.incident = incident
};

export default {
    state,
    getters,
    actions,
    mutations
}