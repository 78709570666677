<template>
  <div>
    <pulse-loader
      :loading="isLoading"
      class="loader"
      color="#2399A4"
      v-if="isLoading"
    ></pulse-loader>
    <div v-else>
      <ab-content-container>
        <p class="font-semibold text-xl">Safety Plan</p>
          <form  @submit.prevent="handleAddingOrUpdaingSafetyService()"
          class="flex flex-col mx-auto max-w-2xl">
          <div class="mt-6">
            <p class="font-semibold text-sm mb-1">Date of Safety Plan</p>
            <ab-input :full="true" type="date" v-model="submittedDate"></ab-input>
          </div>
          <!--  -->
          <!-- <div class="mt-12" ref="diagnosis">
            <p class="font-bold text-sm mb-1">{{ diagnosisQuestion }}</p>
            <div v-for="(diagnosisAnswer, index) in diagnosisAnswers" :key="index" class="mt-4">
              <label :for="diagnosisAnswer" class="flex items-center text-sm font-light">
                <input v-model="diagnosis" :value="diagnosisAnswer" type="checkbox"/>
                <span class="ml-2">{{ diagnosisAnswer }}</span>
              </label>
            </div>
            <ab-input
              v-if="diagnosisIncludesOther" v-model="diagnosisOther" :full="true" class="inline-block mt-4" label="Please specify"></ab-input>
          </div> -->

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{  safetyServiceQA.riskFactors.question[0] }}
            </p>
              <div v-for="(riskFactorsAnswer, key) in safetyServiceQA.riskFactors.answers" :key="key" class="mt-4">
              <label :for="riskFactorsAnswer" class="flex items-center text-sm font-light">
                <input v-model="riskFactors" :value="riskFactorsAnswer" type="checkbox"/>
                <span class="ml-2">{{ riskFactorsAnswer }}</span>
              </label>
            </div>
            <!-- <ab-radio
              v-for="(riskFactorsAnswer, key) in safetyServiceQA.riskFactors.answers"
              :key="key"
              :label="riskFactorsAnswer"
              :value="riskFactorsAnswer"
              class="mb-2"
              v-model="riskFactors"
              :required="true">
            </ab-radio> -->
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{  safetyServiceQA.survivalRiskLevel.question[0] }}
            </p>
            <ab-radio
              v-for="(survivalRiskLevelAnswer, key) in safetyServiceQA.survivalRiskLevel.answers"
              :key="key"
              :label="survivalRiskLevelAnswer"
              :value="survivalRiskLevelAnswer"
              class="mb-2"
              v-model="survivalRiskLevel"
              :required="true">
            </ab-radio>
          </div>            
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{  socialRiskLevelQuestion }}
            </p>
            <ab-radio
              v-for="(socialRiskLevelAnswer, key) in safetyServiceQA.socialRiskLevel.answers"
              :key="key"
              :label="socialRiskLevelAnswer"
              :value="socialRiskLevelAnswer"
              class="mb-2"
              v-model="socialRiskLevel"
              :required="true">
            </ab-radio>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ violenceGetawayQuestion }}</p>
            <textarea
              v-model="violenceGetaway"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
           <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ emergencyGetawayQuestion }}</p>
            <textarea
              v-model="emergencyGetaway"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
            <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ trusteesQuestion }}</p>
            <textarea
              v-model="trustees"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>

          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ inhouseWeaponsQuestion }}</p>
            <textarea
              v-model="inhouseWeapons"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm"></textarea>
          </div>
          
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ childrenDependencyQuestion }}</p>
            <textarea
              v-model="childrenDependency"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm"></textarea>
          </div>
      
          <div class="mt-12">
            <div
              class="flex flex-col justify-center items-center"
              v-if="!isLoading"
            >
              <ab-button
                @click="handleAddingOrUpdaingSafetyService()"
                class="mt-2 submit-btn"
                label="submit"
              ></ab-button>
              <ab-button
                @click="routeToNavigation()"
                class="mt-2"
                :cancel="true"
                label="cancel"
              ></ab-button>
            </div>
          </div>
        </form>
      </ab-content-container>
    </div>
  </div>
</template>
<script>
import vue from 'vue';
import SafetyServiceService from "./SafetyServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import moment from "moment";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { mapGetters, mapActions } from 'vuex';
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-safety-service",
  mixins: [utilMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    // AbErrors,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getSafetyServiceQA();
    this.gbvmis = this.$route.params.id;
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD");
    if(!this.editService) {
      this.getIfBeneHasPreviousSafetyPlan();
    }
 },
  data() {
    return {
      gbvmis: "",
      latestCounselingServiceDate: "",
      emergencyGetaway: "",
      childrenDependency: "",
      riskFactors: [],
      survivalRiskLevel: "",
      socialRiskLevel: "",
      violenceGetaway: "",
      inhouseWeapons: "",
      submittedDate: "",
      trustees: "",
      tomorrowsDate: "",
      safetyServiceQA: {},
      errors: {},
      error: "",
      isLoading: false
    };
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: value => value < this.tomorrowsDate,
      },
      riskFactors: {
        required
      },
      survivalRiskLevel: {
        required
      },
      socialRiskLevel: {
        required
      },
    }
  },
  methods: {
    ...mapActions(['fetchAllServices']),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getSafetyServiceQA() {
        this.isLoading = true;
        SafetyServiceService.getQaData()
        .then((response) => {
          this.safetyServiceQA = JSON.parse(JSON.stringify(response.data));
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    catchErrors() {
      this.errors = [];
       if(!this.safteyPlanDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
      if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last cousnelling date and less than tomorrow.");
      }
      if(!this.riskFactors) {
        this.errors.push("Risk Factors Field is Required")
      }
      if(!this.socialRiskLevel) {
        this.errors.push("Social Risk Factors Field is Required")
      }
      if(!this.survivalRiskLevel) {
        this.errors.push("Survival Risk Factors Field is Required")
      }
      this.handleArrayErrors(this.errors)
    },
    safteyPlanDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.safteyPlanDateExists() && this.dateHasError();
    },
    getIfBeneHasPreviousSafetyPlan() {
      SafetyServiceService.getLatestSafetyService({
        gbvmis: this.gbvmis,
        number: 1,
        serviceType: 'safety'
      }).then((response) => {
        if(!!response.data.length) {
          this.latestSafetyService = response.data[response.data.length - 1];
          this.populateEditService(this.latestSafetyService);
        }
      }).finally(() => {
         setTimeout(() => {
            if(this.latestSafetyService) {
              let latestSafetyServiceDate = moment(this.latestSafetyService.submittedDate).format('MM/DD/YYYY');
              this.informUser({
                duration: 7000,
                state: "success", 
                title: "Previously submitted plan exists",
                content: "Saftey Plan has been filled from the previously submitted plan on " + latestSafetyServiceDate,
              });
            }
         },500)
      })
    },
    async populateEditService(latestSafetyService) {
      if(!!latestSafetyService) {
        this.submittedDate = moment(new Date(latestSafetyService.submittedDate)).format("YYYY-MM-DD");
        this.childrenDependency = latestSafetyService.childrenDependency;
        this.emergencyGetaway = latestSafetyService.emergencyGetaway;
        this.inhouseWeapons = latestSafetyService.inhouseWeapons;
        this.riskFactors = latestSafetyService.riskFactors;
        this.survivalRiskLevel = latestSafetyService.survivalRiskLevel;
        this.socialRiskLevel = latestSafetyService.socialRiskLevel;
        this.violenceGetaway = latestSafetyService.violenceGetaway;
        this.trustees = latestSafetyService.trustees;  
      } else if(!!this.editService) {
        this.submittedDate = moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD");
        this.childrenDependency = this.editService.childrenDependency;
        this.emergencyGetaway = this.editService.emergencyGetaway;
        this.inhouseWeapons = this.editService.inhouseWeapons;
        this.riskFactors = this.editService.riskFactors;
        this.survivalRiskLevel = this.editService.survivalRiskLevel;
        this.socialRiskLevel = this.editService.socialRiskLevel;
        this.violenceGetaway = this.editService.violenceGetaway;
        this.trustees = this.editService.trustees;
      }
      
    },
    addSafetyService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
        this.isLoading = true;
        const newSafetyService = this.getNewSafetyService();
        SafetyServiceService.addNewSafetyService(newSafetyService)
          .then(() => this.$router.push({ path: `/bene/${this.gbvmis}` }))
          .catch((error) => console.log(error))
          .finally(() => (this.isLoading = false));
      }
    },
    updateSafetyService() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.catchErrors();
      } else {
      this.isLoading = true;
      SafetyServiceService.updateSafetyService(this.getNewSafetyService())
        .then(() => {
          this.$router.push({
            path: `/bene/${this.$route.params.id}/management`,
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
      }
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestCounselingDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestCounselingDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },

    getNewSafetyService() {
      let newSafetyService = {};
      if (!!this.editService) newSafetyService.id = this.editService.id;
      newSafetyService.gbvmis = this.gbvmis;
      newSafetyService.childrenDependency = this.childrenDependency;
      newSafetyService.emergencyGetaway = this.emergencyGetaway;
      newSafetyService.inhouseWeapons = this.inhouseWeapons;
      newSafetyService.riskFactors = this.riskFactors;
      newSafetyService.trustees = this.trustees;
      newSafetyService.survivalRiskLevel = this.survivalRiskLevel;
      newSafetyService.socialRiskLevel = this.socialRiskLevel;
      newSafetyService.violenceGetaway = this.violenceGetaway;
      newSafetyService.inhouseWeapons = this.inhouseWeapons;
      newSafetyService.submittedDate = new Date(this.submittedDate).toJSON();
      newSafetyService.serviceType = "safety";

      return newSafetyService;
    },
    handleAddingOrUpdaingSafetyService() {
      if(!this.editService && !this.latestSafetyService) {
        this.addSafetyService();
      }
      if(!!this.editService && !this.latestSafetyService) {
        this.updateSafetyService();
      }
      if(!!this.latestSafetyService && !this.editService) {        
        this.updateServiceAndCreateANewDbEntry(this.latestSafetyService);
      }
    },
    updateServiceAndCreateANewDbEntry(data) {
      let newDataObject = this.deleteDataBeforeUpdating(data);
      this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
      this.isLoading = true;
      SafetyServiceService.addNewSafetyService(newDataObject)
        .then(() => {
          this.$router.push({
            path: `/bene/${this.$route.params.id}`,
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
      }
    },
    deleteDataBeforeUpdating(serviceData) {
        delete serviceData.id;
        delete serviceData.createdBy;
        delete serviceData.createdByCenter;
        delete serviceData.updatedByLast;

        serviceData.gbvmis = this.gbvmis;
        serviceData.childrenDependency = this.childrenDependency;
        serviceData.emergencyGetaway = this.emergencyGetaway;
        serviceData.inhouseWeapons = this.inhouseWeapons;
        serviceData.riskFactors = this.riskFactors;
        serviceData.trustees = this.trustees;
        serviceData.survivalRiskLevel = this.survivalRiskLevel;
        serviceData.socialRiskLevel = this.socialRiskLevel;
        serviceData.violenceGetaway = this.violenceGetaway;
        serviceData.inhouseWeapons = this.inhouseWeapons;
        serviceData.submittedDate = new Date(this.submittedDate).toJSON();
        serviceData.serviceType = "safety";
        return serviceData;
    }
  },
  computed: {
    ...mapGetters(['allServices']),
    socialRiskLevelQuestion() {
      return this.safetyServiceQA.socialRiskLevel.question[0]
    },
    survivalRiskLevelQuestion() {
      return this.safetyServiceQA.survivalRiskLevel.question[0];
    },
    childrenDependencyQuestion() {
      return this.safetyServiceQA.childrenDependency.question[0];
    },
    violenceGetawayQuestion() {
      return this.safetyServiceQA.violenceGetaway.question[0];
    },
    emergencyGetawayQuestion() {
      return this.safetyServiceQA.emergencyGetaway.question[0];
    },
    inhouseWeaponsQuestion() {
      return this.safetyServiceQA.inhouseWeapons.question[0];
    },
    trusteesQuestion() {
      return this.safetyServiceQA.trustees.question[0];
    },
    placesToGoQuestion() {
      return this.safetyServiceQA.placesToGo.question[0];
    }
  }
};
</script>
