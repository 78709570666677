<template>
  <ab-content-container>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#2399A4"
    ></pulse-loader>
    <div v-else>
      <div>
          <p class="font-semibold text-xl">
            Financial Service - PCAP Feedback
          </p>
        <form
          class="flex flex-col mx-auto max-w-2xl"
          @submit.prevent="!editService ? addPcapFeedbackService() : updatePcapFeedbackService()">
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">Submit Date</p>
            <ab-input v-model="submittedDate"
                      :full="true"
                      type="date"></ab-input>
          </div>
    
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ feedbackQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in feedbackAnswers"
              :key="index"
              v-model="feedback"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">{{ feedbackDateQuestion }}</p>
            <ab-input
              v-model="feedbackDate"
              :full="true"
              type="date"
            ></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ notesQuestion }}</p>
            <textarea
              v-model="notes"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>
          <div class="mt-12">
            <div v-if="!isLoading" class="flex flex-col justify-center items-center">
              <ab-button class="mt-2" label="submit"
                         @click="!editService ? addPcapFeedbackService() : updatePcapFeedbackService()"></ab-button>
              <ab-button :cancel="true" class="mt-2" label="cancel" @click="routeToNavigation()"></ab-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ab-content-container>
</template>
<script>
import PcapFeedbackServiceService from "./PcapFeedbackServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { methodsMixin } from "@/shared/financial/mixins/methodsMixin";
import { mapActions, mapGetters } from 'vuex';
import moment from "moment";
import vue from "vue";
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-financial-pcapfeedback-service",
  mixins: [utilMixin, methodsMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getPcapFeedbackServiceQA();
    this.gbvmis = this.$route.params.id;
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 
  },
  data() {
    return {
      gbvmis: "",
      tomorrowsDate: "",
      feedback: "",
      feedbackDate: null,
      notes: "",
      submittedDate: "",
      pcapFeedbackServiceQA: {},
      errors: {},
      error: "",
      isLoading: false,
      lastSubmittedPcap: {}, 
      latestCounselingServiceDate: ""
    }
  },
  validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: (value) => value < this.tomorrowsDate,
      },
    }
  },
  methods: {
    ...mapActions(["fetchAllServices"]),
    async getAllServices() {
      await this.fetchAllServices(this.gbvmis);
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getPcapFeedbackServiceQA() {
      this.isLoading = true;
      PcapFeedbackServiceService.getQaData()
        .then((response) => {
          this.pcapFeedbackServiceQA = JSON.parse(
            JSON.stringify(response.data)
          );
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = !!this.editService.submittedDate
        ? moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD")
        : null;
      this.feedbackDate = !!this.editService.feedbackDate
        ? moment(new Date(this.editService.feedbackDate)).format("YYYY-MM-DD")
        : null;
      this.feedback = this.editService.feedback;
      this.notes = this.editService.notes;
    },
    addPcapFeedbackService() {
      if(!!this.$v) {
          this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
            this.isLoading = true;
            const newPcapFeedbackService = this.getNewPcapFeedbackService();
            PcapFeedbackServiceService.addNewPcapFeedbackService(newPcapFeedbackService)
              .then(() => this.$router.push({path: `/bene/${this.gbvmis}`}))
              .catch(error => console.log(error))
              .finally(() => this.isLoading = false);
        }
      }
    },
    updatePcapFeedbackService() {
      if(!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          PcapFeedbackServiceService.updatePcapFeedbackService(this.getNewPcapFeedbackService())
            .then(() => {
              this.$router.push({path: `/bene/${this.$route.params.id}/management`});
            })
            .catch(error => console.log(error))
            .finally(() => this.isLoading = false);
        }
      }
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    catchErrors() {
      this.errors = [];
      
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
      if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last Pcap Submission date and less than tomorrow.");
      }
      this.handleArrayErrors(this.errors)
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestPcapDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestPcapDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    getNewPcapFeedbackService() {
      let newPcapFeedbackService = {};
      if (!!this.editService) newPcapFeedbackService.id = this.editService.id;
      newPcapFeedbackService.gbvmis = this.gbvmis;

      newPcapFeedbackService.feedbackDate = new Date(
        this.feedbackDate
      ).toJSON();
      newPcapFeedbackService.feedback = this.feedback;
      newPcapFeedbackService.notes = this.notes;

      newPcapFeedbackService.submittedDate = new Date(
        this.submittedDate
      ).toJSON();
      newPcapFeedbackService.serviceType = "pcap feedback";

      return newPcapFeedbackService;
    }
  },
  computed: {
    ...mapGetters(['allServices']),
    // QA
    feedbackQuestion() {
      return this.pcapFeedbackServiceQA.feedback.question[0];
    },
    feedbackAnswers() {
      return this.pcapFeedbackServiceQA.feedback.answers;
    },
    feedbackDateQuestion() {
      return this.pcapFeedbackServiceQA.feedbackDate.question[0];
    },
    feedbackDateAnswers() {
      return this.pcapFeedbackServiceQA.feedbackDate.answers;
    },
    notesQuestion() {
      return this.pcapFeedbackServiceQA.notes.question[0];
    },
    notesAnswers() {
      return this.pcapFeedbackServiceQA.notes.answers;
    },
  },
};
</script>
