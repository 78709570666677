<template>
  <ab-content-container>
    <pulse-loader
      v-if="isLoading"
      :loading="isLoading"
      class="loader"
      color="#2399A4"
    ></pulse-loader>
    <div v-else>
      <div>
        <p class="font-semibold text-xl">Financial Service - PCAP Followup</p>
        <form
          class="flex flex-col mx-auto max-w-2xl"
          @submit.prevent="!editService? addPcapFollowupService(): updatePcapFollowupService()">
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">Submit Date</p>
            <ab-input v-model="submittedDate"
              :full="true"
              type="date"></ab-input>
          </div>
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">{{ followupDateQuestion }}</p>
            <ab-input
              v-model="followupDate"
              :full="true"
              type="date"
            ></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ categoryQuestion }}</p>
            <ab-radio
              v-for="(answer, index) in categoryAnswers"
              :key="index"
              v-model="category"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <!-- Select Multiple -->
          <div class="mt-12">
            <p class="font-bold text-sm mb-1">{{ typeQuestion }}</p>
            <div
              v-for="(singleType, index) in typeAnswers"
              :key="index"
              class="mt-4"
            >
              <label
                :for="singleType"
                class="flex items-center text-sm font-light"
              >
                <input v-model="type" :value="singleType" type="checkbox" />
                <span class="ml-2">{{ singleType }}</span>
              </label>
            </div>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">
              {{ reassessmentNeededQuestion }}
            </p>
            <ab-radio
              v-for="(answer, index) in reassessmentNeededAnswers"
              :key="index"
              v-model="reassessmentNeeded"
              :label="answer"
              :value="answer"
              class="mb-2"
            ></ab-radio>
          </div>
          <div class="mt-6">
            <p class="text-sm font-semibold mb-1">
              {{ reassessmentDateQuestion }}
            </p>
            <ab-input
              v-model="reassessmentDate"
              :full="true"
              type="date"
            ></ab-input>
          </div>
          <div class="mt-12">
            <p class="font-semibold text-sm mb-1">{{ notesQuestion }}</p>
            <textarea
              v-model="notes"
              class="
                w-full
                h-24
                border border-solid border-secondary
                rounded-sm
              "
            ></textarea>
          </div>

          <div class="mt-12">
            <div v-if="!isLoading" class="flex flex-col justify-center items-center">
              <ab-button class="mt-2" label="submit"
                         @click="!editService ? addPcapFollowupService() : updatePcapFollowupService()">
              </ab-button>
              <ab-button :cancel="true" class="mt-2" label="cancel" @click="routeToNavigation()"></ab-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ab-content-container>
</template>
<script>
import PcapFollowupServiceService from "./PcapFollowupServiceService";
import AbInput from "@/components/abaad/AbInput/AbInput";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbRadio from "@/components/abaad/AbRadio/AbRadio";
import vue from "vue";
import moment from "moment";
import { utilMixin } from "@/mixins/forms/utilMixin";
import { methodsMixin } from "@/shared/financial/mixins/methodsMixin";
import { mapActions, mapGetters } from 'vuex';
import { toasterMixin } from "@/components/abaad/AbToaster/toaster.js";
import { required, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators';

vue.use('required', required);
vue.use('minValue', minValue);
vue.use('maxValue', maxValue);
vue.use('requiredIf', requiredIf);

export default {
  name: "create-financial-pcapfollowup-service",
  mixins: [utilMixin, methodsMixin, toasterMixin],
  components: {
    AbInput,
    AbContentContainer,
    AbButton,
    AbRadio,
    PulseLoader,
  },
  props: {
    editService: {
      type: Object,
      required: false,
    },
  },
  created() {
    window.scrollTo(0,0);
    this.getPcapFollowupServiceQA();
    this.gbvmis = this.$route.params.id;
    if(!this.allServices.length) {
      this.getAllServices().then(() => {
        let latestCounseling =  this.allServices.counseling[this.allServices.counseling.length - 1];
        this.latestCounselingServiceDate =  moment(latestCounseling.submittedDate).format('YYYY-MM-DD');
      })
    }
    this.tomorrowsDate = moment().add(1, "days").format("YYYY-MM-DD"); 

  },
  data() {
    return {
      gbvmis: "",
      followupDate: null,
      category: "",
      type: [],
      reassessmentNeeded: false,
      reassessmentDate: null,
      notes: "",
      submittedDate: "",
      pcapFollowupServiceQA: {},
      errors: [],
      error: "",
      isLoading: false,
      latestCounselingServiceDate: "",
      tomorrowsDate: "",
    }
  },
   validations() {
    return {
      submittedDate: {
        required,
        minValue: value => value >= this.latestCounselingServiceDate,
        maxValue: (value) => value < this.tomorrowsDate,
      },
    }
  },
  methods: {
    ...mapActions(["fetchAllServices"]),
    async getAllServices() {
      this.fetchAllServices(this.gbvmis)
    },
    routeToNavigation() {
      if (this.editService) {
        return this.$router.push({
          path: `/bene/${this.$route.params.id}/management`,
        });
      }
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getPcapFollowupServiceQA() {
      this.isLoading = true;
      PcapFollowupServiceService.getQaData()
        .then((response) => {
          this.pcapFollowupServiceQA = JSON.parse(
            JSON.stringify(response.data)
          );
          if (!!this.editService) {
            this.populateEditService();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    async populateEditService() {
      this.submittedDate = !!this.editService.submittedDate ? moment(new Date(this.editService.submittedDate)).format("YYYY-MM-DD") : null;
      this.followupDate = !!this.editService.followupDate ? moment(new Date(this.editService.followupDate)).format("YYYY-MM-DD") : null;
      this.category = this.editService.category;
      this.type = this.editService.type;
      this.reassessmentNeeded = this.editService.reassessmentNeeded
        ? "Yes"
        : "No";
      this.reassessmentDate = !!this.editService.reassessmentDate
        ? moment(new Date(this.editService.reassessmentDate)).format(
            "YYYY-MM-DD"
          )
        : null;
      this.notes = this.editService.notes;
    },
    addPcapFollowupService() {
      if(!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          const newPcapFollowupService = this.getNewPcapFollowupService();
          PcapFollowupServiceService.addNewPcapFollowupService(newPcapFollowupService)
            .then(() => this.$router.push({path: `/bene/${this.gbvmis}`}))
            .catch(error => console.log(error))
            .finally(() => this.isLoading = false);
        } 
      }
    },
    submitDateExists() {
      return !!this.submittedDate;
    },
    submitDateHasError() {
      return this.submitDateExists() && this.dateHasError();
    },
    catchErrors() {
      this.errors = [];
      
      if(!this.submitDateExists()) {
        this.errors.push("Submitted Date is Required.");
      }
      if(this.submitDateHasError()) {
        this.errors.push("Submitted Date must be greater than last Pcap Submission date and less than tomorrow.");
      }
      this.handleArrayErrors(this.errors)
    },
    updatePcapFollowupService() {
      if(!!this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.catchErrors();
        } else {
          this.isLoading = true;
          PcapFollowupServiceService.updatePcapFollowupService(this.getNewPcapFollowupService())
              .then(() => {
                this.$router.push({path: `/bene/${this.$route.params.id}/management`});
              })
              .catch(error => console.log(error))
              .finally(() => this.isLoading = false);
        }
      }
    },
    getNewPcapFollowupService() {
      let newPcapFollowupService = {};
      if (!!this.editService) newPcapFollowupService.id = this.editService.id;
      newPcapFollowupService.gbvmis = this.gbvmis;

      newPcapFollowupService.followupDate = new Date(
        this.followupDate
      ).toJSON();
      newPcapFollowupService.category = this.category;
      newPcapFollowupService.type = this.type;
      newPcapFollowupService.reassessmentNeeded =
        !!this.reassessmentNeeded &&
        this.reassessmentNeeded.toLowerCase().includes("yes");
      newPcapFollowupService.reassessmentDate = new Date(
        this.reassessmentDate
      ).toJSON();
      newPcapFollowupService.notes = this.notes;

      newPcapFollowupService.submittedDate = new Date(
        this.submittedDate
      ).toJSON();
      newPcapFollowupService.serviceType = "pcap followup";

      return newPcapFollowupService;
    },
    dateHasError() {
      var formattedUserInput = moment(this.submittedDate).format("YYYY-MM-DD");
      var userInputLessThanTomorrowDate = moment(formattedUserInput).isBefore(this.tomorrowsDate);
      var userInputGreaterThanLatestPcapDate = moment(formattedUserInput).isSameOrAfter(this.latestCounselingServiceDate);

      let dateHasValidationError;
      if (userInputLessThanTomorrowDate && userInputGreaterThanLatestPcapDate) {
        dateHasValidationError = false;
      } else {
        dateHasValidationError = true;
      }

      return dateHasValidationError
    },
    getTomorrowsDate() {
      return moment().add(1, "days").format("YYYY-MM-DD");
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters(['allServices']),
    // QA
    followupDateQuestion() {
      return this.pcapFollowupServiceQA.followupDate.question[0];
    },
    followupDateAnswers() {
      return this.pcapFollowupServiceQA.followupDate.answers;
    },
    categoryQuestion() {
      return this.pcapFollowupServiceQA.category.question[0];
    },
    categoryAnswers() {
      return this.pcapFollowupServiceQA.category.answers;
    },
    typeQuestion() {
      return this.pcapFollowupServiceQA.type.question[0];
    },
    typeAnswers() {
      return this.pcapFollowupServiceQA.type.answers;
    },
    reassessmentNeededQuestion() {
      return this.pcapFollowupServiceQA.reassessmentNeeded.question[0];
    },
    reassessmentNeededAnswers() {
      return this.pcapFollowupServiceQA.reassessmentNeeded.answers;
    },
    reassessmentDateQuestion() {
      return this.pcapFollowupServiceQA.reassessmentDate.question[0];
    },
    reassessmentDateAnswers() {
      return this.pcapFollowupServiceQA.reassessmentDate.answers;
    },
    notesQuestion() {
      return this.pcapFollowupServiceQA.notes.question[0];
    },
    notesAnswers() {
      return this.pcapFollowupServiceQA.notes.answers;
    },
  },
};
</script>
