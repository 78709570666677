import _ from "lodash";
// import moment from "moment";
export const searchMixin = {
  computed: {
    // filteredBenis() {

    //   if(this.search.includes(" ")) {
    //     return this.allBenis;
    //   }
    //   if (!!this.search) {
    //     return this.allBenis.filter((beni) => {
    //       let beniBirthDate = moment(beni.dateBirth).format("DD MMM YYYY");
    //       return (
    //         beni.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.gbvmis.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.gender.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.phoneNumber.includes(this.search) ||
    //         beni.dateBirth.includes(this.search.toLowerCase())  ||
    //         beni.maritalStatus.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.unhcrNumber.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.placeBirth.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.motherName.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.countryOrigin.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.id.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.createdBy.name.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beniBirthDate.toLowerCase().includes(this.search.toLowerCase())
    //       )
    //     });
    //   }
      
    // },
    // filteredActivityBenis() {
    //   if(this.search.includes(" ")) {
    //     return this.allBenis;
    //   }
    //   if (!!this.search) {
    //     return this.allBenis.filter((beni) => {
    //       let beniBirthDate = moment(beni.dateBirth).format("DD MMM YYYY");
    //       return (
    //         beni.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.gbvmis.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.gender.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.phoneNumber.includes(this.search) ||
    //         beni.dateBirth.includes(this.search.toLowerCase())  ||
    //         beni.maritalStatus.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.unhcrNumber.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.placeBirth.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.motherName.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.countryOrigin.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.id.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beni.createdBy.name.toLowerCase().includes(this.search.toLowerCase()) ||
    //         beniBirthDate.toLowerCase().includes(this.search.toLowerCase())
    //       )
    //     });
    //   }
      
    // },
    // slicedFilteredBenis() {
    //   return this.filteredBenis.slice(0, this.sliceRange);
    // },
  },
  methods: {
    expandBenis() {
      this.sliceRange += 10;
      window.scrollTo(0,document.body.scrollHeight);
    },
    sortBySubmitDate(benis) {
      return _.sortBy(benis, ["dateBirth"]);
    }
  }
};
