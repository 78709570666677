<template>
  <div class="fit-content">
    <label class="flex items-center" :for="label">
      <input type="checkbox"
             @input="change()"
             :disabled="disabled"
             :checked="checked"
             :id="label" />
      <span class="ml-2 text-xs text-gray-800">{{label}}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "ab-checkbox",
  data() {
    return {
      checked: false,
    }
  },
  props: {
    label: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    value: {

    }
  },
  mounted() {
    this.checked = this.value;
  },
  methods: {
    change() {
      this.checked = !this.checked;
      this.$emit("input", this.checked);
    }
  }
}
</script>
