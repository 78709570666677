<template>
  <div>
    <StatisfactionForm
      :activity="activity"
      :form-model="'Awareness'"
      :editService="$route.params.editService"
    />
  </div>
</template>
<script>
import StatisfactionForm from "./StatisfactionForm/StatisfactionForm.vue";
export default {
  name: "AwarenessSatisfactionForm",
  components: {
    StatisfactionForm,
  },
  props: {
    activity: {
      type: Object,
      required: false,
    },
    editService: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    window.scroll(0, 0);
  },
};
</script>
