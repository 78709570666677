import axios from "@/http/http-common";
import Vue from "vue";

class PsychologistDataService {
  async getQaData() {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/forms/formoptions/psychologistservice", {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
  async addNewPsychologistService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/create/psychologist", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  async updatePsychService(data) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.post("/services/update/psychologist", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getLastServiceByType(gbvmis, serviceType) {
    const accessToken = await new Vue().$auth.getTokenSilently();
    return axios.get("/services/read/latest", {
      params: {
        gbvmis: gbvmis,
        number: 1,
        serviceType: serviceType
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
  }
}
export default new PsychologistDataService();
