<template>
  <ab-content-container>
    <pulse-loader v-if="isLoading" :loading="isLoading" class="loader" color="#2399A4"></pulse-loader>
    <div v-else>
      <div class="col-span-3">
        <p class="text-xl font-semibold">
          Family Composition: <span>{{ gbvmis }}</span>
        </p>
      </div>
      <div class="flex justify-center mt-8">
        <!-- <table class="shadow-lg">
          <thead>
            <th class="border thead text-left font-medium">Name</th>
            <th class="border thead text-left font-medium">Relation to PA</th>
            <th class="border thead text-left font-medium">Date of Birth</th>
            <th class="border thead text-left font-medium">Education Status</th>
            <th class="border thead text-left font-medium">Health Status</th>
            <th class="border thead text-left font-medium">Mental Status</th>
            <th class="border thead text-left font-medium">
              Dependant child to survivor
            </th>
          </thead>
          <tbody></tbody>
          <single-family-member
            v-for="mem in familyComp"
            :key="mem.id"
            :familyMember="mem"
            @updateFamilyComp="updateFamilyComposition"
          ></single-family-member>
          <single-family-member
            @updateFamilyComp="updateFamilyComposition"
            :family-member="newMember"
            :is-new="true"
            :gbvmis="gbvmis"
          ></single-family-member>
        </table>
         -->
        <b-table-simple
          class="shadow-lg bg-white table b-table-stacked-sm"
          hover
          :responsive="true"
          sort-icon-right
          stacked="lg"
          :bordered="true"
          :fields="beniFamCompColumns">
          <b-thead>
             <th class="border thead text-left font-medium">Name</th>
            <th class="border thead text-left font-medium">Relation to PA</th>
            <th class="border thead text-left font-medium">Date of Birth</th>
            <th class="border thead text-left font-medium">Education Status</th>
            <th class="border thead text-left font-medium">Health Status</th>
            <th class="border thead text-left font-medium">Mental Status</th>
            <th class="border thead text-left font-medium">Dependant</th>
          </b-thead>
             <b-tbody>
                <single-family-member
                v-for="mem in familyComp"
                :key="mem.id"
                :familyMember="mem"
                @updateFamilyComp="updateFamilyComposition">
              </single-family-member>

              <single-family-member
                @updateFamilyComp="updateFamilyComposition"
                :family-member="newMember"
                :is-new="true"
                :gbvmis="gbvmis">
              </single-family-member>
             </b-tbody>
          </b-table-simple>


      </div>
      <ab-not-found v-if="!familyComp.length && !isLoading"></ab-not-found>
    </div>
    <div class="mt-8">
      <div class="flex justify-center items-center" v-if="!isLoading">
        <ab-button
          @click="routeToNavigation()"
          :cancel="true"
          label="Back"
        ></ab-button>
      </div>
    </div>
    <footer class="footer">
      <img
        class="footer-img"
        src="@/assets/images/form-footer.jpeg"
        alt="form-footer"
      />
    </footer>
  </ab-content-container>
</template>

<script>
import AbButton from "@/components/abaad/AbButton/AbButton";
import AbNotFound from "@/components/abaad/AbNotFound/AbNotFound";
import SingleFamilyMember from "./SingleFamilyMember";
import FamilyCompService from "./FamilyCompService";
import PulseLoader from "vue-spinner/src/PulseLoader";
import AbContentContainer from "@/components/abaad/AbContentContainer/AbContentContainer";

export default {
  name: "FamilyComp",
  components: {
    AbNotFound,
    AbButton,
    SingleFamilyMember,
    PulseLoader,
    AbContentContainer
  },
  created() {
    this.gbvmis = this.$route.params.id;
    this.getFamilyComp();
    this.setTableHeader();
  },
  data() {
    return {
      gbvmis: "",
      familyComp: [],
      newMember: {},
      beniFamCompColumns: [],
      errors: {},
      error: "",
      isLoading: false,
    };
  },
  methods: {
    routeToNavigation() {
      return this.$router.push({ path: `/bene/${this.$route.params.id}` });
    },
    getFamilyComp() {
      this.isLoading = true;
      FamilyCompService.getFamilyComp(this.gbvmis)
        .then((response) => {
          this.familyComp = JSON.parse(JSON.stringify(response.data));
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    updateFamilyComposition(familyComposition) {
      this.familyComp = familyComposition;
    },
    setTableHeader() {
      this.beniFamCompColumns = [
          {
            key: "name",
            label: "Name",
          },
          {
            key: "relationToPar",
            label: "Relation To Parent"
          },
          {
            key: "birthDate",
            label: "Date Of Birth"
          },
          {
            key: "healthStatus",
            label: "Health Status"
          },
          {
            key: "mentalStatus",
            label: "Mental Status"
          },
          {
            key: "dependant",
            label: "Dependant child to survivor"
          },
          {
            key: "addRemoveFamilyMember",
            label: "Add/Remove Family Member",
          }
        ]
      }
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 180px;
}
.thead {
  padding: 0.5rem;
  font-size: 16px;
  
}
thead.border.thead {
  font-size: 16px;
}
</style>
